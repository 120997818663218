import { defineStore} from 'pinia';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
// import commonObj from '@/contracts/commonContract.js';

import { CATTLE_BADGE_COUNT } from '@/contracts/polygonAddress.js';
// Bronze:青铜
// Silver:白银
// Gold: 黄金徽章
// Platinum: 铂金徽章
// Diamond: 钻石徽章
// Master: 荣耀徽章
export const badgeType = [
  {
    id: '',
    cardKey: 'cattleBadge',
    name: 'card.38',
    cardType: 'Bronze',
    mataName: '',
    image: '',
    desc: 'card.38'
  },
  {
    id: '',
    cardKey: 'cattleBadge',
    name: 'card.39',
    cardType: 'Silver',
    mataName: '',
    image: '',
    desc: 'card.39'
  },
  {
    id: '',
    cardKey: 'cattleBadge',
    name: 'card.40',
    cardType: 'Gold',
    mataName: '',
    image: '',
    desc: 'card.40'
  },
  {
    id: '',
    cardKey: 'cattleBadge',
    name: 'card.41',
    cardType: 'Platinum',
    mataName: '',
    image: '',
    desc: 'card.41'
  },
  {
    id: '',
    cardKey: 'cattleBadge',
    name: 'card.42',
    cardType: 'Diamond',
    mataName: '',
    image: '',
    desc: 'card.42'
  },
  {
    id: '',
    cardKey: 'cattleBadge',
    name: 'card.43',
    cardType: 'Master',
    mataName: '',
    image: '',
    desc: 'card.43'
  }
]
const useCattleBadgeStore = defineStore('cattleBadge', {
  state: () => ({
    contract: {}, // 合约对象
    ownerAddr: null, // 徽章卡牌地址
    tokenIds: [], // 用户的牛牛徽章卡牌 ID 列表
    cattleBadgeList: [], // 用户徽章的卡牌列表
    cattleBadgeInfo: {} // 牛牛徽章卡牌信息
  }),
  actions: {
    /**
     * 构建合约对象
     */
    createContract() {
      const appStore = useAppStore();
      const { ethers, signer } = appStore.ethObj;
      this.contract = new ethers.Contract(CATTLE_BADGE_COUNT.address, CATTLE_BADGE_COUNT.abi, toRaw(signer));
    },
    /**
     * 获取用户徽章卡牌列表
     * @param {Address} addr 
     * @returns  {Number[]} id数组
     */
    async getCattleBadgeList(addr){
      // console.log('getCattleBadgeList contract>>',this.contract)
      const resp = await this.contract.checkUserBadgeIDList(addr);
      // console.log('resp',resp)
      this.tokenIds = resp ?resp.map(it => +it):[];
      let idsTotal = this.computedAIdsSameChild(this.tokenIds);
      // console.log('idsTotal',idsTotal)
      let list = [];
      await Promise.all(
        Object.keys(idsTotal).map(id => {
          return this.contract.badgeInfo(id);
        })
      ).then(items => {
        items.forEach(it1=>{
          let item = badgeType.find(it => it.cardType === it1[0]);
          list.push({...item,id: it1[3],length: idsTotal[it1[3]]})
        })
        // console.log('dealCattleBadgeList ---list',list)
        this.cattleBadgeList = [...list]
      })
      return this.tokenIds
    },
    // 处理相同数量徽章
    computedAIdsSameChild(list){
      let obj = {};
      list.forEach(it => {
        if(!!obj[it]){
          obj[it] = obj[it]+1;
        }else{
          obj[it] = 1;
        }
      })
      return obj;
    },
    async getCattleInfo(id){
      let info = await this.contract.badgeInfo(id);
      let item = badgeType.find(it => it.cardType === info[0]);
      this.cattleBadgeInfo = {...item,id}
      return item;
    },
    /**
     * 获取当前用户是否有这个徽章
     * @param {Number} id
     * @returns
     */
    async getOwnerHasBadge(id) {
      try {
        const appStore = useAppStore();
        const resp = await this.contract.checkUserBadgeIDList(appStore.defaultAccount);
        let list = resp.map(it => +it) || [];
        return list.includes(Number(id));
      } catch (error) {
        return false
      }
    },
  }
})

export default useCattleBadgeStore
