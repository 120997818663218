import { defineStore } from 'pinia';
import blindBox from '../contracts/blindBox';
import boxObj from '@/contracts/openBox';
import { useAppStore } from '@store/appStore';
import haloBoxObj from '@/contractsApi/polygon/HaloMintContract';
import itemsObj from '@/contractsApi/polygon/ItemVoucher';
import {
  BLIND_BOX_CONT as POLYGON_BLIND_BOX_CONT,
  IGO_BLIND_BOX_CONT,
  BINANCE_BOX_CONT,
  OPEN_BINANCE_BOX_CONT,
  BV_BLIND_BOX_CONT,
  OPEN_IGO_BOX_CONT,
  HALO_BLIND_BOX_CONT,
  IGO_BOX_EXCHANGE_CONT,
  FRAGMENT_CONT
} from '@/contracts/polygonAddress.js';
import { CHAINID_HEX,CHAINID } from '@/constants';

const useBlindBoxStore = defineStore('blindBoxStore', {
  state: () => ({
    obj: {}, // 合约对象
    ownerAddr: null,
    totalNum: 0, // 用户拥有的盲盒總數
    tokenIdArr: [], // 用户的盲盒卡牌 tokenID 列表
    blindBoxList: [], // 官网盲盒列表
    blindBoxInfo: {
      id: 1,
      name: 'card.1',
      tokenId: '',
      image: 'https://game.legendranch.app/legendaryranch/boxgif/box.png',
      desc: 'card.33'
    }
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createBlindBoxObj(addressObj) {
      this.obj = new blindBox(addressObj);
    },

    /**
     * 获取所有者地址
     * @param {Number} id
     * @returns
     */
    async getOwnerAddr(id) {
      try {
        const { contract } = new blindBox(POLYGON_BLIND_BOX_CONT);
        let resp = await contract.ownerOf(id);
        this.ownerAddr = resp;
      } catch (error) {
        console.log(error);
      }
      return this.ownerAddr;
    },

    /**
     * 获取全部盲盒列表
     * @param {*} addr
     */
    async getAllBoxList(addr) {
      const appStore = useAppStore();
      const { ethers, signer, chainId } = appStore.ethObj;
      console.log('chainId',chainId,addr)
      let list = [];
      if ([CHAINID_HEX,CHAINID].includes(chainId)) {//CHAINID_HEX: AIA testnet/Mainnet 
        const box = new blindBox(POLYGON_BLIND_BOX_CONT);
        const p1 = box.getInsideBoxList(addr, 'blindBox');
        list = await Promise.all([p1]);
      }
      // if (chainId == '0x38') {//56 BSC Mainnet
      //   const igoBox = new blindBox(IGO_BLIND_BOX_CONT);
      //   const p2 = igoBox.getOutsideBoxList(addr, 'Mystery-Box');
      //   list = await Promise.all([p2]);
      // } else if (['0x61'].includes(chainId)) {//97: BSC Testnet
      //   const box = new blindBox(BLIND_BOX_CONT);
      //   const p1 = box.getInsideBoxList(addr, 'blindBox');
      //   const haloBox = new haloBoxObj();
      //   console.log('getAllBoxList->haloBox',haloBox)
      //   const p2 = haloBox.getBoxList();
      //   list = await Promise.all([p1]);
      // } else if (['0x89'].includes(chainId)) {// 137 Polygon Mainnet
      //   const box = new blindBox(POLYGON_BLIND_BOX_CONT);
      //   const p1 = box.getInsideBoxList(addr, 'blindBox');
      //   const haloBox = new haloBoxObj();
      //   const p2 = haloBox.getBoxList();
      //   list = await Promise.all([p1, p2]);
      // }
      console.log('this.blindBoxList ',list)
      this.blindBoxList = list.flat();
    },

    /**
     * 獲取二開盲盒
     */
    async getOtherVoucherList(addr) {
      const appStore = useAppStore();
      const { ethers, signer, chainId } = appStore.ethObj;
      console.log('獲取二開盲盒',chainId)
      let list = [];
      this.blindBoxList = [];
      if (chainId == '0x38') {
        const bvBox = new blindBox(BV_BLIND_BOX_CONT);
        const p1 = bvBox.getBoxListByTokenURI(addr);
        const binanceBox = new blindBox(BINANCE_BOX_CONT);
        const p2 = binanceBox.getBoxListByTokenURI(addr);
        list = await Promise.all([p1, p2]);
      } else if (['0x89',CHAINID_HEX].includes(chainId)) {
        const haloBox = new haloBoxObj();
        const p1 = haloBox.getTicketList();
        const itemVoucher = new itemsObj();
        const p2 = itemVoucher.checkAllIteminfo();
        const igoBox = new blindBox(IGO_BOX_EXCHANGE_CONT);
        const p3 = igoBox.getBoxListByBatchToken(addr);
        list = await Promise.all([ p3]);
      }

      this.blindBoxList = list.flat();
    },

    setBlindBoxList(list) {
      this.blindBoxList = list;
    },

    /**
     * 获取blindBoxList
     */
    async getBoxList(addr) {
      this.totalNum = await this.contract.balanceOf(addr);
      let pList = [];
      for (let i = 0; i < +this.totalNum; i++) {
        pList.push(this.contract.tokenOfOwnerByIndex(addr, i));
      }
      const list = [];
      // 构造 blindBox 数组
      await Promise.all(pList).then(values => {
        values.forEach((item, index) => {
          list.push({
            id: +item,
            name: 'card.1',
            index: index,
            cardType: 'blindBox',
            image: 'https://game.legendranch.app/legendaryranch/boxgif/box.png'
          });
        });
      });
      this.blindBoxList = list;
    },

    /**
     * 获取盲盒父母 ID
     * @param {Number} id 盲盒TokenID
     */
    async checkParents(id) {
      const box = new blindBox(POLYGON_BLIND_BOX_CONT);
      const resp = await box.contract.checkParents(id);
      return resp.map(it => Number(it));
    },

    /**
     * 获取盲盒信息
     * @param {Number} id tokenID
     * @param {String} type 盲盒类型
     * @returns
     */
    getBoxInfo(id, type) {
      const box = new blindBox();
      const itemVoucher = new itemsObj();
      if (type === 'items') {
        this.blindBoxInfo = itemVoucher.itemType.find(it => it.id == id);
      } else {
        this.blindBoxInfo = box.boxType.find(item => item.cardType === type);
      }
      console.log('blindBoxInfo...',id, type, this.blindBoxInfo);
      return this.blindBoxInfo;
    },

    /**
     * 是否授权全部
     * @param {String} owner 用户地址
     * @param {String} type 盲盒类型
     * @param {String} contractAddr 授权的合约地址
     * @returns
     */
    async isApproveAll(owner, type = 'Halo-Box', contractAddr = OPEN_IGO_BOX_CONT.address) {
      let box;
      if (type === 'blindBox') {
        // 牛牛遊戲初始盲盒
        box = new blindBox(POLYGON_BLIND_BOX_CONT);
      } else if (type === 'Mystery-Box') {
        // IGO 賣的盲盒
        box = new blindBox(IGO_BLIND_BOX_CONT);
      } else if (
        type === 'Skins-Pack' ||
        type === 'Items-Pack' ||
        type === 'Bovine-Hero' ||
        type === 'Cattle'
      ) {
        // IGO 開出來的盲盒
        box = new blindBox(BV_BLIND_BOX_CONT);
      } else if (type === 'Halo-Box') {
        // Halo Mark 的盲盒 
        box = new blindBox(HALO_BLIND_BOX_CONT);
      } else if (type === 'binance_box') {
        // 幣安賣的盲盒
        box = new blindBox(BINANCE_BOX_CONT);
      }
      // console.log('contract..', box);
      const hasAllowned = await box.contract.isApprovedForAll(owner, contractAddr);
      return hasAllowned;
    },

    /**
     * 授权全部
     * @param {String} type 盲盒类型
     * @returns
     */
    async authAll(type = 'Halo-Box', contractAddr = OPEN_IGO_BOX_CONT.address) {
      let box;
      if (type === 'blindBox') {
        // 牛牛遊戲初始盲盒
        box = new blindBox(POLYGON_BLIND_BOX_CONT);
      } else if (type === 'Mystery-Box') {
        // IGO 賣的盲盒
        box = new blindBox(IGO_BLIND_BOX_CONT);
      } else if (
        type === 'Skins-Pack' ||
        type === 'Items-Pack' ||
        type === 'Bovine-Hero' ||
        type === 'Cattle'
      ) {
        // IGO 開出來的盲盒
        box = new blindBox(BV_BLIND_BOX_CONT);
      } else if (type === 'Halo-Box') {
        // Halo Mark 的盲盒 
        box = new blindBox(HALO_BLIND_BOX_CONT);
      } else if (type === 'binance_box') {
        // 幣安賣的盲盒
        box = new blindBox(BINANCE_BOX_CONT);
      }
      const resp = await box.contract.setApprovalForAll(contractAddr, true);
      await resp.wait();
      return resp;
    },

    /**
     * 开启盲盒
     * @param {Number} id tokenID
     * @param {String} type 盲盒类型
     */
    async openBox(id, type = 'blindBox') {
      let obj;
      if (type === 'blindBox') {
        obj = new boxObj(OPEN_BOX_CONT);
      } else if (type === 'Mystery-Box') {
        // 原始 IGO 盲盒
        obj = new boxObj(OPEN_IGO_BOX_CONT);
      } else if (
        type === 'Skins-Pack' ||
        type === 'Items-Pack' ||
        type === 'Bovine-Hero' ||
        type === 'Cattle'
      ) {
        // IGO 二开盲盒
        obj = new boxObj(OPEN_IGO_BOX_CONT);
      } else if (type === 'binance_box') {
        // 开启币安盲盒
        obj = new boxObj(OPEN_BINANCE_BOX_CONT);
      }
      const resp = await obj.openBox(id);
      return resp;
    }
  },

  getters: {}
});

export default useBlindBoxStore;
