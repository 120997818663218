export default {
  header: {
    1: 'المزرعة',
    2: 'لعبة ربح عملات رقمية',
    3: 'NFT متجر',
    4: 'الحاكمية',
    5: 'التمويل الاجتماعي',
    6: 'المزيد',
    7: 'بين النجوم رانجيلاند',
    8: 'بين النجوم صندوق الرمل',
    9: 'رحلة بين النجوم',
    10: 'البدء',
    11: 'المستند',
    12: 'محفظتي',
    13: 'الصفحة الرئيسية'
  },
  home: {
    1: 'BVT شراء',
    2: 'BVG شراء',
    3: 'الكون GameFi',
    4: 'بين النجوم رانجيلاند',
    5: 'بين النجوم صندوق الرمل',
    6: 'رحلة بين النجوم',
    7: '!أول عالم موازٍ لعالم الأبقار ، مزرعة ، تربية ، عمل ، قتال',
    8: 'العالم الموازي ثلاثي الأبعاد لكوكب عالم الأبقار  ، سفينة الفضاء ، تفصيل!',
    9: 'الفضاء الفائق الموازي الثالث من عالم الأبقار ألعاب الواقع الافتراضي  ، الاستكشاف ، الاستغلال ، والمعركة!',
    10: 'إلعب الآن',
    11: 'قريبا',
    12: 'عالم العاب ربح العملات رقمية',
    13: 'التنشئة الاجتماعية',
    14: 'الحشود والتحالفات ',
    15: 'مدونة التشفير',
    16: '<p>يمكن للاعبين تشغيل شبكتهم الاجتماعية الافتراضية في عالم اللعبة</p><p>نظام اجتماعي غريب: المطابقة العشوائية للاعبين ، ومهام الأبراج المحصنة العشوائية ، ونظام العلاقة الحميمة والنمو المتبادل ، ووحدة اتصال عبر الإنترنت غنية بالميزات في الوقت الفعلي</p>',
    17: 'NFT لعبة ربح عملات رقمية',
    18: 'كوكب المنزل',
    19: 'كوكب الاتحاد',
    20: 'كوكب بايونير',
    21: 'صندوق البيض الغامض',
    22: 'ثور صغير',
    23: 'بقرة صغيرة',
    24: '<p>امتثالاً لـ ERC-721 ، يتمتع Bovine Hero بمعرف فريد وصور رمزية ، والتي يمكن تداولها بحرية في أسواق NFT ؛ </p><p> نادر العرض ؛ إجمالي المعروض من Bovine hero هو 10000 ، مع العرض الأولي 500 ؛ </p><p>يرث جميع سمات الماشية العادية. بالإضافة إلى ذلك ، تتمتع بصفات أعلى نسبيًا ، وتكاثر غير محدود ، وحياة أبدية ؛ </p><p>الشخصيات التي يُسمح لها بالسفر بحرية إلى عوالم أخرى موازية من BovineVerse في المستقبل لتحقيق الخلود.</p>',
    25: 'أمنياتنا',
    26: 'نرغب في تطوير رؤية عالمية متعددة الكواكب بفضل تقنية blockchain المتقدمة". في هذا الاقتصاد اللامركزي واللعبة والنظام الاجتماعي ، سيمتلك كل لاعب حيوانات أبقار للتواصل الاجتماعي والمغامرة والإبداع ، ويمكن للاعبين الاستمتاع تمامًا بالألعاب في هذا العالم المذهل في أي وقت وفي أي مكان.',
    27: 'شركاؤنا',
    28: 'مجموعتنا',
    29: 'شروط',
    30: 'خصوصية',
    31: 'اتصل بنا',
    32: '<p> مجتمعات DAO عبر الألعاب ذات الاهتمامات المشتركة </p> <p> الاقتصاد الذي يحكمه DAO المطبق في المجتمعات ؛ استغلال الموارد والتخصيص والتجارة على أساس التحالف </p>',
    33: '<p>استنادًا إلى منصة البث المباشر للعبة لإنشاء المحتوى وتوزيعه بموجب الويب 3.0 والامتثال لاتفاقيات الحوافز الاقتصادية اللامركزية KOL</p><p> إنشاء ومشاركة اللعب أو المشاهد أو الدعائم أو المعدات في اللعبة ؛ توزيع وعرض ومكافأة مقاطع فيديو اللعبة والبث المباشر. </p>'
  },
  farm: {
    1: 'خلفية المشروع',
    2: 'حدد الرموز المراد تثبيتها',
    3: 'قفل المبل',
    4: 'المبلغ المربوط',
    5: 'التخصيص المتبقي',
    6: 'المبلغ المكدس في هذا',
    7: 'حصص الآن',
    8: 'إفادة',
    9: 'المبلغ المكتسب',
    10: 'المبلغ الغير مكتسب',
    11: 'تم دفن كنوز لا تعد ولا تحصى تحت الأراضي الشاسعة من عالم الأبقار BovineVerse. في العام 121 من برج الثور، انتشر التعدين بشكل كبير بين مواطنين في عالم الأبقارBovineVerse. دعت Cross-Star Liberty League، وهو أحد التحالفين الرئيسيين، ويدعم عالم الأبقار BovineVerse ثلاث شركات تعدين لاستكشاف هذا الكون الواسع، وهي شركة Jefferol Glory Company (BNB)، شركة Workers Mining Union (USDT)، وشركة Worley Mining Company (FIL). يتم توزيع مكافآت التعدين اليومية البالغة 5M BVG على هذه الشركات الثلاث بنسبة 50٪ و 30٪ و 20٪ على التوالي. يتمتع اللاعبون بحرية الانضمام إلى المنظمة التي يريدونها. يتم منح كل لاعب مخصصات Staking فقط من 10 BNB و 5000 USDT و 100 FIL. جميع الإيرادات المحققة تعتمد على BVG، وهي رموز عالمية في Cross-Star Liberty League.',
    12: 'أبرز النقاط',
    13: 'الخزانة',
    14: 'بداية حد منخفض',
    15: 'عودة مرتفعة',
    16: 'ا قيود زمنية',
    17: 'ضمان آلية الانكماش',
    18: 'رموز فائدة',
    19: 'معدل APR المقدر',
    20: 'عنوان العقد',
    21: 'المنجم:',
    22: 'BVG:',
    23: 'عقد استخراج الماس ومكافأة عملات BVG قد الرسمية Certik رسميا. CertiK يجب ان سلسلة كتل',
    24: 'انقر',
    25: 'هنا',
    26: 'عرض تقارير مراجعة الحسابات.'
  },
  airdrop: {
    1: 'الرجاء إدخال عنوانك',
    2: 'بحث',
    3: 'العنوان',
    4: 'النوع',
    5: 'المبلغ',
    6: 'فعالية',
    7: 'الحالة',
    8: 'المبلغ المكتس',
    9: 'منتهية الصلاحية',
    10: 'كسب المبلغ الأن',
    11: 'تجميد',
    12: 'عُلم'
  },
  dialog: {
    1: 'تأكيد',
    4: 'إغلاق'
  },
  msg: {
    1: 'تم الاتصال بمحفظتك بنجاح',
    2: 'فشل الاتصال بالمحفظة',
    3: 'تم التوثيق بنجاح',
    4: 'فشل التوثيق',
    5: 'تم التثبيت بنجاح',
    6: ' فشل التثبيت،',
    7: 'تم الاستلام بنجاح',
    8: 'فشل الاستلام',
    9: 'تم فك الاسترداد بنجاح',
    10: 'فشل فك الاسترداد',
    11: 'تم التعديل بنجاح',
    12: 'شل التعديل',
    13: 'مبلغ غير كافي للتحويل',
    14: 'تم تقديم المعاملة ويتم التحقق من القطع،يرجى تغييرها في وقت لاحق',
    15: 'تثمين التجارب الناجحة',
    16: '',
    17: '',
    18: '',
    19: '',
    20: ''
  }
};
