import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueI18n from '@/utils/i18n.js';
import { handleThrottle } from '@/utils/tools.js';
import { createPinia } from 'pinia';
import { useAppStore } from '@store/appStore';
import useAccountStore from '@/store/accountStore';
import * as bpDirectives from '@/globalDirectives/index';
import  '@/utils/flexible.js'

// element ui
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';

// swiper ui
import SwiperCore, { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper';
SwiperCore.use([Navigation, Pagination, Autoplay, EffectCoverflow]);
import 'swiper/swiper.scss';

// vant ui
import {
  Slider,
  Lazyload,
  Popup,
  Uploader,
  Checkbox,
  CheckboxGroup,
  Step,
  Steps,
  Tab,
  Tabs,
  Empty,
  Dialog,
  ConfigProvider,
} from 'vant';
import 'vant/lib/slider/style';
import 'vant/lib/tab/style';
import 'vant/lib/tabs/style';
import 'vant/lib/popup/style';
import 'vant/lib/checkbox/style';
import 'vant/lib/uploader/style';
import 'vant/lib/checkbox-group/style';
import 'vant/lib/step/style';
import 'vant/lib/steps/style';
import 'vant/lib/dialog/style';

import '@vant/touch-emulator';

import animate from 'animate.css';
import '@css/index.scss';

/* import { mockXHR } from '@/service/mock';
if (process.env.NODE_ENV == 'development') {
  mockXHR();
} */

const pinia = createPinia();
const app = createApp(App);

app
  .use(pinia)
  .use(router)
  .use(ElementPlus)
  .use(animate)
  .use(VueI18n)
  .use(Slider)
  .use(Popup)
  .use(Uploader)
  .use(CheckboxGroup)
  .use(Checkbox)
  .use(Tab)
  .use(Tabs)
  .use(Step)
  .use(Steps)
  .use(Empty)
  .use(Dialog)
  .use(ConfigProvider)
  .use(Lazyload, {
    lazyComponent: true,
  })
  .mount('#app');

// 获取当前设备尺寸
const appStore = useAppStore();
appStore.getCurDevice();

// 获取用户信息
const accStore = useAccountStore();
accStore.getProfileInfo();

// 添加监听屏幕变化
window.onresize = handleThrottle(() => {
  appStore.getCurDevice();
});

// 全局指令
bpDirectives.numberDirective(app);

/* window.addEventListener('load', () => {
  window.alert('加载完...');
  appStore.linkWallet();
}); */
