import { defineStore } from 'pinia';
// import { ethers, signer } from '@/utils/ethers';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import commonObj from '@/contracts/commonContract.js';
import { CATTLE_CONT,CATTLE_EXCHANGE } from '@/contracts/polygonAddress.js';

const useCattleStore = defineStore('cattle', {
  state: () => ({
    contract: {}, // 合约对象
    ownerAddr: '', // 卡牌地址
    tokenIds: [], // 用户的牛牛卡牌 ID 列表
    cattleList: [], // 用户的卡牌列表
    cattleInfo: {} // 牛牛卡牌信息
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createContract() {
      const appStore = useAppStore();
      const { ethers, signer, chainId } = appStore.ethObj;
      // if (chainId == '0x61') {
      //   this.contract = new ethers.Contract(
      //     CATTLE_NFT_CONT.address,
      //     CATTLE_NFT_CONT.abi,
      //     toRaw(signer)
      //   );
      // } else if (chainId == '0x89') {
      //   this.contract = new ethers.Contract(CATTLE_CONT.address, CATTLE_CONT.abi, toRaw(signer));
      // }

      this.contract = new ethers.Contract(CATTLE_CONT.address, CATTLE_CONT.abi, toRaw(signer));
    },

    async isApprovedAll(contractAddr) {
      const appStore = useAppStore();
      const approveAll = await this.contract.isApprovedForAll(
        appStore.defaultAccount,
        contractAddr
      );
      return approveAll;
    },

    async getOwnerAddr(id) {
      try {
        let resp = await this.contract.ownerOf(id);
        this.ownerAddr = resp;
      } catch (error) {
        console.log(error);
      }
      return this.ownerAddr;
    },

    /**
     * 获取用户牛牛卡牌列表
     * @param {Address} addr 用户地址
     * @param {Boolean} isHero 是否是创世牛牛
     */
    async getCattleIds(addr, isHero = true) {
      let resp = await this.contract.checkUserCowListType(addr, isHero);
      this.tokenIds = resp ? resp.map(item => +item) : [];
      return this.tokenIds;
    },

    /**
     * 获取用户牛牛卡牌信息
     * @param {Number} id 卡牌Id
     */
    async getCattleInfo(id) {
      const { contract } = new commonObj();
      const resp = await contract.cowInfoes(id);
      const parentIds = resp.parents.map(it => +it);
      const info = {
        cls: +resp[1][0],
        parents: parentIds,
        isAdult: +resp[1][2],
        gender: +resp[0][0],
        life: +resp[0][3],
        growth: +resp[0][4],
        energy: +resp[0][2],
        attack: +resp[0][6],
        stamina: +resp[0][7],
        defense: +resp[0][8],
        milk: +resp[0][9],
        milkRate: +resp[0][10],
        deadTime: (+resp[0][12]) * 1000
      };
      // console.log('CattleInfo..', resp, info);
      this.cattleInfo = info ? info : [];
      return this.cattleInfo;
    },

    /**
     * 转账
     */
    async transfer(payload) {
      const resp = await this.contract.transferFrom(payload.from, payload.to, payload.id);
      await resp.wait();
    },

    // 查询活动剩余兑换牛牛数量
    async mintersCreation( contractAddr = CATTLE_EXCHANGE.address) {
      const resp = await this.contract.mintersCreation(contractAddr)
      const count = resp.toString();
      return parseInt(count)
    }
  },

  getters: {}
});

export default useCattleStore;
