import { defineStore } from 'pinia';
// import { ethers, signer } from '@/utils/ethers';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import { AIRDROP_CONT } from '@/contracts/polygonAddress';
import { $GET, $POST } from '@/service/require.js';

const useAirDropStore = defineStore('airdrop', {
  state: () => ({
    contract: {}, // 合约对象
    address: '', // 用戶地址
    totalNum: 10, // 总数
    page: 1, // 当前页
    // 空投列表
    airdropList: [],
    // 签名参数
    sign: {}
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createContract() {
      const appStore = useAppStore();
      const { ethers, signer, chainId } = appStore.ethObj;
      this.contract = new ethers.Contract(AIRDROP_CONT.address, AIRDROP_CONT.abi, toRaw(signer));
    },
    //  空托是v2 版本功能,暂且屏蔽
    /**
     * 获取当前地址空投列表
     * @param {Boolean} isDefault 是否用默认参数请求
     * @param {Object} data 请求参数: 分页、排序方式、用戶地址
     */
    async fetchAirDropList(isDefault, data) {
      let resp;
      if (isDefault) {
        try {
          resp = await $GET('/airdrop/list', '', true);
        } catch (error) {
          console.log(resp, error);
        }
        this.airdropList = resp?.list ? resp.list : null;
        this.totalNum = resp?.total ? resp.total : 1;
        this.page = resp?.page ? resp.page : 1;
        console.log('默认空投列表', resp);
        return;
      }

      try {
        resp = await $GET('/airdrop/list', { ...data }, true);
      } catch (error) {
        console.log(resp, error);
      }
      this.airdropList = resp?.list ? resp.list : null;
      this.totalNum = resp?.total ? resp.total : 1;
      this.page = resp?.page ? resp.page : 1;
      console.log('空投列表', resp);
    },

    /**
     * 获取用户对应池的奖励签名
     * @param {Number} id 请求参数: Tokenid
     */
    async fetchSignParams(id) {
      const resp = await $GET(`/airdrop/sign/${id}`, '', true);
      this.sign = resp;
      console.log('奖励签名', this.sign);
      return resp;
    },

    /**
     * 修改记录状态为 领取中
     * @param {Number} id 请求参数: Tokenid
     */
    async changeStatus(id) {
      const resp = await $GET(`/airdrop/claim/${id}`, '', true);
      console.log('修改记录', resp);
      return resp;
    },

    /**
     * 领取
     */
    async claim(payload) {
      console.log('payload..', payload);
      const resp = await this.contract.airdrop(
        payload.id, // airdrop_id
        payload.category, // 类型
        payload.from_id, // Address_id
        payload.card_id, // card_id
        payload.amount, // 数量
        payload.r,
        payload.s,
        payload.v
      );
      await resp?.wait();
    }
  },

  getters: {}
});

export default useAirDropStore;
