import { defineStore } from 'pinia';
import { toRaw } from 'vue';
import { useAppStore, storeToRefs } from '@store/appStore.js';
import { BVG_MINT_CONT } from '@/contracts/polygonAddress.js';

const appStore = useAppStore();

const useBVGMintStore = defineStore('bvgMint', {
  state: () => ({
    contract: {}, // 合约对象
    decimal: 18, // 精度
    poolInfo: {}, // 池子信息
    totalInfo: {} // 总量信息
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createContract() {
      const { ethers, signer } = appStore.ethObj;
      this.contract = new ethers.Contract(BVG_MINT_CONT.address, BVG_MINT_CONT.abi, toRaw(signer));
    },

    /**
     * 设置精度
     */
    async setDecimal() {
      const decimal = await this.contract.decimals();
      this.decimal = Number(decimal);
    },

    /**
     * 获取用户的池子信息
     * @param {Number} id 池子ID,1为BNB,2为USDT，3为FIL
     * @returns {Object} userStakeAmount 用户质押的数量 poolStakeAmount 池子总质押量 remainAmount 剩余可领取量
     */
    async getPoolInfo(id) {
      try {
        const { defaultAccount } = storeToRefs(useAppStore());
        // 用户质押数量
        const { stakeAmount } = await this.contract.userInfo(defaultAccount.value, id);
        // 总產出
        const totalReward = { 1: 2500000 * 30, 2: 1500000 * 30, 3: 1000000 * 30 };
        // 全局总领取量
        const totalClaimed = await this.contract.totalClaimed();
        const rate = {
          1: 0.5,
          2: 0.3,
          3: 0.2
        };
        // 池子数据
        const poolInfo = await this.contract.poolInfo(id);
        const estimatedAPR = 0;
        this.poolInfo = {
          userStakeAmount: Number(stakeAmount) / Math.pow(10, this.decimal),
          poolStakeAmount: Number(poolInfo.TVL) / Math.pow(10, this.decimal),
          remainAmount:
            totalReward[id] - (Number(totalClaimed) / Math.pow(10, this.decimal)) * rate[id],
          limit: Number(poolInfo.limit - stakeAmount) / Math.pow(10, this.decimal),
          estimatedAPR
        };
      } catch (e) {
        console.log(e);
        return e?.data?.message ? e.data.message : 'error';
      }
    },

    /**
     * 获取三个池子信息
     * @returns userClaimed 用户已领取总量 unclaimedAll 用户待领取收益
     */
    async getTotalInfo() {
      try {
        const { defaultAccount } = storeToRefs(useAppStore());
        // 用户已领取总量
        const claimedAll = await this.contract.userClaimed(defaultAccount.value);
        // 用户待领取收益
        const unclaimedAll = await this.contract.calculateAllReward(defaultAccount.value);
        this.totalInfo = {
          userClaimed: Number(claimedAll) / Math.pow(10, this.decimal),
          unclaimedAll: Number(unclaimedAll) / Math.pow(10, this.decimal)
        };
      } catch (e) {
        console.log(e);
        return e?.data?.message ? e.data.message : 'error';
      }
    },

    /**
     * 质押 BNB 或者其他币种
     * @param {Number} payload.id 池子ID,1为BNB,2为USDT，3为FIL
     * @param {Number} payload.amount 质押数量
     */
    async stake(id, amount) {
      try {
        // 质押主链币（BNB） 构造对象
        if (id === 1) {
          const resp = await this.contract.stakeBnb({ value: amount });
          await resp?.wait();
        } else {
          // 质押 BVG 或 FIL
          const resp = await this.contract.stakeToken(id, amount);
          await resp?.wait();
        }
      } catch (e) {
        console.log(e);
        return e?.data?.message ? e.data.message : 'msg.6';
      }
    },

    /**
     * 解除质押，赎回本金并领取收益
     *  @param {Number} id 池子ID,1为BNB,2为USDT，3为FIL
     */
    async unStake(id) {
      try {
        // 解除质押 BNB
        if (id === 1) {
          const resp = await this.contract.unStakeBnb();
          await resp?.wait();
        } else {
          // 解除质押 BVG 或 FIL
          const resp = await this.contract.unStakeToken(id);
          await resp?.wait();
        }
      } catch (e) {
        console.log(e);
        return e?.data?.message ? e.data.message : 'msg.10';
      }
    },

    /**
     * 领取所有池子收益
     */
    async claimAll() {
      try {
        const resp = await this.contract.claimAllReward();
        await resp?.wait();
      } catch (e) {
        console.log(e);
        return e?.data?.message ? e.data.message : 'msg.8';
      }
    }
  },

  getters: {}
});

export { storeToRefs, useBVGMintStore };
