export default {
  undefined: '',
  header: {
    1: 'Event',
    2: 'GameFi',
    3: 'NFT',
    4: 'DAO',
    5: 'SocialFi',
    // 6: 'More',
    6: 'Docs',
    7: 'Interstellar Rangeland',
    8: 'Interstellar Sandbox',
    9: 'Interstellar Trek',
    10: 'Getting Started',
    11: 'Whitepaper',
    12: 'My Wallet',
    13: 'Home',
    14: 'My Friends',
    15: 'My Planet',
    16: 'Crypto Blog',
    17: 'Leaderboards',
    18: 'Guest Mode',
    19: 'Interstellar Mining',
    20: 'CattleMart Event',
    21: 'Marketplace',
    22: 'NFT Farm',
    23: 'Connect Wallet',
    24: 'Disconnect',
    25: 'Beta Assets',
    26: 'Will be open soon',
    27: 'Beta-Invite Party',
    28: 'Guild Advancement Program',
    29: 'Airdrop',
    30: 'Voucher Center',
    31: 'OATs Airdrop',
    32: '$LAZIO Mining',
    33: 'Bridge',
    34: 'Game Store',
    35: 'YOHO Chain',
    36: '$BVT&$BVG Mining',
    37: 'Order Record',
    38: 'Project Introduction',
    39: 'Genesis NFT Exchange'
  },
  home: {
    1: 'Buy BVT',
    2: 'Buy BVG',
    3: 'GAMES',
    4: 'Interstellar Rangeland',
    5: 'Interstellar Sandbox',
    6: 'Interstellar Trek',
    7: '<p>Ancient parallel world</p><p>Breeding, Milking, Fighting!</p>',
    8: '<p>3D parallel world</p> <p>Planet, Spaceship, Creation!</p>',
    9: '<p>Parallel hyperspace</p> <p>VR Gaming, Exploration, Battle!</p>',
    10: 'Play Now',
    11: 'Coming Soon',
    12: 'SOCIALFI WORLDS',
    13: 'Socializing',
    14: 'Alliances',
    15: 'Crypto Blog',
    16: 'Players can develop their virtual social networks in YOHO;',
    17: 'GAMING NFT',
    18: 'Homeland',
    19: 'Unionland',
    20: 'Genland',
    21: 'Mystery Egg Box',
    22: 'Baby Bull',
    23: 'Baby Cow',
    24: 'Unique ID and avatar;',
    25: 'OUR VISION',
    26: 'We wish to develop a multi-verse worldview by virtue of advanced blockchain technology. In this decentralized economy, game and social system, each player will own bovine animals to socialize, adventure and create. Players can fully enjoy the low-latency games in this amazing universe anytime and anywhere.',
    27: 'OUR PARTNERS',
    28: 'OUR COMMUNITY',
    29: 'Terms',
    30: 'Privacy',
    31: '',
    32: 'Cross-game DAO communities with shared interests;',
    33: 'Based on game streaming platform for content creation and distribution under Web 3.0 and decentralized KOL economic incentive agreements;',
    34: 'Calf',
    35: 'Planet',
    36: 'Bull',
    37: 'Cow',
    38: 'Adult Bull',
    39: 'Adult Cow',
    40: 'Carrying Capacity',
    41: '',
    42: 'Uniqueness',
    43: 'Liquidity',
    44: 'Rarity',
    45: 'Deployed on ERC-721, freely tradable at NFT marketplaces; Cross-game characters, which can freely travel across three parallel worlds of YOHO.',
    46: 'The total supply is only 10,000 with its initial supply of 514.',
    47: 'Social circles with other players: random matchmaking and random assigned side quest;',
    48: 'Affinity system; freely chat in real-time online communication platform.',
    49: 'DAO-governed economy applied in communities;',
    50: 'Resource exploitation, allocation, trade and plunder are all alliance based.',
    51: 'Creating and sharing gameplay, in-game scenes, gaming items and kit;',
    52: 'Posting, viewing and rewarding game videos and live made by players.',
    53: 'Higher attributes and eternal life;',
    54: 'Free of breeding limits.',
    55: 'ABOUT US',
    56: 'RESOURCES',
    57: 'PRODUCTS',
    58: 'Our Community',
    59: 'About YOHO',
    60: 'Press kit',
    61: 'Contact Us',
    62: 'Terms of Service',
    63: 'Audit Report',
    64: 'Whitepaper',
    65: 'Github',
    66: 'FAQ',
    67: 'Games',
    68: 'NFT Marketplace',
    69: 'Airdrop',
    70: 'TOKEN ECONOMICS $BVT',
    71: 'Contract Address',
    72: 'Join IDO in',
    73: 'Buy $BVT in',
    74: 'ROADMAP',
    75: 'Before',
    76: 'Contracted more than 30 gaming guilds on purchase of lands NFT and Bovine Hero.',
    77: 'Website 1.0.',
    78: 'Deployed NFT in BSC & Polygon.',
    79: 'First play-to-earn game Interstellar Rangeland finished the alpha stage on BSC.',
    80: 'Token Mining Campaign reached a peak of 1.1M USD.',
    81: 'IGO with Project Galaxy Earth and Metaverse Campaign gained over 5k+ engagements.',
    82: 'Integrated the GVG (guild vs guild) in the first game developmentFirst play-to-earn game Interstellar Rangeland finished the alpha stage on Polygon.',
    83: 'NFT Farming- over 5k+ users participated1st year Anniversary Campaign gained over 10k+ engagements.',
    84: 'Community grew over 200k followers.',
    85: 'Partnered with more well-known Web3 project(including Cyberconnect, DeGame, SnapFigure DAO)Private sale startsInterstellar Rangeland begins the beta stage.',
    86: 'Launch our own NFT marketplace',
    87: 'DAO - community governancePrivate sale closesIDO for BVT2nd game - Interstellar Sandbox finishes its alpha stage.',
    88: 'Partnering with more gaming guilds Interstellar Sandbox kicks offCrypto assets including NFT and tokens can be leveraged in both gamesIntegrate the ecological system with BVT.',
    89: 'Interstellar blog social platform goes onlineSupport the third VR game - Interstellar Trek- augmented reality interactive game mode.',
    90: 'Offline events such as esport game challenge and online campaign will be launched to bring more fun and challenge to the players.',
    91: 'Launch the Metaverse with star wars over parallel worlds.',
    92: 'Skin Box ',
    93: 'Supreme Hay',
    94: 'Supreme EXP Card',
    95: 'Supreme STR Battery',
    96: 'Supreme HP Potion',
    97: 'Private Sale',
    98: 'team',
    99: 'Liquidity',
    100: 'Advisor&PR',
    101: 'Ecology',
    102: 'Accessories',
    103: 'CEX Listing',
    104: 'Join IDO',
    105: 'View more info',
    106: 'Football Prediction',
    107: '<p>The secret to predicting!</p><p>Go Win!</p>',
    108: 'ELITE GAMERS',
  },
  igo: {
    // 这个是表格的
    1: 'Name',
    2: 'Qty',
    3: 'Probability',
    4: 'Rarity',
    5: 'Bovine Hero',
    6: 'Cattle',
    7: 'Items Pack',
    8: 'Skins Pack',
    // 一些介绍
    9: '1. What’s in the mystery box?',
    10: 'Mystery boxes are the genesis NFT of YOHO. IGO Participants have a chance to win the valuable in-game assets, Bovine Hero, Cattle, Items Pack or Skins Pack.',
    11: '2. What are the applications of these NFT?',
    12: '<strong>Bovine Hero</strong> is the crypto avatar that can be used across three parallel worlds of YOHO. Each of them has the highest freedom, is hand-designed and has no replication. The rarity of them decides their premium in-game attributes. They are allowed for unlimited breeding and eternal life.',
    13: `<strong>Regular Cattles</strong>are also main characters in games of YOHO with limited breeding and lifetime. Regular cattles are also YOHO's citizens to participate in farming, breeding, fighting, and exploring.`,
    14: '<strong>Gaming Items Pack</strong> includes the following: hay, strength battery, experience card, HP potion and rename card.',
    15: '<strong>Skins Pack</strong> includes different apparels for the Cattle and Bovine Hero. The skin with various designs changes the appearance of cattles, making them standout and different in battles.',
    16: 'All these NFT are deployed in <strong>ERC 721</strong> and are freely tradable in the NFT Marketplace. Besides, they are cross-game assets, breaking the barriers between time and space, that means they are allowed for use in different games. You will be able to open the mystery box on <strong>April 5</strong> through <strong>Project Galaxy.</strong>',
    17: '3.What are the probability and rarity of each NFT in the mystery box?',
    18: '4. What can the whitelist benefit from?',
    19: 'All whitelisted users can participate in YOHO IGO 48 hours ahead of the public sale. Besides, they are qualified for a 25% off of the public sale price!',
    20: '5. How can I join the whitelist campaign?',
    21: '1. YOHO OAT hodler, <strong>440 WL spots</strong>',
    22: `Each participant needs to hold a <a onclick="window.open('https://galaxy.eco/YOHO/campaign/GCaMWUUuJF');">YOHO Community Badge NFT</a> or <a onclick="window.open('https://galaxy.eco/YOHO/campaign/GCaMWUUuJF');">YOHO Loyalty NFT</a>. <strong>440</strong> lucky winners will be selected from these Badge holders.`,
    23: '',
    24: '',
    25: '',
    26: '2. Galaxy Girl hodlers, <strong>500 WL spots</strong>',
    27: 'To be qualified for this round, you need to be a <strong>Galaxy Girl hodlers</strong>.  500 lucky winners will be selected from <strong>Galaxy Girl hodlers</strong>.  <strong>First come first served</strong>.',
    28: '3. Community Campaign Winner, <strong>500 WL spots</strong>',
    29: `We will pick 500 lucky winners to whitelist from the participants of <a onclick="window.open('https://gleam.io/sDYK8/YOHO-igo-whitelist-campaign')">Gleam Campaign</a>, partner giveaway and Discord event.`,
    30: '6. YOHO reserves all rights of final interpretation for all events.',
    31: 'Total Supply:1800',
    32: 'Whitelist',
    33: '150 USDT each',
    34: '2022/3/18 12:00 - 2022/3/20 12:00(UTC)',
    35: 'Public Sale',
    36: '200 USDT each',
    37: '2022/3/20 12:00 - 2022/3/22 12:00(UTC)',
    38: 'BUY NOW',
    39: 'WL Round-Galaxy Girl',
    40: 'WL Round-YOHO OAT & Community Campaign',
    41: 'Public Round',
    42: 'Back',
  },
  blindBox: {
    1: 'Unit Price',
    2: 'Total Supply',
    3: 'DAYS',
    4: 'HOURS',
    5: 'MINUTES',
    6: 'SECONDS',
    7: 'MERGE FRAGMENT',
    8: 'BUY NOW',
    9: '1. Open Galaxy Chest to randomly get Bovine Hero, Regular Cattle, Cattle Box and Star Fragment.',
    10: '2. Each Galaxy Chest is priced at $50, with a total supply of 200,000. There is no personal purchase limit. ',
    11: '3. The Star Fragment acquried from Galaxy Chest is random, and the fragment can be collected to draw NFT planet, Bovine Hero, and Regular Cattle.',
    12: '4. Deployed on ERC-721, Galaxy Chest can be used to freely trade on the third NFT marketplace such as Opensea and Element.',
    13: '5. NFT planets can only be acquired from Star Swirl by merging Star Fragments. On the Star Swirl page, players can consume Star Fragments to win Homeland and Genland by chance.',
    14: '',
    15: '',
    16: 'Content',
    17: 'Chance',
    18: 'Halo Chest',
    19: 'Bovine Hero',
    20: 'Cattle',
    21: 'Cattle Box',
    22: 'Star Fragment',
    23: 'Homeland and Genland',
    24: '* The fourth round of Bovine Hero Swirl must win Bovine Hero, on a first come first served basis',
    25: 'Rules',
    26: 'Star Swirl',
    27: 'What is included in Halo Chest?',
    28: 'Halo Chest',
    29: 'Halo Chest is the mystery box solely sold on CattleMart Event, from which player can randomly get Bovine Hero, Calf, Asteroid Fragment. The Asteroid Frag can be merged to win Homeland, Genland, Bovine Hero and calf. Player opening most Chest each day can share FOMO Prize.',
    30: 'Current Round',
    31: 'Pool',
    32: 'Next Round',
    33: 'FOMO PRIZE POOL',
    34: 'The FoMo Pool is set to reward those players who actively participate in this stargazer event. Whenever the player open a Halo Chest, the pool will be injected by 20,000 BVG. Every time player completes a swirl draw, the pool will be invested by 5,000 BVG. Every day, three active players will be able to split the total BVG in the pool produced the day before. ',
    35: 'The Chosen One',
    36: 'Planet Researcher',
    37: 'Golden Giant',
    38: 'Opening time',
    39: 'CounTotal FRAG consumedtdown',
    40: 'Total Halo Chests opened',
    41: 'Rewards',
    42: 'Current winners',
    43: 'Claimed Reward',
    44: 'Unclaimed Reward',
    45: 'Your win! Collect Your Rewards!',
    46: 'Consume most FRAGs',
    47: 'Open most Chests',
    48: 'Opening Mystery Box',
    49: 'Approve NFT',
    50: 'We’ll ask your approval to access your token. This is a one-time only operation.',
    51: 'Confirm opening',
    52: 'Confirm to open your mystery box.',
    53: 'Congrats! You get',
    54: 'Staking NFT',
    55: 'Confirm stakings',
    56: 'Confirm to stake your NFT into the NFT Farm',
  },
  halo: {
    1: 'CattleMart is officially open to all! CattleMart is a limited-time cattle street with sale of a special mystery box - Halo Chest, from which diversified premium game items can be obtained. In this incredible event, we will integrate NFT, Merge, and FOMO Prize all together into a single awesome Mart that captivated players. Players are free to own more than one chest at this time. To be more privileged, active participants are all welcome to invite others to the CattleMart event and receive additional rewards as a referee! What are you waiting for? Come and grab your Chest in our limited-time CattleMart!',
    2: 'CattleMart Description',
    3: '1. This event is officially launched by YOHO, starting at 12:00 PM UTC, June 23.',
    4: '2. Each Halo Chest is priced at $20, with a total supply of 20,000. There is no purchase cap for each address. ',
    6: '3. Refer a friend: once you buy a Halo Chest, you are qualified to be a referrer. You will receive 20% of the total payment for each referral as rewards. Once your friend enters your wallet address as an invitation code and the payment is confirmed, the 20% will be automatically sent to your address.',
    7: "4. Whitelisted users can receive an additional 10% off on their first order. Click <a href='https://docs.google.com/spreadsheets/d/1Xvuc6T7X8tS0nAiqOz3-Mm-WN-M_lANUb146FHEcswo/edit?usp=sharing'>here</a> to see if your address is whitelisted. Whitelisted users are those who claimed their anniversary OATs, those who held DC roles of Bovine Hero, Twitter Bird, Coser, Gambling King and submitted their addresses on time.",
    8: '5. Once you buy a Halo Chest and you can get one of the following: Bovine Hero Voucher, Calf Voucher, CattleMart Box and Asteroid fragments. ',
    9: '6. Those who get Asteroid Fragments can participate in the Fragms Merge to win planet, Bovine Hero, Calf, CattleMart Box or BVG. ',
    10: '  a. Through merging your fragments, you can randomly get the following assets: CattleMart Box, Calf, Bovine Hero, Planet, or some exact amount of fragments and BVG.',
    11: '  b. The exact probability tables of each merge are specified on each corresponding page.',
    12: '  c. All these NFTs are freely tradable on the secondary NFT marketplace, like TofuNFT, Element and Galler.',
    13: '7. The FOMO Pool is set to reward those players who actively participate in our CattleMart. Whenever the player opens a Halo Chest, 5,000 BVG will be automaticaly added to the pool. Every time a player completes a merge, 2,000 BVG will be automatically added to the pool. Three winners (the Chosen One, Planet Researcher, and Golden Giant) will share the BVG in the pool in the current round. Noted that the FOMO rewards must be claimed within 24 hours once the winners are announced at 12:00 AM UTC. ',
    14: '  a. The Chosen One: 50% of the prize, the final participants of the merge',
    15: '  b. Planet Researcher: 30% of the prize, participant who consumes most fragments',
    16: '  c. Golden Giant: 20% of the prize, participant who opens most Chest',
    17: '8. Players need to consume enough fragments to win Bovine Hero by chance. For merging fragments to draw Bovine Hero, only 20 Bovine Heroes are offered for this draw. First come first serve. ',
    18: '9. Players need to consume enough fragments to win Homeland by chance. Only 2 Homelands are offered for this draw. First come first serve.  ',
    19: 'BUY NOW',
    20: 'MERGE FRAGMENTS',
    21: 'OPEN MY HALO CHEST',
    22: 'WHAT YOU CAN GET',
    23: 'FOMO PRIZE POOL',
    24: 'NEXT ROUND',
    25: 'Round',
    26: 'The Chosen One',
    27: 'Planet Researcher',
    28: 'Golden Giant',
    29: 'Rewards',
    30: 'Winner',
    31: 'Claimed Rewards',
    32: 'Unclaimed Rewards',
    33: 'You won! Collect your rewards!',
    34: 'Round',
    35: 'Prize Pool',
    36: 'Learn How to Play >',
    37: '<strong>Description:</strong> Halo Chest is the mystery box solely sold on Cattle Mart Campaign, from which the owner can randomly get Bovine Hero, Calf, CattleMart Box, Asteroid Fragment. The Asteroid Fragment can be merged to win Homeland, Bovine Hero and Calf. ',
    38: 'Referral Address',
    39: 'Paste',
    40: "*leave it blank if you don't have one",
    41: 'Total',
    42: 'Discounted Total (Whitelist ONLY)',
    43: 'ADD 10 MORE',
    44: 'OPEN MY HALO CHEST',
    45: '< BACK TO MAIN',
    46: '*Once you complete one order, you are welcome to share with your friends an invitation code(wallet address), to receive an additional 20% of their order as referral reward.',
    47: 'Available Asteroid Fragms:',
    48: 'Fragms Required for 1 Merge:',
    49: 'Qty left:',
    50: 'Possibility:',
    51: 'Possibility of Bovine Hero:',
    52: 'Fragms Required:',
    53: 'Merge',
    54: 'Buy More',
    55: 'Open my Boxes',
    56: 'Burn',
    57: 'What you may get',
    58: 'No.',
    59: 'Fragms',
    60: '1 Fragm and 50,000 BVG',
    61: '2 Fragms and 100,000 BVG ',
    62: '1. After you get a CattleMart Box, you can randomly get 1 Calf or Asteroid Fragment with 50% probability.',
    63: '2. There is no capped supply for CattleMart Box in this draw;',
    64: '3. Once you confirm the merge, 5 Asteroid Fragments that you used for merging will be burnt.',
    65: '1. There is no capped supply for calves in this merge.',
    66: '2. Once you confirm the merge, 10 Asteroid Fragments that you used for merging will be burnt',
    67: '1. Only 20 Bovine Heroes are supplied for the Fragments Merge. First come first serve. ',
    68: '2. Once the player wins Bovine Hero before the 4th round, the round will restart from the 1st round.',
    69: '3. There is no limitation on the number of Bovine Here one can obtain, which means each player can get more than one Bovine Hero from this merge.',
    70: '1. Only 2 Homelands are offered for this merge. First come first serve. ',
    71: '2. There is no limitation on the number of planets one can obtain, which means each player can get more than one planet from this merge. However, each address is limited to joining one planet. That means one can trade them if holding more than one.',
    72: '',
    73: '',
    74: '',
    75: '',
  },
  farm: {
    1: 'BACKGROUND',
    2: 'Select Tokens to Stake',
    3: 'Lock Amount',
    4: 'My Staked Amount',
    5: 'My Remaining Allocation',
    6: 'Amount Staked in the Pool',
    7: 'Remaining Rewards',
    8: 'STATEMENT',
    9: 'Claimed Rewards',
    10: 'Unclaimed Rewards',
    11: 'Countless treasures were buried underneath the vast lands of YOHO. In the 121st year of Taurus, mining went extremely viral among bovine citizens. Cross-Star Liberty League, one of the two major alliances, has called upon three mining companies to explore this broad universe, namely Jefferol Glory Company (BNB), Workers Mining Union (USDT), and Worley Mining Company (FIL). The daily mining rewards of 5M BVG are distributed to these three companies with 50%, 30% and 20% respectively. Players are free to join the organization they want. Each player is only given staking allocations with 10 BNB, 5,000 USDT and 100 FIL. All revenues earned are BVG based, a universal token in YOHO.',
    12: 'HIGHLIGHTS',
    13: 'VAULT',
    14: 'Low Threshold',
    15: 'High Return',
    16: 'No Time Constraint',
    17: 'Deflation Mechanism',
    18: 'Utility Tokens',
    19: 'Estimated APR',
    20: 'Contracts',
    21: 'Mining:',
    22: 'BVG:',
    23: 'The contracts regarding mining and reward token BVG has been officially approved by Certik official. CertiK is one of the fastest growing and most trusted companies in blockchain security and also a pioneer in blockchain security, utilizing best-in-class Formal Verification and AI technology to secure and monitor blockchains, smart contracts, and Web3 apps.',
    24: 'Click ',
    25: 'here',
    26: 'to review the audit report.',
    27: 'Background',
    28: "Is the Interstellar mining over? Never. There is no end to the exploration of metaverse. This is merely the beginning! There are still countless wealth and assets in the YOHO that can be exchanged for BVG. Moreover, OAT (On-chain Achievement Token) is one of the indispensable materials in the early days, playing an extremely significant role. Bovinians, let's rock on it! OAT is a designated badge launched by YOHO on the Web3 infrastructure platform Project Galaxy based on BNB Chain (previously Binance Smart Chain). Until now, only five have been released. They are rare and precious on-chain credentials, and also a certificate of the YOHO ecological social identity.",
    29: 'Information',
    30: '1. Event period: 30 Days',
    31: '3. Daily rewards, 1,666,667 BVG for a total of 50,000,000 BVG.',
    32: '4. These NFTs are demand staking; that means, you can stake and unstake anytime.',
    33: '5. The power of each NFT is dependent of its rarity. The rarer it is, the higher its power is.',
    34: '6. The power of IGO Mystery Box is 10 times higher than the highest power of OATs. ',
    35: 'My Unstaked NFT',
    36: 'My Staked NFT',
    37: 'Name',
    38: 'Token ID',
    39: 'Power',
    40: 'Estimated Daily Rewards',
    41: '2. Contract',
    42: 'Unclaimed Rewards',
    43: 'Claimed Rewards',
  },
  airdrop: {
    1: 'Please enter your address',
    2: 'Search',
    3: 'Address',
    4: 'Type',
    5: 'Amount',
    6: 'Event',
    7: 'Status',
    8: 'Claimed',
    9: 'Waiting to lease',
    10: 'Claim Now',
    11: 'Frozen',
    12: 'Claiming',
    13: 'Chain',
    14: '1. The airdrop rewards include tokens and NFT on BNB Chain and Polygon. Be sure to claim them with the correct network connected.',
    15: '2. Be sure to connect your wallet with our webpage.',
    16: '3. Airdrop rewards are open to claim starting from the first working day of next month from the day of announcement and closing in a week.',
    17: '4. Failure to claim within this period is regarded as inactive user.',
    18: "5. Click <a href='https://YOHO.medium.com/claiming-airdrop-rewards-tutorial-c4f428bcea3' target='_blank'>here</a> to view a step-by-step tutorial.",
    19: '6. The final right of interpretation regarding events belongs to YOHO Official.',
    20: "7. For any more questions, please contact us through <a href='mailto:YOHO{'@'}gmail.com' target='_blank'>here</a>.",
    21: 'Learn How to Claim Airdrop',
    22: 'Description',
  },
  market: {
    1: '最低價',
    2: '最高價',
    3: '最新時間',
    4: '',
    5: '',
    6: '',
    7: '',
  },
  panel: {
    1: 'Account',
    2: 'NFT',
    3: 'Affinity',
    4: 'Information',
    5: 'Gender',
    6: 'Wallet',
    7: 'Name',
    8: 'Planet',
    9: "Planet's Win Rate",
    10: 'Match Result',
    11: 'Win Rate',
    12: 'Play-to-Earn',
    13: 'Male',
    14: 'Female',
    15: 'Planet',
    16: 'Bovine Hero',
    17: 'Cattle',
    18: 'Mystery Box',
    19: 'Others',
    20: 'Filter',
    21: 'Reset',
    22: 'Category',
    23: 'Bloodline',
    24: 'Items',
    25: 'Male',
    26: 'Female',
    27: 'My Listing',
    28: 'Price: High to Low',
    29: 'Price: Low to High ',
    30: 'Recently Listed',
    31: 'DELISTING',
    32: 'History',
    33: 'Planet Lease',
    34: 'No data',
    35: 'Badge',
    36: 'Currency'
  },
  detail: {
    1: '',
    2: 'Attributes',
    3: 'Physical Combat',
    4: 'Milking',
    5: 'HP',
    6: 'EXP',
    7: 'STR',
    8: 'ATP',
    9: 'STA',
    10: 'DEF',
    11: 'Production',
    12: 'Rate',
    13: 'Holder',
    14: 'Buy Now',
    15: 'Sell',
    16: 'Open',
    17: 'Transfer',
    18: 'Contract Address',
    19: 'Token ID',
    20: 'Description',
    21: 'Capacity',
    22: 'Tax rate',
    23: 'Transfer your NFT',
    24: 'Please enter the {0} address that you would like to transfer it to',
    25: 'Change Profile Photo',
    26: 'Number',
    27: 'Delist',
    28: 'Name',
    29: 'Status',
    30: 'Amount',
    31: 'Price',
    32: 'Sold ',
    33: 'Bought',
    34: 'Time',
    35: 'Parent Information',
    36: 'Father',
    37: 'Mother',
    38: 'Setting up your listing',
    39: 'Set your fixed price',
    40: 'Fee',
    41: '2% of the total sale will be paid to YOHO as a platform fee.',
    42: 'Sell NFT',
    43: 'Confirm sell',
    44: 'Comfirm to sell your NFT into the NFT Market',
    45: 'Order Confirmation',
    46: 'Please confirm the amount you will pay',
    47: 'Available Balance',
    48: 'Insufficient Balance. Buy More Crypto >',
    49: 'Collected tax',
    50: 'Couple',
    51: 'Bros',
    52: 'Confidants',
    53: 'Besties',
    54: 'Confirmation',
    55: 'Approve your wallet',
    56: "We'll ask your approval to access your token.This is a one-time only operation.",
    57: 'Confirm your payment',
    58: 'Confirm to buy the mystery box.',
    59: 'Confirm your merge',
    60: 'Confirm to merge your NFT',
    61: 'Remaining Life',
    62: 'Note: this mystery box can only be opened in Interstellar Rangeland.',
    63: 'Rent',
    64: 'For Rent',
    65: 'Unavailable',
    66: 'In the lease',
    67: 'Renew',
    68: '* Lease term',
    69: '* Lease renewable during the lease term',
  },
  card: {
    1: 'Cattle Mystery Box',
    2: 'IGO Mystery Box',
    3: 'Bovine Hero',
    4: 'Regular Cattle',
    5: 'Items Pack',
    6: 'Skins Pack',
    7: 'The IGO mystery boxes are the genesis NFT of YOHO. Only IGO Participants have a chance to win the valuable in-game assets, Bovine Hero, Cattle, Items Pack or Skins Pack. All these NFT are deployed in ERC 721 and are freely tradable in the NFT Marketplace. Besides, the assets are cross-game assets, breaking the barriers between time and space, that means they are allowed for use in different games.',
    8: 'Bovine Hero is the crypto avatar that can be used across three parallel worlds of YOHO.  Each of them has the highest freedom, is hand-designed and has no replication. The rarity of them decides their premium in-game attributes. They are the only species that allowed for unlimited breeding and eternal life. These NFT are deployed in ERC 721 and are freely tradable in the NFT Marketplace. Besides, they are cross-game assets, breaking the barriers between time and space, that means they are allowed for use in different games. ',
    9: "Regular Cattles are also main characters in games of YOHO with limited breeding and lifetime. Regular cattles are also YOHO's citizens to participate in farming, breeding, fighting, and exploring.These NFT are deployed in ERC 721 and are freely tradable in the NFT Marketplace. Besides, they are cross-game assets, breaking the barriers between time and space, that means they are allowed for use in different games.",
    10: 'The Item Pack of YOHO includes the following gaming items: hay, strength battery, experience card, HP potion and rename card. These are all in-game items that could be used to enhance the attributes of cattles or Bovine Heros. These pack are deployed in ERC 721 and are freely tradable in the NFT Marketplace. Besides, they are cross-game assets, breaking the barriers between time and space, that means they are allowed for use in different games.',
    11: 'The skins in the pack with various designs changes the appearance of cattles, making them standout and different in battle. These NFT are deployed in ERC 721 and are freely tradable in the NFT Marketplace. Besides, they are cross-game assets, breaking the barriers between time and space, that means they are allowed for use in different games.',
    12: 'Homeland',
    13: 'Unionland',
    14: 'Ex Planet',
    15: 'Homeland is the supreme planet NFT in Interstellar Rangeland. Homeland president can tax the locals and the Unionland planet, in charge of the distribution of the revenue for the sake of well being and wealth. The president of Homeland can govern a guild with up to 10,000 members.',
    16: '',
    17: '',
    18: 'CattleMart Box',
    19: 'Regular Cattle Voucher',
    20: 'Bovine Hero Voucher',
    21: 'Genland Voucher',
    22: 'Homeland Voucher',
    23: 'Asteroid Fragment',
    24: 'One can randomly obtain Calf or Asteroid Fragms from CattleMart Box. Regular Cattle can yield token through farming, breeding, fighting. Merge Asteroid Fragms on merging page to win Bovine Hero, Cattle, Homeland, and Genland NFT.',
    26: 'Just like Bovine Hero, cattles are special characters in Interstellar Rangeland, actively participating in farming, breeding, fighting, and exploring to yield BVG and BVT Rewards. Deployed on ERC-721, regular cattles can also be traded in marketplace.',
    27: 'Bovine Hero is one of the supreme NFT assets in YOHO with limited quantity. It is deployed on ERC-721 and is freely tradable at NFT marketplaces. Compared with regular cattles, Bovine Hero is featured by higher attributes, unlimited breeding and eternal life. ',
    28: 'The rare land asset NFT in YOHO built by members from Homeland and Unionland with a carrying capacity of 1,000. Genland President has the right to tax locals and linked Unionland.',
    29: 'Homeland is the supreme planet NFT in Interstellar Rangeland. Homeland president can tax the locals and the Unionland planet, in charge of the distribution of the revenue for the sake of well being and wealth. The president of Homeland can govern a guild with up to 10,000 members.',
    30: 'Collect pieces of Fragms to play merge gameplay to get Homeland, Genland, Bovine Hero, CattleMart Box and Calf NFT. Consuming Fragms have a chance to win FOMO Prize.',
    31: 'Halo Chest',
    32: 'Halo Chest is the mystery box solely sold on Cattle Mart Campaign, from which owner can randomly get Bovine Hero, Calf, CattleMart Box, Asteroid Fragment. The Asteroid Framgnet can be merged to win Homeland, Genland, and Bovine Hero (limited supply, first comes first served). Each day player opening the most chest splits a FOMO Prize Pool.',
    33: 'Cattle Mystery box is from the mating of Cows and Bull of YOHO. Deployed on ERC-721, mystery box is one of the NFT assets in YOHO. After opening the box, there is a chance to get Bovine Hero, calf and cattle piece. ',
    34: 'One of the NFT assets in YOHO, Bovine Hero is released with limited quantity, deployed on ERC-721 and is freely tradable at NFT marketplaces. Rare supply. Compared with cattles, Bovine Hero is featured by higher attributes, unlimited breeding and eternal life. It is the cross-game characters breaking the barriers between time and space.',
    35: 'Just like Bovine Hero, cattles are special characters in Interstellar Rangeland, actively participating in farming, breeding, fighting, and exploring to yield BVG and BVT Rewards.',
    36: 'YOHO Gaming Mystery Box',
    37: 'YOHO Cattle Mystery Box sold in BinanceNFT contains exclusive gaming characters that are required to participate in the cryptobattles and cryptomilking of Interstellar Rangeland. The exclusive boxes include supreme Bovine Heroes worth $1000, regular cattle worth $200 and items packs . Once you open the mystery box, you will need to bridge it into Polygon through our official bridge.',
    38: 'Bronze badge',
    39: 'Silver badge',
    40: 'Gold badge',
    41: 'Platinum badge',
    42: 'Diamond badge',
    43: 'Badge of glory',
  },
  rangeland: {
    1: 'Play Now',
    2: 'Marketplace',
    3: 'DEMO',
    4: 'Leaderboard',
    // 排行榜
    5: 'Leaderboard Week {0}',
    6: 'Milking',
    7: 'Breeding',
    8: 'Contribution',
    9: 'Milking Amount',
    10: 'Rank',
    11: 'Name',
    12: 'Players',
    13: 'Coming soon',
    14: 'Breeding Entries',
    15: 'Tax',
  },
  // 土地售卖领取
  claimAssets: {
    1: 'Homeland',
    2: 'Homeland is the supreme planet NFT in Interstellar Rangeland. Homeland president can tax the locals and the Unionland planet, in charge of the distribution of the revenue for the sake of well being and wealth. The president of Homeland can govern a guild with up to 10,000 members.',
    3: 'Cattle Box',
    4: 'Cattle Mystery box is from the mating of Cows and Bull of YOHO. Deployed on ERC-721, mystery box is one of the NFT assets in YOHO. After opening the box, there is a chance to get Bovine Hero, calf and cattle piece. ',
    5: 'Bovine Hero',
    6: 'One of the NFT assets in YOHO, Bovine Hero is released with limited quantity, deployed on ERC-721 and is freely tradable at NFT marketplaces. Rare supply. Compared with cattles, Bovine Hero is featured by higher attributes, unlimited breeding and eternal life. It is the cross-game characters breaking the barriers between time and space.',
    7: 'Calf',
    8: 'Just like Bovine Hero, cattles are special characters in Interstellar Rangeland, actively participating in farming, breeding, fighting, and exploring to yield BVG and BVT Rewards. Deployed on ERC-721, regular cattles can also be traded in marketplace',

    9: 'Claim',
    10: 'Apply Now',
    11: 'Planet',
    12: 'Bovine Hero',
    14: 'Contract',
    15: 'Supply',
    16: 'Who is eligible',
    17: 'whitelists',
    18: 'Available',
    19: 'Claimed',
    20: 'You are NOT eligible',

    21: 'TBVG is the game currency issued by YOHO that can be obtained by participating in the game, airdrops, mining, staking Bovine Heroes, etc. After the game goes online, players can obtain TBVG for free through tasks like PVE. TBVG can be used to purchase gaming items and proceed the transaction, etc. ',
    22: 'TBVT is the cryptocurrency issued by YOHO that can be obtained by participating in the game and airdrop activities. Players can use TBVT to purchase gaming items, breed, level up, and so on. The more TBVT you own, the more votes you get in DAO governance in YOHO. ',

    23: 'COMING SOON',
    24: 'open to all',
    25: 'Minted',
    26: 'Cattle Mystery Box',
    27: 'Description',
    28: 'infinite',
    29: 'Out of supply',
    30: 'You have claimed',
    31: 'You are not eligible',
    32: 'Play Now',
  },
  // 公测拉新邀请
  referral: {
    1: 'Fraternity & Sorority',
    2: 'Beta-Invite Party',
    3: 'Season 1',
    4: 'Role',
    5: 'Referral Address',
    6: 'Paste',
    7: 'Confirm your Referral',
    8: '# of Invite',
    9: 'Valid Invite',
    10: 'Unclaimed Rewards',
    11: 'Claimed Rewards',
    12: 'Gift Pack',
    13: 'Supreme Hay',
    14: 'Supreme STR',
    15: 'Supreme EXP',
    16: 'HP Portion',
    17: 'Claim Beta Rewards',
    18: "Feed at least two baby cattles to adult cattles. Click<a href='https://www.youtube.com/watch?v=HiZxJo4IfrM' target='_blank'>here</a>to learn.",
    19: "Receive 200 TBVT from milking or 1000 TBVT from battling. Click<a href='https://www.youtube.com/watch?v=_UQ_NCppv0U' target='_blank'>here</a>to learn.",
    20: "Tax contribution for your guild is at least 100 TBVT. Click<a href='https://www.youtube.com/watch?v=-5HH7lIcWCg' target='_blank'>here</a>to learn.",
    21: "Upgrade at least one tech to Lv 3 in Tech Tree. Click<a href='https://www.youtube.com/watch?v=qb704wLapdU' target='_blank'>here</a>to learn.",
    22: "Rangeland/Farm level has to be at least Lv 2. Click<a href='https://doc-en.bovine-verse.games/interstellar-rangeland-beta/stall/stall-player-level' target='_blank'>here</a>to learn.",
    23: 'Below are the information of address',
    24: 'Address',
    25: 'Status',
    26: 'Invite Track',
    27: 'Whitelist gamer',
    28: 'Freshman',
    29: 'Claim Rewards',
    30: 'Invited Address',
    31: "You haven't invited anyone yet.",
    32: '1. To appreciate the support from our OG players, we now hold this Beta- Invite Party for whitelist users, to split a prize pool worth 20,000 USDT.',
    33: '2. Whitelist users are selected based on the Snapshot of in-game activity.',
    34: '3. Only whitelist users are qualified to be referrals to this event to share the prize pool worth $20,000U.',
    35: '4. Each whitelist user can invite as many as their friends to our P2E game Interstellar Rangeland.',
    36: '5. For each valid invite, whitelist users can receive 5 USDT as bonus and new users can receive a gift pack through the event page.',
    37: '6. To ensure fairness, we have set the bonus limit for each whitelist to be 50 USDT.',
    38: '7. To count as a valid invite, new user needs to first confirm your address as his referral and then completes five in-game tasks.',
    39: '8. This bonus invite contest is held throughout the entire Beta. If you are not in the whitelist of Season 1, please be active and you will get chance to be in next season.',
    40: "9. Users can refer to the video tutorial following each task or join <a href='https://discord.com/invite/YOHO' target='_blank'>YOHO community</a> for gameplay tutorials.",
    41: '10. Users can use the Invite Track to check out the tasks details and status of their invites.',
    42: '11. The final right of interpretation regarding events belongs to YOHO Official.',
    43: 'Learn How to Earn',
    44: 'Event Description',
  },

  // 公测资产兑换
  assetsExchange: {
    1: 'Hay',
    2: 'STR Battery',
    3: 'EXP Card',
    4: 'Skin Mystery Box',
    5: 'Rename Card',
    6: 'Redeem',
    7: 'Hay',
    8: 'Prime Hay',
    9: 'Supreme Hay',
    10: 'STR Battery',
    11: 'Prime STR Battery',
    12: 'Supreme STR Battery',
    13: 'EXP Card',
    14: 'Prime EXP Card',
    15: 'Supreme EXP Card',
    16: 'Skin Mystery Box',
    17: 'Rename Card',
    18: `Your farm's level has to be at least Lv.2 to redeem the voucher`,
    19: 'You have to earn at least 3000 TBVT to redeem the voucher',
    20: 'Voucher',
    21: 'Total Supply',
    22: 'Unit Price',
    23: 'TBVT Balance',
    24: 'Total Price',
    25: `Today's Limit`,
    26: 'Official Assets Redemption Requirement:',
    27: `1. Farm's level should be at Level 2 or above.`,
    28: '2. You have earned at least 3,000 TBVT in public beta.',
    29: 'Each address has a daily limit of 100,000 TBVT for redemption;',
    30: 'All items are distributed in the form of voucher. The holder of voucher can redeem the voucher for official items and assets later in the official game.',
    31: 'Click here to learn how to redeem',

    32: 'On use, grant STR.',
    33: 'On use, grant STR.',
    34: 'On use, grant STR.',
    35: 'On use, grant EXP',
    36: 'On use, grant EXP',
    37: 'On use, grant EXP',
    38: 'On use, grant STR',
    39: 'On use, grant STR',
    40: 'On use, grant STR',
    41: 'On use, extend the life circle by 2 days (Limitation: limited to 10-day life circle expansion per cattle.)',
    42: 'On use, extend the life circle by 5 days (Limitation: limited to 10-day life circle expansion per cattle.)',
    43: 'On use, extend the life circle by 10 days (Limitation: limited to 10-day life circle expansion per cattle.)',
    44: 'Randomly get 1 Skin NFT',
    45: 'The rename card allows players to change their name.',

    46: 'Hay Voucher',
    47: 'Prime Hay Voucher',
    48: 'Supreme Hay Voucher',
    49: 'STR Battery Voucher',
    50: 'Prime STR Battery Voucher',
    51: 'Supreme STR Battery Voucher',
    52: 'EXP Card Voucher',
    53: 'Prime EXP Card Voucher',
    54: 'Supreme EXP Card Voucher',
    55: 'Skin Mystery Box Voucher',
    56: 'Rename Card Voucher',

    57: 'Out of stock',
    58: 'CattleMart Voucher',
    59: 'Items Voucher',
    60: 'IGO Mystery Box(Polygon)',
    61: 'Items Pack Voucher',
    62: 'Redeem Now',
    63: 'Cattle Voucher',
    64: 'held',
    65: 'Total selected vouchers: {0}, Total cost: {1} BVT',
    66: 'Please select the voucher(s) for redemption.',
    67: 'Others',
  },

  // 资产道具购买
  gameStore: {
    1: 'Baby Cattle',
    2: 'HP Potion',
    3: 'STR Battery',
    4: 'EXP Card',
    5: 'Skin Mystery Box',
    6: 'Regular Cattle',
    7: 'HP Potion',
    8: 'Prime HP Potion',
    9: 'Supreme STR Battery',
    10: 'History',
    11: 'Index',
    12: 'Orig.price',
    13: 'Price',
    14: 'Buy',
    15: 'Qty',
    16: 'Total',
    17: 'Available',
    18: 'Time',
    19: 'STR Battery',
    20: 'Prime STR Battery',
    21: 'Supreme STR Battery',
    22: 'EXP Card',
    23: 'Prime EXP Card',
    24: 'Supreme EXP Card',
    25: 'Skin Mystery Box',
  },

  // 公会拉新活动
  guild: {
    1: 'GUILD',
    2: 'ELITES',
    3: 'Step 1',
    4: 'Apply to be Qualified',
    5: 'Build a community with at least 30 people (WeChat/TG/ any other social medias)',
    6: 'Spots left: {0}',
    7: 'Claim Beta Assets',
    8: 'Apply',
    9: 'Step 2',
    10: 'Build your Community',
    11: '1. Claim cattle boxes from the Beta Asset page. ',
    12: '2. Feed the baby cattle into adults and give a certain amount of STR.',
    13: '3.Screenshots of your battle or milk (shared on WeChat Moments (open to all) or Twitter)',
    14: '-Moments: Contains a hashtag of YOHO',
    15: "-Twitter: {'@'}YOHO",
    16: '4. Guild president should collect all valid address and proof and submit it here or to YOHO official admin.',
    17: 'Step 3',
    18: 'Hit the Highest Rewards',
    19: 'Phase I: Guild Rewards 100 USDT',
    20: 'Condition: community reaches at least 30 new members and the number of  valid addresses reaches at least 10',
    21: 'Phase II: Guild Rewards 500 USDT',
    22: 'Condition: community reaches at least 100 new members and the number of  valid addresses reaches at least 60',
    23: 'Phase III: Guild Rewards 1000 USDT & Planet Merit Badge NFT',
    24: 'Condition: community reaches at least 200 new members and the number of  valid addresses reaches at least 150',
    25: 'Phase IV: Guild Rewards 2000 USDT & Planet President Badge NFT',
    26: 'Condition: community reaches at least 500 new members and the number of valid addresses reaches at least 500',
    27: 'Claim',
    28: 'Mint',
    // ELITES 精英
    29: 'Be a Valid Address',
    30: '1. Claim cattle boxes from the Beta Asset page.',
    31: '2. Feed the baby cattle into adults and give a certain amount of STR.',
    32: '3.Screenshots of your battle or milk (shared on WeChat Moments (open to all) or Twitter)',
    33: '-Moments: Contains a hashtag of YOHO',
    34: "-Twitter: {'@'}YOHO",
    35: '4. Submit your approve along with your address to your guild president',
    36: 'Claim your Badge',
    37: 'Submit Proof of Your Community',
    38: 'Group name must include YOHO.',
    39: "Each eligible president can claim a Beta Planet. Their members can choose to join the Planet or other's. Planet's name must be the same as group name.",
    40: 'Valid addresses need to be submitted to YOHO official admins for review.',
    41: 'Claimed',
  },
  // 徽章空投
  oatBadge: {
    1: 'Buy More',
    2: 'Qualified OATs',
    3: 'Your $BVT Rewards',
    4: 'Remaining Spots',
    5: 'Level',
    6: 'Amount',
    7: 'Event Description',
    8: '1. Rewards will be available to claim in 11:00 AM UTC, Aug 23',
    9: '2. For each grade of rewards, we only offer certain numbers for OAT holders.  First come first served.',
    10: '4. BVT Contract: ',
    11: '5. The final right of interpretation regarding events belongs to YOHO Official.',
    12: 'Buy More $BVT',
    13: 'Join Our Community',
    14: 'Grade',
    15: 'BVT Amount/Holder',
    16: 'Available',
    17: '3. We have classified the type of OATs that we issued and calculated a total weight for each holder. The snapshot was taken on Aug 22',
  },
  // LAZIO 质押
  lazio: {
    1: 'Buy more',
    2: 'Read more',
    3: 'Deposit ends in',
    4: 'balance',
    5: 'Deposit',
    6: 'Referral address',
    7: 'Paste',
    8: 'Period',
    9: 'Flexible',
    10: '3-Days',
    11: '7-Days',
    12: 'Estimated APY',
    13: 'My total staked',
    14: 'Amount staked in the pool',
    15: 'Total rewards',
    16: 'Contract',
    17: 'More detail',
    18: 'Description',
    19: '1. Event time: 12:00 PM UTC Sept 16 to 12:00 PM UTC, Oct 1',
    20: '2. Since this event is the extra credit for all our BVT Holders, there is no minimum requirement of $BVT for this staking. ',
    21: '3. Different staking periods grant different mining powers. The longer the staking period the higher the mining power it presents.',
    22: '4.  The $LAZIO rewards can be claimed anytime.',
    23: '5. We have prepared 30% of your rewards as referral rewards. You are very welcome to refer your friend to join this event. For each valid invite, you will be able to share 20% of rewards from your friends earning. If your invited friend invites another friend, you will be able to share 10% of his or her earnings.',
    24: '6. The final right of interpretation regarding events belongs to YOHO Official.',
    25: 'Referral rewards',
    26: 'Claimable rewards',
    27: 'Claimed rewards',
    28: 'Claim rewards',
    29: 'Next release time: ',
    30: 'Days',
    31: 'h',
    32: 'm',
    33: 's',
  },
  // 跨链桥
  bridge: {
    1: 'Bridge',
    2: 'From',
    3: 'To',
    4: 'Swap',
    5: 'Token',
    6: 'NFT',
    7: 'Reminder：',
    8: 'Crosschain Fee is 1 %. Minimum Crosschain amounts for BVT and BVG are 40 BVT and 50,000 BVG respectively.',
    9: 'Estimated Time of Crosschain Arrival is 10-30 min.',
    10: 'For NFT Voucher, we only offer bridging from BNB Chain(formerly BSC) to Polygon.',
    11: 'If your NFT is a mystery box in BNB Chain, you will need to first open it before bridging to Polygon.',
    12: 'Bridge History',
    13: 'Chain',
    14: 'Type',
    15: 'Amount',
    16: 'Txn hash',
    17: 'Status',
    18: 'Completed',
    19: 'Failed',
    20: 'In progress',
    21: 'In transaction ',
    22: 'Out transaction',
    23: 'Balance:',
    24: 'We only support bridging the updated BVG from Polygon to BNB Chain (0x29AB77a43Ec69D0DFFf3Ff72bff457e09b258351) with a ratio of 4000:1.',
    25: '',
    26: 'Updated',
    27: `<div>YOHO Chain is a multi-chain L2 network</div><div>with unified liquidity secured by ZK-Rollups</div>`,
    28: 'YOHO Chain Introduction',
    29: `<div>YOHO Chain is a multi-chain L2 network with unified liquidity secured by ZK-Rollups.YOHO Chain ecosystem connects various L1 chains and L2 networks that enables developers and traders to leverage unified liquidity from isolated chains with a secure and seamless user experience, contributing to a more accessible and efficient DeFi ecosystem for everyone.</div>
    <div>YOHO Chain innovatively applies zero-knowledge technology to multi-chain interoperability with a trustless and decentralized separation of powers design that safeguards the security of assets and trades as well as multi-chain data transits. Moreover, YOHO Chain eliminates the disparity among stablecoins from different blockchains and of different types, achieving multi-chain liquidity aggregation to form a massive stablecoin liquidity pool via a unique protocol-controlled-debt (PCD) mechanism, delivering a capital efficient, cost-friendly, and easy-to-access infrastructure</div>`,
    30: 'Zero-knowledge Proofs with Zero Security Compromise',
    31: 'A Rigorous Consensus Mechanism with Checks and Balances',
    32: 'Native Asset Aggregation From Multiple Chains',
    33: 'YOHO Chain Features',
  },
  // 年终
  yearend: {
    1: 'Buy more',
    2: 'Read more',
    3: 'Deposit ends in',
    4: 'Balance',
    5: 'Deposit',
    6: 'Referral address',
    7: 'Paste',
    8: 'Period',
    9: 'Flexible',
    10: '3-Months',
    11: '6-Months',
    12: 'Estimated APY',
    13: 'My staked',
    14: 'Amount staked in the pool',
    15: 'Total rewards',
    16: 'Contract',
    17: 'More detail',
    18: 'Description',
    19: '1. Event time: 8:00 AM UTC Dec 28 to 8:00 AM UTC, Jan 27',
    20: '2. Since this event is the extra credit for all our BVT and BVG Holders, there is no minimum requirement of $BVT and $BVG for this staking. ',
    21: '3. Different staking periods grant different mining powers. The longer the staking period the higher the mining power it presents.',
    22: '4.  The rewards can be claimed anytime.',
    23: '5. We have prepared 20% of your rewards as referral rewards. You are very welcome to refer your friend to join this event. For each valid invite, you will be able to share 20% of rewards from your friends earning.',
    24: '6. The final right of interpretation regarding events belongs to YOHO Official.',
    25: 'Referral rewards',
    26: 'Claimable rewards',
    27: 'Claimed rewards',
    28: 'Claim rewards',
    29: 'Next release time: ',
    30: 'Days',
    31: 'h',
    32: 'm',
    33: 's',
    34: '12-Months',
    35: 'Store and retrieve',
    36: 'Staked expiration time',
    37: 'Token',
  },

  dialog: {
    1: 'Confirm',
    2: 'Close',
    3: 'Back',
    4: 'Edit',
    5: 'Save',
    6: 'Cancel',
    7: 'More',
    8: 'Approve',
    9: 'Stake',
    10: 'Unstake',
    11: 'Claim',
    12: 'You are ready!',
    13: 'Congratulations!',
    14: 'You now get the following',
    15: 'Sold Out',
    16: "Amount can't be less than ",
  },
  common: {
    1: 'APPROVE',
    2: 'Stake Now',
    3: 'UNSTAKE',
    4: 'CLAIM REWARDS',
    5: 'Loading',
  },
  msg: {
    1: 'Wallet connected',
    2: 'Failed to connect the wallet',
    3: 'Authorized',
    4: 'Failed to authorize',
    5: 'Staked',
    6: 'Failed to stake',
    7: 'Received',
    8: 'Failed to Receive',
    9: 'Unstaking succeed',
    10: 'Redemption failed',
    11: 'Changed',
    12: 'Modified failed',
    13: 'Insufficient balance',
    14: 'The transaction has been submitted, and waiting to be packed to the block, please make edit later',
    15: 'Copied',
    16: 'Order confirmed',
    17: 'Transferred',
    18: 'Failed to transfer',
    19: "You don't have any NFT yet.",
    20: 'Sold',
    21: 'Failed to sell',
    22: 'Please connect your wallet.',
    23: 'This NFT is currently listed in marketplace, please confirm to delist it from the market.',
    24: 'Listing cancelled ',
    25: 'Failed to cancel',
    //25: 'Failed to delist',
    26: 'Your NFT is listed',
    27: 'Failed to list',
    28: 'Your NFT is sold',
    29: 'Incorrect network',
    30: "Listing price can't be zero",
    31: 'Incorrect network',
    32: 'Coming soon',
    33: 'Invalid address',
    34: 'Failed to open',
    35: 'Max 10',
    36: 'You have linked with your referral',
    37: "You don't have any NFT yet",
    38: 'Open at 12:00PM UTC, June 28',
    39: 'Failed to purchase',
    40: 'Please enter the valid address',
    41: `You can't `,
    42: 'Invalid address',
    43: 'This address is not in the whitelist',
    44: 'No claimable reward',
    45: 'Please refresh manually',
    46: '',
    47: '',
    48: 'Rewards claimed',
    49: 'You have not yet completed all tasks',
    50: 'This is only open to new users',
    51: 'Your rewards has been claimed',
    52: 'Claimed',
    53: 'Referral Confirmed',
    54: 'Switched',
    55: 'Please check your network',
    56: 'You have not yet qualified for this program.',
    57: "You don't have any claimable rewards.",
    58: 'Your rewards have been claimed.',
    59: '{0}Token added successfully!',
    60: 'Not Open Yet',
    61: 'Out of supply',
    62: 'Please enter any amount greater than 0',
    63: 'This staking period has ended',
    64: 'Official launch coming soon',
    65: "Amount can't be less than 40 BVT",
    66: "Amount can't be less than 50,000 BVG",
    67: 'Please select a card',
    68: 'Succeed',
    69: 'Failed to bridge',
    70: 'In progress',
    71: 'Successfully rented out',
    72: 'Successfully renewed',
    73: 'Please enter your wallet address',
    74: 'Not yet open for sale, please stay tuned',
    75: 'User cancels transaction'
  },


  exchangeRecord:{
    1:"Exchange Records",
    2:"Hash",
    3:"Consumed Quantity of Genesis NFTs",
    4:"Quantity of NiuNiu Obtained",
    5:"Time",
  },
  swap: {
    1: "Genesis NFT Exchange",
    2: "Remaining NiuNiu Exchange Quantity",
    3: "Current Holdings of Genesis NFTs",
    4: "Enter the Quantity for NiuNiu Exchange",
    5: "Confirm",
    6: "Exchange Records",
    7: "The Genesis NFT exchange event has ended",
    8: "Dear YOHO Genesis NFT Community and Bullfight Game Community members: ",
    9: "Welcome to participate in our innovative and exciting event — the YOHO Genesis NFT exchange for Bullfight Game Genesis NiuNiu NFT! In this unique event, you have the opportunity to acquire exclusive Genesis NiuNiu NFTs by exchanging YOHO NFTs. Let's explore this anticipated interactive experience together!",
    10: "Event Rules",
    11: "1. Total of 1000 Genesis Bulls: We have prepared 1000 Genesis Bulls for this event, each one is limited edition with unique value. <br />2. Exchange one Genesis Bull NFT for every 5 YOHO NFTs: Surprise! You only need to provide 5 YOHO NFTs to exchange for one Genesis Bull NFT. This is your only chance, don't miss it! <br />3. One-time opportunity: This event is one of a kind, the opportunity is only available this time. We will strictly follow a first-come, first-served basis until the Genesis Bull NFTs are sold out.",
    12: "Exchange Steps", 
    13: "1. Collect YOHO NFTs: During the event, collect your favorite YOHO NFTs, ensuring you have enough NFTs to participate in the exchange. <br />2. Submit Exchange Application: On our designated exchange page, fill out and submit your YOHO NFT information along with the quantity of Genesis Bull NFTs you want to exchange. <br />3. Wait for Confirmation: Our team will confirm your application as soon as possible, ensuring everything complies with the event rules. <br />4. Receive Genesis Bull NFT: Once your application is approved, you will receive the unique Genesis Bull NFT, becoming one of the lucky winners in the event!",
    14: "Important Notes", 
    15:" 1. Ensure your YOHO NFT information is accurate to avoid inconvenience during the exchange process. <br />2. After a successful exchange, the Genesis Bull NFTs will be automatically transferred to your NFT wallet. <br />3. If you have any questions during the event, feel free to contact our customer service team for support. ",
    16: "This is a unique opportunity, let's witness the collision of YOHO Genesis NFT and Bullfight Game, creating your irreplaceable digital assets. Thank you for your participation, and good luck!",
    17: "Hash",
    18: "Consumed Quantity of Genesis NFTs",
    19: "Quantity of NiuNiu Obtained",
    20: "Time",
    21: "Input quantity cannot be empty or zero",
    22: "Insufficient quantity of Genesis NFTs required for exchanging for Bull Hero NFT",
    23: "Input quantity cannot exceed the remaining exchangeable quantity.",
    24: "Transaction successful",
    25: "Transaction failed"
  }
};
