// 预设好的RPC
const supportedChains = [
  {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://rpc.ankr.com/eth'],
    blockExplorerUrls: ['https://bscscan.com/']
  },
  {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed1.ninicoin.io'],
    blockExplorerUrls: ['https://bscscan.com/'],
  },
  {
    chainId: '0x528',
    chainName: 'AIA Testnet',
    nativeCurrency: {
      name: 'AIA',
      symbol: 'AIA',
      decimals: 18,
    },
    rpcUrls: ['https://aia-dataseed1-testnet.aiachain.org'],
    blockExplorerUrls: ['https://testnet.aiascan.com/'],
  },
  {
    chainId: '0x527',
    chainName: 'AIA Mainnet',
    nativeCurrency: {
      name: 'AIA',
      symbol: 'AIA',
      decimals: 18,
    },
    rpcUrls: ['https://aia-dataseed1.aiachain.org'],
    blockExplorerUrls: ['https://aiascan.com/'],
  },
  {
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: ['https://polygonapi.terminet.io/rpc'], // https://rpc-mainnet.matic.network
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
];

export default supportedChains;
