import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router';
import {useAppStore} from '@store/appStore';
import $load from '@cps/GlobalLoading';
import {allowEnter, checkRightChain} from './routerHelp';
let chainId = Number(process.env.VUE_APP_CHAINID)
let needChains = ['0x'+chainId.toString(16),chainId];
const routes = [
    // {
    //     path: '/',
    //     name: 'home',
    //     component: () => import(/* webpackChunkName: "home" */ '@/views/Home/index.vue'),
    //     meta: {
    //         welcome: true,
    //     },
    // },
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "nftMarket" */ '@/views/NftMarket/index.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/CattleMart/background',
        name: 'background',
        component: () =>
            import(
                /* webpackChunkName: "stargazerEvent/BlindBox" */
                '@/views/NFT/StargazerEvent/Background.vue'
                ),
    },
    {
        path: '/CattleMart/buy',
        name: 'buyBox',
        component: () =>
            import(
                /* webpackChunkName: "stargazerEvent/BlindBox" */
                '@/views/NFT/StargazerEvent/BuyBox.vue'
                ),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/CattleMart/merge',
        name: 'mergeFragments',
        component: () =>
            import(
                /* webpackChunkName: "stargazerEvent/BlindBox" */
                '@/views/NFT/StargazerEvent/mergeFragments.vue'
                ),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/voucherCenter',
        name: 'assetsExchange',
        component: () =>
            import(/* webpackChunkName: "assetsExchange" */ '@/views/NFT/AssetsExchange/index.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/swap',
        name: 'swap',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/NFT/Swap/index.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/nftMarket',
        name: 'nftMarket',
        component: () => import(/* webpackChunkName: "nftMarket" */ '@/views/NftMarket/index.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/nftMarket/detail',
        name: 'nftMarketDetail',
        component: () =>
            import(/* webpackChunkName: "nftMarketDetail" */ '@/views/NftMarket/Detail/index.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/nftMarket/myListing',
        name: 'myListing',
        component: () =>
            import(/* webpackChunkName: "myListing" */ '@/views/NftMarket/MyListing/index.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/nftMarket/myListing/SetListing',
        name: 'setListing',
        component: () =>
            import(/* webpackChunkName: "setListing" */ '@/views/NftMarket/MyListing/SetListing.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/nftMarket/listingDetail',
        name: 'listingDetail',
        component: () =>
            import(
                /* webpackChunkName: "listingDetail" */
                '@/views/NftMarket/MyListing/ListingDetail/index.vue'
                ),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/profile',
        name: 'cardPackage',
        component: () => import(/* webpackChunkName: "cardPackage" */ '@/views/CardPackage/index.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/cardPackage/detail',
        name: 'cardPackageDetail',
        component: () =>
            import(/* webpackChunkName: "cardPackageDetail" */ '@/views/CardPackage/NFTDetail/index.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/cardPackage/PanletLease',
        name: 'planetLease',
        component: () =>
            import(/* webpackChunkName: "NFTDetail" */ '@/views/CardPackage/NFTDetail/components/PlanetLease.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/transfer',
        name: 'transfer',
        component: () =>
            import(
                /* webpackChunkName: "transfer" */
                '@/views/CardPackage/NFTDetail/components/Transfer.vue'
                ),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/betaAssets',
        name: 'betaAssets',
        component: () => import(/* webpackChunkName: "betaAssets" */ '@/views/BetaAssets/index.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/rangeland',
        name: 'rangeland',
        component: () => import(/* webpackChunkName: "Rangeland" */ '@/views/Rangeland/index.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path: '/guild',
        name: 'guild',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/activity/guild/index.vue'),
        meta: {
            requireAccount: true, // 需要钱包地址
            needChains,
            needTips: true, // 加载完时，错链需要弹窗提示
        },
    },
    {
        path:'/exchange-record',
        name:'exchageRecord',
        component: () => import(/* webpackChunkName: "exchageRecord" */ '@/views/activity/exchange-record/index.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    // 每次进来，先确定是否拿到钱包
    const appStore = useAppStore();
    if (!to.meta.requireAccount) {
        // 进入的时候不依赖钱包拿链上数据的，先放用户进去，再不阻塞地获取钱包
        next(true);
        appStore.linkWallet(to);
    } else {
        $load({
            isShow: true,
        });

        // 没有账号，先去拿账号信息
        if (!appStore.defaultAccount) {
            await appStore.linkWallet();
        }

        $load({
            isShow: false,
        });

        await checkRightChain(to, from);
        next(true);
    }

    if (to.meta.welcome) {
        const appStore = useAppStore();
        // 如果首次进入，才执行动画
        if (appStore.isFirstEnter) {
            appStore.setIsFirstEnter(false);
            appStore.setWelcoming(true);
        }
    }

    try {
    } catch (error) {
        console.log('error..', error);
    }
});

export default router;
