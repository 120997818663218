import { ethers } from '@/utils/ethers';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import { OPEN_HALO_BOX } from './polygonAddress';

export default class {
  /**
   * 构建
   * @param {Object} addressObj 地址对象：包括地址和abi 默认为盲盒合约对象
   */
  constructor(addressObj = OPEN_HALO_BOX) {
    this.contract = null; // 盲盒合约对象
    this.totalNum = 0; // 总数
    this.tokenList = []; // 用户的盲盒 ID 列表
    this.boxList = []; // 盲盒列表
    // 盒子类型
    this.boxType = [
      {
        id: 1,
        name: 'card.1',
        cardType: 'blindBox',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/5.gif',
        desc: 'card.7'
      },
      {
        id: 2,
        name: 'card.2',
        cardType: 'Mystery-Box',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/5.gif',
        desc: 'card.7'
      },
      {
        id: 3,
        name: 'card.3',
        cardType: 'Bovine-Hero',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/1.gif',
        desc: 'card.8'
      },
      {
        id: 4,
        name: 'card.4',
        cardType: 'Cattle',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/2.gif',
        desc: 'card.9'
      },
      {
        id: 5,
        name: 'card.5',
        cardType: 'Items-Pack',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/3.gif',
        desc: 'card.10'
      },
      {
        id: 6,
        name: 'card.6',
        cardType: 'Skins-Pack',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/4.gif',
        desc: 'card.11'
      },
      {
        id: 7,
        name: 'card.6',
        cardType: 'Halo-Box',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/4.gif',
        desc: 'card.11'
      }
    ];
    this.craeteContract(addressObj);
  }

  /**
   * 构建对象
   * @param {Object} addressObj：包括合约地址、abi
   * 例如 去旁边的 address.js 里拿 PROXY_QUERY_CONT 传入
   * @returns 合约对象
   */
  craeteContract(addressObj) {
    const appStore = useAppStore();
    const { ethers, signer } = appStore.ethObj;
    const contract = new ethers.Contract(addressObj.address, addressObj.abi, toRaw(signer));
    this.contract = contract;
    return this.contract;
  }

  /**
   * 开启盲盒
   * @param {Number} id tokenID
   */
  async openBox(id) {
    const resp = await this.contract.openBox(id, {
      gasPrice: ethers.utils.parseUnits('5.5', 'gwei'),
      gasLimit: 1000000
    });
    const event = await resp.wait();
    console.log('open event..', event);
    return event;
  }
}
