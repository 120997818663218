import { defineStore } from 'pinia';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import blindBox from '../contracts/blindBox';
import { HALO_BLIND_BOX_CONT } from '@/contracts/polygonAddress';

const useTicketStore = defineStore('ticket', {
  state: () => ({
    contract: {}, // 合约对象
    totalNum: 0, // 用户拥有的盲盒總數
    ticketType: [
      {
        id: 1,
        name: 'card.18',
        cardType: 'box_ticket', // 盲盒
        image: 'https://game.legendranch.app/legendaryranch/ticket/box_ticket.png',
        desc: 'card.24',
        amount: 1
      },
      {
        id: 2,
        name: 'card.20',
        cardType: 'creation', //创世牛牛
        image: 'https://game.legendranch.app/legendaryranch/ticket/creation.png',
        desc: 'card.26',
        amount: 1
      },
      {
        id: 3,
        name: 'card.21',
        cardType: 'exPlanet', // 拓荒星球
        image: 'https://game.legendranch.app/legendaryranch/ticket/exPlanet.png',
        desc: 'card.28',
        amount: 1
      },
      {
        id: 4,
        name: 'card.22',
        cardType: 'homePlanet', // 家园星球
        image: 'https://game.legendranch.app/legendaryranch/ticket/homePlanet.png',
        desc: 'card.29',
        amount: 1
      },
      {
        id: 5,
        name: 'card.19',
        cardType: 'normal', // 普通牛牛
        image: 'https://game.legendranch.app/legendaryranch/ticket/normal.png',
        desc: 'card.25',
        amount: 1
      },
      {
        id: 6,
        name: 'card.23',
        cardType: 'shred', // 碎片
        image: 'https://game.legendranch.app/legendaryranch/ticket/shard.png',
        desc: 'card.30',
        amount: 1
      },
      {
        id: 0,
        name: 'BVG',
        cardType: 'bvg',
        image: 'https://game.legendranch.app/legendaryranch/ticket/box_ticket.png',
        desc: 'BVG',
        amount: 1
      }
    ],
    ticketList: [], // 官网盲盒列表
    ticketInfo: {
      id: 1,
      name: 'card.1',
      image: 'https://game.legendranch.app/legendaryranch/ticket/shard.png',
      desc: 'card.7',
      amount: 1
    }
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createContract(addressObj = HALO_BLIND_BOX_CONT) {
      const appStore = useAppStore();
      const { ethers, signer } = appStore.ethObj;
      const contract = new ethers.Contract(addressObj.address, addressObj.abi, toRaw(signer));
      this.contract = contract;
      return this.contract;
    },

    /**
     * 获取列表
     */
    async getTicketList(addr) {
      let pList = [];
      for (let i = 1; i <= 6; i++) {
        pList.push(this.contract.balanceOf(addr, i));
      }
      const list = [];
      // 构造 ticket 数组
      await Promise.all(pList).then(values => {
        // console.log('val..', values);
        values.forEach((item, index) => {
          if (Number(item) !== 0) {
            const findItem = this.ticketType.find(it => it.id === index + 1);
            const cloneType = findItem;
            cloneType.amount = Number(item);
            list.push(cloneType);
          }
        });
      });
      this.ticketList = list;
    },

    /**
     * 获取兑换券信息
     * @param {Number} id TokenID
     * @param {String} addr Address
     */
    async getTicketInfo(id, addr) {
      const balance = await this.contract.balanceOf(addr, id);
      const resp = this.ticketType.find(it => it.id === Number(id));
      this.ticketInfo = { ...resp };
      this.ticketInfo.amount = +balance;
      return this.ticketInfo;
    },

    /**
     * 是否授权全部
     * @param {String} owner 用户地址
     * @param {String} contractAddr 合约地址
     * @returns
     */
    async isApproveAll(owner, contractAddr) {
      const hasAllowned = await this.contract.isApprovedForAll(owner, contractAddr);
      return hasAllowned;
    },

    /**
     * 授权全部
     * @param {String} contract 合约地址
     * @param {String} type 盲盒类型
     * @returns
     */
    async authAll(contract, type = 'Halo-Box') {
      const resp = await this.contract.setApprovalForAll(contract, true);
      await resp.wait();
      return resp;
    }
  },

  getters: {}
});

export default useTicketStore;
