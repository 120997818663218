import { reactive, getCurrentInstance } from 'vue';
import router from '@/router';
import { useAppStore } from '@store/appStore';
import { ElMessage } from 'element-plus';
import i18n from '@/utils/i18n';

const $t = i18n.global.t;

//  导航栏
let bannerList = reactive([
  {
    id: 3,
    name: 'header.21',
    urlName: 'nftMarket',
    active: false,
  },{
    id:4,
    name:'header.37',
    active: false,
    urlName: 'myListing',
    query: {
      selectType:'history'
    }
  },
  {
    id:6,
    name:'header.39',
    active: false,
    urlName: 'swap',
  },
  {
    id:4,
    name:'header.38',
    active: false,
    urlName: 'myListing',
    urlType:'outLink',
    url:'https://yohos-organization.gitbook.io/legendranch-cn/part-01/xiang-mu-jian-jie'
  },
  
]);

/**
 * 修改导航栏
 * @param {Array} newList 新的导航栏列表
 */
function setBannerList(newList) {
  bannerList = newList;
}

/**
 * 语言列表
 */
let langList = reactive([
  {
    id: 1,
    name: 'English',
    active: false,
    target: 'en',
  },
  {
    id: 2,
    name: '繁体中文',
    active: true,
    target: 'cn',
  },
  {
    id:3,
    name:'日本語',
    active: false,
    target:'jp'
  }
  // {
  //   id: 3,
  //   name: '한국어',
  //   active: false,
  //   target: 'kn',
  // },
  //  {
  //   id: 4,
  //   name: 'عربى',
  //   active: false,
  //   target: 'ar',
  // },
]);

/**
 * 修改语言
 * @param {String} target 语言
 */
function setLangList(target) {
  const list = langList.map(lan => {
    lan.active = false;
    if (target === lan.target) {
      lan.active = true;
    }
    return lan;
  });
  langList = list;
}

/**
 * 路由跳转
 * @param {Object} item
 */
function jump(item) {
  // 外链跳转
  if (item.urlName?.includes?.('doc')) {
    const appStore = useAppStore();
    let link;
    switch (appStore.curLang) {
      case 'cn':
        link = '#';
        break;
      default:
        link = '#';
        break;
    }
    window.open(link);

    return;
  }
  if(item.urlType == 'outLink' && item.url) {
    window.open(item.url);
  }

  // 组件跳转
  if (item.urlName !== '/') {
    router.push({ name: item.urlName,query: item.query });
  } else {
    ElMessage({
      type: 'info',
      message: $t('header.26'),
    });
  }
}

export { bannerList, setBannerList, langList, setLangList, jump };
