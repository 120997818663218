<template>
  <transition
    :duration="1000"
    enter-active-class="animate__animated animate__fadeInDown"
    leave-active-class="animate__animated animate__fadeOutUp"
  >
    <TopBar v-if="!welcoming && update" />
  </transition>

  <div v-if="update">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view>
  </div>
</template>

<script>
import TopBar from '@cps/TopBar';
import { useAppStore, storeToRefs } from '@store/appStore';
import useAccountStore from '@store/accountStore';
import {
  onMounted,
  onBeforeMount,
  ref,
  getCurrentInstance,
  watchEffect,
  nextTick,
  watch,
} from 'vue';
import { ElMessage } from 'element-plus';
import { checkRightChain } from '@/router/routerHelp';
import destroyAllStore from '@/utils/destroyAllStore.js';
import tp from 'tp-js-sdk';
window.tp = tp;

export default {
  components: {
    TopBar,
  },
  setup() {
    const appStore = useAppStore();
    const accStore = useAccountStore();

    const { welcoming } = storeToRefs(appStore);

    const update = ref(true); // 将组件销毁再重建
    /**
     * 控制因此語言面板
     */
    function handleTouch(e) {
      const panel = document.querySelector('#lang-panel');
      if (panel && !panel.contains(e.target)) {
        appStore.setIsShowLangPanel(false);
      }
    }
    onMounted(() => {
      if (appStore.curDevice !== 'pc') {
        document.body.addEventListener('touchstart', handleTouch);
      }
      const gThis = getCurrentInstance().appContext.config.globalProperties;
      gThis.$i18n.locale = appStore.curLang;
    });

    onBeforeMount(() => {
      if (appStore.curDevice !== 'pc') {
        document.body.removeEventListener('touchstart', handleTouch);
      }
    });

    /**
     * 清空一些store
     */
    function resetStore() {
      // testStore.$reset();
    }

    /**
     * 切链or切账号，更新数据
     */
    watch(
      // appStore.ethObj.connected,
      () => [appStore.updateTarget],
      async () => {
        // 没锁住，才去重置
        if (!appStore.lockUpdate) {
          // if (window.ethereum?.isTokenPocket) {
          // 如果用的TP钱包，则直接刷新，因为tp不刷新的话，window.ethereum的rpc还是旧的
          // 后来TP远程施法，又可以拿到最新的rpcUrl了
          // window.location.reload();
          // }

          console.log('window.chaindid', window.ethereum);

          // 更新当前链对不对
          await checkRightChain();

          // 更新所有页面内所有组件
          update.value = !update.value;

          // 重新获取个人信息
          accStore.getProfileInfo();
          accStore.getSpanceName();

          // 初始化相关store
          // resetStore();
          destroyAllStore();
        }

        nextTick(() => {
          update.value = true;
        });
      }
    );

    // 获取个人信息
    watchEffect(() => {
      if (!appStore.defaultAccount);
      accStore.getProfileInfo();
      accStore.getSpanceName();
    });

    return {
      welcoming,
      update,
    };
  },
};
</script>

<style lang="scss">
// body {
//   width:100%;
//   position:fixed;
// }
</style>
