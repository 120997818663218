import { defineStore } from 'pinia'
import { $GET} from '@/service/require.js'
import $load from '@cps/GlobalLoading'
import { useAppStore } from './appStore';

const useExchangeStore = defineStore('exchange',{
    state:() =>({
        records:[],
        page:1,
        total:0
    }),
    actions:{
        async fetchRecords() {
            const appStore = useAppStore();
            if (!appStore.defaultAccount) return;
            const limit = 10
          
            $load({ isShow: true });
            const resp = await $GET('/activity/exchange/log', {
                offset:(this.page-1)*limit,
                limit,
                address:appStore.defaultAccount,
                // address:'0xd3c0b6Aa1538d639912789be705F18b5Fd89fcE6',
             }).finally(() =>{
                $load({ isShow: false });
            })
            this.records = resp.list || []
            this.total = resp.total || 0
            console.log('this.total>>...', this.total)
        },
        setPage(page) {
            this.page = page
            this.fetchRecords()
        }
    }
})

export default useExchangeStore