<!-- 移动端导航栏 -->
<script setup>
import { computed, getCurrentInstance, reactive, ref } from 'vue';
import { showPop, setShowPop } from '../Wallets/useWallet';
import WalletPop from '../Wallets/index.vue';
import { langList, setLangList, bannerList, jump } from './useTopBar';
import { useAppStore } from '@store/appStore';
import { plusXing } from '@/utils/tools';
import router from '@/router';
import { pickSideNav } from '../../views/CardPackage/useCardPackage';
import useAccountStore from '@/store/accountStore';

const accStore = useAccountStore();

const gThis = getCurrentInstance().appContext.config.globalProperties;
const appStore = useAppStore();

const { ctx: that } = getCurrentInstance();

const handleWalletConnect = async () => {
  showDrawer.value = false;
  // setShowPop(true);
  await appStore.onConnect(appStore.walletOptions.metamask);
};

function handleClick() {
  showDrawer.value = false;
  pickSideNav('account');
  router.push({ name: 'cardPackage' });
}

// 语言栏选各种的语言
const pickedLang = computed(() => langList.find((item) => item.active));

/**
 * 选择语言
 */
function pickLang(lang) {
  gThis.$i18n.locale = lang;

  setLangList(lang);
  appStore.setLang(lang);
}

const isShowWalletPanel = ref(false); // 是否显示钱包面板
/**
 * 鼠标移入钱包栏
 */
function hanldeClickWallet(val) {
  isShowWalletPanel.value = val;
}

// 弹出菜单
const showDrawer = ref(false);

/**
 * 处理弹出菜单按钮
 */
function handleMenu() {
  showDrawer.value = !showDrawer.value;
}

/**
 * 处理点击了导航栏，跳转
 */
function handleJump(l) {
  jump(l);
  showDrawer.value = false;
}

// spaceName
const spaceName = computed(() => {
  return !accStore.spaceName ? appStore.defaultAccount : accStore.spaceName;
});

// 打开状态的面板，数组里面是对应的id项
const activeId = ref([2, 5, 6]);

function goGitbook() {
  //window.open(`https://yohos-organization.gitbook.io/legendranch-cn`, '_blank');
}
function goPlay() {
  window.open(`https://game.legendranch.app`, '_blank');
}
</script>

<template>
  <div class="mob-wrap">
    <!-- 左边 -->
    <div style="display: flex; align-items: center">
      <!-- <img src="@img/mob/icon-menu.png" alt="" class="menu" @click="handleMenu" /> -->
      <i class="menu iconfont icon-sidebar" @click="handleMenu"></i>
      <img
        src="@img/home/logo-bovine-verse.png"
        alt=""
        class="cow-logo"
      />
      <img
        src="@img/home/play.png"
        alt=""
        @click="goPlay"
        class="cow-play"
      />
    </div>
    <!-- 右边 -->
    <div id="lang-panel">
      <!-- 选择语言 -->
      <div class="lang-container" @click="appStore.setIsShowLangPanel(!appStore.isShowLangPanel)">
        <i class="iconfont icon-lang-earth"></i>
        <div class="holder">
          {{ pickedLang && pickedLang.name }}
          <!-- <i :class="['icon-right-arrow iconfont']"></i> -->
          <img src="@img/common/icon-sanjiao-white.svg" alt="" class="arrow" />
        </div>
        <transition name="bp">
          <div class="panel" v-show="appStore.isShowLangPanel">
            <li
              class="lang-item"
              v-for="lang in langList"
              :key="lang.id"
              @click="pickLang(lang.target)"
            >
             {{lang.name }}
            </li>
          </div>
        </transition>
      </div>
    </div>
  </div>
  <!-- 弹出层 -->
  <el-drawer size="70%" direction="ltr" v-model="showDrawer" :with-header="false">
    <div class="wallet" @click="hanldeClickWallet(!isShowWalletPanel)">
      <img :src="accStore.avatar" alt="" class="avatar" />

      <div class="name" v-if="appStore.defaultAccount">
        {{ plusXing(spaceName, 4, 4) }}
      </div>
      <div class="name" v-else @click="handleWalletConnect">{{ $t('header.23') }}</div>
      <transition name="el-zoom-in-top">
        <div class="mob-wallet-panel" v-show="isShowWalletPanel && appStore.defaultAccount">
          <li class="link" @click="handleClick">
            {{ $t('header.12') }}
          </li>
          <li class="link" @click="appStore.resetApp(that)">{{ $t('header.24') }}</li>
        </div>
      </transition>
    </div>

    <!-- 导航栏列表 -->
    <div class="menu-list">
      <!-- 点击了首页 -->
      <div
        class="menu-tab"
        @click="
          $router.push({ name: 'home' });
          showDrawer = false;
        "
      >
        {{ $t('header.13') }}
      </div>
      <!-- 点击了其他页 -->
      <div v-for="l in bannerList" :key="l.id">
        <!-- 二级菜单 -->
        <el-collapse v-if="l?.children?.length" v-model="activeId">
          <el-collapse-item :title="$t(l.name)" class="menu-tab" :name="l.id">
            <div
              @click.stop="handleJump(it)"
              class="menu-item"
              v-for="it in l.children"
              :key="it.id"
            >
              {{ $t(it.name) }}
            </div>
          </el-collapse-item>
        </el-collapse>

        <div class="menu-tab" @click.stop="handleJump(l)" v-else>{{ $t(l.name) }}</div>
      </div>
    </div>
  </el-drawer>
  <WalletPop></WalletPop>
</template>

<style lang="scss" scoped>
@import './index.scss';

.mob-wrap {
  width: 100%;
  height: $mobTopBarHeight;
  // background-color: rgba($color: #000, $alpha: 0.8);
  background-color: #000;
  @include flexPos(space-between);
  padding: 0.4rem;
  position: relative;
  z-index: 999;
}

.menu {
  // width: 0.45rem;
  // height: 0.45rem;
  color: #fff;
  font-size: 0.35rem;
}

.cow-logo {
  width: 2rem;
  max-height: 0.5rem;
  margin-left: 0.5rem;
  // border-radius: 50%;
}
.cow-play {
  width:1.28rem;
  margi-right:0.1rem;

}

.lang-container {
  height: $mobTopBarHeight;
  cursor: pointer;
  .icon-lang-earth {
    font-size: 0.45rem;
  }

  .panel {
    width: 100%;
    top: $mobTopBarHeight;
    font-size: 16px;
    text-align: center;
  }

  .arrow {
    width: 0.15rem;
    @media (max-width: $phone) {
      width: 0.18rem;
    }
  }
}

.holder {
  @include flexPos(center);
  border-radius: 0.46rem;
  height: 0.6rem;
  font-size: 16px;
  color: #fff;
}

.icon-right-arrow {
  font-size: 12px;
  transform: rotate(90deg);
}
</style>

<style lang="scss">
// 弹出层
.el-drawer {
  padding: 0.24rem 0.4rem;
  background-color: #000 !important;
  color: #fff;
  font-size: 0.28rem;

  .el-drawer__body {
    overflow: auto;
  }

  .wallet {
    @include flexPos(center);
    margin-bottom: 0.1rem;

    img {
      width: 0.92rem;
      height: 0.92rem;
      border-radius: 50%;
      position: relative;
      z-index: 99;
    }

    .name {
      width: 2.6rem;
      height: 0.54rem;
      border: solid 1.5px #fff;
      border-radius: 0.7rem;
      @include flexPos(center);
      transform: translateX(-0.3rem);
      padding-left: 0.22rem;

      &.addr {
        padding: 0 0.4rem;
      }
    }
  }

  .menu-list {
    font-weight: bold;

    .menu-tab,
    [role='tab'] {
      font-size: 0.36rem;
    }
    > div {
      border-top: solid 1px #999;
      padding: 0.32rem 0;

      &:first-child {
        border-top: none;
      }
    }

    .el-collapse-item__header,
    .el-collapse-item__wrap {
      font-size: 0.36rem;
      font-weight: bold !important;

      background-color: transparent !important;
      color: #fff !important;
      border: none;
    }

    .el-collapse-item__header {
      height: 0.5rem;
    }

    .el-collapse-item__content {
      padding-bottom: 0;
    }

    .menu-item {
      font-size: 0.32rem;
      // text-align: center;
      color: #fff;
      margin-top: 0.25rem;
    }

    .el-collapse {
      border: none;
    }
  }
}

.avatar {
  border: solid 2px #fff;
  border-radius: 50%;
  // padding: 0.1rem;
  box-sizing: border-box;
  /* background-color: #333; */
}
</style>
