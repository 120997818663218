export default {
  undefined: '',
  header: {
    1: '이벤트',
    2: '게임파이',
    3: 'NFT',
    4: 'DAO',
    5: '소셜파이',
    // 6: '더 보기',
    6: '문서',
    7: '인터스텔라 레인지랜드',
    8: '인터스텔라 샌드박스',
    9: '인터스텔라 트렉',
    10: '시작하기',
    11: '백서',
    12: '내 지갑',
    13: '훔페이지',
    14: '내 친구',
    15: '내 행성',
    16: '크립토 블로그',
    17: '순위표',
    18: '게스트 모드',
    19: '인터스텔라 마이닝',
    20: '캐틀마트 마이닝',
    21: '시장',
    22: 'NFT 농장',
    23: '지갑연결',
    24: '연결을 끊여습니다',
    25: '베타 자산',
    26: '곧 온다',
    27: '베타-초대 이벤트',
    28: '길드 승급 프로그램',
    29: '에어드랍',
    30: '교환권센터',
    31: 'OATs 에어드랍',
    32: '$LAZIO 마이닝',
    33: '브리지',
    34: '게임 스토어',
    35: 'BV Chain',
    36: '$BVT&$BVG 마이닝',
  },
  home: {
    1: 'BVT 구매',
    2: 'BVG 구매',
    3: '게임',
    4: '인터스텔라 레인지랜드',
    5: '인터스텔라 샌드박스',
    6: '인터스텔라 트렉',
    7: '<p>고대 평행세계</p><p>번식, 밀킹, 전투!</p>',
    8: '<p>3D 평행 세계</p> <p>행성, 우주선, 창조!</p>',
    9: '<p>초공간 평행 세계</p> <p>VR게임, 탐험,개발, 전투!</p>',
    10: '게임 시작',
    11: 'Coming Soon',
    12: '소셜파이 월드',
    13: '소셜라이징',
    14: '동맹',
    15: '크립토 블로그',
    16: '플레이어는 BV에서 가상 소셜 네트워크를 개발할 수 있습니다.;',
    17: 'NFT게임',
    18: '훔랜드',
    19: '유니언랜드',
    20: '겐란드',
    21: '미스터리 에그 박스',
    22: '아기 황소',
    23: '아기 젖소',
    24: '고유 ID 및 아바타;',
    25: '우리의 목표',
    26: '우리는 고급 블록체인 기술을 통해 다중우주 세계관을 발전시키고자 합니다. 이 분산된 경제, 게임 및 소셜 시스템에서 각 플레이어는 소를 소유하여 사교하고 모험하고 창조하며 플레이어는 짧은 대기 시간으로 언제든지 이 신기한 우주에서 게임을 완전히 즐길 수 있습니다.',
    27: '우리 파트너들',
    28: '커뮤니티',
    29: '서비스 약관',
    30: '개인정보 보호정책',
    31: '',
    32: '크로스 게임 DAO커뮤니티 및 수입 공통체;',
    33: 'Web 3.0에서 콘텐츠 제작 및 배포를 위한 게임 라이브 스트리밍 플랫폼을 기반으로 하며 분산형 KOL 경제적 인센티브 계약을 준수합니다.;',
    34: '일반 소 ',
    35: '행성',
    36: '황소',
    37: '젖소',
    38: '성인 황소',
    39: '성인 젖소',
    40: '수용력',
    41: '',
    42: '독특성',
    43: '유동성',
    44: '희소성',
    45: 'ERC-721에 배치되어 NFT 시장에서 자유롭게 거래할 수 있으며, BV의 다른 평행 세계로 자유롭게 이동할 수 있는 크로스 게임 캐릭터.',
    46: '총 발행량은 10,000마리며, 첫 공급은 514마리뿐입니다.',
    47: '다른 플레이어와 소셜 서클: 무작위 매치메이킹 및 무작위 할당 사이드 퀘스트.;',
    48: '친밀도 시스템; 실시간 온라인 커뮤니케이션 플랫폼에서 자유롭게 채팅.',
    49: '커뮤니티에 적용된 DAO 관리 경제;',
    50: '동맹을 기반으로 한 자원개발,분배,무역 및 약탈.',
    51: '게임 플레이, 게임 내 장면, 게임 아이템 및 키트 생성 및 공유;',
    52: '플레이어가 만든 게임 비디오 및 라이브 게시, 시청 및 보상.',
    53: '더 높은 속성과 영생;',
    54: '번식 제한 없음.',
    55: '회사정보',
    56: '자원',
    57: '제품',
    58: '우리 커뮤니티',
    59: 'BV 정보',
    60: '미디어 킷',
    61: '문의하기',
    62: '서비스 약관',
    63: '감사 보고서 ',
    64: '백서',
    65: '깃허브',
    66: '자주 묻는 질문',
    67: '게임',
    68: 'NFT마켓플레이스',
    69: '에어드랍',
    70: '토큰노믹스 $BVT',
    71: '컨트랙트 주소',
    72: 'Join IDO in',
    73: 'Buy $BVT in',
    74: '로드맵',
    75: '이전',
    76: 'NFT 및 Bovine Hero 토지 매입으로 30개 이상의 게임 길드와 협력.',
    77: '웹사이트 1.0.',
    78: 'BSC 및 Polygon에 NFT 배포.',
    79: '최초의 P2E 게임 인터스텔라 레인지랜드는 BSC에서 알파 단계 완료.',
    80: '토큰 마이닝 캠페인은 110만 달러의 최고치를 기록.',
    81: 'Project Galaxy와 함께하는 IGO 미스터리 박스 판매; 지구 및 메타버스 캠페인 5,000회 이상의 참여를 얻었습니다.',
    82: '첫 번째 게임 개발에서 GVG(길드 대 길드) 통합. 첫 번째 P2E 게임 인터스텔라 레인지랜드는 Polygon에서 알파 단계 완료.',
    83: 'NFT Farming- 5,000명 이상의 사용자가 참여했습니다. 1주년 기념 캠페인은 10,000개 이상의 참여를 얻었습니다.',
    84: '커뮤니티는 200,000명 이상의 팔로워를 늘림.',
    85: '유명한 Web3 프로젝트(Cyberconnect, DeGame, SnapFigure DAO 포함)와 파트너쉽 개인 판매 시작;인터스텔라 레인지랜드 베타 단계 시작.',
    86: '자체 NFT 마켓플레이스 출시',
    87: 'DAO - 커뮤니티; 프라이빗 세일 종료; BVT IDO 시작; 두번째 게임 - 인터스텔라 샌드박스 알파 단계 완료.',
    88: '더 많은 길드와 협력; 인터스텔라 샌드박스 퍼블릭 베타 단계 시작; NFT와 토큰을 포함한 암호화 자산은 두 게임 모두에서 활용될 수 있습니다. 생태 시스템을 BVT와 통합합니다.',
    89: '인터스텔라 블로그 소셜 플랫폼이 온라인. 세 번째 VR 게임인 인터스텔라 트렉 - 증강 현실 인터랙티브 게임 모드를 지원합니다.',
    90: 'e스포츠 게임 챌린지, 온라인 캠페인 등 오프라인 이벤트를 진행해 플레이어들에게 더 많은 재미와 도전을 선사할 예정이다.',
    91: '평행 세계에 대한 스타워즈로 메타버스 시작.',
    92: '스킨 박스',
    93: '최상급 건초',
    94: '최상급 경험카드',
    95: '최상급 STR 배터리',
    96: '최상급 HP 포션',
    97: '프라이빗 ',
    98: '팀',
    99: '유동성',
    100: '어드바이저&PR',
    101: '생태시스템',
    102: '아이팀',
    103: '거래소 상장',
    104: 'IDO 참여',
    105: '더 많은 정보 보기',
    106: '축구 퀴즈',
    107: '<p>퀴즈에 도전하여</p><p>상품을 받으세요!</p>',
    108: '엘리트 게이머',
  },
  igo: {
    1: '이름',
    2: '수량',
    3: '확률',
    4: '희귀성',
    5: 'Bovine Hero',
    6: '일반 소',
    7: '아이템 팩',
    8: '스킨 팩',
    9: '1. 미스터리 박스는 무엇을 들어있습니까?',
    10: '미스터리 박스는 BV의 기원 NFT입니다. IGO 참여자는 귀중한 게임 내 자산인 Bovine Hero, 일반 소, 아이템 팩 또는 스킨 팩을 획득할 수 있는 기회가 있습니다.',
    11: '2. 이러한 NFT의 적용은 무엇입니까?',
    12: '<strong>Bovine Hero</strong>는 BV의 세 평행 세계에서 사용할 수 있는 크립토 아바타입니다. 그들 각각은 최고의 자유도를 가지며 손으로 디자인되었으며 유일한 존재입니다.희귀도가 게임에서 프리미엄 속성을 결정합니다. 그들은 무제한 번식과 영생을 허용합니다.',
    13: `<strong>일반 소</strong>는 번식과 수명이 제한된 BV 게임의 주인공이기도 합니다. 일반 소는 또한 농업, 번식, 싸움 및 탐험에 참여하는 BV의 시민입니다.`,
    14: '<strong>게임 아이템 팩</strong> 에는 건초, 체력 원액, 경험 카드, 건강의 원액 및 닉네임 카드가 포함됩니다.',
    15: '<strong>스킨 팩</strong> 에는 일반 소와 BV을 위한 다양한 의상이 포함되어 있습니다. 다양한 디자인의 스킨은 소의 외형을 변화시켜 전투에서 더욱 돋보이게 합니다.',
    16: '이러한 모든 NFT는<strong>ERC 721</strong> 에 배포되며 NFT 마켓플레이스에서 자유롭게 거래할 수 있습니다. 게다가, 그것들은 시간과 공간 사이의 장벽을 깨는 크로스 게임 자산이므로 다른 게임에서 사용할 수 있습니다.미스터리 박스는 <strong>4월 5일</strong> Project Galaxy 통해 열 수 있습니다.',
    17: '3.미스터리 박스에 있는 각 NFT의 확률과 희귀도는 어떻게 됩니까?',
    18: '4. 화이트리스트는 어떤 이점을 얻을 수 있습니까?',
    19: '화이트리스트에 등록된 모든 사용자는 퍼블리 세일 48시간 전에 BV IGO에 참여할 수 있으며 퍼블리 세일 가격의 25% 할인을 받을 수 있습니다!',
    20: '5. 화이트리스트 캠페인에 참여하려면 어떻게 해야 합니까?',
    21: '1. BV OAT 홀더, <strong>440 화이트 리스트 지원</strong>',
    22: ` 각 참가자는 <a onclick="window.open('https://galaxy.eco/BV/campaign/GCaMWUUuJF');">BV 커뮤니티 배지 NFT</a> 또는 <a onclick="window.open('https://galaxy.eco/BV/campaign/GCaMWUUuJF');">BV Loyalty NFT</a>를 보유해야 합니다. 이 배지 소유자 중에서 <strong>440</strong>명의 행운의 당첨자가 선정됩니다.`,
    23: '',
    24: '',
    25: '',
    26: '2. 갤럭시 걸 홀더,<strong>500 화이트 리스트 지원</strong>',
    27: '이번 라운드에 참가하려면 <strong>Galaxy Girl 홀더러여야 합니다</strong>. <strong>Galaxy Girl 홀더 </strong>중에서 <strong>500</strong>명의 행운의 당첨자를 선정합니다. 선착순입니다.',
    28: '3. 커뮤니티 캠페인 우승자, <strong>500 화이트 리스트 지원</strong>',
    29: `화이트리스트<a onclick="window.open('https://gleam.io/sDYK8/community-https://gleam.io/IYT2r/BV-community-campaign');"> Gleam 이벤트</a>, 파트너 Giveaway 및 Discord 이벤트 참여자 중 500명을 선정하여 화이트리스트를 드립니다.`,
    30: '6. BV는 모든 이벤트에 대한 최종 해석의 모든 권리를 보유합니다.',
    31: '총 공급량:1800',
    32: '화이트 리스트',
    33: '각 150 USDT',
    34: '2022/3/18 12:00 - 2022/3/20 12:00(UTC)',
    35: '퍼블릭 세일',
    36: '각 200 USDT',
    37: '2022/3/20 12:00 - 2022/3/22 12:00(UTC)',
    38: '바로 구매',
    39: 'WL 라운드 갤럭시 걸',
    40: 'WL 라운드 BV OAT 및 커뮤니티 캠페인',
    41: '공개 라운드',
    42: '뒤',
  },
  blindBox: {
    1: '단가',
    2: '총 공급량',
    3: '일',
    4: '시간',
    5: '분',
    6: '초',
    7: '조각 합성',
    8: '바로 구매',
    9: '1. 헤일로 상자를 열면 무작위로 Bovine Hero,일반 소,소 박스, 별 조각을 획득할 수 있습니다. 조각을 모아 플래닛, Bovine Hero,일반 소를 뽑을 수 있습니다.',
    10: '2.헤일로 상자 단가: $50,총 공급량: 200,000. 개인 구매한도 없습니다.',
    11: '3.ERC-721에 배치된 헤일로 상자는 Tofu등 제3의 NFT 마켓플레이스 또는 BV의 마켓에서 자유롭게 거래할 수 있습니다.',
    12: '4.NFT 행성은 별 조각을 병합해야만 별 스월에서 얻을 수 있습니다. 스타 스월 페이지에서 플레이어는 별 조각을 합성하여 홈랜드과 겐랜드를 획득할 수 있습니다.',
    13: '5.참고: 별 조각을 제외하고 본 이벤트에서 획득한 아이템은 모두 티켓 형태로 배포됩니다.  이벤트를 통해 획득한 티켓은 내 지갑에서 확인하실 수 있습니다.  게임이 상장된 후 플레이어는 해당 티켓으로 게임 내 아이템으로 교환할 수 있습니다.',
    14: '',
    15: '',
    16: '내용',
    17: '확률',
    18: '헤일로 박스',
    19: 'Bovine Hero',
    20: '일반 소',
    21: '소 박스',
    22: '별 조각',
    23: '홈랜드 및 겐란드',
    24: '* 네 번째 라운드에서는 반드시 Bovine Hero 얻을 수 있습니다.한정 수량,선차순입니다.',
    25: '규칙',
    26: '스타 스월',
    27: 'Halo Chest에는 무엇이 포함되어 있습니까?',
    28: 'Galaxy Chest',
    29: 'Galaxy Chest is an NFT asset deployed on ERC-721. From Galaxy Chest, players can acquire Bovine Hero, Regular Cattle, Cattle Box, Star Fragment by chance, valuable in-game assets that have a great value in BV as well as in NFT marketplaces. In addition, Star Fragment can be collected to win NFT planets, precious and virtual land assets in BV.',
    30: 'issue number',
    31: 'Enter address to search qualified pre-order spots',
    32: 'search',
    33: 'FoMo',
    34: 'The FoMo Pool is set to reward those players who actively participate in this stargazer event. Whenever the player open a Halo Chest, the pool will be injected by 20,000 BVG. Every time player completes a swirl draw, the pool will be invested by 5,000 BVG. Every day, three active players will be able to split the total BVG in the pool produced the day before. ',
    35: 'Lucky Prospector',
    36: 'Land Researcher',
    37: 'Treasure Judge',
    38: 'Opening time',
    39: 'CounTotal FRAG consumedtdown',
    40: 'Total Halo Chests opened',
    41: 'Claimable BVG',
    42: 'Address',
    43: 'Countdown',
    44: 'Claim',
    45: 'Join the Last Swirl',
    46: 'Consume most FRAGs',
    47: 'Open most Chests',
    48: '미스터리 박스 개봉',
    49: 'NFT승인 ',
    50: '토큰을 액세스하려면 승인을 요청할 것이며 이 작업은 한번만 수행할 것입니다.',
    51: '개봉 확인 ',
    52: '미스터리 박스를 열리며 확인을 클릭하십시오.',
    53: '축하합니다, 받으세요',
    54: 'NFT 스테이킹 ',
    55: '스테이킹 확인',
    56: 'NFT 팜에 NFT를 스테이킹하며 클릭하세요.',
    57: '병합 확인',
    58: '미스터리 박스 구매를 확인하십시오.',
    59: '병합 확인',
    60: 'NFT 병합 확인하십시오',
  },
  halo: {
    1: '캐틀마트는 공식적으로 모두에게 열려 있습니다! 캐틀마트는 다양한 프리미엄 게임 아이템을 얻을 수 있는 특별한 미스터리 박스 - 헤일로 상자를 판매하는 한정 무역 센터입니다. 이 놀라운 시기에 우리는 베타 이전에 NFT, 행운의 추첨 및 게임을 모두 하나의 참여 경험으로 통합할 것이며 플레이어는 현재 하나 이상의 상자를 자유롭게 소유할 수 있습니다. 더 많은 특권을 누리기 위해 플레이어는 모두 다른 사람들을 캐틀마트 이벤트에 초대하고 추천인으로 추가 보상을 받을 수 있습니다! 그래서, 당신은 무엇을 기다리고 있습니까? 지금 바로 BV의 캐틀마트로 달려가세요!',
    2: '캐틀마트 설명',
    3: '1. 이 이벤트는 6월 23일 한국시간 기준 9:00 부터 BV에서 공식적으로 시작됩니다.',
    4: '2. 각 헤일로 상자의 가격은 $20이며 총 공급량은 20,000개 입니다. 각 주소에 대한 구매 한도는 없습니다.',
    6: '3. 친구 초대: 헤일로 상자를 구입하면 추천인 자격이 부여됩니다. 각 추천에 대해 총 지불액의 20%를 보상으로 받게 됩니다. 친구가 초대 코드로 지갑 주소를 입력하고 결제가 확인되면 자동으로 20%가 귀하의 주소로 전송됩니다.',
    7: "4. 화이트리스트에 등록된 사용자는 첫 주문 시 추가 10% 할인을 받을 수 있습니다. 귀하의 주소가 허용 목록에 있는지 확인하려면 여기를 <a href='https://docs.google.com/spreadsheets/d/1Xvuc6T7X8tS0nAiqOz3-Mm-WN-M_lANUb146FHEcswo/edit?usp=sharing'>클릭</a>하십시오. 화이트리스트에 있는 사용자는 기념일 OAT를 신청한 사용자, Bovine Hero,트위터 버드, Coser, Gambling King의 역할을 담당하고 제 시간에 주소를 제출한 사용자입니다.",
    8: '5. 헤일로 상자를 구매하면 Bovine Hero 교환권, 일반 소  교환권, 캐틀마트 박스 및  행성 조각 중 하나를 얻을 수 있습니다.',
    9: '6. 작은 행성 조각를 획득한 유저들은 조각 병합에 참여하여 플래닛, Bovine Hero, 일반 소 , 캐틀마트 박스 또는 BVG를 획득할 수 있습니다.',
    10: '  a. 조각을 병합하면 캐틀박스, 일반 소 , Bovine Hero, 플래닛 또는 일정한 수량의 조각과 BVG 중 하나를 얻을 수 있습니다.',
    11: '  b. 각 병합의 정확한 확률 테이블은 각 해당 페이지에 지정됩니다.',
    12: '  c. 이 모든 NFT는 TofuNFT, Element 및 Galler와 같은 2차 NFT 시장에서 자유롭게 거래할 수 있습니다.',
    13: '7. FOMO 풀은 캐틀마트에 적극적으로 참여하는 플레이어에게 보상을 제공합니다. 플레이어가 헤일로 상자를 열 때마다 5,000 BVG가 자동으로 풀에 추가됩니다. 플레이어가 병합을 완료할 때마다 2,000 BVG가 자동으로 풀에 추가됩니다. 매일 3명의 우승자(선택받은 자,플래닛 연구원, 골드 장인)가 수집한 BVG를 전날 풀에서 공유합니다. FOMO 보상은 한국식간 9:00시에 당첨자가 발표된 후 24시간 이내에 청구해야 합니다.',
    14: '  a. 선택받은 자: 50%, 최종 추점 참가자 (Top 1)',
    15: '  b. 플래닛 연구원: 30%, 당일 작은 행성 조각 많이 소비한 참가자 (Top 2)',
    16: '  c. 골드 장인: 20% 당일 헤일로 상자 많이 여는 참가자(Top 3)',
    17: '8. Bovine Hero을 뽑기 위해 조각을 병합하는 경우, 이 추첨에는 20개의 Bovine Heroes만 제공됩니다.선착순입니다.자세히 알아보려면 여기를 클릭하세요.',
    18: '9. 플레이어는 홈랜드와 겐랜드를 획득하기 위해 충분한 조각을 소비해야 합니다. 이 추첨에는 2개의 홈랜드만 제공됩니다. 선착순입니다. 자세히 알아보려면 여기를 클릭하세요.',
    19: '즉시 구매',
    20: '조각 병합',
    21: '내 헤일로 상자 열기',
    22: '얻을 수 있는 것',
    23: 'FOMO 상금 풀',
    24: '다음 라운드',
    25: '라운드',
    26: '선택받은 자',
    27: '플래닛 연구원',
    28: '황금 장인',
    29: '보상',
    30: '우승자',
    31: '클래임된',
    32: '언클래임 리워드',
    33: '당신이 이겼습니다! 보상을 수집하세요!',
    34: '라운드',
    35: '상금 풀',
    36: '게임 방법 배우로 가기 ',
    37: '<strong>설명:</strong> 헤일로 상자는 캐틀마트 이벤트에서 판매된 미스터리 박스로, 개봉후 랜덤으로 Bovine Hero,송아지, 행성 조각을 얻을 수 있습니다. 행성 조각은 홈랜드,겐랜드,유니랜드,Bovine Hero 및 송아지를 추점할 수 있습니다.',
    38: '추천인 주소',
    39: '붙여넣기',
    40: '*없으시면 비워두세요',
    41: '총',
    42: '할인 합계 (화이트 리스트만)',
    43: '10개 추가',
    44: '내 헤일로 상자 열기',
    45: '< 홈',
    46: '*하나의 주문을 완료하면 친구에게 초대 코드(지갑 주소)를 공유하여 주문의 20%를 추천인 보상으로 추가로 받을 수 있습니다.',
    47: '사용 가능한 행성 조각 수:',
    48: '한 번 합성하며 필요한 조각:',
    49: '남은 금액:',
    50: '확률:',
    51: '가능성 Bovine Hero',
    52: '합성에 필요한 조각:',
    53: '병합',
    54: '더 구매하기',
    55: '박스 열기',
    56: 'Burn',
    57: '얻을 수 있는 것',
    58: '라운드',
    59: '조각',
    60: '1 조각 및 50,000 BVG',
    61: '2 조각 및 100,000 BVG',
    62: '1. 캐틀마트 상자를 열면 무작위로 일반 소  또는 작은 행성 조각 1개를 얻을 수 있습니다.',
    63: '2. 이 추첨에는 캐틀마트 상자에 대한 공급 한도가 없습니다.',
    64: '3. 병합을 확인하면 병합에 사용한 5개의 작은 행성 조각이 소각됩니다.',
    65: '1. 이 추첨에 송아지에 대한 공급 한도가 없습니다.',
    66: '2. 병합을 확인하면 병합에 사용한 10개가 소각됩니다.',
    67: '1. 조각 병합은 20개 Bovine Hero만 제공됩니다. 선착순입니다.',
    68: '2. 4 라운드 이전에 Bovine Hero을 획득하면 1라운드부터 재개됩니다.',
    69: '3. 이 병합에서 얻을 수 있는 소는 제한이 없습니다. 즉, 각 플레이어는 이 병합에서 둘 이상의 Bovine Hero을 얻을 수 있습니다.',
    70: '1. 이 병합에는 2개 홈랜드만 제공됩니다. 선착순입니다.',
    71: '2. 얻을 수 있는 행성은 제한이 없습니다. 즉, 각 플레이어는 이 병합에서 하나 이상의 행성을 얻을 수 있습니다. 그러나 각 주소는 하나의 행성에 합류하는 것으로 제한됩니다. 즉, 하나 이상을 보유하는 경우 하나를 거래할 수 있습니다.',
  },
  farm: {
    1: '백그라운드',
    2: '스테이킹 토큰 선택',
    3: '락업 수량',
    4: '내 스테이킹 총 수량',
    5: '스테이킹할 수 있는 할당량',
    6: '풀에 스테이킹된 수량 ',
    7: '남은 보상',
    8: '상세내역',
    9: '클래임 된 보상',
    10: '언클래임 된 보상',
    11: '수많은 보물들이 BV의 광대한 땅 밑에 묻혀 있습니다. 황소자리 121년, 마이닝는 소 시민들 사이에서 매우 입소문이 납니다. 두 개의 주요 동맹 중 하나인 크로스 스타 리버티 리그(Cross-Star Liberty League)는 제페롤 글로리 회사(BNB), 노동자 채굴 유니언(USDT) 및 월리 채굴 회사(FIL)라는 3개의 채굴 회사가 이 넓은 우주를 탐험할 것을 요청했습니다. 500만 BVG의 일일 채굴 보상은 이 세 회사에 각각 50%, 30%, 20%로 분배됩니다.플레이어는 원하는 조직에 자유롭게 가입할 수 있으며 각 플레이어는 10 BNB, 5000 USDT 및 100 FIL로만 스테이킹 할당을 받습니다.얻은 모든 수익은 크로스 스타 리버티 리그의 범용 토큰인 BVG 기반으로 합니다.',
    12: '하이라이트',
    13: '금고',
    14: '낮은 문턱',
    15: '고수익',
    16: '시간 제약 없음',
    17: '디플레이션 메커니즘',
    18: '유틸리티 토큰',
    19: '예상 APR',
    20: '콘트랙트 주소',
    21: '마이닝:',
    22: 'BVG:',
    23: '마이닝 및 보상 토큰 BVG에 관한 콘트랙트는 Certik 관계자의 공식 승인을 받았습니다.CertiK는 블록체인 보안 분야에서 가장 빠르게 성장하고 가장 신뢰할 수 있는 회사 중 하나이자 블록체인 보안의 선구자이기도 하며, 동급 최강의 공식 검증 및 AI 기술을 활용하여 블록체인, 스마트 콘트랙트 및 Web3 앱을 보호하고 모니터링합니다. ',
    24: '여기를',
    25: '클릭하여',
    26: '감사 보고서를 검토하십시오.',
    27: '백그라운드',
    28: '인터스텔라 채굴은 끝났나요? 아닙니다. 메타버스의 탐구는 끝이 없습니다. 이것은 단지 시작일 뿐입니다! BV에는 BVG로 교환할 수 있는 수많은 부와 자산이 있습니다. 또한, OAT(On-chain Achievement Token)는 초기에 없어서는 안될 필수 재료 중 하나로 매우 중요한 역할을 합니다. Bovinians, 힘내자! OAT는 BNB 체인(Binance Smart Chain) 기반의 Web3 인프라 플랫폼 Project Galaxy에서 BV가 출시한 지정 배지입니다. 지금까지 5개만 출시되었습니다. 그들은 희귀하고 귀중한 온체인 자격 증명이며 또한 BV 생태 사회적 정체성의 인증서입니다.',
    29: '정보',
    30: '1. 이벤트 기간: 30일 ',
    31: '3. 일일 보상, 1,666,667 BVG, 총 50,000,000 BVG.',
    32: '4. 이러한 NFT는 수요 스테이킹입니다. 즉, 언제든지 스테이킹 및 언스테이킹이 가능합니다.',
    33: '5. 각 NFT의 해시레이트는 희귀도에 따라 다릅니다. 희소할수록 해시레이트가 높아집니다.',
    34: '6. IGO 미스터리 박스의 해시레이트는 OAT의 최고 해시레이트보다 10배 더 높습니다.',
    35: '내 언스테이킹 NFT',
    36: '내 스테이킹한 NFT',
    37: '이름',
    38: 'Token ID',
    39: 'Power',
    40: '힘',
    41: '2. 컨트렉트',
    42: '언클래임 보상',
    43: '클래임된 보상',
  },
  panel: {
    1: '계정',
    2: 'NFT',
    3: '친밀관계',
    4: '개인정보',
    5: '성별',
    6: '지갑',
    7: '닉네임',
    8: '플래닛',
    9: '플래닛의 승률',
    10: '결과',
    11: '승률',
    12: '게임 수익',
    13: '남',
    14: '여',
    15: '플래닛',
    16: 'Bovine Hero',
    17: '일반 소',
    18: '미스터리 박스',
    19: '기타',
    20: '필터',
    21: '초기화',
    22: '유형',
    23: '혈통',
    24: '개 아이템',
    25: '황소',
    26: '젖소',
    27: '내 리스팅',
    28: '높은가격순',
    29: '낮은가격순 ',
    30: '최신 리스팅',
    31: '폐지',
    32: '내역',
    33: '플래닛 임대',
    34: '데이터 없음',
    35: '배지'
  },
  detail: {
    1: '기본정보',
    2: '기본속성',
    3: '전투 속성',
    4: '착유',
    5: '생명치',
    6: 'EXP',
    7: '체력치',
    8: '전투력',
    9: '스테미나',
    10: '방어력',
    11: '생산능력',
    12: '생산속도',
    13: '홀더',
    14: '지금구매',
    15: '판매',
    16: '오픈',
    17: '이체',
    18: '컨트렉트 주소',
    19: '토큰 ID',
    20: '설명',
    21: '인원수',
    22: '세금',
    23: '이체',
    24: '이체할 {0} 주소를 입력하세요.',
    25: '아바타 선택',
    26: '수량',
    27: '폐지',
    28: '이름',
    29: '현황',
    30: '수량',
    31: '가격',
    32: '판매된',
    33: '구매된',
    34: '시간',
    35: '부모님의 정보',
    36: '아버지',
    37: '어머니',
    38: '리스팅 설정',
    39: '고정 가격 설정',
    40: '비용',
    41: '총 판매액의 2%는 플랫폼 수수료로 BV에 지불됩니다.',
    42: 'NFT판매',
    43: '판매 확인',
    44: 'NFT 마켓에 NFT 판매 확인',
    45: '주문 확인',
    46: '지불할 금액을 확인하십시오',
    47: '사용 가능한 잔액',
    48: '잔액 부족합니다. 구매하러 가기 >',
    49: '징수된 세금',
    50: '커플',
    51: '브라더스',
    52: '남베프',
    53: '베프',
    54: '거래 확인',
    55: '지갑승인',
    56: '토큰 거래 하기 전에 승인이 필요하며 한 번만 승인하며 됩니다',
    57: '거래 확인',
    58: '미스터리 박스 구매 확인',
    59: '병합 확인',
    60: '확인을 클릭하여 NFT 병합을 진행합니다',
    61: '남은 생면 시간',
    62: '참고: 이 미스터리 박스는 인터스텔라 레인지랜드 오픈할 수 있습니다.',
    63: '임대',
    64: '임대 중',
    65: '취소',
    66: '임대 중',
    67: '임대 갱신',
    68: '* 임대 시간',
    69: '* 임대 기간 중 언제든지 임대를 갱신할 수 있습니다.',
  },
  card: {
    1: '소 미스터스 박스 ',
    2: 'IGO 미스터리 박스',
    3: 'Bovine Hero',
    4: '일반 소',
    5: '아이템 팩',
    6: '스킨 팩',
    7: 'IGO 미스터리 박스는 BV의 기원 NFT입니다. IGO 참가자만이 귀중한 게임 내 자산인 Bovine Hero, 일반 소, 아이템 팩 또는 스킨 팩을 획득할 수 있는 기회가 있습니다. 이러한 모든 NFT는 ERC 721에 배포되며 NFT 마켓플레이스에서 자유롭게 거래할 수 있습니다. 게다가, 자산은 시간과 공간의 장벽을 깨는 크로스 게임 자산입니다. 즉, 다른 게임에서 사용할 수 있습니다.',
    8: 'Bovine Hero는 BV의 세 평행 세계에서 사용할 수 있는 크립토 아바타입니다. 그들 각각은 최고의 자유도를 가지며 손으로 디자인되었으며 유일한 존재입니다. 희귀도가 게임 내 프리미엄 속성을 결정합니다. 그들은 무한한 번식과 영생을 허용합니다. 이러한 NFT는 ERC 721에 배포되며 NFT 마켓플레이스에서 자유롭게 거래할 수 있습니다. 게다가, 그것들은 시간과 공간 사이의 장벽을 깨는 크로스 게임 자산입니다. 즉, 다른 게임에서 사용할 수 있습니다.',
    9: '일반 소는 번식과 수명이 제한된 BV 게임의 주인공이기도 합니다. 일반 소는 또한 농업, 번식, 전투 및 탐험에 참여하는 BV의 시민입니다. 이러한 NFT는 ERC 721에 배포되며 NFT 마켓플레이스에서 자유롭게 거래할 수 있습니다. 게다가, 그것들은 시간과 공간 사이의 장벽을 깨는 크로스 게임 자산입니다. 즉, 다른 게임에서 사용할 수 있습니다.',
    10: 'BV의 아이템 팩에는 건초, 체력 원액, 경험 카드, 건강한 원액 및 닉네임 카드가 포함되어 있습니다. 이들은 모두 일반 소나 Bovine Hero의 속성을 강화하는 데 사용할 수 있는 게임 아이템입니다. 이 팩은 ERC 721에 배포되며 NFT 마켓플레이스에서 자유롭게 거래할 수 있습니다. 게다가, 그것들은 시간과 공간 사이의 장벽을 깨는 크로스 게임 자산입니다. 즉, 다른 게임에서 사용할 수 있습니다.',
    11: '다양한 디자인의 스킨은 소의 외형을 변경시켜 전투에서 더욱 돋보이게 만듭니다. 이러한 NFT는 ERC 721에 배포되며 NFT 마켓플레이스에서 자유롭게 거래할 수 있습니다. 게다가, 그것들은 시간과 공간 사이의 장벽을 깨는 크로스 게임 자산입니다. 즉, 다른 게임에서 사용할 수 있습니다.',
    12: '홈랜드',
    13: '유니언랜드',
    14: '겐란드',
    15: '홈랜드는 인터스텔라 레인지랜드의 고급 플래닛 NFT입니다. 홈랜드의 회장은 거주하는 플레이어와 유니랜드 행성에 세금을 부과하고, 행성의 수입을 분배하고, 행성을 개발하고 부자가 되도록 이끌 수 있습니다. 홈랜드의 회장은 최대 10,000명의 회원으로 길드를 관리할 수 있습니다.',
    16: '',
    17: '',
    18: '캐틀마트 상자',
    19: '일반 소  교환권',
    20: 'Bovine Hero 교환권',
    21: '겐랜드 교환권 ',
    22: '홈랜드 교환권 ',
    23: '행성 조각',
    24: '캐틀마트 상자에서 무작위로 일반 소  또는 행성 조각을 얻을 수 있습니다. 송아지는 농사, 번식, 전투를 통해 토큰을 얻을 수 있습니다. 통합 페이지에서 행성 조각을 병합하여 Bovine Hero, 일반 소 , 플래닛, 겐랜드 NFT를 획득할 수 있습니다.',
    26: 'Bovine Hero랑 마찬가지로 송아지는 인터스텔라 레인지랜드의 특별한 캐릭터로 BVG 및 BVT 보상을 얻기 위해 채굴, 번식, 전투 및 탐험에 적극적으로 참여합니다. ERC-721에 배포된 송아지는 마켓플레이스에서도 거래할 수 있습니다. ',
    27: 'BV의 NFT 자산 중 하나입니다. Bovine Hero는 한정 수량으로 출시되어 ERC-721에 배포되며 NFT 마켓플레이스에서 자유롭게 거래할 수 있습니다. 일반 소에 비해 Bovine Hero는 더 높은 속성을 있을 뿐만 아니라 무제한 번식 및 생명을 가지고 있습니다.시간과 공간의 경계를 허무는 크로스 게임 캐릭터입니다.',
    28: 'BV의 희귀 토지 자산 NFT는 1,000개를 수용할 수 있는 홈랜드 및 유니랜드의 회원이 구축했습니다. 겐랜드 대통령은 지역 및 연결된 유니랜드에 세금을 부과할 권리가 있습니다.',
    29: '홈랜드는 인터스텔라 레인지랜드의 고급 플래닛 NFT입니다. 홈랜드의 회장은 거주하는 플레이어와 유니랜드 행성에 세금을 부과하고, 행성의 수입을 분배하고, 행성을 개발하고 부자가 되도록 이끌 수 있습니다. 홈랜드의 회장은 최대 10,000명의 회원으로 길드를 관리할 수 있습니다.',
    30: '행성 조각을 모아서 병합 게임플레이를 플레이하여 홈랜드, 겐랜드, Bovine Hero, 캐틀마트 상자 및 NFT를 얻을 수 있습니다. 조각을 소비하면 FOMO 상품을 받을 수 있는 기회가 있습니다.',
    31: '헤일로 상자',
    32: '헤일로 상자는 캐틀마트 이벤트에서 판매된 미스터리 박스로, 개봉후 랜덤으로 Bovine Hero,일반 소 , 행성 조각을 얻을 수 있습니다. 행성 조각은 홈랜드,겐랜드,유니랜드,Bovine Hero 및 송아지를 추점할 수 있습니다. 하루에 가장 많은 헤일로 상자를 여는 플레이어는 FOMO 상금 풀에 참여할 수 있습니다.',
    33: '황소과 젓소 짝짓기한 후 소 미스터리 박스를 획드할 수 있습니다. ERC-721에 배포된 미스터리 박스는 BV의 NFT 자산 중 하나입니다. 미스터리 박스를 오픈하면 일정 확률로 Bovine Hero,일반 소 , 소 조각을 얻을 수 있습니다.',
    34: 'BV의 NFT 자산 중 하나입니다. Bovine Hero는 한정 수량으로 출시되어 ERC-721 에 배포되며  NFT 마켓플레이스에서 자유롭게 거래할 수 있습니다. 일반 소에 비해  Bovine Hero는 더높은 속성을 있을 뿐만 아니라 무제한 번삭 및 생명을 가지고 있습니다.시간과 공간의 경계를 허무는 크로스 게임 캐릭터입니다.',
    35: 'Bovine Hero랑 마찬가지로 일반 소는 인터스텔라 레인지랜드의 특별한 캐릭터로 BVG 및 BVT 보상을 얻기 위해 채굴, 번식, 전투 및 탐험에 적극적으로 참여합니다.',
    36: 'BV 게임 미스터리 박스',
    37: 'BinanceNFT에서 판매되는 BV Blind Box에는 전설적인 목장의 암호 전투 및 암호 착유에 참여하는 데 필요한 독점 게임 캐릭터가 포함되어 있습니다. 독점 상자를 열면 $1,000 Genesis Cow, $200 Regular Maverick 또는 아이템 팩을 얻을 수 있는 기회가 주어집니다. 블라인드 박스를 연 후 공식 브릿지를 통해 폴리곤에 연결해야 합니다.',
    38: '청동 배지',
    39: '실버 배지',
    40: '금배지',
    41: '플래티넘 배지',
    42: '다이아몬드 배지',
    43: '영광의 휘장',
  },
  airdrop: {
    1: '주소를 입력하세요',
    2: '검색',
    3: '수소',
    4: '유형',
    5: '수량',
    6: '이벤트',
    7: '현황',
    8: '수령된',
    9: '만료된',
    10: '클래임',
    11: '동결',
    12: '수령 중',
    13: '체인',
    14: '1. 에어드랍 보상에는 BNB 체인 및 Polygon 체인의 토큰과 NFT가 포함됩니다. 올바른 네트워크가 연결된 상태에서 보상을 클릭하십시오.',
    15: '2. 지갑과 웹페이지를 반드시 연결하십시오.',
    16: '3. 에어드랍 보상은 발표일로부터 다음 달 1일 근무일부터 신청 가능하며, 일주일 후 마감됩니다.',
    17: "4. 이 기간 내에 <a href='https://BV.medium.com/claiming-airdrop-rewards-tutorial-c4f428bcea3' target='_blank'>단계별 튜토리얼</a>을 확인하십시오.",
    18: '5. 여기를 클릭하여 단계별 튜토리얼을 확인하십시오.',
    19: '6. 이벤트에 대한 최종 해석권은  BV Official에 있습니다.',
    20: "7. 모르는 사항은 있으시면 여기를 통해 <a href='mailto:BV{'@'}gmail.com' target='_blank'>문의</a>.하십시오.",
    21: '에어드랍 클래임 방법 알아보기 ',
    22: '설명',
  },
  rangeland: {
    1: '시작하기',
    2: '마켓 플레이스',
    3: '데모',
    4: '리더보드',
    5: '리더보드 {0}주차',
    6: '착유',
    7: '번식',
    8: '기여도',
    9: '착유 량',
    10: '순위',
    11: '닉네임',
    12: '플레이어',
    13: '출시 예정',
    14: '복제 수',
    15: '세금',
  },

  // 土地售卖领取
  claimAssets: {
    1: '홈랜드',
    2: '홈랜드는 인터스텔라 레인지랜드의 고급 플래닛 NFT입니다. 홈랜드의 회장은 거주하는 플레이어와 유니랜드 행성에 세금을 부과하고, 행성의 수입을 분배하고, 행성을 개발하고 부자가 되도록 이끌 수 있습니다. 홈랜드의 회장은 최대 10,000명의 회원으로 길드를 관리할 수 있습니다.',
    3: '소 미스터스 박스',
    4: '황소과 젓소 짝짓기한 후 미스터리 박스를 획드할 수 있습니다. ERC-721에 배포된 미스터리 박스는 BV의 NFT 자산 중 하나입니다. 미스터리 박스를 오픈하면 일정 확률로 Bovine Hero,일반 소, 소 조각을 얻을 수 있습니다.',
    5: 'Bovine Hero',
    6: 'BV의 NFT 자산 중 하나입니다.Bovine Hero는 한정 수량으로 출시되어 ERC-721에 배포되며 NFT 마켓플레이스에서 자유롭게 거래할 수 있습니다. 일반 소에 비해 Bovine Hero는 더 높은 속성을 있을 뿐만 아니라 무제한 번식 및 생명을 가지고 있습니다.시간과 공간의 경계를 허무는 크로스 게임 캐릭터입니다.',
    7: '일반 소',
    8: 'Bovine Hero랑 마찬가지로 일반 소는 인터스텔라 레인지랜드의 특별한 캐릭터로 BVG 및 BVT 보상을 얻기 위해 채굴, 번식, 전투 및 탐험에 적극적으로 참여합니다. ERC-721에 배포된 일반 소는 마켓플레이스에서도 거래할 수 있습니다.',

    9: '클래임',
    10: '지금 신청',
    11: '플래닛',
    12: 'Bovine Hero',
    14: '컨트랙트',
    15: '발행량',
    16: '적격자',
    17: '화이트리스트',
    18: '클래임할 수 있는 수량',
    19: '클래인된 수량',
    20: '자격 없음',

    21: 'TBVG는 게임 참여, 에어드랍, 채굴, 스테이킹 등을 통해 얻을 수 있는 BV에서 발행하는 게임 화폐입니다.  게임이 온라인 상태가 된 후 플레이어는 PVE와 같은 작업을 통해 무료로 TBVG를 얻을 수 있습니다. TBVG는 게임 아이템 구매 및 거래 진행 등에 사용할 수 있습니다.',
    22: 'TBVT는 게임 참여, 에어드랍등을 통해 얻을 수 있는 BV에서 발행하는 게임 화폐입니다.  TBVT는 게임 아이템 구매,번식, 레벨 업 및 거래등에 사용할 수 있습니다. 플레이어가 TBVT가 많을수록 BV의 DAO 거버넌스에서 더 많은 표를 얻을 수 있습니다.',

    23: 'COMING SOON',
    24: '모두 유저',
    25: '민트됨',
    26: '미스터리 박스',
    27: '설명',
    28: '제한 없음',
    29: '공급 부족',
    30: '수령했습니다',
    31: '자격 없음',
    32: '시작하기',
  },

  // 公测拉新邀请
  referral: {
    1: '공식 베타',
    2: '초대 이벤트',
    3: '(시즌 1)',
    4: '역할',
    5: '추천인 주소',
    6: '붙여넣기',
    7: '확인',
    8: '총 초대 인원수',
    9: '유효 추천인수',
    10: '언클래임 보상',
    11: '클래임된 보상',
    12: '선물 팩',
    13: '최상급 건초',
    14: '최상급 STR 배터리',
    15: '최상급 EXP 카드',
    16: 'HP 포션',
    17: '베타 보상 받기',
    18: "최소한 성곡적으로 황소/젖소 두 마리 키워야합니다. <a href='https://www.youtube.com/watch?v=HiZxJo4IfrM' target='_blank'>배우로 가기</a>",
    19: "착유에서 최소 200 TBVT 또는 전투에서 1000 TBVT 를 받습니다. <a href='https://www.youtube.com/watch?v=_UQ_NCppv0U' target='_blank'>배우로 가기</a>",
    20: "길드에 대한 세금 기여도는 최소 100 TBVT 입니다. <a href='https://www.youtube.com/watch?v=-5HH7lIcWCg' target='_blank'>배우로 가기</a>",
    21: "테크 트리에서 최소한 3급까지 업그레이드해야합니다. <a href='https://www.youtube.com/watch?v=qb704wLapdU' target='_blank'>배우로 가기</a>",
    22: "농장/목장의 레벨은 2급 이상이어야 합니다. <a href='https://doc-en.bovine-verse.games/interstellar-rangeland-beta/stall/stall-player-level' target='_blank'>배우로 가기</a>",
    23: '아래는 주소에 대한 정보',
    24: '주소',
    25: '현황',
    26: '초대 트랙',
    27: '화이트리스트',
    28: '신규 가입자',
    29: '클래임',
    30: '초대받은 주소',
    31: '아직 초대한 사람이 없습니다.',
    32: '1. 플레이어의 지원에 감사하시 위해 화이트리스트 사용자를 위한 베타 초대 이벤트를 개최하여 20,000 USDT 상당의 상금 풀을 분할합니다.',
    33: '2. 화이트리스트 사용자는 게임 내 활동의 스냅샷을 기반으로 선택됩니다.',
    34: '3. 화이트리스트에 등록된 사용자만 이벤트에 참여할 수 있는 추천인이 될 수 있으며,  20,000 USDT 상당의 상금을 공유할 수 있습니다.',
    35: '4. 각 화이트리스트 사용자는  P2E 게임 인터스텔라 레인지랜드에 친구를 초대할 수 있습니다. 초대할 수 있는 인원수는 제한 없습니다.',
    36: '5. 각 유효한 초대에 대해 화이트리스트 사용자는 5 USDT를 보너스로 받을 수 있으며 신규 사용자는 이벤트 페이지를 통해 선물 팩을 받을 수 있습니다.',
    37: '6. 이벤트의 공정성을 보장하기 위해 각 화이트리스트 사용장에 대한 보너스 한도를 최대 50 USDT로 설정했습니다.',
    38: '7. 유효한 초대로 간주하려면 신규 사용자가 먼저 화이트리스트 사용자의 주소를 입력하여 추천인으로 연동한 다음 5가지 게임 내 미션을 완료해야 합니다.',
    39: '8. 이번 초대 이벤트는 베타 기간 동안 진행됩니다. 시즌 1 화이트리스트에 없으시 분들은 적극적으로 참여해 주시면 다음 시즌 기회가 주어집니다.',
    40: "9. 사용자가 각 미션을 따라 비디오를 참조하거나 게임 플레이 배우하기 위해  <a href='https://discord.com/invite/BV' target='_blank'>BV 커뮤니티에</a> 가입할 수 있습니다.",
    41: '10. 사용자는 초대 트랙을 사용하여 미션 세부 정보 및 초대 상태를 확인할 수 있습니다.',
    42: '11. 이벤트의 대한 최종 해석권은 BV Official에 있습니다.',
    43: '적립 방법 알아보기',
    44: '이벤트 설명',
  },

  // 公测资产兑换
  assetsExchange: {
    1: '건초',
    2: 'STR 배터리',
    3: 'EXP 카드',
    4: '스킨 미스터리 박스',
    5: '닉네임 카드',
    6: '교환',
    7: '건초',
    8: '상급 건초',
    9: '최상급 건초',
    10: 'STR 배터리',
    11: '상급 STR 배터리',
    12: '최상급 STR 배터리',
    13: 'EXP 카드',
    14: '상급 EXP 카드',
    15: '최상급 EXP 카드',
    16: '스킨 미스터리 박스',
    17: '닉네임 카드',
    18: '교환권을 사용하려면 농장 레벨이 최소 Lv.2여야 합니다.',
    19: '교환권을 사용하려면 최소 3000TBVT를 벌어야 합니다.',
    20: ' 교환권',
    21: '총 공급',
    22: '단가',
    23: 'TBVT 잔액',
    24: '총 금액',
    25: '오늘 남은 한도',
    26: '교한에는 다음 조건을 필요합니다.',
    27: '1. 농장 레벨 2급 이상 ',
    28: '2. 누적 TBVT 수익: 3000개 이상',
    29: '단일 주소에 대한 일일 교환 소비 한도: 10W TBVT',
    30: '모두 아이템은 교환 후 쿠폰 (NFT)으로 표시됩니다. 공식 런칭 후, 사용자는 쿠폰으로 공식 자산을 교환할 수 있습니다.',
    31: '교환 방법 알아보기 ',

    32: '사용 시 STR을 부여합니다.',
    33: '사용 시 STR을 부여합니다.',
    34: '사용 시 STR을 부여합니다.',
    35: '사용 시 EXP를 부여합니다.',
    36: '사용 시 EXP를 부여합니다.',
    37: '사용 시 EXP를 부여합니다.',
    38: '사용 시 스태미나 부여합니다.',
    39: '사용 시 스태미나 부여합니다.',
    40: '사용 시 스태미나 부여합니다.',
    41: '사용 시 수명을 2일 연장합니다.',
    42: '사용 시 수명을 5일 연장합니다.',
    43: '사용 시 수명을 10일 연장합니다.',
    44: '무작위로 스킨 NFT 1개 획득',
    45: '닉네임 카드를 사용하면 플레이어가 이름을 변경할 수 있습니다.',

    46: '건초 교환권',
    47: '상급 건초 교환권',
    48: '최상급 건초 교환권',
    49: 'STR 배터리 교환권',
    50: '상급 STR 배터리 교환권',
    51: '최상급 STR 배터리 교환권',
    52: 'EXP 카드 교환권',
    53: '상급 EXP 카드 교환권',
    54: '최상급 EXP 카드 교환권',
    55: '스킨 미스터리 박스 교환권',
    56: '닉네임 카드 교환권',

    57: '품절',
    58: '헤일로 상자',
    59: '공식 베타 자산',
    60: 'IGO 미스터리 박스(Polygon)',
    61: '아이템 교환권',
    62: '즉시 교환',
    63: '성체 소 교환권',
    64: '소유됨',
    65: '총 {0} 개가 교환되었습니다. 비용은 {1} BVT 입니다.',
    66: '교환할 교환권을 선택하세요.',
    67: '기타 교환권',
  },

  // 资产道具购买
  gameStore: {
    1: '송아지',
    2: 'HP포션',
    3: 'STR 배터리',
    4: '경험카드',
    5: '스킨 팩',
    6: '송아지',
    7: 'HP 포션',
    8: '상급 HP 포션',
    9: '최상급 HP 포션',
    10: '거래 내역',
    11: '번호',
    12: '원가',
    13: '가격',
    14: '구매',
    15: '구매 수량',
    16: '비용',
    17: '잔액',
    18: '시각',
    19: 'STR 배터리',
    20: '상급 STR 배터리',
    21: '최상급 STR 배터리',
    22: 'EXP 카드',
    23: '상급 EXP 카드',
    24: '최상급 EXP 카드',
    25: '스킨 미스터리 박스',
  },

  // 公会拉新活动
  guild: {
    1: '동업 조합',
    2: '엘리트',
    3: '1단계',
    4: '참여 자격 신청',
    5: '최소 30명으로 커뮤니티 구축해야합니다.(WeChat/TG/기타 소셜 미디어)',
    6: '남은 자리: {0}',
    7: '베타 자산 청구',
    8: '신청하기',
    9: '2단계',
    10: '커뮤니티 구축',
    11: '1. 베타 자산 청구 페이지에서 미스티리 박스를 클래임하십시오.',
    12: '2. 송아지를 황소/젖소에게 먹이고 일정량의 STR을 주십시오.',
    13: '3. 전투 또는 착유의 스크린샷(WeChat 모멘트(모두에게 공개) 또는 트위터에서 공유)',
    14: '-모멘트: #BV 해시태그 포함',
    15: "-트위터: {'@'}BV",
    16: '4. 길드 회장은 모든 유효한 주소와 증명 스크린샷을 수집하여 여기 또는 BV 공식 관리자에게 제출해야 합니다.',
    17: '3단계',
    18: '최고의 보상을 받자',
    19: '1단계: 길드 보상 100 USDT',
    20: '조건: 커뮤니티가 최소 30명의 신규 회원에 도달하고 유효한 주소의 수가 최소 10개 있어야 합니다.',
    21: '2단계: 길드 보상 500 USDT',
    22: '조건: 커뮤니티가 최소 100명의 신규 회원에 도달하고 유효한 주소 수가 최소 60개 있어야 합니다.',
    23: '3단계: 길드 보상 1000 USDT 및 행성 공로 배지 NFT',
    24: '조건: 커뮤니티가 최소 200명의 신규 회원에 도달하고 유효한 주소가 최소 150개 있어야 합니다.',
    25: '4단계: 길드 보상 2000 USDT 및 행성 회장 배지 NFT',
    26: '조건: 커뮤니티가 최소 500명의 신규 회원에 도달하고 유효한 주소의 수가 최소 500개 있어야 합니다.',
    27: '클래임',
    28: '민트',
    // ELITES 精英
    29: '유효한 주소가 되십시오.',
    30: '1. 베타 자산 청구 페이지에서 미스터리 박스를 클래임하십시오.',
    31: '2. 송아지를 황소/젖소에게 먹이고 일정량의 STR을 주십시오.',
    32: '3. 전투 또는 착유의 스크린샷(WeChat 모멘트(모두에게 공개) 또는 트위터에서 공유)',
    33: '-모멘트: #BV 해시태그 포함',
    34: "-트위터: {'@'}BV",
    35: '4. 주소와 함께 증명서를 길드 회장에게 제출하십시오.',
    36: '배지 받기',
    37: '커뮤니티의 증명서 제출',
    38: '그룹 이름에는 BV가 포함되어야 합니다.',
    39: '자격을 갖춘 각 회장은 베타 플래닛을 신청할 수 있으며 그들의 회원은 행성에 가입하거나 다른 사람을 선택할 수 있습니다. 플래닛의 이름은 그룹 이름과 같아야 합니다.',
    40: '검토를 위해 유효한 주소를 BV 공식 관리자에게 제출해야 합니다.',
    41: '수령됨',
  },
  // 徽章空投
  oatBadge: {
    1: '$BVT 구매',
    2: '적격 OATs',
    3: '당신의 $BVT 보상',
    4: '남은 자리',
    5: '레벨',
    6: '수량',
    7: '이벤트 설명',
    8: '1. 보상은 8월 23일 UTC 오전 11시에 청구할 수 있습니다.',
    9: '2. 각 등급 보상은 특정 수의 OAT 보유자에게만 제공합니다. 선착순입니다.',
    10: '4. BVT 컨트렉트 주소 : ',
    11: '5. 본 이벤트에 대한 최종 해석권은 BV Official에 있습니다.',
    12: '$BVT 더 구입하기',
    13: '커뮤니티 가입하기',
    14: '등급',
    15: 'BVT 수량/홀더',
    16: '당첨자 (명)',
    17: '3.우리는 발행한 OAT의 유형을 분류했으며 프로젝트 갤럭시에서 처음 청구한 OAT 유형을 기반으로 각 보유자의 총 가중치를 계산할 것입니다. 스냅샷은 8월 22일에 촬영되었습니다.',
  },
  // LAZIO 质押
  lazio: {
    1: '더 구매하기 ',
    2: '더 보기 ',
    3: '이벤트 종료 시간',
    4: '사용가능한 잔액 ',
    5: '입금',
    6: '추천 주소',
    7: '붙이기',
    8: '기간 ',
    9: '유연한',
    10: '3일 ',
    11: '7일',
    12: '예상 APY',
    13: '총 스테이킹',
    14: '풀에 스테이킹된 금액',
    15: '총 보상',
    16: '컨트랙트',
    17: '더 보기 ',
    18: '이벤트 설명',
    19: '1. 이벤트 시간: 9월 16일 21:00-  10월 1일 21:00 (한국 시간)',
    20: '2. 이 이벤트는 모든 BVT 보유자를 위한 추가 크레딧이므로 이 스테이킹에 대한 $BVT의 최소 요구 사항은 없습니다.',
    21: '3. 다른 스테이킹 주기는 다른 마이닝 파워를 부여합니다. 더 오래 스테이킹할수록 더 많은 마이닝 파워를 갖게 됩니다.',
    22: '4. $LAZIO 보상은 언제든지 청구할 수 있습니다.',
    23: '5. 리워드의 30%를 추천인 보상으로 준비했습니다. 친구에게 이 이벤트를 추천할 수 있으며 유효한 각 초대에 대해 친구가 얻은 보상의 20%를 공유할 수 있습니다. 초대한 친구가 다른 친구를 초대하면 그 친구의 보상 중 10%를 공유할 수 있습니다.',
    24: '6. 이벤트에 대한 최종 해석권은 BV에 있습니다.',
    25: '추천 보상',
    26: '보상 클래임',
    27: '클래임된 보상',
    28: '보상 클래임',
    29: '다음 청구 시간: ',
    30: '일',
    31: '시간',
    32: '분',
    33: '초',
  },
  // 跨链桥
  bridge: {
    1: '브리지',
    2: '에서 ',
    3: '에게',
    4: '스왑',
    5: '토큰',
    6: 'NFT',
    7: '알림:',
    8: '크로스체인 수수료는 1%입니다. BVT 및 BVG의 최소 크로스체인 금액은 각각 40 BVT 및 50,000 BVG입니다.',
    9: '크로스체인 도착 예상 시간은 10-30분입니다.',
    10: 'NFT 교환권의 경우 BNB체인(구 BSC)에서 Polygon으로 브리지만 제공합니다.',
    11: 'NFT가 BNB 체인의 미스터리 박스인 경우 Polygon 에 브리지하기 전에 먼저 오픈해야 합니다.',
    12: '브리지 내역',
    13: '체인',
    14: '유형',
    15: '수량',
    16: '해시',
    17: '현황',
    18: '완료됨',
    19: '실패함',
    20: '진행 중',
    21: '자산 트랜잭션',
    22: '아웃 트랜잭션',
    23: '잔액:',
    24: '업데이트된 BVG(0x29AB77a43Ec69D0DFFf3Ff72bff457e09b258351)를 폴리곤에서 BNB 체인으로 4000:1 비율로 브리징하는 것만 지원합니다.',
    25: '',
    26: '신규',

    27: `<div>BV Chain은 ZK-Rollups에 의해 보호되는 통합</div><div>유동성을 갖춘 다중 체인 L2 네트워크입니다</div>`,
    28: 'BV Chain 소개',
    29: `<div>BV Chain은 ZK-Rollups에 의해 보호되는 통합 유동성을 갖춘 다중 체인 L2 네트워크입니다. BV Chain 생태계는 다양한 L1 체인과 L2 네트워크를 연결하여 개발자와 거래자가 안전하고 원활한 사용자 경험을 통해 격리된 체인의 통합 유동성을 활용할 수 있도록 하여 모든 사람이 보다 접근 가능하고 효율적인 DeFi 생태계에 기여합니다.</div>
    <div>BV Chain은 다중 체인 데이터 전송은 물론 자산 및 거래의 보안을 보호하는 무신뢰 및 분산 권한 분리 설계를 통해 다중 체인 상호 운용성에 영지식 기술을 혁신적으로 적용합니다. 또한 BV Chain은 다양한 블록체인 및 유형의 스테이블 코인 간의 격차를 제거하여 다중 체인 유동성 집계를 달성하여 고유한 프로토콜 제어 부채(PCD) 메커니즘을 통해 대규모 스테이블 코인 유동성 풀을 형성하여 자본 효율적이고 비용 친화적이며 접근하기 쉬운 인프라를 제공합니다.</div>
    `,
    30: '보안 손상이 없는 영지식 증명',
    31: '견제와 균형을 갖춘 엄격한 합의 메커니즘',
    32: '여러 체인의 기본 자산 집계',
    33: 'BV Chain 특정',
  },
  // 年终
  yearend: {
    1: '더 구매하기 ',
    2: '더 보기 ',
    3: '이벤트 종료 시간',
    4: '사용가능한 잔액 ',
    5: '입금',
    6: '추천 주소',
    7: '붙이기',
    8: '기간 ',
    9: '유연한',
    10: '3월 ',
    11: '7월',
    12: '예상 APY',
    13: '총 스테이킹',
    14: '풀에 스테이킹된 금액',
    15: '총 보상',
    16: '컨트랙트',
    17: '더 보기 ',
    18: '이벤트 설명',
    19: '1. 이벤트 시간: 12월 28일 17:00-  1월 27일 17:00 (한국 시간)',
    20: '2. 이 이벤트는 모든 BVT 보유자를 위한 추가 크레딧이므로 이 스테이킹에 대한 $BVT의 최소 요구 사항은 없습니다.',
    21: '3. 다른 스테이킹 주기는 다른 마이닝 파워를 부여합니다. 더 오래 스테이킹할수록 더 많은 마이닝 파워를 갖게 됩니다.',
    22: '4. $LAZIO 보상은 언제든지 청구할 수 있습니다.',
    23: '5. 리워드의 20%를 추천인 보상으로 준비했습니다. 친구에게 이 이벤트를 추천할 수 있으며 유효한 각 초대에 대해 친구가 얻은 보상의 20%를 공유할 수 있습니다.',
    24: '6. 이벤트에 대한 최종 해석권은 BV에 있습니다.',
    25: '추천 보상',
    26: '보상 클래임',
    27: '클래임된 보상',
    28: '보상 클래임',
    29: '다음 청구 시간: ',
    30: '일',
    31: '시간',
    32: '분',
    33: '초',
    34: '12월',
    35: '저장 및 검색',
    36: '서약 만료 시간',
    37: '토큰',
  },

  dialog: {
    1: '확인',
    2: '닫기',
    3: '뛰로가기',
    4: '편집',
    5: '저장',
    6: '취소',
    7: '더 보기',
    8: '승인',
    9: '스테이킹',
    10: '중도 해지',
    11: '클래임',
    12: '준비되었습니다!',
    13: '축하합니다!',
    14: '다음을 얻습니다.',
    15: '매진',
    16: '금액은 이상이어야 합니다',
  },
  common: {
    1: '승인',
    2: '스테이킹',
    3: '중도 해지',
    4: '클래임',
    5: '로딩중',
  },
  msg: {
    1: '성공적으로 지갑을 연결했습니다.',
    2: '지갑 연결 실패했습니다',
    3: '승인 성공',
    4: '승인 실패',
    5: '스테이킹 성공했습니다',
    6: '스테이킹 실패했습니다',
    7: '수령 성공',
    8: '수령 실패',
    9: '중도 해지 성공',
    10: '중도 해지 실패',
    11: '성공적으로 수정됨',
    12: '수정 실패했습니다',
    13: '잔액 불충분',
    14: '거래가 제출되었고 블록 검사 중입니다. 잠시 후 다시 시도하십시오.',
    15: '복제 성공',
    16: '성공적인 구매',
    17: '전송 성공',
    18: '전송 실패',
    19: '아직 NFT가 없습니다.',
    20: '',
    21: '',
    22: '지갑을 연결하십시오',
    23: '이 NFT는 현재 마켓플레이스에 등록되어 있습니다. 마켓에서 삭제하려면 확인하십시오.',
    24: '성공적으로 제거됨',
    25: '제거실패했습니다',
    //25: '삭제 실패',
    26: '당신의 NFT가 나열됩니다',
    27: '나열 실패',
    28: 'NFT가 판매되었습니다',
    29: '올바른 네트워크',
    30: '상장 가격은 0이 될 수 없습니다',
    31: '잘못된 네트워크',
    32: '출시 예정',
    33: '잘못된 주소',
    34: '열기 실패',
    35: '최대 10개',
    36: '추천인 이미 연동됨',
    37: '아직 카드가 없습니다.',
    38: '6월28일 21:00 PM(한국시간) 오픈',
    39: '성공적인 실패',

    40: '주소 입력하십시오.',
    41: '본인 주소를 연동할 수 없습니다.',
    42: '잘못된 주소입니다.',
    43: '이 주소는 화이트리스트에 없습니다.',
    44: '청구 가능한 보상 없음',
    45: '수동으로 새로 고쳐주세요',
    46: '',
    47: '',
    48: '클래임된 보상',
    49: '아직 모든 미션을 완료하지 않습니다.',
    50: '신규자에게만 열려 있습니다.',
    51: '당신의 보상이 청구되었습니다.',
    52: '클래임됨',
    53: '추천 확인됨',
    54: '메인체인이을 전환되었습니다',
    55: '네트워크를 확인하십시오.',
    56: '아직은 이벤트 자격을 받지 못했습니다.',
    57: '현재 클래임할 수 있는 보상이 없습니다.',
    58: '귀하의 보상이 청구되었습니다.',
    59: '{0}토큰이 성공적으로 추가되었습니다!',
    60: '아직 열리지 않음',
    61: '공급이 없음',
    62: '수량을 입력하십시오.(0보다 커야합니다.)',
    63: '이 스테이킹 기간이 종료되었습니다.',
    64: '퍼블릭 베타 改成 공식 출시 예정',
    65: '금액은 40BVT 이상이어야 합니다.',
    66: '금액은 50,000 BVG 이상이어야 합니다.',
    67: '카드를 선택하십시오.',
    68: '성공했습니다.',
    69: '실패했습니다.',
    70: '진행 중입니다.',
    71: '임대 성공',
    72: '임대 갱신 성공',
    73: '지갑 주소를 입력하십시오.',
  },
};
