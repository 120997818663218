import { useAppStore } from '../store/appStore';
import $load from '@cps/GlobalLoading';
import { ElMessage } from 'element-plus';
import i18n from '@/utils/i18n';
import { useRouteItem } from './useRouterTools';
import { ethers } from 'ethers';

const $t = i18n.global.t;

/**
 * 检查是否账户或者链正确
 */
export async function checkRightChain(to, from) {
  const curRouteItem = useRouteItem();
  const targetRoute = to || curRouteItem;

  /**
   * 等待获取小狐狸插件钱包
   * @returns
   */
  function _waiting() {
    const duration = 100;
    return new Promise((resolve, reject) => {
      let count = 0;
      const timer = setInterval(() => {
        if (window.ethereum?.chainId) {
          resolve();
          clearInterval(timer);
        }
        count++;

        // 5秒内还获取不到小狐狸，则
        const sec = (5 * 1000) / duration;
        if (count > sec) {
          reject();
          clearInterval(timer);
        }
      }, duration);
    });
  }

  await _waiting();
  const appStore = useAppStore();
  // 匹配路由中 meta 的依赖项，如果当前链不在meta依赖项中，则链不对
  const chainId = window?.ethereum?.chainId || appStore.ethObj.chainId;
  // return;
  const inclu = targetRoute.meta?.needChains?.includes(chainId);
  if (!inclu && targetRoute.meta?.needChains !== undefined) {
    appStore.rightLink = false;
  } else {
    appStore.rightLink = true;
  }

  // 如果链不对，弹窗切链 or 提示
  if (!inclu && targetRoute.meta?.needChains !== undefined && targetRoute.meta?.needTips) {
    // ElMessage.error($t('msg.6'));

    appStore.chainChanged(targetRoute.meta?.needChains[0]);
  }
}

/**
 * 是否允许进入页面
 * @param {*} to
 * @param {*} from
 * @param {*} next
 */
export async function allowEnter(to, from, next) {
  // 每次进来，先确定是否拿到钱包
  const appStore = useAppStore();
  if (appStore.defaultAccount || !to.meta.requireAccount) {
    // 进入的时候不依赖钱包拿链上数据的，先放用户进去，再不阻塞地获取钱包
    next(true);
    appStore.linkWallet();
  } else {
    $load({ isShow: true });
    await appStore.linkWallet();
    next(true);
    $load({ isShow: false });
  }

  if (to.meta.welcome) {
    const appStore = useAppStore();
    // 如果首次进入，才执行动画
    if (appStore.isFirstEnter) {
      appStore.setIsFirstEnter(false);
      appStore.setWelcoming(true);
    }
  }
}
