import { defineStore, storeToRefs, reactive, toRaw } from 'pinia';
import { ElMessage } from 'element-plus';
import i18n from '@/utils/i18n.js';
import $load from '@cps/GlobalLoading';
import { getClientSize } from '@/utils/tools';
import { getStore, removeStore } from '@/utils/tools';
import { ethers } from 'ethers';
import WalletObj, { showPop, setShowPop } from '@cps/Wallets/useWallet';
import Web3Modal, { connectors } from 'web3modal';
import { getChainData } from '@/utils/tools';
// import providerOptions from '@/utils/config';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { useRoute } from 'vue-router';
import { checkRightChain } from '../router/routerHelp';

const $t = i18n.global.t;

// 默认配置
const INITIAL_STATE = {
  ethers: ethers,
  instance: null, // 钱包实例对象 window.ethereum
  provider: null, // web3Provider
  signer: null, // 签名器 获取地址用的，构建合约对象需 toRaw
  connected: false, // 是否链接钱包
  chainId: null, // 当前链ID （HEX）：0x38
  networkId: 137, // 当前网络ID （Number）
  cachedProvider: null, // 钱包类型： injected || walletConnect
  injectedType: null, // 外部的钱包类型
  baseGasPrice: 0 // 基础gasPrice，后面设置gasPrice的时候，+= 此值
};

const useAppStore = defineStore('app', {
  state: () => ({
    defaultAccount: null, //钱包账号
    lang: localStorage.getItem('lang') || 'en', // 语言
    web3Modal: null,
    walletModal: null,
    ethObj: { ...INITIAL_STATE },
    isChangeChains: false,
    // 可以连接钱包的链ID
    allowChain: [56, '0x38', 97, '0x61', 137, '0x89' ], //137 马蹄主网  56 BSC主网 1319 AIA正式链  1320 AIA 测试链
    // allowChain: [97, '0x61', 137, '0x89'], // 测试链
    welcoming: false, // 开场动画视频
    isFirstEnter: true, // 是否 首次进入
    loading: false, // 是否加载中
    isShowLangPanel: false, // 是否显示语言栏面板
    curDevice: 'pad', // 当前视口的尺寸: phone:750、pad:1280、pc:1920
    rightLink: false, // 是否在对的链
    lockUpdate: true, // 是否锁住，禁止更新所有组件和store
    updateTarget: false, // 更新所有组件数据的标记
    chainTimer: null, // 切链timer
    walletOptions: {
      tokenpocket: {
        id: 'tokenpocket',
        logo: require('@img/common/tokenpocket.png'),
        name: 'TokenPocket',
        description: 'Connect to your TokenPocket Wallet',
        type: 'injected',
        check: 'isTokenPocket',
        package: window?.ethereum
      },
      metamask: {
        id: 'metamask',
        logo: require('@img/common/metamask.svg'),
        name: 'MetaMask',
        description: 'Connect to your MetaMask Wallet',
        type: 'injected',
        check: 'isMetaMask',
        package: window?.ethereum
      },
      walletconnect: {
        id: 'walletconnect',
        logo: require('@img/common/walletconnect.svg'),
        name: 'WalletConnect',
        description: 'Scan with WalletConnect to connect',
        type: 'walletconnect',
        check: 'isWalletConnect',
        package: WalletConnectProvider
      },
      // injected providers
      bitkeep: {
        id: 'bitkeep',
        logo: require('@img/common/bitkeep.png'),
        name: 'BitKeep',
        description: 'Connect to your BitKeep Wallet',
        type: 'injected',
        check: 'isBitKeep',
        package: window?.bitkeep?.ethereum
      },
      coin98: {
        id: 'coin98',
        logo: require('@img/common/coin98.svg'),
        name: 'COIN98',
        description: 'Connect to your COIN98 Wallet',
        type: 'injected',
        check: 'isCoin98',
        package: window?.coin98?.provider
      },
      okxwallet: {
        id: 'okxwallet',
        logo: require('@img/common/icon-ouyi.jpg'),
        name: 'OKX Wallet',
        description: 'Connect to your OKW Wallet',
        type: 'injected',
        check: 'isOkxWallet',
        isShow: false,
        package: window?.okxwallet
      }
    }
  }),

  actions: {
    /**
     * 连接小狐狸钱包
     */
    async linkWallet() {
      if (this.defaultAccount) {
        return true;
      }
      this.walletModal = new WalletObj();
      const localStore = getStore('WEB3_CACHED_PROVIDER') || 'metamask';
      if (localStore) {
        await this.onConnect(this.walletOptions[localStore])
          .then(() => { })
          .catch(err => {
            console.log('连接小狐狸失败', err);
          });
      } else {
        removeStore('WEB3_CACHED_PROVIDER');
        return false;
      }
    },

    /**
     * 链接钱包
     */
    async onConnect(item) {
      const $route = useRoute();
      try {
        await this.walletModal.checkProvider(item);
      } catch (err) {
        err ?? ElMessage.error(err?.[1]?.message || err);
      }
      this.ethObj = { ...this.walletModal.walletObj };
      this.defaultAccount = this.walletModal.account;
      setShowPop(false);

      // 重新检查链
      checkRightChain();
    },

    /**
     * 重置
     */
    async resetApp(that) {
      // 尝试断开链接，清除缓存
      await this.walletModal.clearCachedProvider(that);
      this.defaultAccount = null;
      this.ethObj.connected = false;

      that?.$forceUpdate();
      this.setUpdateApp();
    },

    /**
     * 主动触发切链操作
     * @param {*} chainId
     * @returns
     */
    async chainChanged(chainId) {
      if (this.defaultAccount == null) {
        ElMessage({
          message: $t('msg.22'),
          type: 'error'
        });
        return;
      }
      let instance = window?.ethereum;
      if (this.ethObj.injectedType === 'bitkeep') {
        instance = window?.bitkeep?.ethereum;
      } else if (this.ethObj.injectedType === 'coin98') {
        instance = window?.coin98?.provider;
      }
      // 初始的provider
      const web3Provider = new ethers.providers.Web3Provider(instance, 'any');
      const chainData = getChainData(chainId);

      // 记录旧的chainId
      const oldChainId = this.ethObj.chainId;
      try {
        // 用户有链的id就直接切换
        await web3Provider.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chainData.chainId }]
        });
        this.pollingChain(oldChainId);
      } catch (switchError) {
        console.log('switchError...', switchError, ethereum);
        // 如果没有该链则添加该链
        if (switchError.code === 4902) {
          await web3Provider.provider
            .request({ method: 'wallet_addEthereumChain', params: [chainData] })
            .then(async resp => {
              if (window.ethereum?.isTokenPocket) {
                // TP钱包才给 loading提示，因为PC点了拒绝，也会到这里。。
                ElMessage.info($t('common.5'));
              }
              this.pollingChain(oldChainId);
            })
            .catch(e => {
              console.log('切链失败', e);
            });
        }
      }
    },

    /**
     * 轮询链id
     * @param {*} oldChainId
     */
    async pollingChain(oldChainId) {
      clearInterval(this.chainTimer);
      this.chainTimer = setInterval(() => {
        const web3Provider = new ethers.providers.Web3Provider(window?.ethereum, 'any');
        // 获取新的chainId
        const newChainId = web3Provider?.provider?.chainId;
        // 根据判断俩chainId，判断是否成功切了链

        if (+newChainId !== +oldChainId) {
          this.ethObj.chainId = newChainId;
          this.ethObj.provider = web3Provider;
          if (window.ethereum?.isTokenPocket) {
            // TP钱包，iPhone有坑，切换了链，chainId变了，但是rpc没变。这里强行修改rpc。
            const chainData = getChainData(newChainId);
            window.ethereum.rpc.rpcUrl = chainData.rpcUrls;
          }
          // 确实成功切了链
          ElMessage.success($t('msg.54'));
          clearInterval(this.chainTimer);
          // 开锁，更新所有组件数据
          this.setUpdateApp();
        }
      }, 500);
    },

    /**
     * 获取钱包地址
     * @returns {*} 钱包地址
     */
    async getDefaultAccount() {
      if (this.defaultAccount !== null) return;
      return this.defaultAccount;
    },

    /**
     * 设置多语言
     */
    setLang(lang) {
      this.lang = lang;
      window.localStorage.setItem('lang', lang);
    },

    /**
     * 控制开场动画视频
     * @param{Boolean} payload 开 或 关
     */
    setWelcoming(payload) {
      this.welcoming = payload;
      if (!payload) {
        // this.linkWallet();
      }
    },

    /**
     * 判断是否为首次进入
     */
    setIsFirstEnter(payload) {
      this.isFirstEnter = payload;
    },

    /**
     * 控制是否显示语言栏面板
     */
    setIsShowLangPanel(payload) {
      this.isShowLangPanel = payload;
    },

    /**
     * 获取当前使用的设备视口宽度
     */
    getCurDevice() {
      const clientWidth = window.innerWidth;
      if (clientWidth <= 750) {
        this.curDevice = 'phone';
      } else if (clientWidth <= 1280 && clientWidth > 750) {
        this.curDevice = 'pad';
      } else {
        this.curDevice = 'pc';
      }
    },

    /**
     * 更新项目，发送销毁重建信号
     */
    setUpdateApp() {
      // 开锁
      this.lockUpdate = false;
      // 更新所有组件数据
      this.updateTarget = !this.updateTarget;
    }, 
  },

  getters: {
    // 获取当前语言: en、cn、kn
    curLang() {
      return this.lang || window.localStorage.getItem('lang') || 'en';
    }
  }
});

export { storeToRefs, useAppStore };
