import { defineStore } from 'pinia';
import { $GET, $POST } from '@/service/require.js';
import $load from '@cps/GlobalLoading';
import { bpMul } from '@/utils/bpMath';
const useMarketStore = defineStore('cattleMarket', {
  state: () => ({
    tokenList: [], // tokenid
    heroList: [], // 我的卡包的 创世牛牛列表
    cattleList: [], // 当前挂售的 牛牛列表
    blindBoxList: [], // 当前挂售的 盲盒列表
    planetList: [], // 当前挂售的 星球列表
    historyList: [], // 交易历史
    cowList: [], // 我的挂单的牛牛列表

    isPickedBox: false, // 是否选中了盲盒
    curPickCate: 'calf', // 当前选中的形式：盲盒、牛牛、星球: blindBox、calf、planet
    curPickBlood: 0, // 当前选择的 血统选项 默认 0； 1 创世 2 普通
    curPickGender: 0, // 当前选择的 性别选项 默认 0； 1 女 2 男,
    curPickCoins:[1,2,4,5], // 当前选择的币种
    // 当前详情页信息
    cattleInfo: {},
    planetInfo: {}, // 当前星球信息

    totalNum: 0, // 卡牌总数
    // 公共参数
    publicParams: {
      page: 1, // 页数，可选，默认第一页
      sort: 0 // 排序方式，不发默认(0)按价格从低到高。1价格降序 2最新时间
    },

    // 当前挂售牛牛的请求参数
    calfFetchParam: {
      class: 0, // 血统，默认0就是全部，1创世；2普通
      gender: 0, // 性别，默认0就是全部，1母牛；2公牛
      isAdult: 0, // 阶段 1小牛，2成年。不发默认(0)全是全部
      life: [5000, 15000], // 生命，数组长度2。不发就是查全部
      growth: [5000, 15000], // 成长，数组长度2。不发就是查全部
      energy: [5000, 15000], // 体力，数组长度2。不发就是查全部
      attack: [5000, 15000], // 战斗(公牛)，数组长度2。不发就是查全部
      stamina: [5000, 15000], // 耐力(公牛)，数组长度2。不发就是查全部
      defense: [5000, 15000], // 防御(公牛)，数组长度2。不发就是查全部
      milk: [5000, 15000], // 产奶量(母牛)，数组长度2。不发就是查全部
      milkRate: [5000, 15000], // 产奶速率(母牛)，数组长度2。不发就是查全部
    },

    // 仅仅用作过滤属性的参数
    filterTag: {
      pickGender: 0 // 全选0，公牛1，母牛2
    },

    currencyRate: {} // 货币汇率
  }),

  actions: {
    /**
     * 设置当前选中的形式
     * @param {String} val 项：calf、blindBox、planet
     */
    setCurPickCate(val) {
      this.curPickCate = val;
    },

    setCurpickCoins(val) {
      const idx  = this.curPickCoins.indexOf(val.id)
      console.log(idx)
      if(idx != -1) {
        this.curPickCoins.splice(idx, 1)
      } else {
        this.curPickCoins.push(val.id)
      }
    },

    /**
     * 删除特殊属性（选择了公牛和母牛）
     * @param {Object} 新的属性
     * @returns {Object} 请求参数副本
     */
    handleSpecialAttr(options) {
      // 牛牛参数
      this.calfFetchParam = {
        ...this.calfFetchParam,
        ...options
      };

      // 获取请求参数的副本
      const params = {
        ...this.calfFetchParam
      };

      if (this.filterTag.pickGender === 0) {
        // 公母牛都选了。只保留基础属性
        delete params.attack;
        delete params.stamina;
        delete params.defense;
        delete params.milk;
        delete params.milkRate;
      } else if (this.filterTag.pickGender === 1) {
        // 只选择了公牛，保留基础属性和攻击属性
        delete params.milk;
        delete params.milkRate;
      } else if (this.filterTag.pickGender === 2) {
        // 只选择了母牛，保留基础属性和产奶属性
        delete params.attack;
        delete params.stamina;
        delete params.defense;
      }

      return params;
    },

    /**
     * 过滤列表 所需要的请求参数
     * @param {Object} options : class、gender
     */
    setFilterParams(options) {
      // 筛选列表选择了 牛牛
      if (this.curPickCate === 'calf') {
        const { curPickCoins}  = this
        console.log('curPickCoins', curPickCoins)
        // 性别选择了公牛和母牛，过滤掉特殊属性
        this.filterTag.pickGender = options.gender;
        console.log(this.curPickCoins)
        // 请求参数
        const params = {
          ...this.publicParams,
          coin: curPickCoins,
          ...this.handleSpecialAttr(options)
        };
        console.log('params===>', {...params, coin: curPickCoins
        })
        this.fetchCattleList({...params, coin: curPickCoins});
        return;
      }

      // 筛选列表选择了 盲盒
      if (this.curPickCate === 'blindBox') {
        this.fetchBlindBoxList();
        return;
      }

      if (this.curPickCate === 'planet') {
        // 筛选列表选择了 星球
        this.fetchPlanetList();
        return;
      }
    },

    /**
     * 属性列表 所需要的请求参数（这里只能是选择了牛牛）
     * @param {Object} options
     * life, growth, energy, attack, stamina, defense, milk, milkRate
     */
    setAttrsParams(options) {
      this.calfFetchParam = {
        ...this.calfFetchParam,
        ...options
      };

      // 请求参数
      const params = {
        ...this.publicParams,
        ...this.handleSpecialAttr(options)
      };
      this.fetchCattleList(params);
    },

    /**
     * 统一处理公共的请求（下拉菜单、分页）
     */
    handlePublicFetch() {
      // 牛牛列表
      if (this.curPickCate === 'calf') {
        // 请求参数
        const params = {
          ...this.publicParams,
          ...this.calfFetchParam,
          coin:this.curPickCoins
        };
        this.fetchCattleList(params);
        return;
      }

      // 盲盒列表
      if (this.curPickCate === 'blindBox') {
        this.fetchBlindBoxList();
        return;
      }

      // 星球列表
      if (this.curPickCate === 'planet') {
        this.fetchPlanetList();
        return;
      }
    },

    /**
     * 下拉菜单 所需要的请求参数
     * @param {Number} sort :0 1 2
     */
    setSelectParams(sort) {
      this.publicParams.sort = sort;
      this.handlePublicFetch();
    },

    /**
     * 设置分页 所需要的请求参数
     * @param {Number} page
     */
    setPageParams(page) {
      this.publicParams.page = page;
      this.handlePublicFetch();
    },

    /**
     * 获取货币和USDT的等价比例
     * @param {Number} params.type 货币类型 1 BVT 2 BVG 3 BNB 4 USDT
     * @param {*} params.amount 数量
     */
    async getCurrency() {
      const resp = await $GET('/currency/rate');
      console.log('getCurrency',resp)
      this.currencyRate = resp;
      return this.currencyRate;
    },

    /**
     * 计算当前价格
     * @param {*} params types 货币类型 amount 价格
     * @returns
     */
    countingPrice(params) {
      for (let i = 0; i < this.currencyRate.length; i++) {
        if (params.types == this.currencyRate[i].types) {
          // console.log('this.currencyRate[i].rate * params.amount',bpMul(Number(this.currencyRate[i].rate), params.amount))
          return bpMul(this.currencyRate[i].rate, params.amount,false,3);
        }
      }
      return 1;
    },

    /**
     * 获取 盲盒列表
     * @param {Object} data 请求参数: 分页、排序方式
     */
    async fetchBlindBoxList() {
      const { page, sort } = this.publicParams;
      $load({ isShow: true });
      const resp = await $POST('/marketplace/mysteryboxes', { page, sort }).finally(() => {
        $load({ isShow: false });
      });
      this.blindBoxList = resp?.list || [];
      this.totalNum = resp?.total || 0;
      this.publicParams.page = resp?.page || 1;
      console.log('盲盒列表', this.blindBoxList);
    },

    /**
     * 获取 牛牛列表
     * @param {Boolean} isDefault 是否用默认参数请求
     */
    async fetchCattleList(params) {
      $load({ isShow: true });
      const resp = await $POST('/marketplace/cattles', { ...params }).finally(() => {
        $load({ isShow: false });
      });

      this.cattleList = resp.list;
      this.totalNum = resp.total;
      this.page = resp.page;
      console.log('挂售的牛牛', resp);
    },

    /**
     * 获取当前我的卡包牛牛列表
     * @param {String} type 请求的牛牛类型
     * @param {Array} list 请求参数
     */
    async fetchCattleArray(type = 'hero', list) {
      const resp = await $POST('/cattle/list', { ids: list });
      if (type === 'hero') {
        this.heroList = resp;
      } else if (type === 'cattle') {
        this.cattleList = resp;
      }
      // 用于我的挂单获取全部的列表，后面再区分创世
      this.cowList = resp;
      return resp;
    },

    /**
     * 获取 星球列表
     */
    async fetchPlanetList() {
      const { page, sort } = this.publicParams;
      $load({ isShow: true });
      const resp = await $POST('/marketplace/planet', { page, sort, coin:[...this.curPickCoins] }).finally(() => {
        $load({ isShow: false });
      });
      this.planetList = resp?.list;
      this.totalNum = resp?.total;
      this.page = resp?.page;
      console.log('挂售的星球列表牛', resp);
    },

    /**
     * 获取牛牛详情
     * @param {Number} id 请求参数: Tokenid
     */
    async fetchCattleInfo(id) {
      if (!id) return;
      const resp = await $GET(`/cattle/${id}`);
      this.cattleInfo = resp;
      console.log('牛牛详情列表', this.cattleInfo);
      return resp;
    },

    /**
     * 获取星球详情
     * @param {Number} id 请求参数: Tokenid
     */
    async fetchPlanetInfo(id) {
      const resp = await $GET(`/planet/${id}`);
      this.planetInfo = resp;
      console.log('星球详情列表', this.planetInfo);
      return resp;
    },

    /**
     * 获取交易记录历史
     * @param {Number} addr 请求参数: 用户地址
     */
    async fetchHistory(addr) {
      const resp = await $POST(`/marketplace/history/${addr}`, { page: 1 });
      this.historyList = resp.history;
      console.log('交易记录历史', this.historyList);
      return resp;
    }
  },

  getters: {}
});

export default useMarketStore;
