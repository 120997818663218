import { defineStore } from 'pinia';
import { ethers, signer } from '@/utils/ethers';
import { useAppStore, storeToRefs } from '@store/appStore.js';
import { IGO_TEST_CONT, OAT_NFT_CONT, OAT_MINT_CONT } from '@/contracts/polygonAddress.js';

const useOATMintStore = defineStore('oatMint', {
  state: () => ({
    contract: {}, // 合约对象
    decimal: 18, // 精度
    cardType: [
      {
        id: 1,
        name: 'BovineVerse Community Badge NFT',
        cid: 1196, // BovineVerse Community Badge NFT
        image: require('@img/farm/oat/1196.png'),
        desc: 'BovineVerse Community Badge NFT',
        power: 274,
        amount: 1,
        cardType: 'oat',
        addrObj: OAT_NFT_CONT
      },
      {
        id: 2,
        name: 'BovineVerse Loyalty NFT(BNB Chain)',
        cid: 1300, // BovineVerse Loyalty NFT(BNB Chain)
        image: require('@img/farm/oat/1300.png'),
        desc: 'BovineVerse Loyalty NFT(BNB Chain)',
        power: 1270,
        amount: 1,
        cardType: 'oat',
        addrObj: OAT_NFT_CONT
      },
      {
        id: 3,
        name: 'BovineVerse Community Call',
        cid: 1423, // BovineVerse Community Call
        image: require('@img/farm/oat/1423.png'),
        desc: 'BovineVerse Community Call',
        power: 846,
        amount: 1,
        cardType: 'oat',
        addrObj: OAT_NFT_CONT
      },
      {
        id: 4,
        name: 'Celebration of BovineVerse Twitter 22K Followers',
        cid: 1473, // Celebration of BovineVerse Twitter 22K Followers
        image: require('@img/farm/oat/1473.png'),
        desc: 'Celebration of BovineVerse Twitter 22K Followers',
        power: 375,
        amount: 1,
        cardType: 'oat',
        addrObj: OAT_NFT_CONT
      },
      {
        id: 5,
        name: 'BovineVerse Community Call with Project Galaxy',
        cid: 1658, // BovineVerse Community Call with Project Galaxy
        image: require('@img/farm/oat/1658.png'),
        desc: 'BovineVerse Community Call with Project Galaxy',
        power: 585,
        amount: 1,
        cardType: 'oat',
        addrObj: OAT_NFT_CONT
      },
      {
        id: 6,
        name: 'BovineVerse IGO Mystery Box',
        cid: 'box', // BovineVerse IGO Mystery Box
        image: require('@img/farm/oat/img-box.png'),
        desc: 'BovineVerse IGO Mystery Box',
        power: 12700,
        amount: 1,
        cardType: 'igo',
        addrObj: IGO_TEST_CONT
      },
      {
        id: 7,
        name: 'Partnership between BovineVerse & B.A.D Hood',
        cid: 1875, // Partnership between BovineVerse & B.A.D Hood
        image: require('@img/farm/oat/1875.png'),
        desc: 'Partnership between BovineVerse & B.A.D Hood',
        power: 600,
        amount: 1,
        cardType: 'oat',
        addrObj: OAT_NFT_CONT
      },
      {
        id: 8,
        name: 'Exclusive OAT for NFT Farming Campaign',
        cid: 2095, // Exclusive OAT for NFT Farming Campaign
        image: require('@img/farm/oat/2095.png'),
        desc: 'Exclusive OAT for NFT Farming Campaign',
        power: 600,
        amount: 1,
        cardType: 'oat',
        addrObj: OAT_NFT_CONT
      }
    ],
    cardInfo: {
      id: 1,
      name: 'BovineVerse Community Badge NFT',
      cid: 1196, // BovineVerse Community Badge NFT
      image: require('@img/farm/oat/1196.png'),
      desc: 'BovineVerse Community Badge NFT',
      power: 274,
      amount: 1,
      cardType: 'oat',
      addrObj: OAT_NFT_CONT
    },
    cardList: [], // 卡牌列表
    oatList: [], // oat 列表
    igoList: [], // igo 列表
    poolInfo: {}, // 池子信息
    totalInfo: {} // 总量信息
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createContract() {
      this.contract = new ethers.Contract(OAT_MINT_CONT.address, OAT_MINT_CONT.abi, signer);
    },

    /**
     * 设置精度
     */
    async setDecimal() {
      const decimal = await this.contract.decimals();
      this.decimal = Number(decimal);
    },

    /**
     * 获取所有的OAT卡牌
     * @returns
     */
    async getUserOATList() {
      const { defaultAccount } = storeToRefs(useAppStore());
      const cidList = this.cardType.map(it => it.cid).filter(x => x !== 'box');
      const pList = [];
      for (let i = 0; i < cidList.length; i++) {
        // pList.push(
        //   this.contract.checkUserCid(
        //     OAT_NFT_CONT.address,
        //     defaultAccount.value,
        //     cidList[i]
        //   )
        // );
        pList.push(this.contract.checkUserOATCid(defaultAccount.value, cidList[i]));
      }
      // 构造 oat 数组
      const list = [];
      let cardIndex = 0;
      await Promise.all(pList).then(values => {
        // console.log('box values..', values);
        // 两层结构 一层获取卡牌类型对应的list ，二层获取准确的tokenid
        for (let index = 0; index < values.length; index++) {
          if (values[index].length > 0) {
            for (let i = 0; i < values[index].length; i++) {
              const findItem = this.cardType.find(item => item.cid == cidList[index]);
              const cloneCard = { ...findItem };
              list.push(cloneCard);
              list[list.length - 1].id = Number(values[index][i]);
              // console.log('index..',  Number(values[index][i]), list[list.length - 1]);
            }
          }
        }
      });
      this.oatList = list;
      this.cardInfo = list[0];
      // console.log('all oatCard..', this.oatList);
      return this.oatList;
    },

    /**
     * 获取未质押的Igo卡牌
     * @returns
     */
    async getUserIGOList(cardList) {
      const { defaultAccount } = storeToRefs(useAppStore());
      const list = [];
      for (let index = 0; index < cardList.length; index++) {
        const cloneCard = { ...this.cardType[5] };
        list.push(cloneCard);
        list[index].id = cardList[index].id;
      }
      this.igoList = list;
      // console.log('card..', list);
      return this.igoList;
    },

    /**
     * 获取对应卡牌的列表
     * @param {Number | String} cid 卡牌ID
     * @param {String} userAddr 用户地址
     * @param {String} NFTAddr 卡牌合约地址
     * @returns
     */
    async getCardList(cid, userAddr, NFTAddr) {
      const list = [];
      const cidList = await this.contract.checkUserCid(NFTAddr, userAddr, cid);
      for (let index = 0; index < cidList.length; index++) {
        const findItem = this.cardType.find(item => item.cid == cid);
        const cloneCard = { ...findItem };
        list.push(cloneCard);
        list[index].id = Number(cidList[index]);
      }
      this.cardList = list;
      // console.log('card..', list);
      return this.cardList;
    },

    getCardInfo(id) {
      const resp = this.oatList.find(it => it.id === Number(id));
      this.cardInfo = { ...resp };
      // console.log('oat info..', this.cardInfo, id);
      return this.cardInfo;
    },

    /**
     * 获取池子信息
     * @returns userClaimed 用户已领取总量 unclaimedAll 用户待领取收益
     */
    async getTotalInfo() {
      try {
        const { defaultAccount } = storeToRefs(useAppStore());
        // 用户已领取总量
        const { claimed } = await this.contract.userInfo(defaultAccount.value);
        // 用户待领取收益
        const unclaimedAll = await this.contract.calculateReward(defaultAccount.value);
        this.totalInfo = {
          userClaimed: Number(claimed) / Math.pow(10, this.decimal),
          unclaimedAll: Number(unclaimedAll) / Math.pow(10, this.decimal)
        };
      } catch (e) {
        console.log(e);
        return e?.data?.message ? e.data.message : 'error';
      }
    },

    /**
     * 获取质押的oat列表
     * @returns
     */
    async checkUserOATList() {
      const { defaultAccount } = storeToRefs(useAppStore());
      const cidList = await this.contract.checkUserOATList(defaultAccount.value);
      const list = [];
      for (let index = 0; index < cidList[1].length; index++) {
        const findItem = this.cardType.find(item => item.cid == cidList[1][index]);
        const cloneCard = { ...findItem };
        list.push(cloneCard);
        list[index].id = Number(cidList[0][index]);
      }
      this.oatList = list;
      // console.log('card..', list);
      return this.oatList;
    },

    /**
     * 获取质押的Igo卡牌
     * @returns
     */
    async checkUserIGOList() {
      const { defaultAccount } = storeToRefs(useAppStore());
      const cidList = await this.contract.checkUserIGOList(defaultAccount.value);
      const list = [];
      for (let index = 0; index < cidList.length; index++) {
        const cloneCard = { ...this.cardType[5] };
        list.push(cloneCard);
        list[index].id = Number(cidList[index]);
      }
      this.igoList = list;
      // console.log('card..', list);
      return this.igoList;
    },

    /**
     * 质押 BNB 或者其他币种
     * @param {Number} payload.id 池子ID,1为BNB,2为USDT，3为FIL
     * @param {Number} payload.amount 质押数量
     */
    async stake(id, type) {
      try {
        // 质押OAT
        if (type === 'oat') {
          const resp = await this.contract.stakeOAT(id, {
            gasLimit: 500000
          });
          await resp?.wait();
        } else {
          // 质押IGO
          const resp = await this.contract.stakeIGO(id, {
            gasLimit: 500000
          });
          await resp?.wait();
        }
      } catch (e) {
        console.log(e);
        return e?.data?.message ? e.data.message : 'msg.6';
      }
    },

    /**
     * 解除质押，赎回本金并领取收益
     *  @param {Number} id
     */
    async unStake(id, type) {
      try {
        // 解除质押OAT
        if (type === 'oat') {
          const resp = await this.contract.unStakeOAT(id);
          await resp?.wait();
        } else {
          // 解除质押IGO
          const resp = await this.contract.unStakeIGO(id);
          await resp?.wait();
        }
      } catch (e) {
        console.log(e);
        return e?.data?.message ? e.data.message : 'msg.10';
      }
    },

    /**
     * 领取所有池子收益
     */
    async claimAll() {
      try {
        const resp = await this.contract.claimReward();
        await resp?.wait();
      } catch (e) {
        console.log(e);
        return e?.data?.message ? e.data.message : 'msg.8';
      }
    }
  },

  getters: {}
});

export default useOATMintStore;
