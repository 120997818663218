import { computed, reactive, ref, readonly } from 'vue';
import useMarketStore from '@store/cattleMarketStore.js';
import useCattleStore from '@/contractStore/cattleStore.js';
/**
 * 是否显示控制面板
 */
const isShowNavPanel = ref(true);
export const showPanel = readonly(isShowNavPanel);
/**
 * 设置是否显示控制面板
 */
export function setShowNavPanel(val) {
  isShowNavPanel.value = val;
}

/**
 * 是否显示转账弹窗
 */
const isShowTransferPop = ref(false);
export const showTransferPop = readonly(isShowTransferPop);
/**
 * 设置是否显示转账弹窗
 */
export function setShowTransferPop(val) {
  isShowTransferPop.value = val;
}

/**
 * 当前选择的侧边栏类型 avatar account nft affinity
 */
const curSideNavType = ref(window.localStorage.getItem('sideNav') === null ? 'account' : window.localStorage.getItem('sideNav'));
export const curNavType = readonly(curSideNavType);
/**
 * 设置是否显示控制面板
 */
export function setCurNavType(val) {
  window.localStorage.setItem('sideNav', val);
  curSideNavType.value = window.localStorage.getItem('sideNav');
  // console.log('curNav..', curNavType.value);
}

/**
 * 侧面导航栏 account nft affinity
 */
const navList = reactive([
  { id: 1, label: 'panel.1', name: 'account', active: true },
  { id: 2, label: 'panel.2', name: 'nft', active: false },
  { id: 3, label: 'panel.3', name: 'affinity', active: false },
]);

export const sideNavs = readonly(navList);

/**
 * 选择侧面导航栏一项内容
 * @param {String} name 导航名称
 */
export function pickSideNav(name) {
  navList.forEach((item, i) => {
    item.active = false;
    if (name === item.name) {
      item.active = true;
      setCurNavType(item.name);
    }
  });
}

/**
 * 当前选择的 卡牌类型 hero cattle planet blindBox others
 */
 const curPickCardType = ref(window.localStorage.getItem('cardType')) || ref('planet');
 export const curCardType = readonly(curPickCardType);
 /**
  * 设置是否当前卡牌类型
  */
 export function setCurCardType(val) {
  window.localStorage.setItem('cardType', val);
  curPickCardType.value = window.localStorage.getItem('cardType');
  // console.log('curCard..', curCardType.value);
 }

/**
 * 我的 NFT 类型导航
 */
const cardTypeList = reactive([
  {
    id: 1,
    label: 'panel.15',
    name: 'planet',
    active: true,
  },
  {
    id: 2,
    label: 'panel.16',
    name: 'hero',
    active: false,
  },
  {
    id: 3,
    label: 'panel.17',
    name: 'cattle',
    active: false,
  },
  // v1.0 没有这个功能 先注释
  {
    id: 4,
    label: 'panel.18',
    name: 'blindBox',
    active: false,
  },
  {
    id: 5,
    label: 'panel.33',
    name: 'lease',
    active: false,
  },
  
  // {
  //   id: 6,
  //   label: 'panel.19',
  //   name: 'others',
  //   active: false,
  // },
  {
    id: 7,
    label: 'panel.35',
    name: 'cattleBadge',
    active: false,
  },
]);
export const cardTypes = readonly(cardTypeList);

/**
 * 选择卡牌选型，展示不同子页面
 */
export async function pickCardType(val) {
  cardTypeList.forEach(async (item, index) => {
    item.active = false;
    if (val === item.name) {
      item.active = true;
      setCurNavType('nft');
      setCurCardType(item.name);
    }
  });
}
