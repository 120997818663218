import { defineStore } from 'pinia';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import { YOHO_GENESIS_NFT, CATTLE_EXCHANGE } from '@/contracts/polygonAddress.js';
import $load from "@cps/GlobalLoading";
import { ElMessage } from "element-plus";
import i18n from '@/utils/i18n.js';

const $t = i18n.global.t;
const useYohoNftStore = defineStore('yohoNft', {
  state: () => ({
    contract: {}, // 合约对象
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createContract() {
      const appStore = useAppStore();
      const { ethers, signer, chainId } = appStore.ethObj;
      this.contract = new ethers.Contract(YOHO_GENESIS_NFT.address, YOHO_GENESIS_NFT.abi, toRaw(signer));
    },

    async isApprovedAll(contractAddr = CATTLE_EXCHANGE.address) {
      const appStore = useAppStore();
      const resp = await this.contract.isApprovedForAll(
        appStore.defaultAccount,
        contractAddr
      );
      return resp;
    },

    async setApprovalForAll(contractAddr = CATTLE_EXCHANGE.address) {
      try {
        const resp = await this.contract.setApprovalForAll(
          contractAddr, true
        );
        await resp?.wait?.();
        return resp;
      } catch(e) {
        ElMessage({
          message: $t("swap.25"),
          type: "error",
        });
        $load({ isShow: false });
      }
    },

    /**
   * 查询NFT余额
   */
    async getBalanceOfNft() {
      const appStore = useAppStore();
      let resp = await this.contract.balanceOf(appStore.defaultAccount);
      const count = resp.toString();
      return parseInt(count);
    },
    
    async getUserNFTs() {
      const appStore = useAppStore();
      let resp = await this.contract.getUserNFTs(appStore.defaultAccount);
      const ids = resp.map(el => {
        return el.toString();
      })
      return ids;
    },
    /**
    * 查询NFT余额
    */
    async getTokenURI(tokenId) {
      let resp = await this.contract.tokenURI(tokenId);
      return resp;
    },
  },

  getters: {}
});

export default useYohoNftStore;
