import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import { bpMul, bpDiv,fixFloor, toThousands } from '@/utils/bpMath';
import { PROXY_QUERY_CONT } from '@/contracts/polygonAddress.js';
import {mode, chainRpc} from '@/utils/globalConst.js'

export default class {
  /**
   * 构建
   * @param {Object} addressObj 地址对象：包括地址和abi 默认为各项数值查询合约对象
   */
  constructor(addressObj = PROXY_QUERY_CONT) {
    this.contract = null; // 各项数值查询合约对象

    this.craeteContract(addressObj);
  }

  /**
   * 构建对象
   * @param {Object} addressObj：包括合约地址、abi
   * 例如 去旁边的 address.js 里拿 PROXY_QUERY_CONT 传入
   * @returns 合约对象
   */
  craeteContract(addressObj) {
    const appStore = useAppStore();
    const { ethers, signer } = appStore.ethObj;
    let contract;
    if (!appStore.defaultAccount) {
      // const rpcProvider = new ethers.providers.JsonRpcProvider('https://polygon-rpc.com');
      const rpcProvider = new ethers.providers.JsonRpcProvider(chainRpc[mode]);
      contract = new ethers.Contract(addressObj.address, addressObj.abi, toRaw(rpcProvider));
    } else {
      contract = new ethers.Contract(addressObj.address, addressObj.abi, toRaw(signer));
    }
    
    this.contract = contract;
    return this.contract;
  }

  async getUserCenter() {
    const appStore = useAppStore();
    const info =await this.contract.userCenter(appStore.defaultAccount);
    const reward = {
      bvt: toThousands(fixFloor(bpDiv(info[8], 10 ** 18),3)),
      bvg: toThousands(fixFloor(bpDiv(info[9], 10 ** 18),3))
    };
    return reward;
  }
}
