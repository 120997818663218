import { defineStore } from 'pinia';
// import { ethers, signer } from '@/utils/ethers';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import blindBox from '../contracts/blindBox';
import { OAT_NFT_CONT } from '../contracts/polygonAddress';

const useOATStore = defineStore('OAT', {
  state: () => ({
    contract: {}, // 合约对象
    totalNum: 0, // 用户拥有的NFT總數
    badgeType: [
      {
        id: 1,
        name: 'BovineVerse Community Badge NFT',
        cardType: '1196', // BovineVerse Community Badge NFT
        image: require('@img/farm/oat/1196.png'),
        desc: 'BovineVerse Community Badge NFT',
        amount: 1
      },
      {
        id: 2,
        name: 'BovineVerse Loyalty NFT(BNB Chain)',
        cardType: '1300', // BovineVerse Loyalty NFT(BNB Chain)
        image: require('@img/farm/oat/1300.png'),
        desc: 'BovineVerse Loyalty NFT(BNB Chain)',
        amount: 1
      },
      {
        id: 3,
        name: 'BovineVerse Community Call',
        cardType: '1423', // BovineVerse Community Call
        image: require('@img/farm/oat/1423.png'),
        desc: 'BovineVerse Community Call',
        amount: 1
      },
      {
        id: 4,
        name: 'Celebration of BovineVerse Twitter 22K Followers',
        cardType: '1473', // Celebration of BovineVerse Twitter 22K Followers
        image: require('@img/farm/oat/1473.png'),
        desc: 'Celebration of BovineVerse Twitter 22K Followers',
        amount: 1
      },
      {
        id: 5,
        name: 'BovineVerse Community Call with Project Galaxy',
        cardType: '1658', // BovineVerse Community Call with Project Galaxy
        image: require('@img/farm/oat/1658.png'),
        desc: 'BovineVerse Community Call with Project Galaxy',
        amount: 1
      },
      {
        id: 6,
        name: 'Partnership between BovineVerse & B.A.D Hood',
        cardType: '1875', // Partnership between BovineVerse & B.A.D Hood
        image: require('@img/farm/oat/1875.png'),
        desc: 'Partnership between BovineVerse & B.A.D Hood',
        amount: 1
      },
      {
        id: 7,
        name: 'Exclusive OAT for NFT Farming Campaign',
        cardType: '2095', // Exclusive OAT for NFT Farming Campaign
        image: require('@img/farm/oat/2095.png'),
        desc: 'Exclusive OAT for NFT Farming Campaign',
        amount: 1
      }
    ],
    oatList: [], // 徽章列表
    oatInfo: {
      id: 1,
      name: 'card.1',
      image: 'https://game.legendranch.app/legendaryranch/ticket/shard.png',
      desc: 'card.7',
      amount: 1
    }
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createContract(addressObj = OAT_NFT_CONT) {
      const appStore = useAppStore();
      const { ethers, signer } = appStore.ethObj;
      const contract = new ethers.Contract(addressObj.address, addressObj.abi, toRaw(signer));
      this.contract = contract;
      return this.contract;
    },

    /**
     * 获取列表
     */
    async getOATList() {
      let pList = [];
      for (let i = 0; i <= this.badgeType.length; i++) {
        pList.push(this.contract.cid(this.badgeType[i].cardType));
      }
      const list = [];
      // 构造 ticket 数组
      await Promise.all(pList).then(values => {
        // console.log('val..', values);
        values.forEach((item, index) => {
          if (Number(item) !== 0) {
            const findItem = this.ticketType.find(it => it.id === index + 1);
            const cloneType = findItem;
            cloneType.amount = Number(item);
            list.push(cloneType);
          }
        });
      });
      this.ticketList = list;
    },

    async getOATListByTokenID(list) {
      let pList = [];
      for (let i = 1; i <= list; i++) {
        pList.push(this.contract.cid(list[i]));
      }
      const oatList = [];
      // 构造 ticket 数组
      await Promise.all(pList).then(values => {
        // console.log('val..', values);
        values.forEach((item, index) => {
          if (Number(item) !== 0) {
            const findItem = this.ticketType.find(it => it.cardType == Number(item));
            const cloneType = findItem;
            cloneType.id = list[index];
            oatList.push(cloneType);
          }
        });
      });
      this.oatList = oatList;
    },

    /**
     * 获取兑换券信息
     * @param {Number} id TokenID
     * @param {String} addr Address
     */
    async getTicketInfo(id, addr) {
      const balance = await this.contract.balanceOf(addr, id);
      const resp = this.ticketType.find(it => it.id === Number(id));
      this.ticketInfo = { ...resp };
      this.ticketInfo.amount = +balance;
      return this.ticketInfo;
    },

    /**
     * 是否授权全部
     * @param {String} owner 用户地址
     * @param {String} contractAddr 合约地址
     * @returns
     */
    async isApproveAll(owner, contractAddr) {
      const hasAllowned = await this.contract.isApprovedForAll(owner, contractAddr);
      return hasAllowned;
    },

    /**
     * 授权全部
     * @param {String} contract 合约地址
     * @returns
     */
    async authAll(contractAddr) {
      const resp = await this.contract.setApprovalForAll(contractAddr, true);
      await resp.wait();
      return resp;
    }
  },

  getters: {}
});

export default useOATStore;
