import axios from 'axios';
import { ElMessage } from 'element-plus';

// const testUrl = 'https://bovineversetest.com/api'; // 测试服
const masterUrl = 'https://www.bovine-verse.games/api'; // 域名https 正式服

const baseURL = process.env.VUE_APP_BASE_URL;

const defaultConfig = {
  baseURL,
  timeout: 30000,
};
Object.assign(axios.defaults, defaultConfig);
axios.defaults.headers['Content-Type'] = 'application/json';

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    /* const uid = localMemory.getItem('uid') || ''

    const params = { 
      uid,
    } */

    /* if (config.method === 'get') {
      config.params = {
        ...config.params,
        ...params,
      };
    } else {
      config.data = {
        ...config.data,
        ...params,
      };
    } */
    return config;
  },
  (err) => {
    // 对请求错误做些什么
    return Promise.reject(err);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (resp) => {
    if (resp.data && typeof resp.data === 'string') {
      return resp;
    }

    if (resp.data?.code && resp.data?.code !== 1) {
      ElMessage({
        message: resp.data?.message,
        type: 'error',
      });
    }
    return resp.data?.data ?? resp.data;
  },
  (err) => {
    return Promise.reject(err);
  }
);

/**
 * get请求
 * @param {Object} params 请求参数query
 * @returns {Promise}
 * @author gzq
 */
export function $GET(url, params, baseurl = false) {
  if (baseurl) {
    axios.defaults.baseURL = masterUrl;
  }
  console.log(url);
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * post请求
 * @param {String} url -必选   [地址]
 * @param {Object} params 请求参数 body
 * @param  {String} type -可选
 * @param  {String} token -可选
 * @returns {Promise}
 * @author gzq
 */
export function $POST(url, params,type = '',token = '') {
  let headers = {};
  if (type === 'token') {
    headers = {
      headers: {
        Token: token,
      },
    };
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, params,headers)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * put请求
 * @param {String} url -必选   [地址]
 * @param {Object} params 请求参数query
 * @param  {String} type -可选  [设定为 form 为 formdata 提交]
 * @returns {Promise}
 */
export function $PUT(url, params, type = '', token = '') {
  let headers = {};
  if (type === 'form') {
    headers = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
  } else if (type === 'token') {
    headers = {
      headers: {
        Token: token,
      },
    };
  }
  return new Promise((resolve, reject) => {
    axios
      .put(url, params, headers)
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default axios;
