import clipboard from 'clipboard';
import { onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { ElMessage } from 'element-plus';
import supportedChains from './chains';
import { TRADE_TYPE } from '@/constants';
/**
 * 地址略写
 * @param {String} str 全地址
 * @param {Number} frontLen 前面多少颗星星
 * @param {Number} endLen 结尾多少个星星
 * @returns {String}
 */
export function plusXing(str, frontLen, endLen) {
  if (str?.length === undefined) return '';
  if (str.length <= 20) return str;
  var len = str.length - frontLen - endLen;
  var xing = '';
  for (var i = 0; i < len; i++) {
    xing = '****';
  }
  return str.substring(0, frontLen) + xing + str.substring(str.length - endLen);
}

/**
 * 克隆
 * @param {*} obj 克隆的对象
 * @param {Boolean} deep 是否深度克隆
 * @returns
 */
export function clone(obj, deep) {
  if (Array.isArray(obj)) {
    //如果是数组
    if (deep) {
      let tempArr = [];
      obj.forEach((item) => {
        tempArr.push(clone(item, deep));
      });
      return tempArr;
    } else {
      return obj.slice(); //复制数组
    }
  } else if (typeof obj === 'object') {
    //如果是对象
    let newObj = {};
    for (const key in obj) {
      if (deep) {
        newObj[key] = clone(obj[key], deep);
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  } else {
    //如果是基本类型
    return obj;
  }
}

/**
 * 根据返回值将科学计数法转换为数字
 * @param {Number} num 数字
 * @param {Number} precision 精度
 * @returns {String}
 */
export function getFullNum(num, precision = 18) {
  //处理非数字
  if (isNaN(num)) {
    return num;
  }

  //处理不需要转换的数字
  var str = '' + num;
  if (!/e/i.test(str)) {
    return num;
  }
  return num.toFixed(precision).replace(/\.?0+$/, '');
}

/**
 * 节流
 * @param {Function} callback 回调函数
 * @param {Number} duration 节流间隔时间
 */
export function handleThrottle(callback, duration = 70) {
  let throttleTimer;
  return () => {
    if (throttleTimer) return;

    throttleTimer = setTimeout(() => {
      callback();
      throttleTimer = null;
    }, duration);
  };
}

/**
 * 将数字转换为千分位表示
 * @param {Number} num 数字
 * @param {Number} precision 精度
 * @returns {String} 123,456,78
 */
export function toThousands(num, precision = 6) {
  //处理非数字
  if (isNaN(num)) {
    return num;
  }

  var res = Number(num)
    .toFixed(precision)
    .toString()
    .replace(/\d+/, function (n) {
      // 先提取整数部分
      return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
        return $1 + ',';
      });
    });
  return res;
}

/**
 * 复制操作
 * 将需要的 className = 'cpy-btn'
 * 需要在 确保能获取到DOM 的生命周期中调用，比如 onMounted
 */
export function handleCopy() {
  const $tx = useI18n();
  const cpyer = new clipboard('.cpy-btn');
  cpyer.on('success', (e) => {
    ElMessage({
      message: $tx.t('msg.15'),
      type: 'success',
    });
    e.clearSelection();
  });
  onUnmounted(() => {
    cpyer.destroy();
  });
}

/**
 * 获取交易的币 小写
 * @param {Number | String} type 类型
 */
export function getTradeTypeCoin(type) {
  const t = String(type);
  return TRADE_TYPE[t].toLocaleLowerCase();
  // switch (t) {
  //   case '1':
  //     return 'tcoin';
  //   case '2':
  //     return 'gcoin';
  //   case '3':
  //     return 'bnb';
  //   case '4':
  //     return 'usdt';
  //   case '5':
  //     return 'matic';
  // }
}

/**
 * 补零，不足位数的前面补0
 * @param {Number} num 数值
 * @param {Number} fill 补零位数
 * @returns
 */
export function padNumber(num, fill = 3) {
  return num.toString().padStart(fill, '0');
}

export function getChainData(chainId) {
  const chainData = supportedChains.filter((chain) => chain.chainId === chainId)[0];
  return chainData;
}

/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content); //从一个对象解析出字符串
  }
  window.localStorage.setItem(name, content);
};

/**
 * 获取localStorage
 */
export const getStore = (name) => {
  if (!name) return;
  // console.log('localStor..',window.localStorage.getItem(name));
  return window.localStorage.getItem(name) == undefined ? null : window.localStorage.getItem(name);
};

/**
 * 删除localStorage
 */
export const removeStore = (name) => {
  if (!name) return;
  window.localStorage.removeItem(name);
};

/**
 * 存储sessionStorage
 */
export const setSessionStore = (name, content) => {
  if (!name) return;
  if (typeof content !== 'string') {
    content = JSON.stringify(content); //从一个对象解析出字符串
  }
  window.sessionStorage.setItem(name, content);
};

/**
 * 获取sessionStorage
 */
export const getSessionStore = (name) => {
  if (!name) return;
  return window.sessionStorage.getItem(name) == undefined
    ? null
    : window.sessionStorage.getItem(name);
};

/**
 * 删除sessionStorage
 */
export const removeSessionStore = (name) => {
  if (!name) return;
  window.sessionStorage.removeItem(name);
};

/**
 * 处理写交易动作,唤起交易之后的信息处理
 * @param {*} successMsg 交易成功的消息
 * @param {Function} func 交易函数
 * @param 交易参数
 * eg: handleWrite($t('msg.25'), this.mintObj.funcName, 参数1, 参数2)
 *
 */
export async function handleWrite(successMsg, func, ...param) {
  const $tx = useI18n();
  return func?.(...param)
    .then(async (resp) => {
      ElMessage({
        type: 'info',
        message: $tx.t('msg.14'),
      });
      const { events } = await resp?.wait?.();

      if (successMsg !== false) {
        // 需要成功提示
        ElMessage({
          type: 'success',
          message: successMsg || 'success',
        });
      }
      console.log('event...', events);
      return events || true;
    })
    .catch((err) => {
      let info = err?.data?.message || err?.message;
      // 点击了拒绝信息
      if (info?.includes?.('User denied transaction')) {
        info = 'User denied transaction signature.';
      }

      // 避免信息太长看懵用户
      info = String(info).length > 100 ? 'error' : info;
      ElMessage({
        type: 'error',
        message: info,
      });
      return false;
    });
}

// metmask提供的可以直接在memask中添加自定义代币的方法

/**
 * address  0x1DaCbcD9B3fc2d6A341Dca3634439D12bC71cA4d
 * symbol  BVT
 * image  https://bovine-verses.oss-ap-southeast-1.aliyuncs.com/token/bvt.png
 * successMsg $tx('msg.59', [obj.address])
 * @param {Object} obj
 */
export async function addToken(obj) {
  window.ethereum
    .request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: obj.address,
          symbol: obj.symbol,
          decimals: 18,
          image: obj.image,
        },
      },
    })
    .then((success) => {
      if (success) {
        ElMessage({
          message: obj.successMsg,
          type: 'success',
        });
      } else {
        ElMessage({
          message: 'error',
          type: 'error',
        });
      }
    })
    .catch(console.error);
}

/**
 * 数字转中文
 * @param {Number} num
 */
export function numToCha(num) {
  var AA = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十');
  var BB = new Array('', '十', '百', '仟', '萬', '億', '点', '');
  var a = ('' + num).replace(/(^0*)/g, '').split('.'),
    k = 0,
    re = '';
  for (var i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re;
        break;
      case 4:
        if (!new RegExp('0{4}//d{' + (a[0].length - i - 1) + '}$').test(a[0])) re = BB[4] + re;
        break;
      case 8:
        re = BB[5] + re;
        BB[7] = BB[5];
        k = 0;
        break;
    }
    if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
    if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
    k++;
  }
  if (a.length > 1) {
    // 加上小数部分(如果有小数部分)
    re += BB[6];
    for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
  }
  if (re == '一十') re = '十';
  if (re.match(/^一/) && re.length == 3) re = re.replace('一', '');
  return re;
}

/**
 * 获取随机数
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 * @returns
 */
export function getRandom(min, max) {
  return Math.floor(Math.random() * (max + 1 - min) + min);
}

/**
 * 处理小数运算精度丢失
 * @param num1
 * @param num2
 * @param symbol 运算符
 */
 export function decimalOperations(num1, num2, symbol) {
  const str1 = num1.toString();
  const str2 = num2.toString();
  let result = 0;
  let str1Length = 0;
  let str2Length = 0;
  //解决整数没有小数点方法
  try {
    str1Length = str1.split('.')[1].length;
  } catch (error) {
    str1Length = 0;
  }
  try {
    str2Length = str2.split('.')[1].length;
  } catch (error) {
    str2Length = 0;
  }
  var step = Math.pow(10, Math.max(str1Length, str2Length));
  switch (symbol) {
    case '+':
      result = (num1 * step + num2 * step) / step;
      break;
    case '-':
      result = (num1 * step - num2 * step) / step;
      break;
    case '*':
      result = (num1 * step * (num2 * step)) / step / step;
      break;
    case '/':
      result = (num1 * step) / (num2 * step);
      break;
    default:
      break;
  }
  return result;
}
