import { defineStore } from 'pinia';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import { CATTLE_EXCHANGE } from '@/contracts/polygonAddress.js';
import $load from '@cps/GlobalLoading';
import { ElMessage } from "element-plus";
import i18n from '@/utils/i18n.js';

const $t = i18n.global.t;
const useCattleExchangeStore = defineStore('cattleExchange', {
  state: () => ({
    contract: {}, // 合约对象
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createContract() {
      const appStore = useAppStore();
      const { ethers, signer, chainId } = appStore.ethObj;
      this.contract = new ethers.Contract(CATTLE_EXCHANGE.address, CATTLE_EXCHANGE.abi, toRaw(signer));
    },

    // amount_ - 兑换牛的数量，tokenIds_ - 按等级排序后的当前用户名下的Yoho NFT tokenId数组，
    async exchangeNft(amount, tokenIds = []) {
      const appStore = useAppStore();
      const contract = toRaw(this.contract);
      const { baseGasPrice } = appStore.ethObj;
      try {
        const estimate = await contract.estimateGas.exchange(amount, tokenIds);
        const resp = await this.contract.exchange(
          amount,
          tokenIds,
          {
            gasPrice: baseGasPrice, // 50000000000000
            from: appStore.defaultAccount,
            gasLimit: (Number(estimate) * 1.2).toFixed(0)
          }
        );
        await resp?.wait?.();
        return resp;
      } catch (e) {
        $load({ isShow: false });
        ElMessage({
          message: $t("swap.25"),
          type: "error",
        });
      }
    }
  },

  getters: {}
});

export default useCattleExchangeStore;
