import { getChainData } from '@/utils/tools';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import { useRouteItem } from '@/router/useRouterTools';

/**
 * 获取预设的rpc构建的signer
 * @param {String} chainId 链id
 * @returns signer
 */
export default function useDefaultRpc(chainId) {
  const appStore = useAppStore();
  const { ethers, signer } = appStore.ethObj;

  const routeItem = useRouteItem();

  let signerRes = toRaw(signer);
  // 如果没有安装小狐狸，或者没有登录钱包，或者用了不对的链，则使用预设rpc构建合约对象
  if (!appStore.defaultAccount || !appStore.rightLink) {
    const chainData = getChainData(chainId || routeItem.meta?.needChains?.[0]);
    signerRes = new ethers.providers.JsonRpcProvider(chainData?.rpcUrls?.[0]);
  }
  return signerRes;
}
