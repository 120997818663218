// 一些全局变量
// 阿里云地址
export const mode = 'test'

export const BASE_OSS = 'https://game.legendranch.app/legendaryranch';

// 链rpc
export const  chainRpc = {
    test:'https://http-testnet.aitd.io',
    prod:'https://walletrpc.aitd.io'
}
