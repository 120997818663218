import { useAppStore } from '@store/appStore';
import { STAR_INFO_CONT } from '@/contracts/polygonAddress';
import useDefaultRpc from '@/contracts/useDefaultRpc';
import { toRaw, reactive } from 'vue';
import { ethers } from 'ethers';
import i18n from '@/utils/i18n.js';
import { bpFormat, fixFloor, toThousands } from '@/utils/bpMath';
import { bpWrite } from '@/service/bpAction';
const $t = i18n.global.t;

export default class {
  constructor() {
    this.contract = null;
    this.appStore = {};
    this.isClaimed = false;
    this.userReward = 0;
    this.hasPermission = false;
    this.userPamars = {
      amount: 10,
      leafs: null,
      proof: null
    };
    this.createContract();
  }

  createContract() {
    this.appStore = useAppStore();
    const signer = useDefaultRpc();

    this.contract = new ethers.Contract(
      STAR_INFO_CONT.address,
      STAR_INFO_CONT.abi,  
      toRaw(signer)
    );
    this.defaultAccount = this.appStore.defaultAccount;
  }

  /**
   * 查询基础信息
   */
  async checkViewInfo() {
    let addr = ethers.constants.AddressZero;
    if (this.defaultAccount) {
      addr = this.defaultAccount;
    }
    const resp = await this.contract.checkRentInfo(addr);

    const info = {
      id: +resp.tokenId, // 星球id
      owner: resp.owner, // 持有者
      tenant: resp.rentOwner, // 租客
      isOwner: resp.owner?.toUpperCase() === this.defaultAccount?.toUpperCase(), // 是否是持有者
      isTenant: resp.rentOwner?.toUpperCase() === this.defaultAccount?.toUpperCase(), // 是否是租客
      isPresident: +resp.tokenId !== 0, // 是否是會長
      rentAmount: toThousands(bpFormat(resp.rentAmount, -3, 18), 3), // 租金
      rentTime: +resp.rentTime * 1000, // 到期時間
      reward: toThousands(bpFormat(resp.reward, -3, 18), 3) // 收益 直接到賬
    };

    console.log('info..', info);
    return info;
  }

  /**
   * 租赁给他人
   * @param {*} parmas.id 星球id
   * @param {*} parmas.addr 租客地址
   * @param {*} parmas.amount 租金
   */
  async setRent(parmas) {
    const resp = await bpWrite(
      $t('msg.64'),
      this.contract.setRentInfo,
      parmas.id,
      parmas.addr,
      parmas.amount
    );
    return resp;
  }

  /**
   * 取消租賃
   */
  async cancelRent() {
    const resp = await bpWrite($t('msg.25'), this.contract.cancelRent);
    return resp;
  }

  /**
   * 續租
   */
  async payRent() {
    const resp = await bpWrite($t('msg.72'), this.contract.payRent);
    return resp;
  }
}
