(function (doc, win) {

    const docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in win ? 'orientationchange' : 'resize',
        recalc = function () {
            var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

            // const clientWidth = docEl.clientWidth;
            const clientWidth = windowWidth;
            // console.log('clientWidth', clientWidth)
            if (!clientWidth) return;
            if (clientWidth >= 750) {
                // 如果超过手机设计稿，则按照 pc 固定基本 fontSize
                docEl.style.fontSize = '100px';
            } else {
                docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
            }
        };
    recalc();
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
})(document, window);