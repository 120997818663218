import { ref, reactive, readonly } from 'vue';
import { BASE_OSS } from '@/utils/globalConst';

/**
 * 是否展示兑换弹窗
 */
const isShowPopValue = ref(false);
export function setIsShowPop(payload) {
  isShowPopValue.value = payload;
}
export const isShowPop = readonly(isShowPopValue);

/**
 * 当前兑换项
 */
const activeItemValue = ref({});
export function setActiveItem(itemInfo) {
  activeItemValue.value = itemInfo;
}
export const activeItem = readonly(activeItemValue);

/**
 * 更新，重新请求所有分类数据
 */
const updateAllInfoState = ref(false);
export const updateAllInfo = readonly(updateAllInfoState);
export function refetchTabInfo() {
  updateAllInfoState.value = !updateAllInfoState.value;
}

// 选项列表
export const tableList = reactive([
  // 光轮宝箱兑换券
  {
    id: 0,
    text: 'assetsExchange.58',
    active: true,
    children: [
      // 创世牛兑换券
      {
        id: 0,
        text: 'card.20',
        img: `${BASE_OSS}/ticket/creation.png`,
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 普通小牛兑换券
      {
        id: 1,
        text: 'card.19',
        img: `${BASE_OSS}/ticket/normal.png`,
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
        // disable: true, // 临时禁用
      },
      // 家园星球兑换券
      {
        id: 3,
        text: 'card.22',
        img: `${BASE_OSS}/ticket/homeland.png`,
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
    ],
  },
  // 正式资产兑换券
  {
    id: 1,
    text: 'assetsExchange.59',
    active: false,
    children: [
      // 初级草料
      {
        id: 1,
        text: 'assetsExchange.7',
        img: require('@img/assetsExchange/img-hay-1.png'),
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 中级草料
      {
        id: 2,
        text: 'assetsExchange.8',
        img: require('@img/assetsExchange/img-hay-2.png'),
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 高级草料
      {
        id: 3,
        text: 'assetsExchange.9',
        img: require('@img/assetsExchange/img-hay-3.png'),
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 初级加速包
      {
        id: 4,
        text: 'assetsExchange.13',
        img: require('@img/assetsExchange/img-grow-1.png'),
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 中级加速包
      {
        id: 5,
        text: 'assetsExchange.14',
        img: require('@img/assetsExchange/img-grow-2.png'),
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 高级加速包
      {
        id: 6,
        text: 'assetsExchange.15',
        img: require('@img/assetsExchange/img-grow-3.png'),
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 初级体力包
      {
        id: 7,
        text: 'assetsExchange.10',
        img: require('@img/assetsExchange/img-power-1.png'),
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 中级体力包
      {
        id: 8,
        text: 'assetsExchange.11',
        img: require('@img/assetsExchange/img-power-2.png'),
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 高级体力包
      {
        id: 9,
        text: 'assetsExchange.12',
        img: require('@img/assetsExchange/img-power-3.png'),
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 皮肤盲盒
      {
        id: 20003,
        text: 'assetsExchange.16',
        img: require('@img/assetsExchange/img-box.png'),
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 改名卡
      {
        id: 14,
        text: 'assetsExchange.17',
        img: require('@img/assetsExchange/img-rename.png'),
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
    ],
  },
  // IGO盲盒
  {
    id: 2,
    text: 'assetsExchange.60',
    active: false,
    children: [
      // 创世牛兑换券
      {
        id: 10004,
        text: 'card.20',
        img: `${BASE_OSS}/boxgif/4.gif`,
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 普通小牛兑换券
      {
        id: 10003,
        text: 'card.19',
        img: `${BASE_OSS}/boxgif/3.gif`,
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
        // disable: true, // 临时禁用
      },
      // 皮肤盲盒兑换券
      {
        id: 10001,
        text: 'assetsExchange.55',
        img: `${BASE_OSS}/boxgif/1.gif`,
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
      // 道具礼包兑换券
      {
        id: 10002,
        text: 'assetsExchange.61',
        img: `${BASE_OSS}/boxgif/2.gif`,
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
    ],
  },
  // 其他兑换券
  {
    id: 3,
    text: 'assetsExchange.67',
    active: false,
    children: [
      // 成年牛兑换券
      {
        id: 10004,
        text: 'assetsExchange.63',
        img: `${BASE_OSS}/ticket/cattle.png`,
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
        cattleVoucherBig: true, // 标记，为了统一图片大小
      },
      // 普通牛兑换券
      {
        id: 10005,
        text: 'card.19',
        img: `${BASE_OSS}/ticket/normal.png`,
        count: 0, // 拥有的数量
        pickedCount: 1, // 选择数量
        allTokenIds: [], // 拥有的tokenId
        pickedTokenIds: [], // 选中的tokenId
      },
    ],
  },
]);

// 是否首次进来资产兑换页面
const isInitAssetsPageValue = ref(true);
export const isInitAssetsPage = readonly(isInitAssetsPageValue);
export function setIsInitAssetsPageValue(status) {
  isInitAssetsPageValue.value = status;
}
