import { ref, toRaw, reactive, readonly } from 'vue';
import { ethers } from 'ethers';
import supportedChains from '@/utils/chains';
import { getStore, setStore, removeStore, setSessionStore } from '@/utils/tools';
// import QRCodeModal from '@walletconnect/qrcode-modal';
// import WalletConnect from '@walletconnect/client';
import WalletConnectProvider from '@walletconnect/web3-provider';
import i18n from '@/utils/i18n.js';
import { ElMessage } from 'element-plus';
import { useAppStore } from '@/store/appStore';

const $t = i18n.global.t;

/**
 * 设置是否展示弹窗
 */
const isShowPop = ref(false);
export const showPop = readonly(isShowPop);
export function setShowPop(payload) {
  isShowPop.value = payload;
}

// 默认配置
const INITIAL_STATE = {
  ethers: ethers,
  instance: null, // 钱包实例对象 window.ethereum
  provider: null, // web3Provider
  signer: null, // 签名器 获取地址用的，构建合约对象需 toRaw
  connected: false, // 是否链接钱包
  chainId: null, // 当前链ID （HEX）：0x38
  networkId: 137, // 当前网络ID （Number）
  cachedProvider: null, // 钱包类型： injected || walletConnect
  injectedType: null, // 外部的钱包类型
  baseGasPrice: 0 // 基础gasPrice，后面设置gasPrice的时候，+= 此值
};
export default class {
  constructor() {
    this.walletObj = { ...INITIAL_STATE };
    this.account = null;
    this.createContract();
  }

  createContract() {
    const curChain = this.getChainData();
    const rpcProvider = new ethers.providers.JsonRpcProvider(curChain.rpcUrls);
    this.walletObj.provider = rpcProvider;
    return this.walletObj;
  }

  async checkProvider(item) {
    const appStore = useAppStore();

    if (item.type === 'injected') {
      const res = this.checkInjectedProviders(item);
      const injectedInstance = await this.ConnectToInjected(res);
      if (!injectedInstance) return;
      this.walletObj.instance = injectedInstance;
      this.walletObj.provider = new ethers.providers.Web3Provider(this.walletObj.instance, 'any');
      console.log('injected..', this.walletObj.provider);
    } else if (item.type === 'walletconnect') {
      const res = await this.ConnectToWalletConnect();
      this.walletObj.instance = res;
      console.log('walletconnect..', res);
    }
    await this.subscribeProvider(this.walletObj.instance);
    const provider = toRaw(this.walletObj.provider);

    if (this.walletObj.provider) {
      const gasPrice = await provider.getGasPrice();
      const signer = provider.getSigner();
      const account = await signer.getAddress();
      this.walletObj.baseGasPrice = +gasPrice;
      this.walletObj.signer = signer;
      this.walletObj.connected = true;
      this.walletObj.chainId = provider?.provider?.chainId;
      this.account = account;

      // 开锁，更新所有组件数据
      // appStore.setUpdateApp();
    }
    setSessionStore('chainId', this.walletObj.chainId);
    console.log('walletObj..', this.walletObj);
  }

  /**
   * 链接插件类型的钱包
   * @param {*} injected
   * @returns
   */
  async ConnectToInjected(injected) {
    let provider = null;
    console.log('inject..', injected);
    if (injected.isTokenPocket) {
      provider = window?.ethereum;
      setStore('WEB3_CACHED_PROVIDER', 'tokenpocket');
    } else if (injected.isBitKeep) {
      provider = window?.bitkeep?.ethereum;
      setStore('WEB3_CACHED_PROVIDER', 'bitkeep');
    } else if (injected.isMetaMask) {
      provider = window?.ethereum;
      setStore('WEB3_CACHED_PROVIDER', 'metamask');
    } else if (injected.isCoin98) {
      provider = window?.coin98?.provider;
      setStore('WEB3_CACHED_PROVIDER', 'coin98');
    } else if (injected.isOkxWallet) {
      provider = window?.okxwallet;
      setStore('WEB3_CACHED_PROVIDER', 'okxwallet');
    } else if (injected.web3) {
      provider = window?.web3?.currentProvider;
      setStore('WEB3_CACHED_PROVIDER', 'web3');
    }else if(window?.ethereum){
      provider = window?.ethereum;
      setStore('WEB3_CACHED_PROVIDER', 'metamask');
    } else {
      const walletKey = Object.keys(injected)[1];
      if (walletKey == 'isMetaMask') {
        window.open('https://metamask.io/download/');
      } else if (walletKey == 'isTokenPocket') {
        window.open('https://extension.tokenpocket.pro/#/');
      } else if (walletKey == 'isBitKeep') {
        window.open('https://bitkeep.com/download');
      } else if (walletKey == 'isCoin98') {
        window.open(
          'https://chrome.google.com/webstore/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg'
        );
      } else if (walletKey === 'isOkxWallet') {
        window.open(
          'https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge'
        );
      }
      return null;
    }

    try {
      const providerStore = getStore('WEB3_CACHED_PROVIDER');
      await provider.request({ method: 'eth_requestAccounts' });
      this.walletObj.cachedProvider = 'injected';
      this.walletObj.injectedType = providerStore;
    } catch (error) {
      ElMessage({
        message: 'User denied transaction',
        type: 'error'
      });
    }
    return provider;
  }

  /**
   * 链接WalletConnect钱包
   * @returns
   */
  async ConnectToWalletConnect() {
    return new Promise(async (resolve, reject) => {
      const provider = new WalletConnectProvider({
        rpc: {
          56: 'https://bsc-dataseed1.ninicoin.io',
          97: 'https://data-seed-prebsc-1-s2.binance.org:8545',
          137: 'https://polygonapi.terminet.io/rpc',
          1319:'https://aia-dataseed1.aiachain.org',
          1320:'https://http-testnet.aitd.io',
        }
      });
      setShowPop(false);
      try {
        await provider.enable();
        this.walletObj.provider = new ethers.providers.Web3Provider(provider, 'any');
        this.walletObj.cachedProvider = 'walletconnect';
        setStore('WEB3_CACHED_PROVIDER', 'walletconnect');
        resolve(provider);
        return provider;
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * 获取链基础信息
   * @param {*} chainId 0x38
   * @returns
   */
  getChainData(chainId) {
    if (!chainId) {
      chainId = supportedChains[0].chainId;
    }
    const chainData = supportedChains.filter(chain => chain.chainId === chainId)[0];
    return chainData;
  }

  /**
   * 检查并获取注入的Provider
   * @param {*} injected
   * @returns
   */
  checkInjectedProviders(injected) {
    const result = {
      injectedAvailable: !!window?.ethereum || !!window?.web3
    };
    if (result.injectedAvailable) {
      const isAvailable = this.verifyInjectedProvider(injected) ?? false;
      console.log('item...', injected, isAvailable);
      if (isAvailable) {
        result[injected.check] = true;
      } else {
        result[injected.check] = false;
      }
    }

    return result;
  }

  /**
   * 验证注入的Provider是否存在 先判断小众分类的钱包是否存在（isBitKeep）
   * @param {*} injectedItem
   * @returns
   */
  verifyInjectedProvider(injectedItem) {
    if (injectedItem.package) {
      return injectedItem.package[injectedItem.check];
    } else {
      return (
        window?.web3 &&
        window?.web3?.currentProvider &&
        window?.web3?.currentProvider[injectedItem.check]
      );
    }
  }

  /**
   * 监听钱包状态
   * @param {*} provider
   * @returns
   */
  async subscribeProvider(provider) {
    if (!provider.on) {
      return;
    }

    const appStore = useAppStore();

    // Subscribe to accounts change
    provider.on('accountsChanged', accounts => {
      const account = accounts[0];
      if (!account) {
        this.clearCachedProvider();
        return;
      } else {
        this.account = account;
        appStore.defaultAccount = account;
      }

      console.log('切账号》。。', account);
      appStore.setUpdateApp();
    });

    // Subscribe to chainId change
    provider.on('chainChanged', async chainId => {
      // this.checkProvider();
      appStore.ethObj.chainId = chainId;
      appStore.setUpdateApp();
    });

    // Subscribe to connection events
    provider.on('connect', (error, payload) => {
      if (error) {
        throw error;
      }

      // Get provided accounts and chainId
      const { accounts, chainId } = payload.params[0];

      this.walletObj.connected = true;
      this.walletObj.chainId = chainId;
      this.account = accounts[0];

      appStore.setUpdateApp();
    });

    // Subscribe to provider disconnection
    provider.on('disconnect', error => {
      console.log('disconnect..', error?.code, error);
      if (
        error.code !== 1013 ||
        error?.message !== 'MetaMask: Disconnected from chain. Attempting to connect.'
      ) {
        this.clearCachedProvider();
      }
    });

    //断开连接
    provider.on('close', () => {
      console.log('断开连接');
      this.clearCachedProvider();
    });
  }

  /**
   * 重置
   */
  async clearCachedProvider() {
    const appStore = useAppStore();
    // 尝试断开链接，清除缓存
    try {
      if (this.walletObj.cachedProvider === 'walletconnect') {
        removeStore('walletconnect');
        removeStore('WEB3_CACHED_PROVIDER');
        removeStore('WALLETCONNECT_DEEPLINK_CHOICE');
        if (this.walletObj.instance) {
          this.walletObj.instance?.disconnect();
        }
      } else if (this.walletObj.cachedProvider === 'injected') {
        removeStore('WEB3_CACHED_PROVIDER');
      }
    } catch (error) {
      console.log(error);
    }

    appStore.defaultAccount = null;
    this.walletObj.connected = false;
    Object.keys(INITIAL_STATE).forEach(e => {
      this.walletObj[e] = INITIAL_STATE[e];
    });
  }
}
