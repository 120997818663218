import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import { IGO_BLIND_BOX_CONT } from './polygonAddress';

export default class {
  /**
   * 构建
   * @param {Object} addressObj 地址对象：包括地址和abi 默认为盲盒合约对象
   */
  constructor(addressObj = IGO_BLIND_BOX_CONT) {
    this.contract = null; // 盲盒合约对象
    this.totalNum = 0; // 总数
    this.tokenList = []; // 用户的盲盒 ID 列表
    this.boxList = []; // 盲盒列表
    // 盒子类型
    this.boxType = [
      {
        id: 1,
        name: 'card.1',
        cardType: 'blindBox',
        mataName: 'Cattle-Mystery-Box',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/box.png',
        desc: 'card.33'
      },
      {
        id: 2,
        name: 'card.2',
        cardType: 'Mystery-Box',
        mataName: 'IGO-Mystery-Box',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/5.gif',
        desc: 'card.7'
      },
      {
        id: 3,
        name: 'card.3',
        cardType: 'Bovine-Hero',
        mataName: 'Bovine-Hero',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/4.gif',
        desc: 'card.8'
      },
      {
        id: 4,
        name: 'card.4',
        cardType: 'Cattle',
        mataName: 'Cattle',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/3.gif',
        desc: 'card.9'
      },
      {
        id: 5,
        name: 'card.5',
        cardType: 'Items-Pack',
        mataName: 'Items-Pack',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/2.gif',
        desc: 'card.10'
      },
      {
        id: 6,
        name: 'card.6',
        cardType: 'Skins-Pack',
        mataName: 'Skins-Pack',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/1.gif',
        desc: 'card.11'
      },
      {
        id: 7,
        name: 'card.31',
        cardType: 'halo_box', // 光轮宝箱
        mataName: 'Halo-Mystery-Box',
        image: 'https://game.legendranch.app/legendaryranch/ticket/haloBox.png',
        desc: 'card.32',
        amount: 1
      },
      {
        id: 8,
        name: 'card.20',
        cardType: 'creation_ticket', //创世牛牛
        mataName: 'Bovine-Hero-Ticket',
        image: 'https://game.legendranch.app/legendaryranch/ticket/creation.png',
        desc: 'card.27',
        amount: 1
      },
      {
        id: 9,
        name: 'card.19',
        cardType: 'normal_ticket', // 普通牛牛
        mataName: 'Cattle-Ticket',
        image: 'https://game.legendranch.app/legendaryranch/ticket/normal.png',
        desc: 'card.26',
        amount: 1
      },
      {
        id: 10,
        name: 'card.18',
        cardType: 'mark_box', // 盲盒
        mataName: 'Mark-Mystery-Box',
        image: 'https://game.legendranch.app/legendaryranch/ticket/markBox.png',
        desc: 'card.24',
        amount: 1
      },
      {
        id: 11,
        name: 'card.22',
        cardType: 'homeland_ticket', // 家园星球
        mataName: 'Homeland-Ticket',
        image: 'https://game.legendranch.app/legendaryranch/ticket/homeland.png',
        desc: 'card.29',
        amount: 1
      },
      {
        id: 12,
        name: 'card.21',
        cardType: 'genland_ticket', // 拓荒星球
        mataName: 'Genland-Ticket',
        image: 'https://game.legendranch.app/legendaryranch/ticket/genland.png',
        desc: 'card.28',
        amount: 1
      },

      {
        id: 13,
        name: 'card.23',
        cardType: 'shard', // 碎片
        mataName: 'shard',
        image: 'https://game.legendranch.app/legendaryranch/ticket/shard.png',
        desc: 'card.30',
        amount: 1
      },
      {
        id: 14,
        name: 'card.36',
        cardType: 'binance_box', // 幣安盲盒
        mataName: 'BovineVerse-Gaming-Mystery-Box',
        image: 'https://game.legendranch.app/legendaryranch/boxgif/binance-box.png',
        desc: 'card.37',
        amount: 1
      }
    ];
    this.craeteContract(addressObj);
  }

  /**
   * 构建对象
   * @param {Object} addressObj：包括合约地址、abi
   * 例如 去旁边的 address.js 里拿 PROXY_QUERY_CONT 传入
   * @returns 合约对象
   */
  craeteContract(addressObj) {
    const appStore = useAppStore();
    const { ethers, signer } = appStore.ethObj;
    const contract = new ethers.Contract(addressObj.address, addressObj.abi, toRaw(signer));
    this.contract = contract;
    return this.contract;
  }

  /**
   * 获取内部合约的盲盒列表
   * @param {String} addr 用户地址
   * @param {String} cardType 卡牌类型
   * @returns
   */
  async getInsideBoxList(addr, cardType) {
    const tokenList = await this.contract.checUserBoxList(addr);
    // console.log('getInsideBoxList tokenList',tokenList)
    this.totalNum = tokenList.length;
    this.tokenList = tokenList.map(it => +it);
    console.log('getInsideBoxList InsideBoxList..', this.tokenList);
    const list = [];
    for (let index = 0; index < this.tokenList.length; index++) {
      const findItem = this.boxType.find(item => item.cardType === cardType);

      const cloneCard = { ...findItem };
      list.push(cloneCard);
      list[index].id = Number(this.tokenList[index]);
    }
    console.log('getInsideBoxList list',list)
    this.boxList = list;
    return this.boxList;
  }

  /**
   * 已知单独卡牌类型
   * @param {String} addr 用户地址
   * @param {String} cardType 卡牌类型
   */
  async getOutsideBoxList(addr, cardType) {
    this.totalNum = await this.contract.balanceOf(addr);
    let pList = [];
    for (let i = 0; i < +this.totalNum; i++) {
      pList.push(this.contract.tokenOfOwnerByIndex(addr, i));
    }
    const list = [];
    // 构造 blindBox 数组
    await Promise.all(pList).then(async values => {
      // console.log('box values..', values);

      for (let index = 0; index < values.length; index++) {
        const findItem = this.boxType.find(item => item.cardType === cardType);
        const cloneCard = { ...findItem };
        list.push(cloneCard);
        list[index].id = Number(values[index]);
      }
    });
    this.boxList = list;
    return this.boxList;
  }

  /**
   * 批量查询 Igo 二开盲盒
   * @param {*} addr 
   * @returns 
   */
  async getBoxListByBatchToken(addr) {
    const resp = await this.contract.batchTokenURI(addr);
    const tokenIds = resp[1].map(item => {
      return item.slice(-5);
    });
    const cardType = {
      10001: 'Skins-Pack',
      10002: 'Items-Pack',
      10003: 'Cattle',
      10004: 'Bovine-Hero'
    };
    const list = [];
    let index = 0;
    for (let i = 0; i < tokenIds.length; i++) {
      if (!cardType[tokenIds[i]]) {
        continue;
      }
      const findItem = this.boxType.find(item => item.cardType === cardType[tokenIds[i]]);
      const cloneCard = { ...findItem };
      list.push(cloneCard);
      list[index].id = +resp[0][i];
      index += 1;
    }
    this.boxList = list;
    return this.boxList;
  }

  /**
   * @param {String} addr 用户地址
   * 标准化获取对应 TokenURI 的盲盒列表
   */
  async getBoxListByTokenURI(addr) {
    this.totalNum = await this.contract.balanceOf(addr);
    let pList = [];
    /**
     * 獲取tokenID列表
     */
    for (let i = 0; i < +this.totalNum; i++) {
      pList.push(this.contract.tokenOfOwnerByIndex(addr, i));
    }
    const list = [];
    const tokenIds = [];
    const urlList = [];
    const infoList = [];
    /**
     * 根據tokenID列表返回的promise去獲取tokenURI
     */
    const urlPromise = await Promise.all(pList).then(async values => {
      // console.log('box values..', values);
      for (let index = 0; index < values.length; index++) {
        const url = this.contract.tokenURI(Number(values[index]));
        urlList.push(url);
        tokenIds.push(Number(values[index]));
      }
      // console.log('urlList...', urlList);
      return urlList;
    });

    /**
     * 根據tokenURI promise 返回數據去請求json
     */
    const infoPromise = await Promise.all(urlPromise).then(urls => {
      for (let inx = 0; inx < urls.length; inx++) {
        const info = this.getTokenURI(urls[inx]);
        infoList.push(info);
      }
      // console.log('infoList...', infoList);
      return infoList;
    });

    /**
     * 根據json數據組成boxlist
     */
    const finPromise = await Promise.all(infoPromise).then(infos => {
      for (let i = 0; i < infos.length; i++) {
        const findItem = this.boxType.find(
          item => item.mataName.replaceAll('-', ' ') === infos[i].name
        );
        const cloneCard = { ...findItem };
        list.push(cloneCard);
        list[i].id = tokenIds[i];
      }
      this.boxList = list;
      return list;
    });

    // console.log('urlPromise...', this.boxList);
    return this.boxList;
  }

  /**
   * @param {String} addr 用户地址
   * 标准化获取对应 TokenURI 的盲盒列表 （本地测试）写死资源使用
   */
   async getBoxListByLocalTest(addr) {
    this.totalNum = await this.contract.balanceOf(addr);
    let pList = [];
    /**
     * 獲取tokenID列表
     */
    for (let i = 0; i < +this.totalNum; i++) {
      pList.push(this.contract.tokenOfOwnerByIndex(addr, i));
    }
    const list = [];
    const tokenIds = [];
    const urlList = [];
    const infoList = [];
    /**
     * 根據tokenID列表返回的promise去獲取tokenURI
     */
    const urlPromise = await Promise.all(pList).then(async values => {
      for (let index = 0; index < values.length; index++) {
        const url = this.contract.tokenURI(Number(values[index]));
        urlList.push(url);
        tokenIds.push(Number(values[index]));
      }
      // console.log('urlList...', urlList);
      return urlList;
    });

    /**
     * 根據json數據組成boxlist
     */
     for (let i = 0; i < tokenIds.length; i++) {
      const cloneCard = { ...this.boxType[13]}
      list.push(cloneCard);
      list[i].id = tokenIds[i];
    }
    this.boxList = list;

    console.log('url list...', list);
    return this.boxList;
  }

  /**
   * 获取TokenURI
   */
  async getTokenURI(url = '') {
    if (url.includes("ipfs")) {
      url = `https://ipfs.io/ipfs/${url.slice(7)}`;
    }
    return await fetch(url)
      .then(res => res.json())
      .then(out => {
        return out;
      })
      .catch(err => console.error(err));
  }

  /**
   * 开启盲盒
   * @param {Number} id tokenID
   */
  async openBox(id) {
    const resp = await this.contract.openBox(id, {
      gasLimit: 1000000
    });
    const event = await resp.wait();
    console.log('event..', event.events[3].topics);
    return event;
  }
}
