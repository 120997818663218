// chainid
export const CHAINID = Number(process.env.VUE_APP_CHAINID);
export const CHAINID_HEX = `0x${CHAINID.toString(16)}`;

// 交易类型
export const TRADE_TYPE = {
  1: 'OXG',
  2: 'OXX',
  3: 'BNB',
  4: 'USDT',
  5: 'AIA'
}