import { defineStore } from 'pinia';
import axios from 'axios';
// import { ethers, signer } from '@/utils/ethers';
import { useAppStore } from '@store/appStore';
import { getChainData } from '@/utils/tools';
import { toRaw } from 'vue';
import { $GET, $POST, $PUT } from '@/service/require.js';
import { createInstance } from 'dotbit';
import SIDfunctions from '@siddomains/sidjs';
const SID = SIDfunctions.default;
// const SIDfunctions = require('@siddomains/sidjs');
function splitArray(array, size){
  let data = [];
  for (let i = 0; i < array.length; i += size) {
    data.push(array.slice(i, i + size))
  }
  return data
}
const useAccountStore = defineStore('account', {
  state: () => ({
    username: '--', // 用户名称
    spaceName: useAppStore()?.defaultAccount, // SpaceID 名称 xxx.bnb
    gender: 0, // 性别 0 未设置 1男 2 女
    avatar: require('../assets/img/home/logo.png'),//'https://game.legendranch.app/legendaryranch/picture/profile/0.png', // 头像路径-uri
    planetName: '--', // 工會名稱
    planetImg: '', // 工會URI
    avartList: [], // 头像列表
    token: '', // 鉴权接口的Header参数：Token
    banlance: 0, // 余额
    relationship: null
  }),

  actions: {
    /**
     * 修改头像 URL
     * @param {String} val 头像URI
     * @returns
     */
    setAvartUrl(val) {
      this.avart = val;
    },

    /**
     * 设置余额
     * @param {Number} val
     */
    setBanlance(val) {
      this.banlance = val;
    },


    /**
     * 获取头像图片列表
     * @param {Array} list 图片名称列表
     * @returns {Array} 头像URL列表
     */
    async getAvatarList(list) {
      let resp;
      // let resList = [];
      try {
        let rList =splitArray(list,50);
        rList.forEach(async(item)=>{
          let resp = await $POST('profile/photo-link', { avatar: item });
          this.avartList=[...this.avartList,...resp]
        })
        // console.log('resList',resList)
        // resp = await $POST('profile/photo-link', { avatar: list.slice(0,50) });
        // this.avartList = resp;
      } catch (error) {
        console.log(error);
        resp =[];
      }
      return resp;
    },

    /**
     * 获取spaceName
     */
    async getSpanceName() {
      // 获取 SpaceID 的name ；如果 bsc 链上有的话，没有展示钱包地址
      const appStore = useAppStore();
      if (!appStore.defaultAccount) return;

      const { ethers } = appStore.ethObj;
      const chainData = getChainData('0x38');
      const provider = new ethers.providers.JsonRpcProvider(chainData.rpcUrls[0]);
      const dotbit = createInstance();
      // sid
      try {
        const sid = new SID({ provider, sidAddress: SIDfunctions.getSidAddress('56') });
        const sname = await sid.getName(appStore.defaultAccount).catch(err => console.log(err));
        this.spaceName = sname?.name ? `${sname?.name}` : appStore.defaultAccount;
      } catch (error) {
        // console.log('sid...', error);
      }
      // bit
      try {
        const account = await dotbit.reverse({
          key: appStore.defaultAccount
        });
        this.spaceName = account?.account ? account?.account : appStore.defaultAccount;
      } catch (error) {
        // console.log('dot...', error);
      }
    },

    /**
     * 获取用户信息
     *  @param {Address} addr 用户地址
     */
    async getProfileInfo() {
      const appStore = useAppStore();
      if (appStore.defaultAccount == null) return;
      const { ethers } = appStore.ethObj;
      // console.log('space name..', this.spaceName);
      // const genderList = ['panel.13', 'panel.14'];
      const resp = await $GET(`/profile/${appStore.defaultAccount}`);
      if (resp) {
        // 名稱
        if (ethers.utils.isAddress(resp.name)) {
          // this.username = plusXing(resp.name, 6, 4);
          this.username = resp.name?.substring?.(resp.name?.length - 6);
        } else {
          this.username = resp.name || '--';
        }
        // 性別
        this.gender = resp.gender || 0;
        // if (resp?.gender != 0) {
        //   // this.gender = genderList[resp.gender];
        // }
        // 頭像
        this.avatar = resp.avatar || this.avatar;
        // 工會
        this.planetName = resp.planetName;
        this.planetImg =
          resp.planetImage !== -1
            ? `https://game.legendranch.app/legendaryranch/picture/guildicon/${resp.planetImage}.png`
            : '';
        this.relationship = resp.relationship;
      }

      return resp;
    },

    /**
     * 设置鉴权接口的Header参数
     */
    async verify() {
      try {

        const appStore = useAppStore();
        const signer = toRaw(appStore.ethObj.signer);
        const sig = await signer.signMessage(
          'Confirm to connect your wallet to Legendranch successfully'
        );
        const account = await signer.getAddress();
        const resp = await $POST(`/auth/verify`, { address: account, sig: `eth ${sig}` });
        if (resp) {
          this.token = resp.token;
        }
        return this.token;
      }catch {
        return '';
      }
    },
    /**
     * 修改性别
     * @param {Number} gender 性别
     * @param {String} token 鉴权接口返回的token
     */
    async editUserGender(gender,token) {
      const resp = await $POST(`/profile/${gender}`, {},'token',token);
      if (resp) {
        console.log('editUserGender',resp)
        this.gender = resp;
      }
      console.log('editUserGender...', gender,resp);
    },

    /**
     * 修改用户名称
     * @param {Address} addr 用户地址
     * @param {Object} data 数据
     */
    async editUserName(addr, data) {
      const resp = await $PUT(`/profile/name/${addr}`, { ...data });
      if (resp) {
        this.username = resp;
      }
      console.log('data..', addr, data);
      console.log(resp);
    },

    /**
     * 修改用户头像
     * @param {String} aid 头像编号
     * @param {String} Token 鉴权接口返回的token
     * @returns {String} aid 头像编号
     */
    async editUserAvatar(aid, token) {
      try {
        const resp = await $PUT('profile/photo', { avatar: aid }, 'token', token);
        if (resp) {
          return resp.avart;
        }
      } catch (error) {
        console.log('editUserAvatar data..', aid);
        console.log(error);
      }
    },

    /**
     * 修改用户资料修改状态
     */
    async resetFlag() {
      try {
        const resp = await this.contract.resetStatus();
      } catch (error) {
        console.log(error);
        return error.message;
      }
    }
  },

  getters: {}
});

export default useAccountStore;
