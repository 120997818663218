import { defineStore } from 'pinia';
import { ethers } from 'ethers';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import { NFT_MINT_CONT } from '@/contracts/polygonAddress.js';

const useCattleMintStore = defineStore('cattleMint', {
  state: () => ({
    contract: {}, // 合约对象
    decimal: 18, // 精度
    tokenIds: [], // 用户的牛牛卡牌 ID 列表
    heroIds: [],
    cattleIds: [],
    heroList: [], // 我挂售的创世列表
    cattleList: [], // 我挂售的牛列表
    blindBoxList: [], // 我挂售的盲盒列表
    planetList: [], // 我挂售的星球列表
    tradeList: [], // 交易信息
    cattleInfo: {}, // 牛牛卡牌信息
    totalNum: 1, // 卡片總數
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createContract() {
      const appStore = useAppStore();
      const { ethers, signer } = appStore.ethObj;
      this.contract = new ethers.Contract(NFT_MINT_CONT.address, NFT_MINT_CONT.abi, toRaw(signer));
    },

    /**
     * 设置精度
     */
    setDecimal(payload) {
      this.decimal = payload;
    },
    /**
     * 获取市场单状态
     * @param {number} goodsType 1-牛牛 2-盲盒 3-星球
     * @param {number} id 
     * @returns boolean true 已售 false 未售
     */
    async getNftCardStatus(goodsType, id) {
      console.log(this.contract)
      console.log('查询参数sellingGoods', goodsType, id)
      let resp = await this.contract.sellingGoods(goodsType, id);
      console.log('查询sellingGoodsresp响应：', resp, resp.id.toString())
      return !resp || resp.id.toString() === '0'
    },

    /**
     * 获取售卖的最低价
     * @param {Number} goodsType 商品类型 (1牛牛、2盲盒、3星球)
     * @param {Number} tradeType 货币类型 1 TCOIN 2 GCOIN 3 BNB 4 USDT ps: Polygon U 精度为 6
     * @returns 
     */
    async getLowerLimit(goodsType, tradeType) {
      const resp = await this.contract.lowerLimits(goodsType, tradeType);
      // const resp = 0
      let price = 0;
      if (tradeType === 4) {
        price = ethers.utils.formatUnits(resp, 6)
      } else {
        price = ethers.utils.formatUnits(resp, 18)
      }
      return price
    },

    /**
     * 获取用户出售的卡牌列表
     * @param {Number} goodsType 牛或者盲盒
     * @param {Address} addr 用户地址
     */
    async getSellList(payload) {
      const resp = await this.contract.getUserSaleList(payload.goodsType, payload.addr);

      const list = [];
      for (let i = 0; i < resp.length; i++) {
        const info = {
          id: +resp[i][0],
          tradeType: +resp[i][1],
          price: +resp[i][1] === 4 ? ethers.utils.formatUnits(resp[i][2], 6) : ethers.utils.formatUnits(resp[i][2], 18)
        };
        list.push({ ...info });
      }
      this.tradeList = list;
      console.log('sellList..', this.tradeList);
      this.tokenIds = this.tradeList == [] ? [] : this.tradeList.map(item => item.id);
      return this.tokenIds;
    },

    /**
     * 根据 tradeType 购买卡牌
     * @param {Number} tradeType 主链币或平台币 3:BNB 主链币需要用 {value：BigNumber} 入参
     * @param {Number} goodsType 挂售类型(1牛牛、2盲盒、3星球)
     * @param {Number} amount 支付价格
     * @param {Number} id 卡牌Id
     */
    async buyCard(payload) {
      const appStore = useAppStore();
      const { baseGasPrice } = appStore.ethObj;
      const contract = toRaw(this.contract);
      // console.log('buyCard',contract,payload)
      let resp;
      const goodsType = { calf: 1, blindBox: 2, planet: 3 };
      if (payload.tradeType == 5) {
        resp = await this.contract.mainCoinPurchase(goodsType[payload.goodsType], payload.id, {
          gasPrice: 10 * 10 ** 9 + baseGasPrice,
          value: payload.amount
        });
      } else {
        // console.log('erc20Purchase参数', goodsType[payload.goodsType], payload.id);

        const limit = await contract.estimateGas.erc20Purchase(
          goodsType[payload.goodsType],
          payload.id,
          {
            from: appStore.defaultAccount
          }
        );
        console.log('limit', limit)
        resp = await this.contract.erc20Purchase(goodsType[payload.goodsType], payload.id, {
          gasPrice: 10 * 10 ** 9 + baseGasPrice,
          gasLimit: (Number(limit) * 1.5).toFixed(0)
        });
      }
      await resp.wait();
    },


    /**
     * 出售
     * @param {Number} goodsType 1 牛 2 盲盒 3 星球
     * @param {Number} tradeType 主链币或平台币
     * @param {Number} id 卡牌Id
     * @param {Number} price 支付价格
     */
    async sellCard(payload) {
      const appStore = useAppStore();
      const { baseGasPrice } = appStore.ethObj;
      const contract = toRaw(this.contract);
      console.log('出售参数：sell(%s,%s,%s,%s,%s)', payload.goodsType,
        payload.id,
        payload.tradeType,
        payload.price,
        payload.payee)

      // const statusResult = await contract.currencyStatus(payload.tradeType)
      // console.log('currencyStatus',statusResult)
      // return

      const limit = await contract.estimateGas.sell(
        payload.goodsType,
        payload.id,
        payload.tradeType,
        payload.price,
        payload.payee,
        {
          from: appStore.defaultAccount,
          // gasLimit: ethers.utils.parseUnits(String(250452), 0),
        }
      );

      console.log(
        'gasLimt..',
        +limit,
        limit,
        (Number(limit) * 1.5).toFixed(0),
        baseGasPrice,
        10 * 10 ** 9 + baseGasPrice
      );

      const resp = await this.contract.sell(
        payload.goodsType,
        payload.id,
        payload.tradeType,
        payload.price,
        payload.payee,
        {
          gasPrice: 10 * 10 ** 9 + baseGasPrice,
          gasLimit: (Number(limit) * 2.5).toFixed(0)
        }
      );
      await resp?.wait();
    },

    /**
     * 取消出售
     * @param {Number} goodsType 1 牛 2 盲盒 3 星球
     * @param {Number} id 卡牌Id
     */
    async cancelSell(payload) {
      const appStore = useAppStore();
      const { baseGasPrice } = appStore.ethObj;
      const contract = toRaw(this.contract);
      const limit = await contract.estimateGas.cancelSell(payload.goodsType, payload.id);

      const resp = await this.contract.cancelSell(payload.goodsType, payload.id, {
        gasPrice: 10 * 10 ** 9 + baseGasPrice,
        gasLimit: (Number(limit) * 1.5).toFixed(0)
      });
      await resp?.wait();
    },

    /**
     * 白名单
     */
    async verifyWhitelist() {
      let valid = true
      const appStore = useAppStore();
      const isWhiteExpireTime = await this.contract.getWhiteExpireTime()
      const whiteList = await this.contract.getWhiteList()
      const isExpired = isWhiteExpireTime * 1000 > Date.now()
      if (isExpired) {
        valid = whiteList.includes(appStore.defaultAccount)
      }

      return valid
    }
  },

  getters: {}
});

export default useCattleMintStore;
