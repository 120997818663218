import { defineStore } from 'pinia';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import commonObj from '@/contracts/commonContract.js';
import leaseObj from '@/contractsApi/polygon/PlanetLease.js';
import {
  STAR_CONT,
  STAR_INFO_CONT,
  PROXY_QUERY_CONT
} from '@/contracts/polygonAddress.js';

const usePlanetLeaseStore = defineStore('planetLease', {
  state: () => ({
    contract: {}, // 合约对象
    leaseContract: {},
    ownerAddr: null,
    // 星球类别
    planetTypes: [
      {
        id: '',
        cardType: 1,
        name: 'card.12',
        image: 'https://game.legendranch.app/legendaryranch/planet/homeland.png',
        desc: 'card.15',
        tax: 20,
        totalTax: 0,
        population: 0,
        populationLimit: '10,000'
      },
      {
        id: '',
        cardType: 2,
        name: 'card.13',
        image: 'https://game.legendranch.app/legendaryranch/planet/unionland.png',
        desc: '',
        tax: 20,
        totalTax: 0,
        population: 0,
        populationLimit: 1000
      },
      {
        id: '',
        cardType: 3,
        name: 'card.14',
        image: 'https://game.legendranch.app/legendaryranch/planet/exland.png',
        desc: '',
        tax: 20,
        totalTax: 0,
        population: 0,
        populationLimit: 1000
      }
    ],
    planetIdArr: [], // 临时存储 planet ID 和 cardId 集合
    leaseList: [], // 用户的星球ID列表 最多两个
    // 租赁信息
    leaseInfo: {
      id: 0, // 星球id
      owner: '', // 持有者
      tenant: '', // 租客
      isOwner: false, // 是否是持有者
      isTenant: false, // 是否是租客
      isPresident: false, // 是否是會長
      rentAmount: 0, // 租金
      rentTime: 0, // 到期時間
      reward: 0 // 收益 直接到賬}
    },
    // 星球信息
    planetInfo: {
      id: '',
      cardType: 1,
      name: 'card.12',
      image: 'https://game.legendranch.app/legendaryranch/planet/homeland.png',
      desc: 'card.15',
      tax: 20,
      totalTax: 0,
      population: 0,
      populationLimit: 10000
    }
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createContract() {
      const appStore = useAppStore();
      const { ethers, signer, chainId } = appStore.ethObj;
      this.leaseContract = new leaseObj();
    },

    /**
     * 獲取租賃信息
     */
    async getViewInfo() {
      const resp = await this.leaseContract.checkViewInfo();
      this.leaseInfo = resp;
    },

    /**
     * 获取星球租賃列表
     */
    async getPlanetLeaseList() {
      // 查詢各項數值合約裏查用戶星球信息
      const leaseContract = new leaseObj();

      const res = await leaseContract.checkViewInfo();
      if (res.isPresident) {
        this.leaseList = [
          {
            id: res.id,
            cardType: 1,
            name: 'card.12',
            image: 'https://game.legendranch.app/legendaryranch/planet/homeland.png',
            desc: 'card.15',
            tax: 20,
            totalTax: 0,
            population: 0,
            populationLimit: '10,000'
          }
        ];
      } else {
        this.leaseList = [];
      }
    },

    /**
     * 构造Planet List
     */
    setPlanetList() {
      // 填充 cardInfo 数据
      let cardList = [];
      for (let index = 0; index < this.planetIdArr.length; index++) {
        for (let id = 0; id < this.planetTypes.length; id++) {
          if (this.planetIdArr[index].cardId == this.planetTypes[id].cardType) {
            const cloneCard = { ...this.planetTypes[id] };
            cardList.push(cloneCard);
            cardList[index].id = this.planetIdArr[index].id;
          }
        }
      }
      this.planetList = cardList;
      return this.planetList;
    },

    /**
     * 获取用户星球的信息
     * @param {Number} id 卡牌Id
     */
    async getPlanetInfo(id, type) {
      const appStore = useAppStore();
      const { ethers } = appStore.ethObj;
      const { contract } = new commonObj(STAR_INFO_CONT);
      const resp = await contract.planetInfo(id);
      const findedItem = this.planetTypes.find(item => item.cardType == Number(type));
      console.log('res..', resp, findedItem, Number(resp.population));
      this.planetInfo = { ...findedItem };
      // this.planetInfo.tax = Number(resp.tax);
      this.planetInfo.population = Number(resp.population);
      this.planetInfo.totalTax = Number(ethers.utils.formatUnits(resp.totalTax || 0)).toFixed(3);
      return this.planetInfo;
    }
  },

  getters: {}
});

export default usePlanetLeaseStore;
