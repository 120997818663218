import { watchEffect } from 'vue';
import { createI18n } from 'vue-i18n';
import cn from '@/locales/cn.js';
import en from '@/locales/en.js';
import kn from '@/locales/kn.js';
import ar from '@/locales/ar.js';
import jp from '@/locales/jp.js'

let i18n;
watchEffect(() => {
  i18n = createI18n({
    fallbackLocale: 'en',
    globalInjection: true,
    legacy: false, // you must specify 'legacy: false' option
    locale: localStorage.getItem('lang') || 'en',
    messages: {
      cn,
      en,
      kn,
      ar,
      jp
    },
  });
});

export default i18n;
