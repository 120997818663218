export default {
    undefined: '',
    header: {
      1: 'アクティビティ',
      2: 'ゲーム',
      3: 'NFT',
      4: 'DAO',
      5: 'ソーシャル',
      6: 'その他',
      7: 'レジェンダリーファーム',
      8: 'インターステラーサンドボックス',
      9: 'インターステラージャーニー',
      10: 'はじめに',
      11: 'ホワイトペーパー',
      12: 'マイウォレット',
      13: 'ホーム',
      14: '友達',
      15: 'マイプラネット',
      16: '暗号ブログ',
      17: 'ランキング',
      18: 'ゲスト',
      19: '開墾イベント',
      20: 'ニウニウマーケットイベント',
      21: '取引市場',
      22: 'NFT ファーム',
      23: 'ウォレットにリンク',
      24: 'リンクを解除',
      25: '公開テストアセット',
      26: 'まもなく開始',
      27: '公開テスト招待パーティー',
      28: 'ギルドリクルートプラン',
      29: 'エアドロップ',
      30: 'クーポンセンター',
      31: 'バッジエアドロップ',
      32: '$LAZIOマイニング',
      33: 'クロスチェーンブリッジ',
      34: 'ゲームストア',
      35: 'YOHO Chain',
      36: '$OXG&$OXXマイニング',
      37: '注文履歴',
      38:'プロジェクト紹介',
      39: '創世記NFTの交換'
    },
    home: {
      1: 'OXGの購入',
      2: 'OXXの購入',
      3: 'ゲーム',
      4: 'レジェンダリーファーム',
      5: 'インターステラーサンドボックス',
      6: 'インターステラージャーニー',
      7: '<p>中古平行宇宙</p> <p>繁殖、産乳、戦闘！</p>',
      8: '<p>3D平行世界</p> <p>惑星、宇宙船、創造！</p>',
      9: '<p>超時空平行宇宙</p> <p>VRゲーム、探索、戦闘！</p>',
      10: 'ゲームに参加',
      11: '近日公開',
      12: 'SocialFiマトリックス',
      13: 'ソーシャル',
      14: '連邦 ',
      15: '暗号ブログ',
      16: 'プレイヤーはゲームの世界で自分の仮想的なソーシャルネットワークを運営できます',
      17: 'ゲームNFT',
      18: 'ホームプラネット',
      19: 'フェデラルプラネット',
      20: '開拓プラネット',
      21: '胚胎ブラインドボックス',
      22: '牛の子',
      23: '母牛の子',
      24: '<p>標準のERC-721プロトコルを採用し、独自のID識別子とアバター要素を持ち、各NFT取引市場で自由に取引できます</p><p>数量が限られ、総発行上限は10,000頭で、最初の供給は500頭のみです</p><p>他の牛よりも属性が高く、無限の繁殖回数と永遠の命を持っています</p><p>クロスゲームの主人公として、自由にYOHOのどんな平行世界でも冒険し、真の意味で永遠の命を得ることができます</p>',
      //24: '獨一無二的ID和頭像',
      25: '私たちのビジョン',
      26: '私たちのビジョンは、最先端のブロックチェーン技術を活用して多様な宇宙の世界観を構築し、分散経済、ゲーム、ソーシャルシステムで、各プレイヤーが自由にソーシャル、冒険、創造を展開できる世界を構築することです。この魔法の宇宙で、いつでも、低遅延で、没入感のあるゲーム体験を得ることができます',
      27: '戦略的なパートナー',
      28: '私たちのコミュニティ',
      29: '利用規約',
      30: 'プライバシーポリシー',
      31: '',
      // 32: '<p>跨遊戲的DAO社群聯盟以及利益共同體</p><p>社群內經濟系統DAO治理</p><p>以聯邦為單位的資源開發、分配、貿易、掠奪玩法</p>',
      // 33: '<p>基於Web3.0的遊戲流媒體內容創造、分發平台以及去中心化KOL經濟激勵協議</p><p>遊戲內的玩法、場景、道具、裝備的創造、分享</p><p>遊戲視頻、遊戲直播的分發、觀看及打賞</p>',
      32: '跨遊戲的DAO社群聯盟以及利益共同體',
      33: '基於Web3.0的遊戲流媒體內容創造、分發平台以及去中心化KOL經濟激勵協議',
      34: '小牛の子',
      35: '惑星',
      36: '雄牛',
      37: '雌牛',
      38: '成熟雄牛',
      39: '成熟雌牛',
      40: '人口上限',
      41: '<p>独自のID識別子およびアバター;</p><p>より高い属性、永遠の命;</p><p>無限の繁殖回数。 </p>',
     // 41: '',
      42: 'ユニークな特性',
      43: '流動性',
      44: '希少性',
      45: '標準のERC-721プロトコルに基づき、各NFT取引市場で自由に取引可能；クロスゲームのキャラクター、その自由度でYOHOのどの平行世界にも漫遊できます。',
      46: '総発行上限は10,000頭；最初の供給は514頭のみです',
      47: 'ストレンジャーソーシャルおよびマッチングシステム：ランダムにプレイヤーをマッチングし、ランダムにダンジョンタスクを割り当てます；',
      48: 'インティメートな関係と共同成長システム：豊富なゲーム内のリアルタイムコミュニケーションシステム。',
      49: 'コミュニティ内経済システムDAOガバナンス；',
      50: '連邦単位でのリソースの開発、分配、貿易、略奪プレイ。',
      51: 'ゲーム内のプレイ方法、シーン、アイテム、装備の創造、共有；',
      52: 'ゲームビデオ、ゲームライブの配信、視聴および報酬。',
      53: 'より高い属性および永遠の生命',
      54: '無限の繁殖回数。',
      55: '私たちについて',
      56: 'リソース',
      57: 'ショーケース',
      58: '私たちのコミュニティ',
      59: 'YOHOについて',
      60: 'メディア',
      61: 'お問い合わせ',
      62: '利用規約',
      63: '監査報告',
      64: 'ホワイトペーパー',
      65: 'Github',
      66: 'FAQ',
      67: 'ゲーム',
      68: 'NFT市場',
      69: 'エアドロップ',
      70: ' 経済モデル $OXG',
      71: '契約アドレス',
      72: 'IDOへの参加 ',
      73: '$OXGの購入',
      74: 'ロードマップ',
      75: '年末',
      76: '30以上のゲームギルドと提携し、土地とジェネシスニウニウNFTを販売；;',
      77: 'ニウ宇宙公式ウェブサイト1.0をオンラインで公開；',
      78: 'BSCおよびPolygonにNFTを展開',
      79: '最初のプレイで稼ぐ（P2E）ゲーム「レジェンダリーファーム」がBSCで内部テストを完了；',
      80: '単一通貨のマイニング活動が110万ドルのピークに達する。',
      81: 'Project GalaxyとIGOブラインドボックス販売を開始；Earth Careイベントには5,000人以上が参加。',
      82: '最初のゲーム開発でGVG（ギルド対ギルド）を統合、「レジェンダリーファーム」はPolygonでアルファステージを完了。',
      83: 'NFTマイニング- 5,000人以上のユーザーが参加；1周年記念イベントには10,000回以上の参加がありました。',
      84: 'コミュニティの成長は20万人を超えました；',
      85: '有名なWeb3プロジェクトと協力（Cyberconnect、DeGame、SnapFigure DAOを含む）；プライベートプレセール開始；「レジェンダリーファーム」は公共テストフェーズに入りました。',
      86: '独自のNFTマーケットを開始；',
      87: 'DAO - コミュニティガバナンス；プライベートセール終了；OXGのDOを開始；2番目のゲーム - 「インターステラーサンドボックス」は内部テストフェーズを完了。',
      88: 'さらに多くのゲームギルドと提携；「インターステラーサンドボックス」が公共テストを開始；これらの2つのゲームでNFTおよびトークンを含む暗号資産を使用できるようになり、OXGをエコシステムに組み込みます。',
      89: 'インターステラーブログソーシャルプラットフォームがオンラインで公開；第3のVRゲーム - 「インターステラージャーニー」の拡張現実インタラクティブゲームモードをサポート。',
      90: 'eスポーツゲームチャレンジ、オンラインイベントなどのオフラインイベントを開始し、プレイヤーにさらなる楽しみと挑戦を提供します。',
      91: '平行世界のプラネットウォーを通じてメタバースを開始します。',
      92: 'スキンブラインドボックス',
      93: 'プレミアムフード',
      94: 'プレミアム成長促進パック',
      95: 'プレミアムエネルギーパック',
      96: 'プレミアムライフポーション',
      97: 'プライベートプレセール',
      98: 'チーム',
      99: '流動性',
      100: 'アドバイザーおよびPR',
      101: 'エコシステム',
      102: 'アイテム',
      103: '取引所上場',
      104: 'IDOへの参加',
      105: 'もっと見る',
      106: 'サッカー予測',
      107: '<p>チャレンジ予測 </p><p>大賞を獲得！</p>',
      108: 'エリートプレイヤー',
    },
    igo: {
      // 这个是表格的
      1: '名字',
      2: '數量',
      3: '概率',
      4: '稀有級別',
      5: '創世牛牛',
      6: '普通牛牛',
      7: '道具禮包',
      8: '皮膚禮包',
      // 一些介绍
      9: '1. 什麼是盲盒？盲盒可以開出什麼？',
      10: '盲盒是 YOHO 的创世NFT；IGO 參與者有機會贏得寶貴的遊戲資產，如創世牛牛、普通牛牛、遊戲道具包或牛牛皮膚包',
      11: '2. 這些NFT的用途是什麼？',
      12: '<strong>創世牛牛</strong> 是可以在 YOHO 的三個平行世界中使用的加密頭像。 它們每個都有最高的自由度並且都是獨一無二的純手工設計。 它們的稀有性決定了它們在遊戲中的優質屬性，並允許無限繁殖和永生',
      13: `<strong>普通牛牛</strong> 也是 YOHO 遊戲中的主要角色，但是繁育次數和壽命是有限的。普通牛牛也是YOHO的公民，可以參與農場、繁殖、戰鬥以及探索等活動。`,
      14: '<strong>遊戲道具包</strong> 包括以下內容：草料、體力包、成長加速包、生命藥劑和更名卡。',
      15: '<strong>牛牛皮膚包</strong> 是普通牛牛和創世牛牛的皮膚服裝；各式各樣的皮膚設計可以更改牛牛們的外觀，使它們在戰鬥中脫穎而出、與眾不同。',
      16: '所有NFT都是基於<strong>ERC-721中</strong>，並且可以在 NFT 市場中自由交易。此外，它們是跨遊戲資產，穿越時間和空間的壁壘，這意味著它們可以在不同的遊戲中使用。您将可以在 <strong>4 月 5 日</strong>通过<strong>Project Galaxy</strong>打开盲盒。您將能夠在 <strong>4月5日</strong>通過 <strong>Project Galaxy</strong> 打開盲盒。',
      17: '3.在盲盒中每個 NFT 的概率以及稀有度是多少？',
      18: '4. 擁有白名單會有什麼好處?',
      19: '所有獲得白名單用戶均可在公開發售前 48 小時參與 YOHO IGO。 此外，他們可享受公售价25%的折扣！',
      20: '5. 如何加入白名單活動?',
      21: '1）YOHO OAT 持有者, <strong>440個白名單名額</strong>',
      22: `每個參與者都需要持有 <a onclick="window.open('https://galaxy.eco/YOHO/campaign/GCaMWUUuJF');">YOHO 社區徽章 NFT</a> 或 <a onclick="window.open('https://galaxy.eco/YOHO/campaign/GCaMWUUuJF');">YOHO Loyalty NFT</a>。 將從這些徽章持有者中選出 <strong>440</strong>名幸運獲獎者。`,
      23: '',
      24: '',
      25: '',
      26: '2）Galaxy Girl持有者，<strong>500個白名單名額</strong>',
      27: '若想要獲得本輪資格，您需要成為 <strong>Galaxy Girl持有者</strong>，<strong>500</strong>名幸運獲獎者將從<strong>Galaxy Girl持有者</strong>中選出，<strong>先到先得。</strong>',
      28: '3）社區活動獲獎者, <strong>500 個白名單名額</strong>',
      29: `我們將從白名單<a onclick="window.open('https://gleam.io/sDYK8/YOHO-igo-whitelist-campaign')">Gleam活動</a>、合作夥伴giveaway和Discord活動的參與者中挑選500名幸運者給予白名單。`,
      30: '6. YOHO 保留所有活動的最終解釋權。',
      31: '總供應量:1800',
      32: '白名單',
      33: '每個150 USDT',
      34: '2022/3/18 12:00 - 2022/3/20 12:00(UTC)',
      35: '公售',
      36: '每個200 USDT',
      37: '2022/3/20 12:00 - 2022/3/22 12:00(UTC)',
      38: '立即購買',
      39: 'WL Round-銀河少女',
      40: 'WL Round-YOHO OAT 和社區活動',
      41: '公開回合',
      42: '返回',
    },
    blindBox: {
      1: '單價',
      2: '總量',
      3: '天',
      4: '時',
      5: '分',
      6: '秒',
      7: '合成行星碎片',
      8: '立即購買',
      9: '1. 開啟光輪寶箱可隨機獲得創世牛牛、普通牛牛、牛牛寶箱或行星碎片，合成一定行星碎片可抽取普通牛牛、創世牛牛以及星球。',
      10: '2. 光輪寶箱單價：$50，總發行量：200,000。 個人購買次數不限。',
      11: '3. 基於ERC-721協議，光輪寶箱可以於Tofu等第三方NFT交易市場以及牛宇宙交易市場交易。',
      12: '4. NFT星球只可在星璇坐標通過行星碎片開出，玩家可於星璇坐標處合成行星碎片獲得家園星球以及拓荒星球。',
      13: '5. 注：除行星碎片外，本次活動開出道具均以兌換券形式發放。 活動開出的兌換券可於我的錢包查看。 遊戲上線後，玩家可憑兌換券於遊戲內兌換相應道具。',
      14: '',
      15: '',
      16: '內容',
      17: '概率',
      18: '光輪寶箱',
      19: '創世牛牛',
      20: '普通牛牛',
      21: '牛牛寶箱',
      22: '行星碎片',
      23: '家園星球及拓荒星球',
      24: '* 第四輪創世牛牛星璇坐標必得創世牛牛，供應有限，先到先得。',
      25: '規則',
      26: '星璇坐標',
      27: '寶箱内容',
      28: 'Galaxy Chest',
      29: 'Galaxy Chest is an NFT asset deployed on ERC-721. From Galaxy Chest, players can acquire Bovine Hero, Regular Cattle, Cattle Box, Star Fragment by chance, valuable in-game assets that have a great value in YOHO as well as in NFT marketplaces. In addition, Star Fragment can be collected to win NFT planets, precious and virtual land assets in BV.',
      30: 'issue number',
      31: 'Enter address to search qualified pre-order spots',
      32: 'search',
      33: 'FOMO PRIZE POOL',
      34: 'YOHO has specially set up a FoMo prize pool to reward players who actively participate in stargazing activities. Every time a player opens a halo chest, the system will inject 20,000 BVG into the pool. The system will inject 5,000 BVG into the pool every time a player draws a planetary fragment. Three active users who actively participate in the activity every day can share the YOHOG in the pool of the previous day on the next day:',
      35: 'Lucky Prospector',
      36: 'Land Researcher',
      37: 'Treasure Judge',
      38: 'Opening time',
      39: 'Total FRAG consumed',
      40: 'Total Halo Chests opened',
      41: 'Claimable BVG',
      42: 'Address',
      43: 'Countdown',
      44: 'Claim',
      45: 'Join the Last Swirl',
      46: 'Consume most FRAGs',
      47: 'Open most Chests',
      48: '打開盲盒',
      49: 'NFT授權',
      50: '我們會徵求您的同意後再訪問您的令牌並且這是一次性操作。',
      51: '確認打開',
      52: '點擊確認打開您的盲盒。',
      53: '恭喜，你獲得',
      54: '質押NFT',
      55: '確認質押',
      56: '確認將你的NFT質押入農場',
    },
    halo: {
      1: '牛牛集市正式向所有人限時開放！在本次集市交易活動中，YOHO將限量售賣特別的活動盲盒-光輪寶箱，開啟後隨機獲得稀有的遊戲道具NFT。牛牛集市將NFT、大樂透和遊戲玩法三合一，集結其中所有的有趣玩法，為玩家打造一個豐富的遊戲體驗。此外，牛牛集市特別設立邀請機制，玩家通過邀請他人參與進集市活動，獲得10%的返現獎勵。如此好玩的大型抽獎活動，你還在等什麼呢？參加限時集市活動，贏取稀有NFT獎勵！',
      2: '牛牛集市活動規則介紹',
      3: '1. 本次活動由YOHO官方發起，於UTC時間6月23日中午12:00開始。',
      4: '2. 每個光輪寶箱售價 20 美元，總供應量 20,000。每個地址沒有購買上限。',
      6: '3. 推薦獎勵：一旦您購買了光輪寶箱，您就有資格成為推薦人。每次推薦您將獲得總付款的 20% 作為獎勵。一旦您的朋友輸入您的錢包地址作為邀請碼並確認付款，20% 將自動發送到您的地址。',
      7: "4. 白名單用戶首次下單可享受額外 10% 的折扣。點擊<a href='https://docs.google.com/spreadsheets/d/1Xvuc6T7X8tS0nAiqOz3-Mm-WN-M_lANUb146FHEcswo/edit?usp=sharing'>此處</a>查看您的地址是否已列入白名單。白名單用戶包括（需在規定時間內提交地址）：牛宇宙一周年 OAT 的持有用戶，DC中擁有 Bovine Hero、Twitter Bird、Coser、Gambling King身份的用戶。",
      8: '5. 購買光輪寶箱後，可獲得以下物品之一：創世牛牛兌換券、小牛兌換券、牛牛集市盲盒或行星碎片。',
      9: '6. 獲得行星碎片的人可以參加碎片合成，贏取星球、創世牛牛、小牛、牛牛集市盲盒或BVG。',
      10: '  a. 通過碎片合成，可隨機獲得以下資產：牛牛集市盲盒，小牛，創世牛牛，星球，或者一定數量的碎片和BVG。',
      11: '  b. 每個合併的確切概率表在各自相應的合成頁面上展示。',
      12: '  c. 所有這些 NFT 都可以在二級 NFT 市場上自由交易，如 TofuNFT、Element 和 Galler。',
      13: '7. FOMO 獎池旨在獎勵那些積極參與牛牛集市活動的玩家。每當開啟一個光輪寶箱，5,000 BVG 將自動注入到池中。玩家每完成一次合併，就會自動將 2,000 BVG 注入到池中。每天三名獲勝者（天選之人、星球研究員和開箱達人）將在前一天的池中分享收集到的 BVG（%为该赢家可分得池子比例）。',
      14: '  a.天選之人：50%，合併的最終參與者',
      15: '  b.星球研究員：30%，消耗碎片最多的參與者',
      16: '  c.開箱達人：20%，打開寶箱最多的參與者',
      17: '8. 玩家需要消耗足夠的碎片才能隨機參與牛牛抽獎。創世牛牛合併只供應20個創世牛牛，先到先得。',
      18: '9. 玩家需要消耗足夠的碎片才能隨機贏得家園星球。本次抽獎僅提供2個家園星球，先到先得。',
      19: '立即購買',
      20: '合成碎片',
      21: '開啟我的光輪寶箱',
      22: '你能得到什麼',
      23: 'FOMO 獎金池',
      24: '下一輪',
      25: '回合',
      26: '天選之人',
      27: '星球研究員',
      28: '開箱達人',
      29: '獎勵',
      30: '獲勝者',
      31: '已領取獎勵',
      32: '未領取獎勵',
      33: '你贏了！領取你的獎勵！',
      34: '回合',
      35: '獎金池',
      36: '學習如何玩转 >',
      37: '<strong>描述:</strong> 光輪寶箱為牛牛集市活動售賣的盲盒，開啟後玩家隨機獲得創世牛牛、普通牛牛、集市盲盒、行星碎片。行星碎片可用於抽取家園星球、創世牛牛和普通牛牛。',
      38: '推薦人地址',
      39: '粘貼',
      40: '*如果沒有，請留空',
      41: '總額',
      42: '折扣總額（僅限白名單）',
      43: '再加十個',
      44: '開啟我的光輪寶箱',
      45: '返回首頁',
      46: '*推薦獎勵：一旦您購買了光輪寶箱，您就有資格成為推薦人。每次推薦您將獲得總付款的 20% 作為獎勵。',
      47: '可用行星碎片數量:',
      48: '合成一次所需碎片:',
      49: '剩餘數量:',
      50: '概率：',
      51: '獲得創世牛牛的概率:',
      52: '合成所需碎片:',
      53: '合成',
      54: '購買更多',
      55: '開啟我的盲盒',
      56: '銷毀',
      57: '你可以得到什麼',
      58: '輪次',
      59: '碎片',
      60: '1 個碎片 和 50,000 BVG',
      61: '2 個碎片 和 100,000 BVG  ',
      62: '1. 在你獲得牛牛集市盲盒後，你將獲得1個小牛或1個行星碎片，50%的概率。',
      63: '2. 本次抽獎中CattleMart Box的供應沒有上限；',
      64: '3. 確認合成後，將銷毀5個用於合併的行星碎片。',
      65: '1. 本次合成不設小牛供應上限。',
      66: '2. 確認合成後，將銷毀10個用於合成的行星碎片。',
      67: '1. 碎片合併僅提供20個創世牛牛。 先到先得。',
      68: '2. 玩家在第4輪前獲得創世牛牛，則從第1輪重新開始。',
      69: '3. 創世牛牛獲得數量沒有限制，也就是說每位玩家可以通過本次合成獲得多個創世牛牛。',
      70: '1. 本次合成僅提供2個家園星球。 先到先得。',
      71: '2. 獲得的星球數量沒有限制，也就是說每個玩家可以通過這次合併獲得多個星球。 但是，每個地址僅限於加入一個星球。 這意味著如果持有多個，你可以交易。',
    },
    farm: {
      1: '背景故事',
      2: '選擇質押币种',
      3: '質押數量',
      4: '我已質押的數量',
      5: '我的剩餘額度',
      6: '該池子裡已質押的量',
      7: '剩餘獎勵數量',
      8: '我的明細',
      9: '已領取獎勵',
      10: '待領取獎勵',
      11: '牛宇宙蘊含著數之不盡的財富和物資，在宙爍121年，墾荒浪潮如火如荼，兩大陣營之一的星邦自由聯盟組織了三家具有開採技術的墾荒公司進行星際探索，傑法爾榮譽墾荒公司（BNB）、工人墾荒聯合體（USDT）、沃利墾荒公司（FIL），墾荒每日總產出500萬個BVG，三個公司分別按50%、30%和20%的比例進行分配。化身牛類生物的玩家們可自由選擇公司加入或退出。每位玩家最多同時質押10 BNB、5,000 USDT和100 FIL。所獲收益均統一兌換成牛宇宙通用貨幣BVG。',
      12: '亮點',
      13: '金庫',
      14: '低門檻',
      15: '高收益',
      16: '隨存隨取',
      17: '通縮機制',
      18: '功能令牌',
      19: '預估年化收益率',
      20: '合約地址',
      21: '挖礦:',
      22: 'BVG:',
      23: '關於挖礦和獎勵代幣 BVG 的合約已經得到 Certik 官方的正式批准。 CertiK 是區塊鏈安全領域發展最快、最受信任的公司之一，也是區塊鏈安全領域的先驅，利用一流的形式驗證和人工智能技術來保護和監控區塊鏈、智能合約和 Web3 應用程序。',
      24: '點擊',
      25: '這裡',
      26: '查看審計報告。',
      27: 'OAT质押背景',
      28: '墾荒活動結束了嗎？不，宇宙探索沒有盡頭，一切才剛剛開始！牛宇宙中仍有數之不盡的財富和物資可以兌換成通用貨幣BVG，而OAT（On-chain Achievement Token）更是早期不可或缺的物資之一，扮演著無比重要的作用，讓無數牛牛為之瘋狂。 OAT是YOHO在Web3 基礎設施平台Project Galaxy上基於BNB Chain（前Binance Smart Chain）首發的定制徽章，目前已發布了5個，它們既是稀缺、珍貴的鏈上憑證，也是牛宇宙生態Social身份的表徵。',
      29: '信息',
      30: '1、 質押週期：30天（預計3月底開始）；',
      31: '2、 每日產出1,666,667個BVG，30天總量為50M BVG；',
      32: '3、 OAT質押為活期，隨進隨出；',
      33: '4、 每個OAT實際算力由鑄造數量而定，數量越稀缺，算力越高；',
      34: '5、 IGO盲盒是普通OAT的10倍算力。',
      35: '未抵押的 NFT',
      36: '質押的 NFT',
      37: '名稱',
      38: 'Token ID',
      39: '算力',
      40: '預計每日獎勵',
      41: '2、 合約地址',
      42: '待領取收益',
      43: '已領取收益',
    },
    market: {
      1: '最低価格',
      2: '最高価格',
      3: '最新時間',
      4: '',
      5: '',
      6: '',
      7: '',
    },
    panel: {
      1: 'アカウント',
      2: 'NFT',
      3: '親密な関係',
      4: '個人情報',
      5: '性別',
      6: 'ウォレット',
      7: 'ニックネーム',
      8: '惑星',
      9: '惑星の勝率',
      10: '結果',
      11: '勝率',
      12: 'ゲーム収益',
      13: '男性',
      14: '女性',
      15: '惑星',
      16: 'ジェネシスニウニウ',
      17: 'ニウニウ',
      18: 'ブラインドボックス',
      19: 'その他',
      20: 'フィルター',
      21: 'リセット',
      22: 'カテゴリ',
      23: '血統',
      24: '出品中',
      25: '雄牛',
      26: '雌牛',
      27: '私の注文',
      28: '価格が高い順',
      29: '価格が低い順 ',
      30: '最新の注文',
      31: '取り下げ',
      32: '履歴',
      33: '惑星のリース',
      34: 'データなし',
      35: 'バッジ',
      36:'通貨'
    },
    detail: {
      1: '基本情報',
      2: '基本属性',
      3: '戦闘属性',
      4: '産乳属性',
      5: '生命値',
      6: '成長値',
      7: '体力値',
      8: '戦闘値',
      9: '耐久値',
      10: '防御値',
      11: '産乳量',
      12: '産乳速度',
      13: '所有者',
      14: '即座に購入',
      15: '販売',
      16: '開く',
      17: '転送',
      18: '契約アドレス',
      19: 'トークンID',
      20: '説明',
      21: '人口',
      22: '稅率',
      23: '転送',
      24: '転送先の {0} アドレスを入力してください',
      25: 'アバターを変更',
      26: '数量',
      27: '取り下げ',
      28: '名前',
      29: '狀態',
      30: '数量',
      31: '価格',
      32: '売却済',
      33: '購入済',
      34: '時間',
      35: '親の情報',
      36: '父親',
      37: '母親',
      38: 'あなたの注文を設定',
      39: 'あなたの固定価格を設定',
      40: '費用',
      41: '総売上の2%がYOHOに支払われるプラットフォーム手数料として採用されます。',
      42: 'NFTを売却',
      43: '売却を確認',
      44: 'NFTをNFTマーケットに売却することを確認',
      45: 'トランザクション確認',
      46: '支払う金額を確認してください',
      47: '利用可能残高',
      48: '残高不足。 もっと暗号通貨を購入に進む>',
      49: '税金',
      50: 'カップル',
      51: '兄弟',
      52: '親友',
      53: '親友',
      54: 'トランザクション確認',
      55: 'ウォレットを認証',
      56: 'トランザクション前にトークンにウォレットの認証が必要です。一度だけ認証すると、以後使用できます',
      57: '取引を確認',
      58: '購入ブラインドボックスを確認するにはクリックしてください。',
      59: '合成を確認',
      60: 'NFTを合成するにはクリックしてください。',
      61: '残りの寿命',
      62: '注意：このブラインドボックスは「伝説の牧場」ゲームでのみ開封可能です。',
      63: '賃貸',
      64: '貸し出し中',
      65: '貸し出しを終了する',
      66: '賃貸中',
      67: '賃貸',
      68: '* 賃貸期間：',
      69: '* 賃貸中に随時延長可能',
    },
    card: {
      1: '牛牛のブラインドボックス',
      2: 'IGOブラインドボックス',
      3: '創世牛牛',
      4: '普通牛牛',
      5: 'アイテムギフトパック',
      6: 'スキンギフトパック',
      7: 'IGOミステリーボックスはYOHOの創世NFTです。IGO参加者だけが貴重なゲームアセット、創世牛牛、普通牛牛、アイテムパック、またはスキンパックを獲得するチャンスがあります。これらのNFTはすべてERC 721にデプロイされ、NFT市場で自由に取引できます。さらに、これらはクロスゲームアセットであり、時間と空間の壁を越えて異なるゲームで使用できます。',
      8: 'Bovine HeroはYOHOの3つの平行世界で使用できる暗号化アバターです。それぞれが最大の自由度を持ち、ユニークで手作りのデザインです。レアリティはゲーム内の優れた特性を決定し、無限の繁殖と永遠の命を許可する唯一の種です。これらのNFTはすべてERC 721にデプロイされ、NFT市場で自由に取引できます。さらに、これらはクロスゲームアセットであり、異なるゲームで使用できます。',
      9: '普通牛牛もYOHOゲームの主要なキャラクターですが、繁殖回数と寿命が制限されています。普通牛牛もYOHOの市民であり、ファーム、繁殖、戦闘、探索などに参加できます。これらのNFTはすべてERC 721にデプロイされ、NFT市場で自由に取引できます。さらに、これらはクロスゲームアセットであり、時間と空間の壁を越えて異なるゲームで使用できます。',
      10: 'YOHOのアイテムパックには次のようなゲームアイテムが含まれます：乾草、パワーバッテリー、経験カード、HPポーション、およびリネームカード。これらはすべてゲーム内アイテムであり、牛や牛ヒーローの属性を強化するために使用できます。これらのパックはERC 721にデプロイされ、NFT市場で自由に取引できます。さらに、これらはクロスゲームアセットであり、時間と空間の壁を超えて異なるゲームで使用できます。',
      11: 'さまざまなスキンデザインは牛牛の外観を変え、戦闘で目立つようにします。これらのNFTはすべてERC 721にデプロイされ、NFT市場で自由に取引できます。さらに、これらはクロスゲームアセットであり、時間と空間の壁を越えて異なるゲームで使用できます。',
      12: 'ホームプラネット',
      13: 'フェデレーションプラネット',
      14: '開拓プラネット',
      15: 'ホームプラネットは「伝説の牧場」のプレミアムプラネットNFTであり、ホームプラネットの会長は、住民プレイヤーとフェデレーションプラネットに対して課税し、プラネットの収益を分配し、プラネットの牛を豊かに導く権利があります。ホームプラネットの人口容量は10,000です。',
      16: '',
      17: '',
      18: 'マーケットブラインドボックス',
      19: '普通牛牛兌換券',
      20: '創世牛牛兌換券',
      21: '開拓プラネット交換券',
      22: 'ホームプラネット交換券',
      23: 'プラネタリーフラグメント',
      24: '牛牛マーケットイベントのマーケットブラインドボックスからは普通牛牛またはプラネタリーフラグメントがランダムに出現することがあります。普通牛牛はゲーム内で代トークンを稼ぐために搾乳、繁殖、戦闘、ステーキングなどの活動に参加します。プラネタリーフラグメントはホームプラネット、開拓プラネット、創世牛牛、普通牛牛などの希少なNFTアセットを入手するために使用されます。',
      26: '普通牛牛と創世牛牛はともに「伝説の牧場」の重要なキャラクターであり、採掘、繁殖、戦闘、およびその他の経済活動に参加してYOHOGおよびYOHOTトークン報酬を獲得します。普通牛牛はERC-721プロトコルにデプロイされ、セカンダリマーケットで自由に取引できます。',
      27: '創世牛牛はERC-721プロトコルにデプロイされ、YOHOのNFTアセットの一部であり、限定供給で永遠の寿命を持ち、NFT取引市場で自由に取引できます。創世牛牛はYOHOのクロスゲームアセットです。',
      28: 'ゲーム内の希少な土地型NFTアセットで、開拓プラネットはホームプラネットまたはフェデレーションプラネットのメンバーによって設立され、人口容量は1,000です。開拓プラネットの会長には、居住者と関連するフェデレーションプラネットから税金を徴収する権利があります。',
      29: 'ホームプラネットは「伝説の牧場」のプレミアムプラネットNFTであり、ホームプラネットの会長は、住民プレイヤーとフェデレーションプラネットに対して課税し、プラネットの収益を分配し、プラネットの牛を豊かに導く権利があります。ホームプラネットの人口容量は10,000です。',
      30: '一定数のプラネタリーフラグメントを集めてスターハブ座標抽選に参加し、創世牛牛、牛牛、プラネットなどの希少なNFTアセットを獲得できます。',
      31: '光輪の宝箱',
      32: '光輪の宝箱は牛牛マーケットイベントで販売されるブラインドボックスで、開封後、プレイヤーは創世牛牛、普通牛牛、プラネタリーフラグメントをランダムに入手できます。プラネタリーフラグメントはホームプラネット、開拓プラネット、創世牛牛、普通牛牛などの希少なNFTアセットを引き出すために使用されます。毎日最大で光輪の宝箱を開封するプレイヤーはFOMO賞金プールの分配に参加できます。',
      33: '雌雄の牛が繁殖すると、牛牛ブラインドボックスが入手できます。牛牛ブラインドボックスはERC-721プロトコルにデプロイされ、YOHOのNFTアセットの一部です。ボックスを開けると、創世牛牛、普通の子牛、牛牛フラグメントを入手するチャンスがあります。',
      34: '創世牛牛はERC-721プロトコルにデプロイされ、YOHOのNFTアセットの一部であり、限定供給で永遠の寿命を持ち、NFT取引市場で自由に取引できます。創世牛牛はYOHOのクロスゲームアセットです。',
      35: '普通牛牛も創世牛牛と同様に「伝説の牧場」の重要なキャラクターであり、採掘、繁殖、戦闘、およびその他の経済活動に参加してYOHOGおよびYOHOTトークン報酬を獲得します。',
      36: 'YOHOゲームのブラインドボックス',
      37: 'Binance NFTで販売されているYOHOブラインドボックスには、「伝説の牧場」の暗号化された戦闘と搾乳に参加するための独占的なゲームキャラクターが含まれています。独占的なギフトボックスを開くと、創世牛牛（$1000相当）、普通の小牛（$200相当）、またはアイテムパックの賞品が獲得できるチャンスがあります。ボックスを開けた後、公式ブリッジを使用してPolygonに接続する必要があります。',
      38: 'ブロンズバッジ',
      39: 'シルバーバッジ',
      40: 'ゴールドバッジ',
      41: 'プラチナバッジ',
      42: 'ダイヤモンドバッジ',
      43: '栄誉バッジ',
    },
    airdrop: {
      1: '請輸入您的地址',
      2: '搜索',
      3: '地址',
      4: '類型',
      5: '數量',
      6: '活動',
      7: '狀態',
      8: '已領取',
      9: '等待租赁',
      10: '立即領取',
      11: '凍結',
      12: '領取中',
      13: '鏈',
      14: '1. 空投獎勵含BNB和Polygon鏈上的代幣和NFT，請務必在領取前切換正確的網絡。',
      15: '2. 請務必在官網頁面鏈接您的錢包。',
      16: '3. 空投獎勵自活動公告之日起次月第1個工作日開放領取；領取通道於一周後關閉。',
      17: '4. 逾期未領取者將視為無效用戶。',
      18: "5. 點擊<a href='https://YOHO.medium.com/claiming-airdrop-rewards-tutorial-c4f428bcea3' target='_blank'>此處</a>查看詳細教程。",
      19: '6. 活動最終解釋權歸YOHO官方所有。',
      20: "7. 如遇更多問題，請點擊<a href='mailto:YOHO{'@'}gmail.com' target='_blank'>此處</a>與我們聯繫。",
      21: '了解如何領取空投',
      22: '描述',
    },
    rangeland: {
      1: 'ゲームに入る',
      2: '取引市場',
      3: 'チュートリアルビデオ',
      4: 'ランキング',
      // 排行榜
      5: '{0}週のランキング',
      6: '搾乳',
      7: '繁殖',
      8: '貢献',
      9: '搾乳量',
      10: '順位',
      11: '名前',
      12: 'プレイヤー',
      13: 'まもなく開始',
      14: '繁殖回数',
      15: '税金',
    },
  
    // 土地售卖领取
    claimAssets: {
      1: '家園星球',
      2: '家園星球是《傳奇牧場》的高級星球NFT，家園星球的會長可以向其居民玩家及聯邦星球征稅，分配星球收益，發展並帶領本星球牛牛致富。家園星球的人口容量為10,000。',
      3: '牛牛盲盒',
      4: '公母牛繁殖後可獲得牛牛盲盒。牛牛盲盒部署在ERC-721協議上，為YOHO其中一個NFT資產。打開盲盒有機會獲得創世牛牛、普通小牛、牛牛碎片。',
      5: '創世牛牛',
      6: '創世牛牛部署在ERC-721協議，為YOHO的NFT資產之一，限量供應，永恆生命，可在NFT交易市場自由貿易。創世牛牛是YOHO的跨遊戲資產。',
      7: '普通牛牛',
      8: '普通牛牛和創世牛牛一樣都是《傳奇牧場》的重要角色，在遊戲中參與挖礦、繁殖、戰鬥及其他經濟活動賺取BVG和BVT代幣獎勵。普通牛牛部署於ERC-721協議，可以於二級市場上自由交易。',
  
      9: '領取',
      10: '一鍵申請',
      11: '星球',
      12: '創世牛牛',
      14: '合約地址',
      15: '發行量',
      16: '可領取用戶',
      17: '白名單用戶',
      18: '可領取',
      19: '已領取',
      20: '不符合资格',
  
      21: 'TBVG為BV發行的遊戲代幣，玩家可通過參與遊戲、空投、挖礦、質押等活動獲得。遊戲上線後，玩家可以通過完成PVE等任務賺取TBVG。TBVG可用于購買遊戲道具、交易等。',
      22: 'TBVT為BV發行的代幣，可通過參與遊戲、空投活動等渠道獲得。玩家可以用TBVT購買遊戲道具、繁殖、升級、交易等。玩家擁有越多的TBVT，在BV的DAO社區自治中擁有的投票權也越多。',
  
      23: '即將上綫',
      24: '所有玩家',
      25: '已鑄造',
      26: '普通牛牛盲盒',
      27: '描述',
      28: '無上限',
      29: '供應量不足',
      30: '你已經領取了',
      31: '你沒有資格',
      32: '進入游戲',
    },
  
    // 公测拉新邀请 BetaInvite
    referral: {
      1: '啟航全民養牛新時代',
      2: '邀请玩家參與公测',
      3: '（第一季）',
      4: '身份',
      5: '推薦人地址',
      6: '粘貼',
      7: '確定您的推薦人',
      8: '邀請人數',
      9: '有效邀請數',
      10: '未領取獎勵',
      11: '已領取獎勵',
      12: '禮品包',
      13: '高級草料',
      14: '高級體力包',
      15: '高級經驗包',
      16: 'HP 藥水',
      17: '領取公測獎勵',
      18: "成功養育至少2只牛崽長大成年。 点击<a href='https://www.youtube.com/watch?v=HiZxJo4IfrM' target='_blank'>此处</a>觀看教程",
      19: "從產奶賺取至少200TBVT或者從戰鬥賺取至少1000TBVT。 点击<a href='https://www.youtube.com/watch?v=_UQ_NCppv0U' target='_blank'>此处</a>觀看教程",
      20: "向所在公會繳納至少100TBVT的稅費。 点击<a hre f='https://www.youtube.com/watch?v=-5HH7lIcWCg' target='_blank'>此处</a>觀看教程",
      21: "任一科技树升至三级。 点击<a href='https://www.youtube.com/watch?v=qb704wLapdU' target='_blank'>此处</a>觀看教程",
      22: "農場/牧場等級至少達到2級。 点击<a href='https://doc-en.bovine-verse.games/interstellar-rangeland-beta/stall/stall-player-level' target='_blank'>此处</a>觀看教程",
      23: '以下信息是地址',
      24: '地址',
      25: '狀態',
      26: '邀請查詢',
      27: '白名單玩家',
      28: '新用戶',
      29: '領取獎勵',
      30: '邀請地址',
      31: '你還沒有邀請任何人。',
      32: '1. 為感謝公測上線以來玩家的支持，我們為白名單用戶特別舉辦公測邀請派對，共同瓜分價值20,000 USDT的獎池。',
      33: '2. 白名單用戶為邀請活動開始前，參與遊戲內活動並經快照的玩家地址。',
      34: '3. 本次活動的推薦人僅可為白名單用戶，共同瓜分價值$20,000 U的大獎池。',
      35: '4. 每個白名單用戶可邀請好友加入《傳奇牧場》公測，邀請好友數量不設上限。',
      36: '5. 每達成一次有效邀請，白名單用戶可獲得5 USDT作為邀請獎勵，新用戶可通過活動頁面獲得禮包。',
      37: '6. 為公平起見，每個白名單地址的獎金上限為50 USDT。',
      38: '7. 新用戶需要輸入白名單用戶的地址作為推薦人，綁定推薦人成功並完成五個遊戲內任務後即可算為有效邀請。',
      39: '8. 本次公測邀請活動時間持續至公測結束。如果您不在本季邀請活動白名單內，可積極參與公測，敬請等待下一季開放。',
      40: "9. 用戶可以觀看每個任務的視頻教程或加入<a href='https://discord.com/invite/YOHO' target='_blank'>YOHO社區</a>了解遊戲玩法。",
      41: '10. 邀請人可前往任務進度頁查看邀請玩家的任務詳情以及完成狀態。',
      42: '11. 活動最終解釋權歸YOHO官方所有',
      43: '學習如何玩賺',
      44: '活動詳情',
    },
  
    // 公测资产兑换
    assetsExchange: {
      1: '草料',
      2: '體力包',
      3: '成長加速包',
      4: '皮膚盲盒',
      5: '改名卡',
      6: '兌換',
      7: '普通草料',
      8: '中级草料',
      9: '高级草料',
      10: '普通體力包',
      11: '中級體力包',
      12: '高級體力包',
      13: '普通成長加速包',
      14: '中級成長加速包',
      15: '高級成長加速包',
      16: '皮膚盲盒',
      17: '改名卡',
      18: '您未滿足牛欄等級，不符合購買資格',
      19: '您未滿足TBVT領取，不符合購買資格',
      20: '兑换券',
      21: '可兑换数量',
      22: '单价',
      23: 'TBVT余额',
      24: '需支付金额',
      25: '今日剩餘額度',
      26: '兑换需要满足以下条件: ',
      27: '1.农场等级2级及以上',
      28: '2.累计TBVT收益达到：3000及以上',
      29: '单个地址每天可兑换消耗上限：10 W TBVT',
      30: '所有道具兑换后都以道具兑换券（NFT）展示，正式上线后用户可凭道具兑换券兑换正式道具资产',
      31: '了解如何兌換',
  
      32: '喂食后给牛牛增加体力值',
      33: '喂食后给牛牛增加体力值',
      34: '喂食后给牛牛增加体力值',
      35: '使用后给牛牛增加成长值',
      36: '使用后给牛牛增加成长值',
      37: '使用后给牛牛增加成长值',
      38: '使用后给牛牛增加体力值',
      39: '使用后给牛牛增加体力值',
      40: '使用后给牛牛增加体力值',
      41: '使用后给牛牛增加生命周期2天',
      42: '使用后给牛牛增加生命周期5天',
      43: '使用后给牛牛增加生命周期10天',
      44: '购买后可以随机获得一个新NFT皮肤',
      45: '可用于修改游戏名称',
  
      46: '普通草料兑换券',
      47: '中级草料兑换券',
      48: '高级草料兑换券',
      49: '普通体力包兑换券',
      50: '中级体力包兑换券',
      51: '高级体力包兑换券',
      52: '普通成长加速包兑换券',
      53: '中级成长加速包兑换券',
      54: '高级成长加速包兑换券',
      55: '皮肤盲盒兑换券',
      56: '改名卡兑换券',
  
      57: '售罄',
      58: '光輪寶箱兌換券',
      59: '正式資產兌換券',
      60: 'IGO盲盒(馬蹄鏈)',
      61: '道具禮包兌換券',
      62: '一鍵兌換',
      63: '成年牛兌換券',
      64: '已擁有',
      65: '共兌換 {0} 兌換券, 需花費 {1} BVT',
      66: '請選擇需要兌換的券',
      67: '其他兌換券',
    },
  
    // 资产道具购买
    gameStore: {
      1: '小牛の子',
      2: '生命薬剤',
      3: '体力パック',
      4: '成長促進パック',
      5: 'スキンボックス',
      6: '普通の牛',
      7: '普通の生命薬剤',
      8: '中級生命薬剤',
      9: '高級生命薬剤',
      10: '取引履歴',
      11: 'シリアル番号',
      12: '元の価格',
      13: '価格',
      14: '購入',
      15: '購入数量',
      16: '費用',
      17: '残高',
      18: '時間',
      19: '普通の体力パック',
      20: '中級体力パック',
      21: '高級体力パック',
      22: '普通の成長促進パック',
      23: '中級成長促進パック',
      24: '高級成長促進パック',
      25: 'スキンボックス',
      26: '',
      27: '',
      28: '',
      29: '',
      30: '',
      31: '',
      32: '',
      33: '',
      34: '',
      35: '',
      36: '',
      37: '',
      38: '',
      39: '',
      40: '',
      41: '',
    },
  
    // 公会拉新活动
    guild: {
      1: '公會',
      2: '精英',
      3: '步驟 1',
      4: '申請獲得資格',
      5: '建立一個至少有 30 人的社區（微信/TG/任何其他社交媒體）',
      6: '剩余位置：{0}',
      7: '領取公测資產',
      8: '申請',
      9: '步驟 2',
      10: '建立您的社區',
      11: '1. 在公測資產頁面領取牛牛盲盒。',
      12: '2.將小牛餵成成年，給予一定量的體力值。',
      13: '3.你的戰鬥或牛奶截圖（分享到微信朋友圈（向所有人可見）或推特）',
      14: '-Moments：包含 YOHO 的標籤',
      15: "-推特：{'@'}YOHO",
      16: '4. 公會會長應收集所有有效地址和證明並在此處提交或提交給YOHO官方管理員。',
      17: '步骤 3',
      18: '赢取最高奖励',
      19: '第一階段：公會獎勵100 USDT',
      20: '條件：社區新成員達到30個以上，有效地址達到10個以上',
      21: '第二階段：公會獎勵500 USDT',
      22: '條件：社區新成員達到100個以上，有效地址達到60個以上',
      23: '第三階段：公會獎勵1000 USDT & 星球功勳徽章NFT',
      24: '條件：社區新成員達到200人以上，有效地址達到150人以上',
      25: '第四階段：公會獎勵2000 USDT & 星球總裁徽章 NFT',
      26: '條件：社區新成員達到500個以上，有效地址達到500個以上',
      27: '領取',
      28: '鑄造',
      // ELITES 精英
      29: '成為有效地址',
      30: '1.從公測資產頁面領取牛牛盲盒。',
      31: '2.將小牛餵養成年，給予一定量的體力值。',
      32: '3.將你的戰鬥或產奶截圖（分享到微信朋友圈（向所有人可見）或推特）',
      33: '-Moments：包含 #YOHO 的標籤',
      34: "-推特：{'@'}YOHO",
      35: '4.將您的截圖連同您的地址一起提交給您的公會會長',
      36: '領取您的徽章',
      37: '提交社群證明',
      38: '社區名必須包含 YOHO。',
      39: '每位符合條件的總統都可以申請一顆 公測星球。他們的成員可以選擇加入星球或其他星球。星球的名稱必須與社區名相同。',
      40: '有效地址需提交給 YOHO 官方管理員審核。',
      41: '已領取',
    },
    // 徽章空投
    oatBadge: {
      1: '購買$BVT',
      2: '符合資格的OATs',
      3: '$BVT獎勵',
      4: '剩餘名額',
      5: '等級',
      6: '數量',
      7: '活動規則',
      8: '1. 獎勵將於8月23日北京時間19點開放領取。',
      9: '2. 每一級別的$BVT獎勵將限量供應，先到先得。',
      10: '4. BVT合約地址：',
      11: '5.  本次空投活動最終解釋權為YOHO官方所有。',
      12: '購買$BVT',
      13: '加入社區',
      14: '奖励级别',
      15: '$BVT數量/获奖者',
      16: '奖励份数',
      17: '3. 我们对我们发行的 OAT 类型进行了分类，并计算了每个持有人的总重量。 快照拍摄于 8 月 22 日',
    },
    // LAZIO 质押
    lazio: {
      1: '購買BVT',
      2: '詳情',
      3: '距離質押活動結束',
      4: '當前餘額',
      5: '質押數量',
      6: '推薦人地址',
      7: '粘貼',
      8: '質押時間',
      9: '活期',
      10: '3天',
      11: '7天',
      12: '預估年化收益率',
      13: '我的質押總量',
      14: '池子質押總量',
      15: '總獎勵',
      16: '合約地址',
      17: '更多詳情',
      18: '描述',
      19: '1. 活動時間：北京時間 9 月 16 日晚上8:00 至北京時間 10 月 1 日晚上8:00',
      20: '2. 由於本次活動是为感谢我們所有 BVT 持有者而准备的額外奖励，因此本次質押沒有最低 $BVT 质押量。',
      21: '3. 不同的質押周期賦予不同的算力。 質押時間越長，它所拥有的算力就越高。',
      22: '4. $LAZIO獎勵可隨時領取。',
      23: '5. 我們为您準備了獎勵的30%作為推薦獎勵。 非常歡迎您推薦您的朋友參加此活動。 對於每個有效邀請，您將能夠分享您朋友賺取 的獎勵的 20%。 如果您邀請的朋友邀請了另一位朋友，您將能夠分享他或她的奖励的 10%。',
      24: '6. 活動最終解釋權歸YOHO官方所有。',
      25: '推薦獎勵',
      26: '可領取獎勵',
      27: '已領取獎勵',
      28: '領取獎勵',
      29: '領取時間為 ',
      30: '天',
      31: '時',
      32: '分',
      33: '秒',
    },
    // 跨链桥 (BVChain)
    bridge: {
      1: '跨鏈橋',
      2: '從',
      3: '兌換成',
      4: '交易',
      5: '代幣',
      6: 'NFT',
      7: '提醒',
      8: '跨鏈費用為 1% BVT 和 BVG 的最低跨鏈金額分別為 40 BVT 和 50,000 BVG。',
      9: '預計跨鏈到達時間為 10-30 分鐘。',
      10: '關於 NFT 兌換券，我們只提供從 BNB Chain（原 BSC）跨鏈到 Polygon ',
      11: '如果你的 NFT 是 BNB Chain 中的一個盲盒，你需要先打開它，然後才能跨鏈到 Polygon。',
      12: '跨鏈歷史記錄',
      13: '鏈',
      14: '類型',
      15: '數量',
      16: '交易哈希',
      17: '狀態',
      18: '已完成',
      19: '失敗',
      20: '正在進行中',
      21: '資產原鏈交易',
      22: '資產新鏈交易',
      23: '餘額：',
      24: '我們僅支持將新版BVG 從马蹄鏈跨到 BNB 鏈 (0x29AB77a43Ec69D0DFFf3Ff72bff457e09b258351)，比例為 4000:1。',
      25: '',
      26: '更新版',
  
      27: `<div>YOHO Chain是一个基于ZK-Rollups的</div><div>具有统一流动性的多链L2网络</div>`,
      28: 'YOHOChain简介',
      29: `<div>YOHO Chain是一个基于ZK-Rollups的具有统一流动性的多链L2网络。YOHO Chain生态系统连接了各种L1链和L2网络，使开发者和交易者能够利用来自隔离链的统一流动性和安全无缝的用户体验，为每个人提供更易于访问和高效的DeFi生态系统。</div>
      <div>YOHO Chain创新地将零知识技术应用于多链互操作，采用去信任去中心化的分权设计，保障资产和交易安全以及多链数据传输。此外，YOHO Chain消除了不同区块链、不同类型的稳定币之间的差异，通过独特的协议控制债务（PCD）机制实现多链流动性聚合，形成海量稳定币流动性池，提供资金高效、成本友好的服务，以及易于访问的基础设施。</div>
      `,
      30: '零安全妥协的零知识证明',
      31: '具有制衡机制的严格共识机制',
      32: '来自多个链的原生资产聚合',
      33: 'YOHO Chain的特点',
    },
    // 年终
    yearend: {
      1: '購買BVT',
      2: '詳情',
      3: '距離質押活動結束',
      4: '當前餘額',
      5: '質押數量',
      6: '推薦人地址',
      7: '粘貼',
      8: '質押時間',
      9: '活期',
      10: '3月',
      11: '6月',
      12: '預估年化收益率',
      13: '我的質押量',
      14: '池子質押總量',
      15: '總獎勵',
      16: '合約地址',
      17: '更多詳情',
      18: '描述',
      19: '1. 活動時間：北京時間 12 月 28 日下午4:00 至北京時間 1 月 27 日下午4:00',
      20: '2. 由於本次活動是为感谢我們所有 BVT 和 BVG 持有者而准备的額外奖励，因此本次質押沒有最低 $BVT 和 $BVG 质押量。',
      21: '3. 不同的質押周期賦予不同的算力。 質押時間越長，它所拥有的算力就越高。',
      22: '4. 獎勵可隨時領取。',
      23: '5. 我們为您準備了獎勵的20%作為推薦獎勵。 非常歡迎您推薦您的朋友參加此活動。 對於每個有效邀請，您將能夠分享您朋友賺取 的獎勵的 20%。',
      24: '6. 活動最終解釋權歸YOHO官方所有。',
      25: '推薦獎勵',
      26: '可領取獎勵',
      27: '已領取獎勵',
      28: '領取獎勵',
      29: '領取時間為 ',
      30: '天',
      31: '時',
      32: '分',
      33: '秒',
      34: '12月',
      35: '隨存隨取',
      36: '質押到期時間',
      37: '代幣',
    },
  
    dialog: {
      1: '確認',
      2: '閉じる',
      3: '戻る',
      4: '編集',
      5: '保存',
      6: 'キャンセル',
      7: 'もっと見る',
      8: '承認',
      9: '質押',
      10: '質押解除',
      11: '受け取り',
      12: '準備完了!',
      13: 'おめでとう！',
      14: 'あなたは以下のアイテムを獲得しました',
      15: '売り切れ',
      16: '金額は最小であるべきです',
    },
    common: {
      1: '承認',
      2: '質押',
      3: '質押解除',
      4: '一括受け取り',
      5: '読み込み中',
    },
    msg: {
      1: 'ウォレットの接続に成功しました',
      2: 'ウォレットの接続に失敗しました',
      3: '認可が成功しました',
      4: '認可に失敗しました',
      5: 'ステーキングが成功しました',
      6: 'ステーキングに失敗しました',
      7: '受け取りが成功しました',
      8: '受け取りに失敗しました',
      9: '償還が成功しました',
      10: '償還に失敗しました',
      11: '変更が成功しました',
      12: '変更に失敗しました',
      13: '残高が不足しています',
      14: 'トランザクションが提出され、ブロック確認中です。しばらくお待ちください。',
      15: 'コピーが成功しました',
      16: '購入が成功しました',
      17: '転送が成功しました',
      18: '転送に失敗しました',
      19: 'まだNFTを所有していません',
      20: '販売が成功しました',
      21: '販売に失敗しました',
      22: 'まずウォレットを接続してください',
      23: 'このNFTは現在出品中です。下取りするかどうか確認してください。',
      24: '売却がキャンセルされました',
      25: '出品をキャンセル',
      26: '出品済み',
      27: '出品に失敗しました',
      28: '売却済み',
      29: 'ネットワークが正しくありません',
      30: '出品金額は0にできません',
      31: 'ネットワークが正しくありません',
      32: 'まだ利用できません',
      33: '無効なアドレス',
      34: '開封に失敗しました',
      35: '最大10個まで',
      36: '紹介者がバインドされました',
      37: 'まだNFTを所有していません',
      38: '6月28日、北京時間の20:00にオープン',
      39: '購入に失敗しました',
      40: 'アドレスを入力してください',
      41: '自身にバインドできません',
      42: 'このアドレスは無効です',
      43: 'このアドレスは推薦資格がありません',
      44: 'まだ受け取れる報酬がありません',
      45: '手動でリフレッシュしてください',
      46: '',
      47: '',
      48: '報酬の受け取りが成功しました',
      49: 'まだすべてのミッションが完了していません',
      50: '新規ユーザーのみ対象',
      51: '既に報酬を受け取りました',
      52: '受け取りが成功しました',
      53: '推薦人のバインドが成功しました',
      54: 'メインネットへの切り替えが成功しました',
      55: 'ネットワークを確認してください',
      56: 'まだ参加資格がありません',
      57: 'まだ受け取れる報酬がありません',
      58: '報酬は既に受け取り済みです。重複して受け取る必要はありません。',
      59: '{0} トークンが正常に追加されました!',
      60: 'まだ開始されていません',
      61: '受け取りが終了しました',
      62: '有効な金額を入力してください',
      63: 'このステーキング期間はすでに終了しています',
      64: '正式にオンライン化され、まもなく開始します',
      65: '金額は 40 OXG 未満にすることはできません',
      66: '金額は 50,000 OXX 未満にすることはできません',
      67: 'カードを選択してください',
      68: 'クロスチェーンが成功しました',
      69: 'クロスチェーンに失敗しました',
      70: 'クロスチェーンが進行中です',
      71: '出租が成功しました',
      72: 'リースの延長が成功しました',
      73: 'ウォレットアドレスを入力してください',
      74:'販売はまだ開始されていません。お待ちください。',
      75:'ユーザーがトランザクションをキャンセルしました'
    },
    exchangeRecord:{
      1:"交換記録",
      2:"ハッシュ",
      3:"消耗創世記NFT数量",
      4:"牛の受け取り数量",
      5:"時間",
    },
    swap: {
      "1": "創世記NFTの交換",
      "2": "残りの交換牛の数量",
      "3": "現在の所有創世記NFTの数量",
      "4": "交換牛の数量を入力してください",
      "5": "確認",
      "6": "交換記録",
      "7": "創世記NFTの交換イベントは終了しました",
      "8": "尊敬なるYOHO創世記NFTコミュニティおよび闘牛ゲームコミュニティのメンバー様: ",
      9: "ようこそ、私たちの革新的でエキサイティングなイベント、YOHO創世記NFTと闘牛ゲームのコラボレーション、創世牛NFTの交換に参加していただきありがとうございます！このユニークなイベントでは、YOHO NFTとの交換を通じて、独特な創世牛NFTを手に入れるチャンスがあります。一緒にこの待ち望ましいインタラクティブな体験を探索しましょう！",
      "10": "活動ルール：",
      "11": "1.総数1000頭の創世牛： このイベントでは、限定発行の創世牛を1000頭用意しました。それぞれがユニークな価値を持っています。YOHO NFT 5つで創世牛NFT 1つと交換： 驚きです！<br />2.5つのYOHO NFTを提供するだけで、創世牛NFTを1つ交換することができます。これはあなたにとって唯一のチャンスですので、お見逃しなく！<br />3.この機会は一度きり： このイベントはユニークで、この機会は一度きりです。先着順に従って創世牛NFTが売り切れるまでの間、厳正に対応いたします。",
      "12": "交換手順：",
      "13": "1.YOHO NFTを収集： イベント期間中にお好きなYOHO NFTを集め、交換に参加するために十分なNFTの数を確保してください。<br />2.交換申請を提出： 指定された交換ページで、ご希望の創世牛NFTの数量と共に、YOHO NFTの情報を記入し、申請を提出してください。<br />3.承認待ち： 弊社のチームが申請をできるだけ早く確認し、すべてがイベントルールに準拠していることを確保します。<br />4.創世牛NFTの受け取り： 申請が承認されると、特別な創世牛NFTを受け取ることができ、イベントの幸運な参加者の一人となります！",
      "14": "注意事項：",
      "15": "1.YOHO NFTの情報が正確かつ正確であることを確認してください。交換プロセス中に不便が生じないようにしてください。<br />2.交換が成功した場合、創世牛NFTは自動的にNFTウォレットに移動されます。<br />3.イベント期間中に質問がある場合は、いつでもカスタマーサービスチームにお問い合わせください。",
      "16": "これは一度きりのユニークな機会です。YOHO創世記NFTと闘牛ゲームの融合を共に目撃し、あなただけの複製できないデジタルアセットを創造しましょう。ご参加いただきありがとうございます。幸運を祈ります！",
      "17": "ハッシュ",
      "18": "消耗創世記NFT数量",
      "19": "牛の受け取り数量",
      "20": "時間",
      "21": "入力数量は空白または0にすることはできません",
      "22": "牛牛を交換するには、必要なGenesis NFTの数量が不足しています",
      "23": "入力数量は残りの交換可能数量を超えてはいけません",
      "24": "取引が成功しました",
      "25": "取引に失敗しました"
    }
  };
  