<!-- 顶部导航栏 -->
<script setup>
import { useAppStore } from '@store/appStore';
import PcComp from './PcComp.vue';
import MobComp from './MobComp.vue';
import { setLangList } from './useTopBar';

const appStore = useAppStore();
setLangList(appStore.curLang);
</script>

<template>
  <MobComp v-if="appStore.curDevice !== 'pc'" />
  <PcComp v-else />

</template>

<style lang="scss" scoped>
.pc-wrap,
.mob-wrap {
  /* position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%; */
  position: relative;
  z-index: 999;
}
</style>
