import { useAppStore } from '@store/appStore';
import { FRAGMENT_CONT, HALO_BLIND_BOX_CONT, HALO_BOX_MINT_CONT } from '@/contracts/polygonAddress';
import { toRaw, reactive } from 'vue';
import { ElMessage } from 'element-plus';
import { ethers } from 'ethers';
import dayjs from 'dayjs';
import { useRoute } from 'vue-router';
import { getChainData } from '@/utils/tools';
import { Contract, Provider } from 'ethers-multicall';
import { bpMul, bpDiv, toThousands } from '@/utils/bpMath';
import i18n from '@/utils/i18n.js';
const $t = i18n.global.t;

export default class {
  constructor() {
    this.ethcallProvider = null;
    this.contract = null;
    this.boxContract = null;
    this.appStore = {};
    this.tokenList = {
      haloIds: [],
      markIds: [],
      normalIds: [],
      creationIds: [],
      homelandIds: [],
      genlandIds: [],
      shardIds: []
    };
    this.supply = 0;
    this.haloBoxList = [];
    this.markBoxList = [];
    this.rewardList = [];
    this.itemType = [
      {
        id: 1,
        name: 'card.31',
        cardType: 'halo_box', // 光轮宝箱
        image: 'https://game.legendranch.app/legendaryranch/ticket/haloBox.png',
        desc: 'card.32',
        check: false,
        amount: 1
      },
      {
        id: 2,
        name: 'card.20',
        cardType: 'creation_ticket', //创世牛牛
        image: 'https://game.legendranch.app/legendaryranch/ticket/creation.png',
        desc: 'card.27',
        check: false,
        amount: 1
      },
      {
        id: 3,
        name: 'card.19',
        cardType: 'normal_ticket', // 普通牛牛
        image: 'https://game.legendranch.app/legendaryranch/ticket/normal.png',
        desc: 'card.26',
        check: false,
        amount: 1
      },
      {
        id: 4,
        name: 'card.18',
        cardType: 'mark_box', // 盲盒
        image: 'https://game.legendranch.app/legendaryranch/ticket/markBox.png',
        desc: 'card.24',
        check: false,
        amount: 1
      },
      {
        id: 5,
        name: 'card.22',
        cardType: 'homeland_ticket', // 家园星球
        image: 'https://game.legendranch.app/legendaryranch/ticket/homeland.png',
        desc: 'card.29',
        check: false,
        amount: 1
      },
      {
        id: 6,
        name: 'card.21',
        cardType: 'genland_ticket', // 拓荒星球
        image: 'https://game.legendranch.app/legendaryranch/ticket/genland.png',
        desc: 'card.28',
        check: false,
        amount: 1
      },

      {
        id: 7,
        name: 'card.23',
        cardType: 'shard', // 碎片
        image: 'https://game.legendranch.app/legendaryranch/ticket/shard.png',
        desc: 'card.30',
        check: false,
        amount: 1
      },
      {
        id: 0,
        name: 'BVG',
        cardType: 'bvg',
        image: 'https://game.legendranch.app/legendaryranch/ticket/bvg.png',
        desc: 'BVG',
        amount: 1
      }
    ];
    this.poolTimestamp = { currentDay: 0, lastDay: 0 };
    this.poolReward = {
      mostOpen: {
        addr: '0x0000000000000000000000000000000000000000',
        amount: 0,
        reward: 0,
        isClaimed: false,
        unclaim: 0
      },
      mostCost: {
        addr: '0x0000000000000000000000000000000000000000',
        amount: 0,
        reward: 0,
        isClaimed: false,
        unclaim: 0
      },
      lastExtract: {
        addr: '0x0000000000000000000000000000000000000000',
        amount: 0,
        reward: 0,
        isClaimed: false,
        unclaim: 0
      }
    };
    this.martBoxInfo = {
      isWhite: false,
      invitor: '0x00000',
      boxPrice: 20,
      boxNum: 20000,
      heroRound: 0,
      heroLeft: 20,
      homelandLeft: 2,
      genlandLeft: 8
    };
    this.createContract();
  }

  createContract() {
    this.appStore = useAppStore();
    this.contract = new Contract(HALO_BOX_MINT_CONT.address, HALO_BOX_MINT_CONT.abi);
    this.shardContract = new Contract(FRAGMENT_CONT.address, FRAGMENT_CONT.abi);
    this.boxContract = new Contract(HALO_BLIND_BOX_CONT.address, HALO_BLIND_BOX_CONT.abi);
  }

  async newProvider() {
    this.ethcallProvider = new Provider(toRaw(this.appStore.ethObj.provider));
    await this.ethcallProvider.init();
  }

  async constProvider() {
    const $route = useRoute();
    const chainData = getChainData($route.meta.needChains[0]);
    const rpcProvider = new ethers.providers.JsonRpcProvider(chainData.rpcUrls[0]);
    this.ethcallProvider = new Provider(rpcProvider);
    await this.ethcallProvider.init();
  }

  /**
   * 获取宝箱价格信息
   */
  async checkBoxInfo() {
    await this.newProvider();
    const p1 = this.contract.boxPrice();
    const p2 = this.contract.totalBox();
    const p3 = this.contract.checkInfo(this.appStore.defaultAccount);
    const p4 = this.contract.whiteList(this.appStore.defaultAccount);
    const p5 = this.contract.normalInfo(this.appStore.defaultAccount);
    const [price, supply, info, isWhiteList, normal] = await this.ethcallProvider.all([
      p1,
      p2,
      p3,
      p4,
      p5
    ]);
    const res = {
      isWhite: isWhiteList,
      invitor: normal.invitor,
      boxPrice: bpDiv(price, 10 ** 18),
      boxNum: +supply,
      heroRound: +info[0],
      heroLeft: +info[1],
      homelandLeft: +info[2],
      genlandLeft: +info[3]
    };
    this.martBoxInfo = res;
    return this.martBoxInfo;
  }

  /**
   * 获取奖池时间错
   * @returns
   */
  async getPoolTimestamp() {
    await this.constProvider();
    const p1 = this.contract.currentDay();
    const p2 = this.contract.lastDay();
    const p3 = this.contract.checkTimeStamp();
    const p4 = this.contract.totalBox();
    const [currentDay, lastDay, endTime, supply] = await this.ethcallProvider.all([p1, p2, p3, p4]);
    this.poolTimestamp = { currentDay: +currentDay, lastDay: +lastDay, endTime: +endTime };
    this.supply = +supply;
    return this.poolTimestamp;
  }

  /**
   * 获取当前奖池信息
   * @param {*} timestamp 时间戳
   * @returns
   */
  async getRewardPool(timestamp) {
    // 三个奖项内容
    const p1 = this.contract.openInfo(timestamp);
    const p2 = this.contract.rewardPool(timestamp);
    const [info, reward] = await this.ethcallProvider.all([p1, p2]);
    const poolList = {
      mostOpen: {
        addr: info.mostOpen,
        amount: +info.openAmount,
        reward: bpDiv(bpMul(reward, 0.2), 10 ** 18)
      },
      mostCost: {
        addr: info.mostCost,
        amount: +info.costAmount,
        reward: bpDiv(bpMul(reward, 0.3), 10 ** 18)
      },
      lastExtract: {
        addr: info.lastExtract,
        amount: bpDiv(reward, 10 ** 18),
        reward: bpDiv(bpMul(reward, 0.5), 10 ** 18)
      }
    };

    this.poolReward = poolList;
    return this.poolReward;
  }

  /**
   * 获取上一轮的奖励
   * @param {*} timestamp 上一轮的时间
   * @returns
   */
  async getUserReward(timestamp) {
    // 待领取奖励及是否已领取
    const r1 = this.contract.isClaimed(timestamp, this.appStore.defaultAccount);
    const r2 = this.contract.countingReward(this.appStore.defaultAccount);
    const r3 = this.contract.userClaimed(this.appStore.defaultAccount);
    const res = await this.ethcallProvider.all([r1, r2, r3]);
    const info = {
      isClaimed: res[0],
      unclaim: bpDiv(res[1], 10 ** 18),
      claimed: bpDiv(res[2], 10 ** 18)
    };
    console.log('info..', info);
    return info;
  }

  /**
   * 获取fomo奖池信息
   * @param {*} timestamp
   * @returns
   */
  async getFomoReward(timestamp) {
    if (!timestamp) return [];
    const rlist = [];
    const tlist = [];
    console.log('lastDay..', timestamp);
    // 获取奖池金额
    for (let inx = 0; inx < 30; inx++) {
      const p = this.contract.rewardPool(timestamp - inx * 86400);
      rlist.push(p);
      tlist.push(timestamp - inx * 86400);
    }
    const res = await this.ethcallProvider.all(rlist);
    // 获取开奖地址
    const list = [];
    for (let i = 0; i < tlist.length; i++) {
      const r = this.contract.openInfo(tlist[i]);
      list.push(r);
    }
    const resp = await this.ethcallProvider.all(list);
    console.log('fomo..', rlist, res, resp);
    // 组建fomolist
    let fomoList = [];
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);
    for (let i = 0; i < resp?.length; i++) {
      const info = {
        round: dayjs(tlist[i] * 1000)
          .utc()
          .format('MMM DD'),
        reward: bpDiv(res[i], 10 ** 18),
        mostOpen: resp[i].mostOpen,
        mostCost: resp[i].mostCost,
        lastExtract: resp[i].lastExtract
      };
      fomoList.push(info);
    }
    // 过滤奖池为零的值
    console.log(
      'filter..',
      fomoList.filter(it => {
        return it.reward != '0';
      })
    );
    return fomoList
      .filter(it => {
        return it.reward != '0';
      })
      .slice(0, 5);
  }

  /**
   * 获取内部合约的盲盒列表
   * @returns
   */
  async getBoxList() {
    let haloList, MarkList;
    try {
      await this.newProvider();
      const p1 = this.boxContract.checkUserCardList(this.appStore.defaultAccount, 1);
      const p2 = this.boxContract.checkUserCardList(this.appStore.defaultAccount, 4);
      const res = await this.ethcallProvider.all([p1, p2]);
      haloList = res[0];
      MarkList = res[1];
    } catch (error) {
      const contract = new ethers.Contract(
        HALO_BLIND_BOX_CONT.address,
        HALO_BLIND_BOX_CONT.abi,
        toRaw(this.appStore.ethObj.signer)
      );
      const p1 = contract.checkUserCardList(this.appStore.defaultAccount, 1);
      const p2 = contract.checkUserCardList(this.appStore.defaultAccount, 4);
      const res = await Promise.all([p1, p2]);
      haloList = res[0];
      MarkList = res[1];
    }

    this.tokenList.haloIds = haloList.map(it => +it);
    this.tokenList.markIds = MarkList.map(it => +it);

    this.haloBoxList = this.formatArray(this.tokenList.haloIds, 'halo_box');
    this.markBoxList = this.formatArray(this.tokenList.markIds, 'mark_box');

    return [...this.haloBoxList, ...this.markBoxList];
  }

  /**
   * 获取Ticket列表
   * @returns
   */
  async getTicketList() {
    let normalList, creationList, homelandList, genlandList, shardList;
    try {
      await this.newProvider();
      const p1 = this.boxContract.checkUserCardList(this.appStore.defaultAccount, 3);
      const p2 = this.boxContract.checkUserCardList(this.appStore.defaultAccount, 2);
      const p3 = this.boxContract.checkUserCardList(this.appStore.defaultAccount, 5);
      const p4 = this.boxContract.checkUserCardList(this.appStore.defaultAccount, 6);
      const p5 = this.shardContract.balanceOf(this.appStore.defaultAccount, 1);
      const res = await this.ethcallProvider.all([p1, p2, p3, p4, p5]);
      normalList = res[0];
      creationList = res[1];
      homelandList = res[2];
      genlandList = res[3];
      shardList = res[4];
    } catch (error) {
      const contract = new ethers.Contract(
        HALO_BLIND_BOX_CONT.address,
        HALO_BLIND_BOX_CONT.abi,
        toRaw(this.appStore.ethObj.signer)
      );
      const shardContract = new ethers.Contract(
        FRAGMENT_CONT.address,
        FRAGMENT_CONT.abi,
        toRaw(this.appStore.ethObj.signer)
      );
      const p1 = contract.checkUserCardList(this.appStore.defaultAccount, 3);
      const p2 = contract.checkUserCardList(this.appStore.defaultAccount, 2);
      const p3 = contract.checkUserCardList(this.appStore.defaultAccount, 5);
      const p4 = contract.checkUserCardList(this.appStore.defaultAccount, 6);
      const p5 = shardContract.balanceOf(this.appStore.defaultAccount, 1);
      const res = await Promise.all([p1, p2, p3, p4, p5]);
      normalList = res[0];
      creationList = res[1];
      homelandList = res[2];
      genlandList = res[3];
      shardList = res[4];
    }

    console.log('getTicketList..', normalList, creationList, homelandList, genlandList, +shardList);
    this.tokenList.normalIds = normalList.map(it => +it);
    this.tokenList.creationIds = creationList.map(it => +it);
    this.tokenList.homelandIds = homelandList.map(it => +it);
    this.tokenList.genlandIds = genlandList.map(it => +it);
    this.tokenList.shardIds = +shardList;

    const normalTicket = this.formatArray(this.tokenList.normalIds, 'normal_ticket');
    const creationTicket = this.formatArray(this.tokenList.creationIds, 'creation_ticket');
    const homelandTicket = this.formatArray(this.tokenList.homelandIds, 'homeland_ticket');
    const genlandTicket = this.formatArray(this.tokenList.genlandIds, 'genland_ticket');
    let shardTicket = [];
    if (this.tokenList.shardIds) {
      const findItem = this.itemType.find(item => item.cardType === 'shard');
      shardTicket = [{ ...findItem }];
      shardTicket[0].id = 1;
      shardTicket[0].amount = this.tokenList.shardIds;
    }

    return [
      ...normalTicket,
      ...creationTicket,
      ...homelandTicket,
      ...genlandTicket,
      ...shardTicket
    ];
  }

  /**
   * 组装盲盒列表
   * @param {Array} list
   * @param {String} type
   * @returns
   */
  formatArray(list, type) {
    const tempList = [];
    for (let index = 0; index < list.length; index++) {
      const findItem = this.itemType.find(item => item.cardType === type);
      const cloneCard = { ...findItem };
      tempList.push(cloneCard);
      tempList[index].id = Number(list[index]);
    }
    return tempList;
  }

  /**
   * 开启光轮宝箱
   * 根据 eventTopic 获取开启盲盒的信息并构建数组
   */
  async openBox(ids) {
    const contract = new ethers.Contract(
      HALO_BOX_MINT_CONT.address,
      HALO_BOX_MINT_CONT.abi,
      toRaw(this.appStore.ethObj.signer)
    );
    const baseGasPrice = this.appStore.ethObj.baseGasPrice;
    // const limit = await contract.estimateGas.openBox(ids);
    const res = await contract.openBox(ids, {
      gasPrice: 20 * 10 ** 9 + baseGasPrice,
      gasLimit: 2000000
    });
    const reward = await res.wait();
    console.log('res..', reward);
    const rewardEvents = reward.events;
    const eventTopics = '0x02a6a2be713fedf52f113c0a759f1c1a23a113476d9b1b1a2a453c910660de4e';
    const list = [];
    for (let inx = 0; inx < rewardEvents.length; inx++) {
      if (rewardEvents[inx].topics[0].toUpperCase() === eventTopics.toUpperCase()) {
        if (+rewardEvents[inx].topics[2] == 1) {
          list.push({ id: 7, amount: +rewardEvents[inx].topics[3] });
        } else {
          list.push({ id: +rewardEvents[inx].topics[2], amount: +rewardEvents[inx].topics[3] });
        }
      }
    }
    this.rewardList = this.formatReward(list);
    console.log('reward..', this.rewardList);
    return this.rewardList;
  }

  /**
   * 开启Mart盲盒
   * 根据 eventTopic 获取开启盲盒的信息并构建数组
   */
  async openMarkBox(ids) {
    const contract = new ethers.Contract(
      HALO_BOX_MINT_CONT.address,
      HALO_BOX_MINT_CONT.abi,
      toRaw(this.appStore.ethObj.signer)
    );
    const baseGasPrice = this.appStore.ethObj.baseGasPrice;
    // const limit = await contract.estimateGas.openCattleBox(ids);
    const res = await contract.openCattleBox(ids, {
      gasPrice: 20 * 10 ** 9 + baseGasPrice,
      gasLimit: 2000000
    });
    const reward = await res.wait();
    console.log('res..', reward);
    const rewardEvents = reward.events;
    const eventTopics = '0x02a6a2be713fedf52f113c0a759f1c1a23a113476d9b1b1a2a453c910660de4e';
    const list = [];
    for (let inx = 0; inx < rewardEvents.length; inx++) {
      if (rewardEvents[inx].topics[0].toUpperCase() === eventTopics.toUpperCase()) {
        if (+rewardEvents[inx].topics[2] == 1) {
          list.push({ id: 7, amount: +rewardEvents[inx].topics[3] });
        } else {
          list.push({ id: +rewardEvents[inx].topics[2], amount: +rewardEvents[inx].topics[3] });
        }
      }
    }
    this.rewardList = this.formatReward(list);
    console.log('reward..', this.rewardList);
    return this.rewardList;
  }

  /**
   * 二开ExtractBox盲盒
   * 根据 eventTopic 获取开启盲盒的信息并构建数组
   */
  async openExtractBox(type) {
    const contract = new ethers.Contract(
      HALO_BOX_MINT_CONT.address,
      HALO_BOX_MINT_CONT.abi,
      toRaw(this.appStore.ethObj.signer)
    );
    const baseGasPrice = this.appStore.ethObj.baseGasPrice;
    let res;
    if (type == 'cattleBox') {
      // 盲盒
      // const limit = await contract.estimateGas.extractNormal(5);
      // console.log('cattleBox limit..', limit);
      res = await contract.extractNormal(5, {
        gasPrice: 20 * 10 ** 9 + baseGasPrice,
        gasLimit: 2000000
      });
    } else if (type == 'cattle') {
      // 普通牛
      // const limit = await contract.estimateGas.extractNormal(10);
      // console.log('cattle limit..', limit);
      res = await contract.extractNormal(10, {
        gasPrice: 20 * 10 ** 9 + baseGasPrice,
        gasLimit: 2000000
      });
    } else if (type == 'hero') {
      // const limit = await contract.estimateGas.extractCreation();
      // console.log('hero limit..', limit);
      res = await contract.extractCreation({
        gasPrice: 20 * 10 ** 9 + baseGasPrice,
        gasLimit: 2000000
      });
    } else if (type == 'genland') {
      // const limit = await contract.estimateGas.extractPioneerPlanet(20);
      // console.log('genland limit..', limit);
      res = await contract.extractPioneerPlanet(20, {
        gasPrice: 20 * 10 ** 9 + baseGasPrice,
        gasLimit: 2000000
      });
    } else if (type == 'homeland') {
      // const limit = await contract.estimateGas.extractPioneerPlanet(50);
      // console.log('homeland limit..', limit);
      res = await contract.extractPioneerPlanet(50, {
        gasPrice: 20 * 10 ** 9 + baseGasPrice,
        gasLimit: 2000000
      });
    }

    const reward = await res?.wait();

    console.log('res..', type, reward);
    const rewardEvents = reward?.events;
    const eventTopics = '0x02a6a2be713fedf52f113c0a759f1c1a23a113476d9b1b1a2a453c910660de4e';
    const list = [];
    for (let inx = 0; inx < rewardEvents.length; inx++) {
      if (rewardEvents[inx].topics[0].toUpperCase() === eventTopics.toUpperCase()) {
        if (+rewardEvents[inx].topics[2] == 1) {
          list.push({ id: 7, amount: +rewardEvents[inx].topics[3] });
        } else {
          list.push({ id: +rewardEvents[inx].topics[2], amount: +rewardEvents[inx].topics[3] });
        }
      }
    }
    this.rewardList = this.formatReward(list);
    console.log('reward..', this.rewardList);
    return this.rewardList;
  }

  /**
   * 组装奖励列表
   * @param {Array} list 奖励列表
   * @param {Number} tid 奖励类型id
   * @returns
   */
  formatReward(list) {
    const tempList = [];
    for (let index = 0; index < list.length; index++) {
      const findItem = this.itemType.find(item => item.id === list[index].id);
      const cloneCard = { ...findItem };
      tempList.push(cloneCard);
      // tempList[index].id = Number(list[index].id);
      tempList[index].amount = Number(list[index].amount);
    }
    return tempList;
  }
}
