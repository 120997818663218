import { defineStore } from 'pinia';
import { useAppStore } from '@store/appStore';
import { toRaw } from 'vue';
import commonObj from '@/contracts/commonContract.js';
import { STAR_CONT, STAR_INFO_CONT, PROXY_QUERY_CONT } from '@/contracts/polygonAddress.js';

const usePlanetStore = defineStore('planet', {
  state: () => ({
    contract: {}, // 合约对象
    ownerAddr: null,
    // 星球类别
    planetTypes: [
      {
        id: '',
        cardType: 1,
        name: 'card.12',
        image: 'https://game.legendranch.app/legendaryranch/planet/homeland.png',
        desc: 'card.15',
        tax: 20,
        totalTax: 0,
        population: 0,
        populationLimit: '10,000'
      },
      {
        id: '',
        cardType: 2,
        name: 'card.13',
        image: 'https://game.legendranch.app/legendaryranch/planet/unionland.png',
        desc: '',
        tax: 20,
        totalTax: 0,
        population: 0,
        populationLimit: 1000
      },
      {
        id: '',
        cardType: 3,
        name: 'card.14',
        image: 'https://game.legendranch.app/legendaryranch/planet/exland.png',
        desc: '',
        tax: 20,
        totalTax: 0,
        population: 0,
        populationLimit: 1000
      }
    ],
    planetIdArr: [], // 临时存储 planet ID 和 cardId 集合
    planetList: [], // 用户的星球ID列表 最多两个
    // 星球信息
    planetInfo: {
      id: '',
      cardType: 1,
      name: 'card.12',
      image: 'https://game.legendranch.app/legendaryranch/planet/homeland.png',
      desc: 'card.15',
      tax: 20,
      totalTax: 0,
      population: 0,
      populationLimit: 10000
    }
  }),

  actions: {
    /**
     * 构建合约对象
     */
    createContract() {
      const appStore = useAppStore();
      const { ethers, signer, chainId } = appStore.ethObj;

      this.contract = new ethers.Contract(STAR_CONT.address, STAR_CONT.abi, toRaw(signer));
    },

    /**
     * 获取所有者地址
     * @param {Number} id
     * @returns
     */
    async getOwnerAddr(id) {
      try {
        let resp = await this.contract.ownerOf(id);
        this.ownerAddr = resp;
      } catch (error) {
        console.log(error);
      }
      return this.ownerAddr;
    },

    /**
     * 获取id 和 cardid 集合
     */
    // TODO 报错
    async getPlanetList(addr) {
      // 根據卡牌id 獲取tokenid
      const cardIds = [1, 2, 3];
      const tokenArr = [];
      // 查詢各項數值合約裏查用戶星球信息
      const { contract } = new commonObj(PROXY_QUERY_CONT);
      await Promise.all(
        cardIds.map(cardId => {
          return contract.checkUserPlanet(addr, cardId);
        })
      ).then(item => {
        // 第一層
        for (let i = 0; i < item.length; i++) {
          // 如果是數組，遍歷數組
          if (Array.isArray(item[i])) {
            // 第二層 選擇對應項
            for (let j = 0; j < +item[i].length; j++) {
              tokenArr.push({ cardId: cardIds[i], id: Number(item[i][j]) });
            }
          } else {
            // 不是數組則直接push
            tokenArr.push({ cardId: cardIds[i], id: Number(item[i]) });
          }
        }
      });
      this.planetIdArr = tokenArr;
      this.setPlanetList();
    },

    /**
     * 构造Planet List
     */
    setPlanetList() {
      // 填充 cardInfo 数据
      let cardList = [];
      for (let index = 0; index < this.planetIdArr.length; index++) {
        for (let id = 0; id < this.planetTypes.length; id++) {
          if (this.planetIdArr[index].cardId == this.planetTypes[id].cardType) {
            const cloneCard = { ...this.planetTypes[id] };
            cardList.push(cloneCard);
            cardList[index].id = this.planetIdArr[index].id;
          }
        }
      }
      this.planetList = cardList;
      return this.planetList;
    },

    /**
     * 获取用户星球的信息
     * @param {Number} id 卡牌Id
     */
    async getPlanetInfo(id, type) {
      const appStore = useAppStore();
      const { ethers } = appStore.ethObj;
      const { contract } = new commonObj(STAR_INFO_CONT);
      const resp = await contract.planetInfo(id);
      const findedItem = this.planetTypes.find(item => item.cardType == Number(type));
      // console.log('res..', resp, findedItem, +resp.types);
      this.planetInfo = { ...findedItem };
      // this.planetInfo.tax = Number(resp.tax);
      this.planetInfo.population = Number(resp.population);
      this.planetInfo.totalTax = Number(ethers.utils.formatUnits(resp.totalTax || 0)).toFixed(3);
      // this.planetInfo.populationLimit = Number(resp.populationLimit);
      // this.planetInfo.tax = 20;
      return this.planetInfo;
    }
  },

  getters: {}
});

export default usePlanetStore;
