<!-- Pc端导航栏 -->
<script setup>
import { computed, getCurrentInstance, ref } from 'vue';
import { bannerList, setBannerList, langList, setLangList, jump } from './useTopBar';
import router from '@/router';
import { useAppStore } from '@store/appStore';
import { plusXing } from '@/utils/tools';
import { showPop, setShowPop } from '../Wallets/useWallet';
import WalletPop from '../Wallets/index.vue';
import { pickSideNav } from '../../views/CardPackage/useCardPackage';
import useAccountStore from '@/store/accountStore';

const accStore = useAccountStore();

/**
 * 展示用户面板
 */
const appStore = useAppStore();
async function handleClick() {
  pickSideNav('account');
  router.push({ name: 'cardPackage' });
}

// spaceName
const spaceName = computed(() => {
  return !accStore.spaceName ? appStore.defaultAccount : accStore.spaceName;
});

const gThis = getCurrentInstance().appContext.config.globalProperties;
const { ctx: that } = getCurrentInstance();

const handleWalletConnect = async () => {
  setShowPop(true);
};

// 语言栏选各种的语言
const pickedLang = computed(() => langList.find((item) => item.active));

/**
 * 鼠标移入导航栏
 */
function enterBan(ban) {
  const list = bannerList.map((item) => {
    item.active = false;
    if (ban.id === item.id) {
      item.active = true;
    }
    return item;
  });
  setBannerList(list);
}

let isShowWalletPanel = ref(false); // 是否显示钱包面板
/**
 * 鼠标移入钱包栏
 */
function enterWallet() {
  isShowWalletPanel.value = true;
}

/**
 * 鼠标移出钱包栏
 */
function leaveWallet() {
  isShowWalletPanel.value = false;
}

let isShowLangPanel = ref(false); // 是否显示语言栏面板
/**
 * 鼠标移入语言栏
 */
function enterLang() {
  isShowLangPanel.value = true;
}

/**
 * 鼠标移出语言栏
 */
function leaveLang() {
  isShowLangPanel.value = false;
}

/**
 * 鼠标离开导航栏
 */
function leaveBan() {
  const list = bannerList.map((item) => {
    item.active = false;
    return item;
  });
  setBannerList(list);
}

/**
 * 选择语言
 */
function pickLang(lang) {
  gThis.$i18n.locale = lang;

  setLangList(lang);
  appStore.setLang(lang);
}

function goGitbook() {
 // window.open(`https://yohos-organization.gitbook.io/legendranch-cn`, '_blank');
}
function goPlay() {
  window.open(`https://game.legendranch.app`, '_blank');
}
</script>

<template>
  <div :class="['pc-wrap']">
    <!-- 导航 -->
    <section :class="['banner-container']">
      <img src="@img/home/logo-bovine-verse.png" alt="" class="cow-logo"  />
       <img
      src="@img/home/play.png"
      alt=""
       @click="goPlay"
      class="cow-play"
      />
      <li
        class="ban-item"
        v-for="ban in bannerList"
        :key="ban.id"
        @mouseenter="enterBan(ban)"
        @mouseleave="leaveBan"
        @click="jump(ban)"
      >
        {{ $t(ban.name) }}
        <!-- 二级菜单 -->
        <!-- <transition name="bp"> -->
        <div class="sec-panel" v-show="ban.children && ban.active">
          <li
            class="sec-item"
            v-for="secItem in ban.children"
            :key="secItem.id"
            @click.stop="jump(secItem)"
          >
            <img :src="secItem.logo" alt="" class="sec-face" v-if="secItem.logo" />
            <div class="sec-name">
              {{ $t(secItem.name) }}
            </div>
            <i class="iconfont icon-triangle icons"></i>
          </li>
        </div>
        <!-- </transition> -->
      </li>
    </section>
    <!-- 工具 -->
    <section class="utils">
      <!-- 钱包 -->
      <div class="wallet-container" @mouseenter="enterWallet" @mouseleave="leaveWallet">
        <img :src="accStore.avatar" alt="" class="avatar" />
        <div class="content" v-if="appStore.defaultAccount">
          {{ plusXing(spaceName, 6, 4) }}
        </div>
        <div class="content" v-else @click="handleWalletConnect">{{ $t('header.23') }}</div>
        <transition name="el-zoom-in-top">
          <div class="wallet-panel" v-show="isShowWalletPanel && appStore.defaultAccount">
            <li class="link" @click="handleClick">
              {{ $t('header.12') }}
            </li>
            <li class="link" @click="appStore.resetApp(that)">{{ $t('header.24') }}</li>
          </div>
        </transition>
      </div>

      <!-- 选择语言 -->
      <div class="lang-container" @mouseenter="enterLang" @mouseleave="leaveLang">
        <i class="iconfont icon-lang-earth"></i>
        <div class="holder">
          {{ pickedLang && pickedLang.name }}
          <img src="@img/common/icon-sanjiao-white.svg" alt="" class="arrow" />
        </div>
        <transition name="el-zoom-in-top">
          <div class="panel" v-show="isShowLangPanel">
            <li
              class="lang-item"
              v-for="lang in langList"
              :key="lang.id"
              @click="pickLang(lang.target)"
            >
              {{ lang.name }}
            </li>
          </div>
        </transition>
      </div>
      <WalletPop />
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import './index.scss';
.pc-wrap {
  height: $pcTopBarHeight;
  background-color: #010538;
  padding: 0 50px;
  @include flexPos(center);
  cursor: pointer;

  /* .top-bar-main {
    width: 
  } */

  .banner-container {
    // margin-right: .2rem;
    // width: 790px;
    @include flexPos(space-between);
    font-size: 19px;
    color: #fff;

    .ban-item {
      position: relative;
      height: $pcTopBarHeight;
      font-weight: bold !important;
      margin-left: 0.4rem;
      white-space: nowrap;

      @include flexPos(center);

      &:hover {
        transform: scale(1.2);
      }

      .sec-panel {
        // width: 228px;
        padding: 0 20px 30px;
        background-color: rgba($color: #000, $alpha: 0.5);
        backdrop-filter: blur(10px);
        position: absolute;
        top: $pcTopBarHeight;
        left: 0;
        border-radius: 0 0 10px 10px;
      }

      .sec-item {
        margin-top: 30px;
        // @include flexPos(space-between);
        display: flex;
        align-items: center;
        font-size: 20px;

        .sec-face {
          flex: 0 0 59px;
          width: 59px;
          height: 59px;
        }

        .sec-name {
          // flex: auto;
          padding-left: 14px;
        }
        .icons {
          font-size: 0.1rem;
          margin-left: 0.06rem;
        }

        .sec-logo {
          flex: 0 0 8px;
          width: 8px;
          height: 10px;
          margin-left: 8px;
        }
      }
    }

    @media screen and (max-width: 1360px) {
      .ban-item {
        margin-left: 0.35rem !important;
      }
    }
  }

  .utils {
    font-size: 20px;
    color: #fff;
    @include flexPos(center);
    margin-left: 0.5rem;

    .wallet-container {
      width: 225px;
      height: 70px;
      @include flexPos(center);
      position: relative;

      .avatar {
        width: 42px;
        height: 42px;
        z-index: 9;
        @include absPos(50%, '', '', 0);
        border-radius: 50%;
        transform: translate(0, -50%);
        border: solid 2px #fff;
        /* padding: 0.05rem; */
        box-sizing: border-box;
        /* background-color: #333; */
      }
      .content {
        width: 185px;
        height: 40px;
        @include flexPos(center);
        // border: solid 2px #fff;
        background-color: #1a1e4c;
        margin-left: 25px;
        padding-left: 10px;
        border-radius: 25px;
        // padding: .2rem;

        &.addr {
          padding: 0 30px 0 60px;
          width: 165px;
        }
      }
    }

    .lang-container {
      margin-left: vw(40);
      .holder {
        background-color: #1a1e4c;
        padding: 0 0.2rem;
      }
    }
  }
}

.cow-logo {
  width: 150px;
  max-height: 50px;
  border-radius: 10px;
  margin: -3px 0 0 0;
}
</style>
