<script setup>
import { defineProps, defineEmits, reactive, computed } from 'vue';
import { showPop, setShowPop } from './useWallet';
import walletObj from './useWallet';
import { useAppStore } from '@store/appStore';
import { useRoute, useRouter } from 'vue-router';
import WalletConnectProvider from '@walletconnect/web3-provider';
import i18n from '@/utils/i18n.js';
const $t = i18n.global.t;
const $router = useRouter();
const appStore = useAppStore();
const props = defineProps({
  title: {
    type: String,
    default: 'header.23'
  }
});

const walletOptions = reactive({
  okxwallet: {
    id: 'okxwallet',
    logo: require('@img/common/icon-ouyi.jpg'),
    name: 'OKX Wallet',
    description: 'Connect to your OKW Wallet',
    type: 'injected',
    check: 'isOkxWallet',
    isShow: true,
    package: window?.okxwallet
  },
  metamask: {
    id: 'metamask',
    logo: require('@img/common/metamask.svg'),
    name: 'MetaMask',
    description: 'Connect to your MetaMask Wallet',
    type: 'injected',
    check: 'isMetaMask',
    isShow: true,
    package: window?.ethereum
  },
  // 第三方
  walletconnect: {
    id: 'walletconnect',
    logo: require('@img/common/walletconnect.svg'),
    name: 'WalletConnect',
    description: 'Scan with WalletConnect to connect',
    type: 'walletconnect',
    check: 'isWalletConnect',
    isShow: true,
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: 'https://bsc-dataseed.binance.org',
        97: 'https://data-seed-prebsc-1-s2.binance.org:8545',
        137: 'https://polygon-rpc.com'
      },
      network: 'binance'
    }
  },
  // 更多项，纯展示
  moreItems: {
    id: 'more',
    logo: require('@img/common/icon-more.svg'),
    name: 'dialog.7',
    description: 'Connect to your TokenPocket Wallet',
    type: null,
    check: null,
    isShow: true
  },
  // injected providers
  bitkeep: {
    id: 'bitkeep',
    logo: require('@img/common/bitkeep.png'),
    name: 'BitKeep',
    description: 'Connect to your BitKeep Wallet',
    type: 'injected',
    check: 'isBitKeep',
    isShow: false,
    package: window?.bitkeep?.ethereum
  },
  coin98: {
    id: 'coin98',
    logo: require('@img/common/coin98.svg'),
    name: 'COIN98',
    description: 'Connect to your COIN98 Wallet',
    type: 'injected',
    check: 'isCoin98',
    isShow: false,
    package: window?.coin98?.provider
  },
  tokenpocket: {
    id: 'tokenpocket',
    logo: require('@img/common/tokenpocket.png'),
    name: 'TokenPocket',
    description: 'Connect to your TokenPocket Wallet',
    type: 'injected',
    check: 'isTokenPocket',
    isShow: false,
    package: window?.ethereum
  },
});

const themeVars = {
  rateIconFullColor: '#07c160',
  popupCloseIconMargin: '0.26rem',
  popupCloseIconSize: '0.2rem',
  overlayBackgroundColor: 'rgba(3, 3, 3, 0.7)'
};

/**
 * 关闭按钮
 */
function handleClose() {
  // 关闭的时候先把初始化
  const residenList = ['tokenpocket', 'metamask', 'walletconnect', 'moreItems'];
  for (const [key, value] of Object.entries(walletOptions)) {
    if (residenList.includes(key)) {
      walletOptions[key].isShow = true;
    } else {
      walletOptions[key].isShow = false;
    }
  }
  setShowPop(false);
}

async function checkWallet(item) {
  // 当点击更多展开
  if (item.id === 'more') {
    for (const [key, value] of Object.entries(walletOptions)) {
      if (key === 'moreItems') {
        walletOptions[key].isShow = false;
      } else {
        walletOptions[key].isShow = true;
      }
    }
    return;
  }
  appStore.onConnect(item);
}
</script>

<template>
  <van-config-provider :theme-vars="themeVars">
    <van-popup
      v-model:show="showPop"
      overlay-class="popup-overlay"
      closeable
      @click-close-icon="handleClose"
    >
      <div class="pop-wrap">
        <div class="head-wrap">
          {{ $t(props.title) }}
        </div>
        <!-- 过渡动画 -->
        <TransitionGroup name="el-zoom-in-top" tag="div" class="content-wrap">
          <div
            class="item-wrap"
            v-for="item in walletOptions"
            :key="item.id"
            @click="checkWallet(item)"
            v-show="item.isShow"
          >
            <div class="item-content">
              <img :src="item.logo" alt="" class="icon" />
              <span>{{ $t(item.name) }}</span>
            </div>
          </div>
        </TransitionGroup>
      </div>
    </van-popup>
  </van-config-provider>
</template>

<style lang="scss" scoped>
.pop-wrap {
  position: relative;
  @include -width(65vw, 50vh, 3.8rem);
  @include flexPos(center);
  flex-direction: column;
  font-size: 0.24rem;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  backdrop-filter: blur(0.3rem);
  background-color: rgba(3, 3, 3, 0.6);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.head-wrap {
  @include flexPos(flex-start, flex-start);
  width: 100%;
  font-size: 0.24rem;
  font-weight: bold;
  padding: 0.2rem 0 0.2rem 0.2rem;
  border-radius: 0.12rem 0.12rem 0 0;
  // background: linear-gradient(139.73deg, rgb(49, 61, 92) 0%, rgb(61, 42, 84) 100%);
  // background:linear-gradient(to right, #400552, #450b88 100%);
  background-color: #450b88;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.5);
}

.trans {
  transform: rotate(180deg);
}

.content-wrap {
  position: relative;
  width: 100%;
  background-color: rgba(3, 3, 3, 0.3);
  border-radius: 0.12rem;
  padding: 0.1rem;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 5rem;
  min-width: fit-content;
  overflow: auto;
}
.item-wrap {
  width: 100%;
  cursor: pointer;
}
.item-content {
  @include flexPos(center);
  flex-direction: column;
  padding: 0.2rem 0;
  border-radius: 0.12rem;
  &:hover {
    // background-color: #101a20;
    background-color: rgba($color: #1d1d1d, $alpha: 1);
  }
  img {
    // @include -width(0.6rem, 0.6rem, 0.6rem);
    @include -height(0.6rem, 0.6rem, 0.6rem);
  }
  span {
    font-size: 0.2rem;
    color: rgb(244, 238, 255);
    margin-top: 0.18rem;
  }
  div {
    color: rgb(136, 136, 136);
    font-size: 0.18rem;
    margin: 0.3em 0px;
  }
}
</style>
