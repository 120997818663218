export default {
  undefined: '',
  header: {
    1: '活動',
    2: '游戲',
    3: 'NFT',
    4: 'DAO',
    5: '社交',
    6: '更多',
    7: '傳奇牧場',
    8: '星際沙盒',
    9: '星際征途',
    10: '入門',
    11: '白皮書',
    12: '我的錢包',
    13: '首頁',
    14: '我的好友',
    15: '我的星球',
    16: '加密博客',
    17: '排行榜',
    18: '游客',
    19: '墾荒活動',
    20: '牛牛集市活動',
    21: '交易市場',
    22: 'NFT 農場',
    23: '鏈接錢包',
    24: '斷開鏈接',
    25: '公測資產',
    26: '即將開放',
    27: '公測邀請派對',
    28: '公會征集計劃',
    29: '空投',
    30: '兌換券中心',
    31: '徽章空投',
    32: '$LAZIO挖礦',
    33: '跨鏈橋',
    34: '遊戲商店',
    35: 'YOHO Chain',
    36: '$BVT&$BVG挖礦',
    37: '訂單記錄',
    38: '項目簡介',
    39: '創世紀NFT兌換'
  },
  home: {
    1: '購買BVT',
    2: '購買BVG',
    3: '游戲',
    4: '傳奇牧場',
    5: '星際沙盒',
    6: '星際征途',
    7: '<p>中古平行宇宙</p> <p>繁殖、產奶、戰鬥！</p>',
    8: '<p>3D平行世界</p> <p>行星、飛船、創造！</p>',
    9: '<p>超時空平行宇宙</p> <p>VR遊戲、探索、戰鬥！</p>',
    10: '進入遊戯',
    11: '即將上线',
    12: 'SocialFi矩阵',
    13: '社交',
    14: '聯邦 ',
    15: '加密博客',
    16: '玩家可以在遊戲世界中經營自己的虛擬社交網絡',
    17: '遊戯NFT',
    18: '家園星球',
    19: '聯邦星球',
    20: '拓荒星球',
    21: '胚胎盲盒',
    22: '公牛崽',
    23: '母牛崽',
    24: '<p>採用標準的ERC-721協議鑄造，擁有獨一無二的ID標識和頭像(Avatar)元素，可以在各個NFT交易市場自由交易</p><p>數量稀缺，總發行上限為10,000頭，首批供應僅為500頭</p><p>屬性高於其他牛類，擁有無限次繁殖次數和永恆的生命</p><p>作為跨遊戲的主角，其自由度可漫游到YOHO中的任何平行世界，獲得真正意義上的永生</p>',
    //24: '獨一無二的ID和頭像',
    25: '我們的願景',
    26: '我們的願景是利用前沿的區塊鏈技術建立多元宇宙的世界观，在去中心化的经济、游戏和社交系统中，每位玩家都可以自由的开展社交、冒险和創造，在這個神奇的宇宙中獲得隨時隨地、低延遲、高沉浸的游戲體驗感',
    27: '戰略合作夥伴',
    28: '我們的社區',
    29: '服務條款',
    30: '隱私政策',
    31: '',
    // 32: '<p>跨遊戲的DAO社群聯盟以及利益共同體</p><p>社群內經濟系統DAO治理</p><p>以聯邦為單位的資源開發、分配、貿易、掠奪玩法</p>',
    // 33: '<p>基於Web3.0的遊戲流媒體內容創造、分發平台以及去中心化KOL經濟激勵協議</p><p>遊戲內的玩法、場景、道具、裝備的創造、分享</p><p>遊戲視頻、遊戲直播的分發、觀看及打賞</p>',
    32: '跨遊戲的DAO社群聯盟以及利益共同體',
    33: '基於Web3.0的遊戲流媒體內容創造、分發平台以及去中心化KOL經濟激勵協議',
    34: '小牛崽',
    35: '星球',
    36: '公牛',
    37: '母牛',
    38: '成年公牛',
    39: '成年母牛',
    40: '人口上限',
    41: '<p>獨一無二的ID標識及頭像;</p><p>擁有更高的屬性、生命永恆;</p><p>無限次繁衍次數。 </p>',
    // 41: '',
    42: '獨特性',
    43: '流動性',
    44: '稀有性',
    45: '標準的ERC-721協議鑄造，在各個NFT交易市場自由交易；跨遊戲角色，其自由度可漫游到YOHO中的任何平行世界。',
    46: '總發行上限為10,000頭；首批供應僅為514頭',
    47: '陌生人社交及匹配系统：随机匹配玩家，随机副本任务；',
    48: '亲密关系及共同成长系统：丰富的游戏内即时沟通系统。',
    49: '社群内经济系统DAO治理；',
    50: '以联邦为单位的资源开发、分配、贸易、掠夺玩法。',
    51: '游戏内的玩法、场景、道具、装备的创造、分享；',
    52: '游戏视频、游戏直播的分发、观看及打赏。',
    53: '拥有更高属性及永恒生命',
    54: '无限次繁殖次数。',
    55: '關於我們',
    56: '資源',
    57: '櫥窗',
    58: '我們的社區',
    59: '關於YOHO',
    60: '媒介',
    61: '聯繫我們',
    62: '服務條款',
    63: '审计报告',
    64: '白皮書',
    65: 'Github',
    66: 'FAQ',
    67: '遊戲',
    68: 'NFT市場',
    69: '空投',
    70: ' 經濟模型 $BVT',
    71: '合約地址',
    72: '參與 IDO ',
    73: '購買$BVT',
    74: '路線圖',
    75: '年末',
    76: '與超過30個遊戲工會簽約合作，出售土地和創世牛牛NFT;',
    77: '牛宇宙官網1.0上線；',
    78: '於BSC及Polygon上部署NFT',
    79: '首個邊玩邊賺（P2E）遊戲《傳奇牧場》完成BSC內測；',
    80: '單幣挖礦活動達到110萬美元的峰值。',
    81: '與Project Galaxy開展IGO盲盒售賣；關愛地球活動獲得了超過5K參與量。',
    82: '在第一個遊戲開發中集成 GVG (公會 vs 公會) 第一款以遊戲為生的遊戲《傳奇牧場》在Polygon上完成了alpha階段。',
    83: 'NFT 挖礦- 超過5,000名用戶參與；一週年紀念活動獲得了超過10K次參與。',
    84: '社區增長已超過20萬的用戶；',
    85: '與知名的Web3項目（包括 Cyberconnect、DeGame、SnapFigure DAO）合作；私募開啟；《傳奇牧場》開始公測階段。',
    86: '啟動我們自己的NFT市場；',
    87: 'DAO - 社區治理；私募結束；開啟BVT的DO；第二款遊戲 - 《星際沙盒》完成了內測階段。',
    88: '與更多遊戲工會達成合作；《星際沙盒》啟動公測；包括NFT和代幣在內的加密資產可以在這兩款遊戲中使用；將BVT納入生態系統。',
    89: '星際博客社交平台上線；支持第三款VR遊戲 - 《星際征途》增強現實互動遊戲模式。',
    90: '將推出電競遊戲挑戰，線上活動等線下賽事，為玩家帶來更多樂趣和挑戰。',
    91: '通過平行世界的星球大戰啟動元宇宙。',
    92: '皮膚盲盒',
    93: '高級草料',
    94: '高級成長加速包',
    95: '高級體力包',
    96: '高級生命藥劑',
    97: '私募',
    98: '團隊',
    99: '流動性',
    100: '顧問&PR',
    101: '生態系統',
    102: '道具',
    103: '交易所上市',
    104: '參加IDO',
    105: '查看更多',
    106: '足球競猜',
    107: '<p>挑戰競猜 </p><p>贏取大獎！</p>',
    108: '精英玩家',
  },
  igo: {
    // 这个是表格的
    1: '名字',
    2: '數量',
    3: '概率',
    4: '稀有級別',
    5: '創世牛牛',
    6: '普通牛牛',
    7: '道具禮包',
    8: '皮膚禮包',
    // 一些介绍
    9: '1. 什麼是盲盒？盲盒可以開出什麼？',
    10: '盲盒是 YOHO 的创世NFT；IGO 參與者有機會贏得寶貴的遊戲資產，如創世牛牛、普通牛牛、遊戲道具包或牛牛皮膚包',
    11: '2. 這些NFT的用途是什麼？',
    12: '<strong>創世牛牛</strong> 是可以在 YOHO 的三個平行世界中使用的加密頭像。 它們每個都有最高的自由度並且都是獨一無二的純手工設計。 它們的稀有性決定了它們在遊戲中的優質屬性，並允許無限繁殖和永生',
    13: `<strong>普通牛牛</strong> 也是 YOHO 遊戲中的主要角色，但是繁育次數和壽命是有限的。普通牛牛也是YOHO的公民，可以參與農場、繁殖、戰鬥以及探索等活動。`,
    14: '<strong>遊戲道具包</strong> 包括以下內容：草料、體力包、成長加速包、生命藥劑和更名卡。',
    15: '<strong>牛牛皮膚包</strong> 是普通牛牛和創世牛牛的皮膚服裝；各式各樣的皮膚設計可以更改牛牛們的外觀，使它們在戰鬥中脫穎而出、與眾不同。',
    16: '所有NFT都是基於<strong>ERC-721中</strong>，並且可以在 NFT 市場中自由交易。此外，它們是跨遊戲資產，穿越時間和空間的壁壘，這意味著它們可以在不同的遊戲中使用。您将可以在 <strong>4 月 5 日</strong>通过<strong>Project Galaxy</strong>打开盲盒。您將能夠在 <strong>4月5日</strong>通過 <strong>Project Galaxy</strong> 打開盲盒。',
    17: '3.在盲盒中每個 NFT 的概率以及稀有度是多少？',
    18: '4. 擁有白名單會有什麼好處?',
    19: '所有獲得白名單用戶均可在公開發售前 48 小時參與 YOHO IGO。 此外，他們可享受公售价25%的折扣！',
    20: '5. 如何加入白名單活動?',
    21: '1）YOHO OAT 持有者, <strong>440個白名單名額</strong>',
    22: `每個參與者都需要持有 <a onclick="window.open('https://galaxy.eco/YOHO/campaign/GCaMWUUuJF');">YOHO 社區徽章 NFT</a> 或 <a onclick="window.open('https://galaxy.eco/YOHO/campaign/GCaMWUUuJF');">YOHO Loyalty NFT</a>。 將從這些徽章持有者中選出 <strong>440</strong>名幸運獲獎者。`,
    23: '',
    24: '',
    25: '',
    26: '2）Galaxy Girl持有者，<strong>500個白名單名額</strong>',
    27: '若想要獲得本輪資格，您需要成為 <strong>Galaxy Girl持有者</strong>，<strong>500</strong>名幸運獲獎者將從<strong>Galaxy Girl持有者</strong>中選出，<strong>先到先得。</strong>',
    28: '3）社區活動獲獎者, <strong>500 個白名單名額</strong>',
    29: `我們將從白名單<a onclick="window.open('https://gleam.io/sDYK8/YOHO-igo-whitelist-campaign')">Gleam活動</a>、合作夥伴giveaway和Discord活動的參與者中挑選500名幸運者給予白名單。`,
    30: '6. YOHO 保留所有活動的最終解釋權。',
    31: '總供應量:1800',
    32: '白名單',
    33: '每個150 USDT',
    34: '2022/3/18 12:00 - 2022/3/20 12:00(UTC)',
    35: '公售',
    36: '每個200 USDT',
    37: '2022/3/20 12:00 - 2022/3/22 12:00(UTC)',
    38: '立即購買',
    39: 'WL Round-銀河少女',
    40: 'WL Round-YOHO OAT 和社區活動',
    41: '公開回合',
    42: '返回',
  },
  blindBox: {
    1: '單價',
    2: '總量',
    3: '天',
    4: '時',
    5: '分',
    6: '秒',
    7: '合成行星碎片',
    8: '立即購買',
    9: '1. 開啟光輪寶箱可隨機獲得創世牛牛、普通牛牛、牛牛寶箱或行星碎片，合成一定行星碎片可抽取普通牛牛、創世牛牛以及星球。',
    10: '2. 光輪寶箱單價：$50，總發行量：200,000。 個人購買次數不限。',
    11: '3. 基於ERC-721協議，光輪寶箱可以於Tofu等第三方NFT交易市場以及牛宇宙交易市場交易。',
    12: '4. NFT星球只可在星璇坐標通過行星碎片開出，玩家可於星璇坐標處合成行星碎片獲得家園星球以及拓荒星球。',
    13: '5. 注：除行星碎片外，本次活動開出道具均以兌換券形式發放。 活動開出的兌換券可於我的錢包查看。 遊戲上線後，玩家可憑兌換券於遊戲內兌換相應道具。',
    14: '',
    15: '',
    16: '內容',
    17: '概率',
    18: '光輪寶箱',
    19: '創世牛牛',
    20: '普通牛牛',
    21: '牛牛寶箱',
    22: '行星碎片',
    23: '家園星球及拓荒星球',
    24: '* 第四輪創世牛牛星璇坐標必得創世牛牛，供應有限，先到先得。',
    25: '規則',
    26: '星璇坐標',
    27: '寶箱内容',
    28: 'Galaxy Chest',
    29: 'Galaxy Chest is an NFT asset deployed on ERC-721. From Galaxy Chest, players can acquire Bovine Hero, Regular Cattle, Cattle Box, Star Fragment by chance, valuable in-game assets that have a great value in YOHO as well as in NFT marketplaces. In addition, Star Fragment can be collected to win NFT planets, precious and virtual land assets in BV.',
    30: 'issue number',
    31: 'Enter address to search qualified pre-order spots',
    32: 'search',
    33: 'FOMO PRIZE POOL',
    34: 'YOHO has specially set up a FoMo prize pool to reward players who actively participate in stargazing activities. Every time a player opens a halo chest, the system will inject 20,000 BVG into the pool. The system will inject 5,000 BVG into the pool every time a player draws a planetary fragment. Three active users who actively participate in the activity every day can share the YOHOG in the pool of the previous day on the next day:',
    35: 'Lucky Prospector',
    36: 'Land Researcher',
    37: 'Treasure Judge',
    38: 'Opening time',
    39: 'Total FRAG consumed',
    40: 'Total Halo Chests opened',
    41: 'Claimable BVG',
    42: 'Address',
    43: 'Countdown',
    44: 'Claim',
    45: 'Join the Last Swirl',
    46: 'Consume most FRAGs',
    47: 'Open most Chests',
    48: '打開盲盒',
    49: 'NFT授權',
    50: '我們會徵求您的同意後再訪問您的令牌並且這是一次性操作。',
    51: '確認打開',
    52: '點擊確認打開您的盲盒。',
    53: '恭喜，你獲得',
    54: '質押NFT',
    55: '確認質押',
    56: '確認將你的NFT質押入農場',
  },
  halo: {
    1: '牛牛集市正式向所有人限時開放！在本次集市交易活動中，YOHO將限量售賣特別的活動盲盒-光輪寶箱，開啟後隨機獲得稀有的遊戲道具NFT。牛牛集市將NFT、大樂透和遊戲玩法三合一，集結其中所有的有趣玩法，為玩家打造一個豐富的遊戲體驗。此外，牛牛集市特別設立邀請機制，玩家通過邀請他人參與進集市活動，獲得10%的返現獎勵。如此好玩的大型抽獎活動，你還在等什麼呢？參加限時集市活動，贏取稀有NFT獎勵！',
    2: '牛牛集市活動規則介紹',
    3: '1. 本次活動由YOHO官方發起，於UTC時間6月23日中午12:00開始。',
    4: '2. 每個光輪寶箱售價 20 美元，總供應量 20,000。每個地址沒有購買上限。',
    6: '3. 推薦獎勵：一旦您購買了光輪寶箱，您就有資格成為推薦人。每次推薦您將獲得總付款的 20% 作為獎勵。一旦您的朋友輸入您的錢包地址作為邀請碼並確認付款，20% 將自動發送到您的地址。',
    7: "4. 白名單用戶首次下單可享受額外 10% 的折扣。點擊<a href='https://docs.google.com/spreadsheets/d/1Xvuc6T7X8tS0nAiqOz3-Mm-WN-M_lANUb146FHEcswo/edit?usp=sharing'>此處</a>查看您的地址是否已列入白名單。白名單用戶包括（需在規定時間內提交地址）：牛宇宙一周年 OAT 的持有用戶，DC中擁有 Bovine Hero、Twitter Bird、Coser、Gambling King身份的用戶。",
    8: '5. 購買光輪寶箱後，可獲得以下物品之一：創世牛牛兌換券、小牛兌換券、牛牛集市盲盒或行星碎片。',
    9: '6. 獲得行星碎片的人可以參加碎片合成，贏取星球、創世牛牛、小牛、牛牛集市盲盒或BVG。',
    10: '  a. 通過碎片合成，可隨機獲得以下資產：牛牛集市盲盒，小牛，創世牛牛，星球，或者一定數量的碎片和BVG。',
    11: '  b. 每個合併的確切概率表在各自相應的合成頁面上展示。',
    12: '  c. 所有這些 NFT 都可以在二級 NFT 市場上自由交易，如 TofuNFT、Element 和 Galler。',
    13: '7. FOMO 獎池旨在獎勵那些積極參與牛牛集市活動的玩家。每當開啟一個光輪寶箱，5,000 BVG 將自動注入到池中。玩家每完成一次合併，就會自動將 2,000 BVG 注入到池中。每天三名獲勝者（天選之人、星球研究員和開箱達人）將在前一天的池中分享收集到的 BVG（%为该赢家可分得池子比例）。',
    14: '  a.天選之人：50%，合併的最終參與者',
    15: '  b.星球研究員：30%，消耗碎片最多的參與者',
    16: '  c.開箱達人：20%，打開寶箱最多的參與者',
    17: '8. 玩家需要消耗足夠的碎片才能隨機參與牛牛抽獎。創世牛牛合併只供應20個創世牛牛，先到先得。',
    18: '9. 玩家需要消耗足夠的碎片才能隨機贏得家園星球。本次抽獎僅提供2個家園星球，先到先得。',
    19: '立即購買',
    20: '合成碎片',
    21: '開啟我的光輪寶箱',
    22: '你能得到什麼',
    23: 'FOMO 獎金池',
    24: '下一輪',
    25: '回合',
    26: '天選之人',
    27: '星球研究員',
    28: '開箱達人',
    29: '獎勵',
    30: '獲勝者',
    31: '已領取獎勵',
    32: '未領取獎勵',
    33: '你贏了！領取你的獎勵！',
    34: '回合',
    35: '獎金池',
    36: '學習如何玩转 >',
    37: '<strong>描述:</strong> 光輪寶箱為牛牛集市活動售賣的盲盒，開啟後玩家隨機獲得創世牛牛、普通牛牛、集市盲盒、行星碎片。行星碎片可用於抽取家園星球、創世牛牛和普通牛牛。',
    38: '推薦人地址',
    39: '粘貼',
    40: '*如果沒有，請留空',
    41: '總額',
    42: '折扣總額（僅限白名單）',
    43: '再加十個',
    44: '開啟我的光輪寶箱',
    45: '返回首頁',
    46: '*推薦獎勵：一旦您購買了光輪寶箱，您就有資格成為推薦人。每次推薦您將獲得總付款的 20% 作為獎勵。',
    47: '可用行星碎片數量:',
    48: '合成一次所需碎片:',
    49: '剩餘數量:',
    50: '概率：',
    51: '獲得創世牛牛的概率:',
    52: '合成所需碎片:',
    53: '合成',
    54: '購買更多',
    55: '開啟我的盲盒',
    56: '銷毀',
    57: '你可以得到什麼',
    58: '輪次',
    59: '碎片',
    60: '1 個碎片 和 50,000 BVG',
    61: '2 個碎片 和 100,000 BVG  ',
    62: '1. 在你獲得牛牛集市盲盒後，你將獲得1個小牛或1個行星碎片，50%的概率。',
    63: '2. 本次抽獎中CattleMart Box的供應沒有上限；',
    64: '3. 確認合成後，將銷毀5個用於合併的行星碎片。',
    65: '1. 本次合成不設小牛供應上限。',
    66: '2. 確認合成後，將銷毀10個用於合成的行星碎片。',
    67: '1. 碎片合併僅提供20個創世牛牛。 先到先得。',
    68: '2. 玩家在第4輪前獲得創世牛牛，則從第1輪重新開始。',
    69: '3. 創世牛牛獲得數量沒有限制，也就是說每位玩家可以通過本次合成獲得多個創世牛牛。',
    70: '1. 本次合成僅提供2個家園星球。 先到先得。',
    71: '2. 獲得的星球數量沒有限制，也就是說每個玩家可以通過這次合併獲得多個星球。 但是，每個地址僅限於加入一個星球。 這意味著如果持有多個，你可以交易。',
  },
  farm: {
    1: '背景故事',
    2: '選擇質押币种',
    3: '質押數量',
    4: '我已質押的數量',
    5: '我的剩餘額度',
    6: '該池子裡已質押的量',
    7: '剩餘獎勵數量',
    8: '我的明細',
    9: '已領取獎勵',
    10: '待領取獎勵',
    11: '牛宇宙蘊含著數之不盡的財富和物資，在宙爍121年，墾荒浪潮如火如荼，兩大陣營之一的星邦自由聯盟組織了三家具有開採技術的墾荒公司進行星際探索，傑法爾榮譽墾荒公司（BNB）、工人墾荒聯合體（USDT）、沃利墾荒公司（FIL），墾荒每日總產出500萬個BVG，三個公司分別按50%、30%和20%的比例進行分配。化身牛類生物的玩家們可自由選擇公司加入或退出。每位玩家最多同時質押10 BNB、5,000 USDT和100 FIL。所獲收益均統一兌換成牛宇宙通用貨幣BVG。',
    12: '亮點',
    13: '金庫',
    14: '低門檻',
    15: '高收益',
    16: '隨存隨取',
    17: '通縮機制',
    18: '功能令牌',
    19: '預估年化收益率',
    20: '合約地址',
    21: '挖礦:',
    22: 'BVG:',
    23: '關於挖礦和獎勵代幣 BVG 的合約已經得到 Certik 官方的正式批准。 CertiK 是區塊鏈安全領域發展最快、最受信任的公司之一，也是區塊鏈安全領域的先驅，利用一流的形式驗證和人工智能技術來保護和監控區塊鏈、智能合約和 Web3 應用程序。',
    24: '點擊',
    25: '這裡',
    26: '查看審計報告。',
    27: 'OAT质押背景',
    28: '墾荒活動結束了嗎？不，宇宙探索沒有盡頭，一切才剛剛開始！牛宇宙中仍有數之不盡的財富和物資可以兌換成通用貨幣BVG，而OAT（On-chain Achievement Token）更是早期不可或缺的物資之一，扮演著無比重要的作用，讓無數牛牛為之瘋狂。 OAT是YOHO在Web3 基礎設施平台Project Galaxy上基於BNB Chain（前Binance Smart Chain）首發的定制徽章，目前已發布了5個，它們既是稀缺、珍貴的鏈上憑證，也是牛宇宙生態Social身份的表徵。',
    29: '信息',
    30: '1、 質押週期：30天（預計3月底開始）；',
    31: '2、 每日產出1,666,667個BVG，30天總量為50M BVG；',
    32: '3、 OAT質押為活期，隨進隨出；',
    33: '4、 每個OAT實際算力由鑄造數量而定，數量越稀缺，算力越高；',
    34: '5、 IGO盲盒是普通OAT的10倍算力。',
    35: '未抵押的 NFT',
    36: '質押的 NFT',
    37: '名稱',
    38: 'Token ID',
    39: '算力',
    40: '預計每日獎勵',
    41: '2、 合約地址',
    42: '待領取收益',
    43: '已領取收益',
  },
  market: {
    1: '最低價',
    2: '最高價',
    3: '最新時間',
    4: '',
    5: '',
    6: '',
    7: '',
  },
  panel: {
    1: '賬戶',
    2: 'NFT',
    3: '親密關係',
    4: '個人信息',
    5: '性別',
    6: '錢包',
    7: '暱稱',
    8: '星球',
    9: '星球勝率',
    10: '結果',
    11: '勝率',
    12: '遊戲收益',
    13: '男',
    14: '女',
    15: '星球',
    16: '創世牛牛',
    17: '牛牛',
    18: '盲盒',
    19: '其他',
    20: '篩選',
    21: '重置',
    22: '類別',
    23: '血統',
    24: '个在售',
    25: '公牛',
    26: '母牛',
    27: '我的掛單',
    28: '價格從高到低',
    29: '價格從低到高 ',
    30: '最新掛單',
    31: '下架',
    32: '歷史',
    33: '星球租賃',
    34: '暫無數據',
    35: '徽章',
    36: '幣種'
  },
  detail: {
    1: '基本信息',
    2: '基礎屬性',
    3: '戰鬥屬性',
    4: '產奶屬性',
    5: '生命值',
    6: '成長值',
    7: '體力值',
    8: '戰鬥值',
    9: '耐力值',
    10: '防禦值',
    11: '產奶量',
    12: '產奶速率',
    13: '持有者',
    14: '立即購買',
    15: '出售',
    16: '開啟',
    17: '轉賬',
    18: '合約地址',
    19: 'Token ID',
    20: '描述',
    21: '人口',
    22: '稅率',
    23: '轉賬',
    24: '請輸入您想轉賬的 {0} 地址',
    25: '更換頭像',
    26: '数量',
    27: '下架',
    28: '名稱',
    29: '狀態',
    30: '數量',
    31: '價格',
    32: '已售',
    33: '已購買',
    34: '時間',
    35: '父母信息',
    36: '父親',
    37: '母親',
    38: '設置你的掛單',
    39: '設置你的固定價格',
    40: '費用',
    41: '總銷售額的 2% 將作為平台費用支付給 YOHO。',
    42: '出售 NFT',
    43: '確認出售',
    44: '確認將你的 NFT 出售到 NFT 市場',
    45: '交易確認',
    46: '請確認你將支付的金額',
    47: '可用餘額',
    48: '餘額不足。 前往購買更多加密貨幣>',
    49: '稅收',
    50: '情侶',
    51: '兄弟',
    52: '基友',
    53: '闺蜜',
    54: '交易確認',
    55: '授權錢包',
    56: '交易前需要授權錢包代幣,僅授權一次即可',
    57: '確認交易',
    58: '點擊確認購買盲盒.',
    59: '確認合成',
    60: '點擊確認合成你的NFT',
    61: '生命剩餘時間',
    62: '注意：此盲盒只能在《傳奇牧場》遊戲中開啟。',
    63: '出租',
    64: '出租中',
    65: '出租下架',
    66: '租賃中',
    67: '租賃',
    68: '* 租賃時間至',
    69: '* 租赁中可随时续租',
  },
  card: {
    1: '牛牛盲盒',
    2: 'IGO 盲盒',
    3: '創世牛牛',
    4: '普通牛牛',
    5: '道具禮包',
    6: '皮膚禮包',
    7: 'IGO 神秘盒子是 YOHO 的創世 NFT。 只有 IGO 參與者有機會贏得寶貴的遊戲資產，創世牛牛、普通牛牛、道具包或皮膚包。 所有這些 NFT 都部署在 ERC 721 中，並且可以在 NFT 市場中自由交易。 此外，他們是跨遊戲資產，穿越時間和空間的壁壘，這意味著他們可以在不同的遊戲中使用。',
    8: 'Bovine Hero 是可以在 YOHO 的三個平行世界中使用的加密頭像。 他們每個都有最高的自由度並且都是獨一無二的純手工設計。稀有度決定了它們在遊戲中的優質屬性且是唯一允許無限繁殖和永生的物種。 所有這些 NFT 都部署在 ERC 721 中，並且可以在 NFT 市場中自由交易。 此外，他們是跨遊戲資產，穿越時間和空間的壁壘，這意味著他們可以在不同的遊戲中使用。 ',
    9: '普通牛牛也是 YOHO 遊戲中的主要角色，但是繁殖次數和壽命有限的。 普通牛牛也是 YOHO 的公民，可以參與農場、繁殖、戰鬥和探索。所有這些 NFT 都部署在 ERC 721 中，並且可以在 NFT 市場中自由交易。 此外，他們是跨遊戲資產，穿越時間和空間的壁壘，這意味著他們可以在不同的遊戲中使用。',
    10: 'YOHO的物品包包括以下游戲物品：乾草、力量電池、經驗卡、HP藥水和改名卡。 這些都是遊戲內物品，可以用來增強牛或牛英雄的屬性。 這些包部署在 ERC 721 中，可在 NFT 市場中自由交易。 此外，它們是跨遊戲資產，打破了時間和空間的壁壘，這意味著它們可以在不同的遊戲中使用。',
    11: '各式各樣的皮膚設計改變了牛牛的外觀，使它們在戰鬥中脫穎而出。 所有這些 NFT 都部署在 ERC 721 中，並且可以在 NFT 市場中自由交易。 此外，他們是跨遊戲資產，穿越時間和空間的壁壘，這意味著他們可以在不同的遊戲中使用。',
    12: '家園星球',
    13: '聯邦星球',
    14: '拓荒星球',
    15: '家園星球是《傳奇牧場》的高級星球NFT，家園星球的會長可以向其居民玩家及聯邦星球征稅，分配星球收益，發展並帶領本星球牛牛致富。家園星球的人口容量為10,000。',
    16: '',
    17: '',
    18: '集市盲盒',
    19: '普通牛牛兌換券',
    20: '創世牛牛兌換券',
    21: '拓荒星球兌換券',
    22: '家園星球兌換券',
    23: '行星碎片',
    24: '牛牛集市活動中的集市盲盒中可隨機開出普通牛牛或行星碎片。普通牛牛於遊戲中參與產奶、繁殖、戰鬥、質押等活動賺取代幣。行星碎片用於開啟星璿坐標，贏取創世牛牛、牛牛和星球等稀有NFT資產。',
    26: '普通牛牛和創世牛牛一樣都是《傳奇牧場》的重要角色，在遊戲中參與挖礦、繁殖、戰鬥及其他經濟活動賺取YOHOG和YOHOT代幣獎勵。普通牛牛部署於ERC-721協議，可以於二級市場上自由交易。',
    27: '創世牛牛部署在ERC-721協議，為YOHO的NFT資產之一，限量供應，永恆生命，可在NFT交易市場自由貿易。創世牛牛是YOHO的跨遊戲資產。',
    28: '遊戲內稀缺的土地類NFT資產，拓荒星球是由家園星球或聯邦星球的成員建立，人口容量為1,000，拓荒星球會長有權向其居民和附屬聯邦星球收取稅收。',
    29: '家園星球是《傳奇牧場》的高級星球NFT，家園星球的會長可以向其居民玩家及聯邦星球征稅，分配星球收益，發展並帶領本星球牛牛致富。家園星球的人口容量為10,000。',
    30: '集齊一定行星碎片參與星璇坐標搖獎，有機會贏取創世牛牛、牛牛和星球等稀有NFT資產。',
    31: '光輪寶箱',
    32: '光輪寶箱為牛牛集市活動售賣的盲盒，開啟後玩家隨機獲得創世牛牛、普通牛牛、行星碎片。行星碎片可用於抽取家園星球、拓荒星球、創世牛牛和普通牛牛。每日開啟最多光輪寶箱的玩家可參與瓜分FOMO獎池。',
    33: '公母牛繁殖後可獲得牛牛盲盒。牛牛盲盒部署在ERC-721協議上，為YOHO其中一個NFT資產。打開盲盒有機會獲得創世牛牛、普通小牛、牛牛碎片。',
    34: '創世牛牛部署在ERC-721協議，為YOHO的NFT資產之一，限量供應，永恆生命，可在NFT交易市場自由貿易。創世牛牛是YOHO的跨遊戲資產。',
    35: '普通牛牛和創世牛牛一樣都是《傳奇牧場》的重要角色，在遊戲中參與挖礦、繁殖、戰鬥及其他經濟活動賺取YOHOG和YOHOT代幣獎勵。',
    36: 'YOHO 遊戲盲盒',
    37: '在幣安NFT 出售的 YOHO 盲盒包含參與传奇牧場的加密戰鬥和加密擠奶所需的獨家遊戲角色。 打开獨家禮盒可有机会获得價值 1000 美元的创世牛牛、價值 200 美元的普通小牛或者道具包。 打開盲盒後，您需要通過我們的官方橋樑將其連接到 Polygon。',
    38: '青铜徽章',
    39: '白银徽章',
    40: '黄金徽章',
    41: '铂金徽章',
    42: '钻石徽章',
    43: '荣耀徽章',
  },
  airdrop: {
    1: '請輸入您的地址',
    2: '搜索',
    3: '地址',
    4: '類型',
    5: '數量',
    6: '活動',
    7: '狀態',
    8: '已領取',
    9: '等待租赁',
    10: '立即領取',
    11: '凍結',
    12: '領取中',
    13: '鏈',
    14: '1. 空投獎勵含BNB和Polygon鏈上的代幣和NFT，請務必在領取前切換正確的網絡。',
    15: '2. 請務必在官網頁面鏈接您的錢包。',
    16: '3. 空投獎勵自活動公告之日起次月第1個工作日開放領取；領取通道於一周後關閉。',
    17: '4. 逾期未領取者將視為無效用戶。',
    18: "5. 點擊<a href='https://YOHO.medium.com/claiming-airdrop-rewards-tutorial-c4f428bcea3' target='_blank'>此處</a>查看詳細教程。",
    19: '6. 活動最終解釋權歸YOHO官方所有。',
    20: "7. 如遇更多問題，請點擊<a href='mailto:YOHO{'@'}gmail.com' target='_blank'>此處</a>與我們聯繫。",
    21: '了解如何領取空投',
    22: '描述',
  },
  rangeland: {
    1: '進入遊戲',
    2: '交易市場',
    3: '教程視頻',
    4: '排行榜',
    // 排行榜
    5: '第{0}周排行榜',
    6: '產奶',
    7: '育種',
    8: '貢獻',
    9: '產奶量',
    10: '排名',
    11: '名稱',
    12: '玩家',
    13: '即將開啟',
    14: '繁殖次數',
    15: '稅',
  },

  // 土地售卖领取
  claimAssets: {
    1: '家園星球',
    2: '家園星球是《傳奇牧場》的高級星球NFT，家園星球的會長可以向其居民玩家及聯邦星球征稅，分配星球收益，發展並帶領本星球牛牛致富。家園星球的人口容量為10,000。',
    3: '牛牛盲盒',
    4: '公母牛繁殖後可獲得牛牛盲盒。牛牛盲盒部署在ERC-721協議上，為YOHO其中一個NFT資產。打開盲盒有機會獲得創世牛牛、普通小牛、牛牛碎片。',
    5: '創世牛牛',
    6: '創世牛牛部署在ERC-721協議，為YOHO的NFT資產之一，限量供應，永恆生命，可在NFT交易市場自由貿易。創世牛牛是YOHO的跨遊戲資產。',
    7: '普通牛牛',
    8: '普通牛牛和創世牛牛一樣都是《傳奇牧場》的重要角色，在遊戲中參與挖礦、繁殖、戰鬥及其他經濟活動賺取BVG和BVT代幣獎勵。普通牛牛部署於ERC-721協議，可以於二級市場上自由交易。',

    9: '領取',
    10: '一鍵申請',
    11: '星球',
    12: '創世牛牛',
    14: '合約地址',
    15: '發行量',
    16: '可領取用戶',
    17: '白名單用戶',
    18: '可領取',
    19: '已領取',
    20: '不符合资格',

    21: 'TBVG為BV發行的遊戲代幣，玩家可通過參與遊戲、空投、挖礦、質押等活動獲得。遊戲上線後，玩家可以通過完成PVE等任務賺取TBVG。TBVG可用于購買遊戲道具、交易等。',
    22: 'TBVT為BV發行的代幣，可通過參與遊戲、空投活動等渠道獲得。玩家可以用TBVT購買遊戲道具、繁殖、升級、交易等。玩家擁有越多的TBVT，在BV的DAO社區自治中擁有的投票權也越多。',

    23: '即將上綫',
    24: '所有玩家',
    25: '已鑄造',
    26: '普通牛牛盲盒',
    27: '描述',
    28: '無上限',
    29: '供應量不足',
    30: '你已經領取了',
    31: '你沒有資格',
    32: '進入游戲',
  },

  // 公测拉新邀请 BetaInvite
  referral: {
    1: '啟航全民養牛新時代',
    2: '邀请玩家參與公测',
    3: '（第一季）',
    4: '身份',
    5: '推薦人地址',
    6: '粘貼',
    7: '確定您的推薦人',
    8: '邀請人數',
    9: '有效邀請數',
    10: '未領取獎勵',
    11: '已領取獎勵',
    12: '禮品包',
    13: '高級草料',
    14: '高級體力包',
    15: '高級經驗包',
    16: 'HP 藥水',
    17: '領取公測獎勵',
    18: "成功養育至少2只牛崽長大成年。 点击<a href='https://www.youtube.com/watch?v=HiZxJo4IfrM' target='_blank'>此处</a>觀看教程",
    19: "從產奶賺取至少200TBVT或者從戰鬥賺取至少1000TBVT。 点击<a href='https://www.youtube.com/watch?v=_UQ_NCppv0U' target='_blank'>此处</a>觀看教程",
    20: "向所在公會繳納至少100TBVT的稅費。 点击<a hre f='https://www.youtube.com/watch?v=-5HH7lIcWCg' target='_blank'>此处</a>觀看教程",
    21: "任一科技树升至三级。 点击<a href='https://www.youtube.com/watch?v=qb704wLapdU' target='_blank'>此处</a>觀看教程",
    22: "農場/牧場等級至少達到2級。 点击<a href='https://doc-en.bovine-verse.games/interstellar-rangeland-beta/stall/stall-player-level' target='_blank'>此处</a>觀看教程",
    23: '以下信息是地址',
    24: '地址',
    25: '狀態',
    26: '邀請查詢',
    27: '白名單玩家',
    28: '新用戶',
    29: '領取獎勵',
    30: '邀請地址',
    31: '你還沒有邀請任何人。',
    32: '1. 為感謝公測上線以來玩家的支持，我們為白名單用戶特別舉辦公測邀請派對，共同瓜分價值20,000 USDT的獎池。',
    33: '2. 白名單用戶為邀請活動開始前，參與遊戲內活動並經快照的玩家地址。',
    34: '3. 本次活動的推薦人僅可為白名單用戶，共同瓜分價值$20,000 U的大獎池。',
    35: '4. 每個白名單用戶可邀請好友加入《傳奇牧場》公測，邀請好友數量不設上限。',
    36: '5. 每達成一次有效邀請，白名單用戶可獲得5 USDT作為邀請獎勵，新用戶可通過活動頁面獲得禮包。',
    37: '6. 為公平起見，每個白名單地址的獎金上限為50 USDT。',
    38: '7. 新用戶需要輸入白名單用戶的地址作為推薦人，綁定推薦人成功並完成五個遊戲內任務後即可算為有效邀請。',
    39: '8. 本次公測邀請活動時間持續至公測結束。如果您不在本季邀請活動白名單內，可積極參與公測，敬請等待下一季開放。',
    40: "9. 用戶可以觀看每個任務的視頻教程或加入<a href='https://discord.com/invite/YOHO' target='_blank'>YOHO社區</a>了解遊戲玩法。",
    41: '10. 邀請人可前往任務進度頁查看邀請玩家的任務詳情以及完成狀態。',
    42: '11. 活動最終解釋權歸YOHO官方所有',
    43: '學習如何玩賺',
    44: '活動詳情',
  },

  // 公测资产兑换
  assetsExchange: {
    1: '草料',
    2: '體力包',
    3: '成長加速包',
    4: '皮膚盲盒',
    5: '改名卡',
    6: '兌換',
    7: '普通草料',
    8: '中级草料',
    9: '高级草料',
    10: '普通體力包',
    11: '中級體力包',
    12: '高級體力包',
    13: '普通成長加速包',
    14: '中級成長加速包',
    15: '高級成長加速包',
    16: '皮膚盲盒',
    17: '改名卡',
    18: '您未滿足牛欄等級，不符合購買資格',
    19: '您未滿足TBVT領取，不符合購買資格',
    20: '兑换券',
    21: '可兑换数量',
    22: '单价',
    23: 'TBVT余额',
    24: '需支付金额',
    25: '今日剩餘額度',
    26: '兑换需要满足以下条件: ',
    27: '1.农场等级2级及以上',
    28: '2.累计TBVT收益达到：3000及以上',
    29: '单个地址每天可兑换消耗上限：10 W TBVT',
    30: '所有道具兑换后都以道具兑换券（NFT）展示，正式上线后用户可凭道具兑换券兑换正式道具资产',
    31: '了解如何兌換',

    32: '喂食后给牛牛增加体力值',
    33: '喂食后给牛牛增加体力值',
    34: '喂食后给牛牛增加体力值',
    35: '使用后给牛牛增加成长值',
    36: '使用后给牛牛增加成长值',
    37: '使用后给牛牛增加成长值',
    38: '使用后给牛牛增加体力值',
    39: '使用后给牛牛增加体力值',
    40: '使用后给牛牛增加体力值',
    41: '使用后给牛牛增加生命周期2天',
    42: '使用后给牛牛增加生命周期5天',
    43: '使用后给牛牛增加生命周期10天',
    44: '购买后可以随机获得一个新NFT皮肤',
    45: '可用于修改游戏名称',

    46: '普通草料兑换券',
    47: '中级草料兑换券',
    48: '高级草料兑换券',
    49: '普通体力包兑换券',
    50: '中级体力包兑换券',
    51: '高级体力包兑换券',
    52: '普通成长加速包兑换券',
    53: '中级成长加速包兑换券',
    54: '高级成长加速包兑换券',
    55: '皮肤盲盒兑换券',
    56: '改名卡兑换券',

    57: '售罄',
    58: '光輪寶箱兌換券',
    59: '正式資產兌換券',
    60: 'IGO盲盒(馬蹄鏈)',
    61: '道具禮包兌換券',
    62: '一鍵兌換',
    63: '成年牛兌換券',
    64: '已擁有',
    65: '共兌換 {0} 兌換券, 需花費 {1} BVT',
    66: '請選擇需要兌換的券',
    67: '其他兌換券',
  },

  // 资产道具购买
  gameStore: {
    1: '小牛崽',
    2: '生命藥劑',
    3: '體力包',
    4: '成長加速包',
    5: '皮膚盲盒',
    6: '普通牛牛',
    7: '普通生命藥劑',
    8: '中级生命藥劑',
    9: '高级生命藥劑',
    10: '交易歷史記錄',
    11: '序號',
    12: '原價',
    13: '價格',
    14: '購買',
    15: '購買數量',
    16: '花費',
    17: '餘額',
    18: '時間',
    19: '普通体力包',
    20: '中级体力包',
    21: '高级体力包',
    22: '普通成长加速包',
    23: '中级成长加速包',
    24: '高级成长加速包',
    25: '皮肤盲盒',
    26: '',
    27: '',
    28: '',
    29: '',
    30: '',
    31: '',
    32: '',
    33: '',
    34: '',
    35: '',
    36: '',
    37: '',
    38: '',
    39: '',
    40: '',
    41: '',
  },

  // 公会拉新活动
  guild: {
    1: '公會',
    2: '精英',
    3: '步驟 1',
    4: '申請獲得資格',
    5: '建立一個至少有 30 人的社區（微信/TG/任何其他社交媒體）',
    6: '剩余位置：{0}',
    7: '領取公测資產',
    8: '申請',
    9: '步驟 2',
    10: '建立您的社區',
    11: '1. 在公測資產頁面領取牛牛盲盒。',
    12: '2.將小牛餵成成年，給予一定量的體力值。',
    13: '3.你的戰鬥或牛奶截圖（分享到微信朋友圈（向所有人可見）或推特）',
    14: '-Moments：包含 YOHO 的標籤',
    15: "-推特：{'@'}YOHO",
    16: '4. 公會會長應收集所有有效地址和證明並在此處提交或提交給YOHO官方管理員。',
    17: '步骤 3',
    18: '赢取最高奖励',
    19: '第一階段：公會獎勵100 USDT',
    20: '條件：社區新成員達到30個以上，有效地址達到10個以上',
    21: '第二階段：公會獎勵500 USDT',
    22: '條件：社區新成員達到100個以上，有效地址達到60個以上',
    23: '第三階段：公會獎勵1000 USDT & 星球功勳徽章NFT',
    24: '條件：社區新成員達到200人以上，有效地址達到150人以上',
    25: '第四階段：公會獎勵2000 USDT & 星球總裁徽章 NFT',
    26: '條件：社區新成員達到500個以上，有效地址達到500個以上',
    27: '領取',
    28: '鑄造',
    // ELITES 精英
    29: '成為有效地址',
    30: '1.從公測資產頁面領取牛牛盲盒。',
    31: '2.將小牛餵養成年，給予一定量的體力值。',
    32: '3.將你的戰鬥或產奶截圖（分享到微信朋友圈（向所有人可見）或推特）',
    33: '-Moments：包含 #YOHO 的標籤',
    34: "-推特：{'@'}YOHO",
    35: '4.將您的截圖連同您的地址一起提交給您的公會會長',
    36: '領取您的徽章',
    37: '提交社群證明',
    38: '社區名必須包含 YOHO。',
    39: '每位符合條件的總統都可以申請一顆 公測星球。他們的成員可以選擇加入星球或其他星球。星球的名稱必須與社區名相同。',
    40: '有效地址需提交給 YOHO 官方管理員審核。',
    41: '已領取',
  },
  // 徽章空投
  oatBadge: {
    1: '購買$BVT',
    2: '符合資格的OATs',
    3: '$BVT獎勵',
    4: '剩餘名額',
    5: '等級',
    6: '數量',
    7: '活動規則',
    8: '1. 獎勵將於8月23日北京時間19點開放領取。',
    9: '2. 每一級別的$BVT獎勵將限量供應，先到先得。',
    10: '4. BVT合約地址：',
    11: '5.  本次空投活動最終解釋權為YOHO官方所有。',
    12: '購買$BVT',
    13: '加入社區',
    14: '奖励级别',
    15: '$BVT數量/获奖者',
    16: '奖励份数',
    17: '3. 我们对我们发行的 OAT 类型进行了分类，并计算了每个持有人的总重量。 快照拍摄于 8 月 22 日',
  },
  // LAZIO 质押
  lazio: {
    1: '購買BVT',
    2: '詳情',
    3: '距離質押活動結束',
    4: '當前餘額',
    5: '質押數量',
    6: '推薦人地址',
    7: '粘貼',
    8: '質押時間',
    9: '活期',
    10: '3天',
    11: '7天',
    12: '預估年化收益率',
    13: '我的質押總量',
    14: '池子質押總量',
    15: '總獎勵',
    16: '合約地址',
    17: '更多詳情',
    18: '描述',
    19: '1. 活動時間：北京時間 9 月 16 日晚上8:00 至北京時間 10 月 1 日晚上8:00',
    20: '2. 由於本次活動是为感谢我們所有 BVT 持有者而准备的額外奖励，因此本次質押沒有最低 $BVT 质押量。',
    21: '3. 不同的質押周期賦予不同的算力。 質押時間越長，它所拥有的算力就越高。',
    22: '4. $LAZIO獎勵可隨時領取。',
    23: '5. 我們为您準備了獎勵的30%作為推薦獎勵。 非常歡迎您推薦您的朋友參加此活動。 對於每個有效邀請，您將能夠分享您朋友賺取 的獎勵的 20%。 如果您邀請的朋友邀請了另一位朋友，您將能夠分享他或她的奖励的 10%。',
    24: '6. 活動最終解釋權歸YOHO官方所有。',
    25: '推薦獎勵',
    26: '可領取獎勵',
    27: '已領取獎勵',
    28: '領取獎勵',
    29: '領取時間為 ',
    30: '天',
    31: '時',
    32: '分',
    33: '秒',
  },
  // 跨链桥 (BVChain)
  bridge: {
    1: '跨鏈橋',
    2: '從',
    3: '兌換成',
    4: '交易',
    5: '代幣',
    6: 'NFT',
    7: '提醒',
    8: '跨鏈費用為 1% BVT 和 BVG 的最低跨鏈金額分別為 40 BVT 和 50,000 BVG。',
    9: '預計跨鏈到達時間為 10-30 分鐘。',
    10: '關於 NFT 兌換券，我們只提供從 BNB Chain（原 BSC）跨鏈到 Polygon ',
    11: '如果你的 NFT 是 BNB Chain 中的一個盲盒，你需要先打開它，然後才能跨鏈到 Polygon。',
    12: '跨鏈歷史記錄',
    13: '鏈',
    14: '類型',
    15: '數量',
    16: '交易哈希',
    17: '狀態',
    18: '已完成',
    19: '失敗',
    20: '正在進行中',
    21: '資產原鏈交易',
    22: '資產新鏈交易',
    23: '餘額：',
    24: '我們僅支持將新版BVG 從马蹄鏈跨到 BNB 鏈 (0x29AB77a43Ec69D0DFFf3Ff72bff457e09b258351)，比例為 4000:1。',
    25: '',
    26: '更新版',

    27: `<div>YOHO Chain是一个基于ZK-Rollups的</div><div>具有统一流动性的多链L2网络</div>`,
    28: 'YOHOChain简介',
    29: `<div>YOHO Chain是一个基于ZK-Rollups的具有统一流动性的多链L2网络。YOHO Chain生态系统连接了各种L1链和L2网络，使开发者和交易者能够利用来自隔离链的统一流动性和安全无缝的用户体验，为每个人提供更易于访问和高效的DeFi生态系统。</div>
    <div>YOHO Chain创新地将零知识技术应用于多链互操作，采用去信任去中心化的分权设计，保障资产和交易安全以及多链数据传输。此外，YOHO Chain消除了不同区块链、不同类型的稳定币之间的差异，通过独特的协议控制债务（PCD）机制实现多链流动性聚合，形成海量稳定币流动性池，提供资金高效、成本友好的服务，以及易于访问的基础设施。</div>
    `,
    30: '零安全妥协的零知识证明',
    31: '具有制衡机制的严格共识机制',
    32: '来自多个链的原生资产聚合',
    33: 'YOHO Chain的特点',
  },
  // 年终
  yearend: {
    1: '購買BVT',
    2: '詳情',
    3: '距離質押活動結束',
    4: '當前餘額',
    5: '質押數量',
    6: '推薦人地址',
    7: '粘貼',
    8: '質押時間',
    9: '活期',
    10: '3月',
    11: '6月',
    12: '預估年化收益率',
    13: '我的質押量',
    14: '池子質押總量',
    15: '總獎勵',
    16: '合約地址',
    17: '更多詳情',
    18: '描述',
    19: '1. 活動時間：北京時間 12 月 28 日下午4:00 至北京時間 1 月 27 日下午4:00',
    20: '2. 由於本次活動是为感谢我們所有 BVT 和 BVG 持有者而准备的額外奖励，因此本次質押沒有最低 $BVT 和 $BVG 质押量。',
    21: '3. 不同的質押周期賦予不同的算力。 質押時間越長，它所拥有的算力就越高。',
    22: '4. 獎勵可隨時領取。',
    23: '5. 我們为您準備了獎勵的20%作為推薦獎勵。 非常歡迎您推薦您的朋友參加此活動。 對於每個有效邀請，您將能夠分享您朋友賺取 的獎勵的 20%。',
    24: '6. 活動最終解釋權歸YOHO官方所有。',
    25: '推薦獎勵',
    26: '可領取獎勵',
    27: '已領取獎勵',
    28: '領取獎勵',
    29: '領取時間為 ',
    30: '天',
    31: '時',
    32: '分',
    33: '秒',
    34: '12月',
    35: '隨存隨取',
    36: '質押到期時間',
    37: '代幣',
  },

  dialog: {
    1: '確認',
    2: '關閉',
    3: '返回',
    4: '編輯',
    5: '保存',
    6: '取消',
    7: '更多',
    8: '授權',
    9: '質押',
    10: '解除質押',
    11: '領取',
    12: '準備好了!',
    13: '恭喜！',
    14: '你获得以下物品',
    15: '售空',
    16: '金額不能小於',
  },
  common: {
    1: '授權',
    2: '質押',
    3: '解除質押',
    4: '一鍵領取',
    5: '正在加載中',
  },
  msg: {
    1: '連接錢包成功',
    2: '連接錢包失敗',
    3: '授權成功',
    4: '授權失敗',
    5: '質押成功',
    6: '質押失敗',
    7: '領取成功',
    8: '領取失敗',
    9: '贖回成功',
    10: '贖回失敗',
    11: '修改成功',
    12: '修改失敗',
    13: '餘額不足',
    14: '交易已提交，區塊校驗中，請稍後再修改',
    15: '複製成功',
    16: '購買成功',
    17: '轉賬成功',
    18: '轉賬失敗',
    19: '你還未持有任何 NFT',
    20: '出售成功',
    21: '出售失敗',
    22: '請先鏈接錢包',
    23: '此 NFT 目前已上架，請確認是否下架。',
    24: '已取消售賣',
    25: '取消下架',
    26: '已掛售',
    27: '掛售失敗',
    28: '已出售',
    29: '您的網絡選擇錯誤',
    30: '掛售金額不可為0',
    31: '網絡不正確',
    32: '暫未開放',
    33: '無效地址',
    34: '打開失敗',
    35: '最多10個',
    36: '已綁定推薦人',
    37: '你還沒有任何NFT',
    38: '6月28號 北京时间 8:00PM 開放',
    39: '購買失敗',
    40: '請輸入地址',
    41: '不能綁定自己',
    42: '該地址無效',
    43: '該地址沒有推薦資格',
    44: '暫無可領取獎勵',
    45: '请手动刷新',
    46: '',
    47: '',
    48: '獎勵領取成功',
    49: '尚未完成所有任務',
    50: '僅對新用戶開放',
    51: '您的獎勵已領取',
    52: '領取成功',
    53: '綁定推薦人成功',
    54: '已成功切換主網',
    55: '請檢查你的網絡',
    56: '暫未獲得參賽資格',
    57: '暫未有可領取獎勵',
    58: '您的獎勵已領取，無需重複領取。',
    59: '{0}代币添加成功!',
    60: '尚未開放',
    61: '已領完',
    62: '請輸入有效金額',
    63: '該質押週期已經結束',
    64: '正式上線, 即將來臨',
    65: '金額不能小於 40 BVT',
    66: '金額不能小於 50,000 BVG',
    67: '請選擇卡牌',
    68: '跨鏈成功',
    69: '跨鏈失敗',
    70: '跨鏈進行中',
    71: '出租成功',
    72: '續租成功',
    73: '請輸入錢包地址',
    74: '暫未開放出售，請您敬請期待！',
    75: '用戶取消交易',
    76: '交易成功'
  },

  exchangeRecord:{
    1:"兌換記錄",
    2:"Hash",
    3:"消耗創世紀NFT數量",
    4:"獲得牛牛數量",
    5:"時間",
  },

  swap: {
    "1": "創世紀NFT兌換",
    "2": "剩餘兌換牛牛數量",
    "3": "當前持有創世紀NFT數量",
    "4": "請輸入兌換牛牛的數量",
    "5": "確認",
    "6": "兌換記錄",
    "7": "創世紀NFT兌換活動已經結束",
    "8": "尊敬的YOHO創世紀NFT社區和鬥牛遊戲社區會員： ", 
    9: "歡迎參與我們創新激動人心的活動——YOHO創世紀NFT兌換鬥牛遊戲創世牛牛NFT！在這次獨一無二的活動中，您將有機會通過交換YOHO NFT獲得獨特的創世牛牛NFT。讓我們一起探索這個令人期待的交互體驗！",
    "10": "活動規則",
    "11": "1. 總量1000頭創世牛： 我們為這次活動準備了1000頭創世牛，每一頭都是限量發行，具有獨特的價值。<br />2. 每5個YOHO NFT換一個創世牛NFT： 驚喜來了！您只需提供5個YOHO NFT，即可兌換一頭創世牛NFT。這是您唯一的一次機會，別錯過哦！<br />3. 僅此一次機會： 這次活動是獨一無二的，機會僅此一次。我們將嚴格按照先到先得的原則，直到創世牛NFT售罄為止。",
    "12": "兌換步驟",
    "13": "1.收集YOHO NFT： 在活動期間，收集您喜歡的YOHO NFT，確保您有足夠的NFT數量來參與兌換。<br />2.提交兌換申請： 在我們指定的兌換頁面，填寫並提交您的YOHO NFT信息以及您想要兌換的創世牛NFT數量。<br />3.等待確認： 我們的團隊將盡快確認您的申請，確保一切符合活動規則。<br />4.領取創世牛NFT： 一旦您的申請通過，您將收到獨特的創世牛NFT，成為活動中的幸運者之一！",
    "14": "注意事項",
    "15": "1. **請確保您的YOHO NFT信息準確無誤，以避免兌換過程中的不便。<br />2. 兌換成功後，創世牛NFT將被自動轉移至您的NFT錢包。<br />3. 活動期間如有任何疑問，隨時聯繫我們的客戶服務團隊獲取支持。",
    "16": "這是一個獨一無二的機會，讓我們一同見證YOHO創世紀NFT和鬥牛遊戲的碰撞，創造屬於您的不可複製的數字資產。謝謝您的參與，祝您好運！",
    "17": "Hash",
    "18": "消耗創世紀NFT數量",
    "19": "獲得牛牛數量",
    "20": "時間",
    "21": "輸入數量不能為空或0",
    "22": "兌換牛牛所需的創世紀NFT數量不足",
    "23": "輸入的數量不能大於剩餘兌換數量",
    "24": "交易成功",
    "25": "交易失敗"
  }
};
