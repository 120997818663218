import { useAppStore } from '@store/appStore';
import { VOUCHER_CONT } from '@/contracts/polygonAddress';
import useDefaultRpc from '@/contracts/useDefaultRpc';
import { toRaw, reactive } from 'vue';
import { ethers } from 'ethers';
import i18n from '@/utils/i18n.js';
import { tableList, isInitAssetsPage } from '@/views/NFT/AssetsExchange/useAssetsExchange';
import { bpFormat } from '@/utils/bpMath';
import { bpWrite, bpRead } from '@/service/bpAction';
const $t = i18n.global.t;

export default class {
  constructor() {
    this.contract = null;
    this.appStore = {};
    this.itemType = [
      // 普通草料
      {
        id: 1, // id
        amount: 0, // 数量
        image: require('@img/assetsExchange/img-hay-1.png'),
        name: 'assetsExchange.46',
        desc: 'assetsExchange.32', // 简介
        cardType: 'items', // 道具
        check: false,
      },

      // 中级草料
      {
        id: 2, // id
        amount: 0, // 数量
        image: require('@img/assetsExchange/img-hay-2.png'),
        name: 'assetsExchange.47',
        desc: 'assetsExchange.33', // 简介
        cardType: 'items', // 道具
        check: false,
      },

      // 高级草料
      {
        id: 3, // id
        amount: 0, // 数量
        image: require('@img/assetsExchange/img-hay-3.png'),
        name: 'assetsExchange.48',
        desc: 'assetsExchange.34', // 简介
        cardType: 'items', // 道具
        check: false,
      },

      // 普通成长加速包
      {
        id: 4, // id
        amount: 0, // 数量
        image: require('@img/assetsExchange/img-grow-1.png'),
        name: 'assetsExchange.52',
        desc: 'assetsExchange.35', // 简介
        cardType: 'items', // 道具
        check: false,
      },

      // 中级成长加速包
      {
        id: 5, // id
        amount: 0, // 数量
        image: require('@img/assetsExchange/img-grow-2.png'),
        name: 'assetsExchange.53',
        desc: 'assetsExchange.36', // 简介
        cardType: 'items', // 道具
        check: false,
      },

      // 高级成长加速包
      {
        id: 6, // id
        amount: 0, // 数量
        image: require('@img/assetsExchange/img-grow-3.png'),
        name: 'assetsExchange.54',
        desc: 'assetsExchange.37', // 简介
        cardType: 'items', // 道具
        check: false,
      },
      // 普通体力包
      {
        id: 7, // id
        amount: 0, // 数量
        image: require('@img/assetsExchange/img-power-1.png'),
        name: 'assetsExchange.49',
        desc: 'assetsExchange.38', // 简介
        cardType: 'items', // 道具
        check: false,
      },
      // 中级体力包
      {
        id: 8, // id
        amount: 0, // 数量
        image: require('@img/assetsExchange/img-power-2.png'),
        name: 'assetsExchange.50',
        desc: 'assetsExchange.39', // 简介
        cardType: 'items', // 道具
        check: false,
      },

      // 高级体力包
      {
        id: 9, // id
        amount: 0, // 数量
        image: require('@img/assetsExchange/img-power-3.png'),
        name: 'assetsExchange.51',
        desc: 'assetsExchange.40', // 简介
        cardType: 'items', // 道具
        check: false,
      },
      // 皮肤盲盒
      {
        id: 20003, // id
        amount: 0, // 数量
        image: require('@img/assetsExchange/img-box.png'),
        name: 'assetsExchange.55',
        desc: 'assetsExchange.44', // 简介
        cardType: 'items', // 道具
        check: false,
      },
      // 改名卡
      {
        id: 14, // id
        amount: 0, // 数量
        image: require('@img/assetsExchange/img-rename.png'),
        name: 'assetsExchange.56',
        desc: 'assetsExchange.45', // 简介
        cardType: 'items', // 道具
        check: false,
      },
    ];
    this.userList = [];
    this.createContract();
  }

  createContract() {
    this.appStore = useAppStore();
    const signer = useDefaultRpc();

    this.contract = new ethers.Contract(VOUCHER_CONT.address, VOUCHER_CONT.abi, toRaw(signer));
    this.defaultAccount = this.appStore.defaultAccount;
  }

  /**
   * 是否授权
   * @param addr 动作合约
   */
  async isApprovedForAll(addr) {
    const { status, datas } = await bpRead(
      this.contract.isApprovedForAll,
      this.defaultAccount,
      addr
    );
    if (!status) console.log('isApprovedForAll...error...');
    return datas;
  }

  /**
   * 授权
   */
  async setApprovalForAll(addr) {
    const { status } = await bpWrite($t('msg.3'), this.contract.setApprovalForAll, addr, true);
    return status;
  }

  /**
   * 查看所有道具信息
   */
  async checkAllIteminfo() {
    const ids = this.itemType.map((it) => {
      return it.id;
    });
    const addrList = this.itemType.map((it) => {
      return this.defaultAccount;
    });
    console.log('check..', this.contract,ids, addrList);
    const resp = await this.contract.balanceOfBatch(addrList, ids);
    console.log('balanceOfBatch resp',resp)
    // 正式资产兑换券
    tableList[1].children.forEach((it, inx) => {
      it.count = +resp[inx];
      // 如果首次进来，给默认选1个
      if (isInitAssetsPage.value) {
        it.pickedCount = +resp[inx] ? 1 : 0;
      }
    });
    // 其他
    this.itemType.forEach((it, inx) => {
      it.amount = +resp[inx];
    });
    this.userList = this.itemType.filter((it) => it.amount);
    return this.userList;
  }
}
