import { ethers } from 'ethers';

let provider;
let signer;
if (window?.ethereum) {
  provider = new ethers.providers.Web3Provider(window?.ethereum, "any");
  signer = provider.getSigner();
  provider.on('network', (newNetwork, oldNetwork) => {
    // When a Provider makes its initial connection, it emits a "network"
    // event with a null oldNetwork along with the newNetwork. So, if the
    // oldNetwork exists, it represents a changing network
    if (oldNetwork) {
      // window.location.reload();
    }
  });
}
// const rpcProvider = new ethers.providers.JsonRpcProvider(
//   'https://data-seed-prebsc-1-s1.binance.org:8545/'
// );

/* const MyEthers = {};

MyEthers.install = function (app) {
  app.config.globalProperties.$eth = {
    eth: ethers,
    provider,
    signer,
    rpcProvider
  };
};

export default MyEthers; */

// console.log('ether...', ethers);

export { provider, signer, ethers };
